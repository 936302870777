import React, { useState } from 'react'
import CourseLeadfrom from './CourseLeadfrom';

const Enroll = (props) => {
    const [showContactForm, setShowContactForm] = useState(false);

    const toggleContactForm = (props) => {
        setShowContactForm(!showContactForm);
    };

  return (
    <div class="course__enroll-btn">
                                 <button onClick={toggleContactForm} class="e-btn e-btn-7 w-100">Enroll <i class="fa fa-arrow-right"></i></button>
                                 {showContactForm && <div className="popup-container" >
                                                    <div className="popup" style={{marginTop:'props.margintop'}}>
                                                   
                                              
<div class="row">
  <div class="col-lg-10">

  <img  loading="lazy" className='lllog' src="/assets/images/dino.png" alt="" />
  </div>
  <div class="col-lg-2 text-center">
  <button className="close-button" onClick={toggleContactForm}>Close</button>

  </div>
</div>
                                                        <CourseLeadfrom coursename={props.course }/>
                                                    </div>
                                                </div>
                                                }
                              </div>
  )
}

export default Enroll