import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom/dist/umd/react-router-dom.development'
import Header from './Component/Header/Header'
import Footer from './Component/Footer/Footer'
import ScoralTop from './Component/ScoralTop'

const Index = () => {
//   useEffect(() => {
// 		// Declare the script element variable in the component's scope
// 		let externalScript;
	  
// 		const LoadExternalScript = () => {
// 		  externalScript = document.createElement("script");
// 		  externalScript.id = "external";
// 		  externalScript.async = true;
// 		  externalScript.type = "text/javascript";
// 		  externalScript.setAttribute("crossorigin", "anonymous");		  
// 		  externalScript.src = '/assets/js/script.js';
// 		};
// 		LoadExternalScript();
	  
// 		return () => {
// 			 if(!LoadExternalScript){
// // Remove the script element from the DOM
// document.body.appendChild(externalScript);
//        }
		  
		
// 		};
// 	  }, []);
	
  return (
   
    <div className="page-wrapper">
      <ScoralTop/>
        <Header/>
    <Outlet/>
    <Footer/>
    </div>
    
   
  )
}

export default Index