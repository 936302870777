import React from 'react'

const Loder = () => {
  return (
    <div className="loader-container">
      <img src="/assets/Spinner-1s-200px.gif" alt="Loading..." />
    </div>
  )
}

export default Loder
