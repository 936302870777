import React from 'react'
import { Link } from 'react-router-dom'

const OurCommunity = () => {
  return (
   <>
     <section className="page-title style-two">
		<div className="pattern-layer-one" style={{backgroundImage: "url(/assets/images/background/pattern-18.png)"}}></div>
		<div className="cloud-layer" style={{backgroundImage: 'url(/assets/images/background/pattern-19.png)'}}></div>
		<div className="big-letter">Team</div>
    	<div className="auto-container">
			<div className="row clearfix">
				<div className="title-column col-lg-12 col-md-12 col-sm-12">
					<div className="inner-column">
						<h1>Our Community</h1>
						<ul className="page-breadcrumb">
							<li><Link to='/'>home</Link></li>
							<li>Our Community</li>
						</ul>
					</div>
				</div>
				
			</div>
		</div>
    </section>
    <section className="team-page-section">
		<div className="auto-container">
			<div className="row clearfix">
				
				<div className="team-block col-lg-3 col-md-6 col-sm-12">
					<div className="inner-box">
						<div className="image">
							<a href="team.html"><img  loading="lazy" src="/assets/images/resource/team-1.jpg" alt="" /></a>
							<ul className="social-box">
								<li><a href="https://www.facebook.com/" className="fa fa-facebook-f"></a></li>
								<li><a href="https://www.twitter.com/" className="fa fa-twitter"></a></li>
								<li><a href="https://www.linkedin.com/" className="fa fa-linkedin"></a></li>
								<li><a href="https://www.instagram.com/" className="fa fa-instagram"></a></li>
							</ul>
						</div>
						<div className="lower-content">
							<h6><a href="team.html">Marcel N. Marsh</a></h6>
							<div className="designation">CEO & Founder</div>
						</div>
					</div>
				</div>
				
				<div className="team-block col-lg-3 col-md-6 col-sm-12">
					<div className="inner-box">
						<div className="image">
							<a href="team.html"><img  loading="lazy" src="/assets/images/resource/team-2.jpg" alt="" /></a>
							<ul className="social-box">
								<li><a href="https://www.facebook.com/" className="fa fa-facebook-f"></a></li>
								<li><a href="https://www.twitter.com/" className="fa fa-twitter"></a></li>
								<li><a href="https://www.linkedin.com/" className="fa fa-linkedin"></a></li>
								<li><a href="https://www.instagram.com/" className="fa fa-instagram"></a></li>
							</ul>
						</div>
						<div className="lower-content">
							<h6><a href="team.html">Nearles S. Kwok</a></h6>
							<div className="designation">Senior Manager</div>
						</div>
					</div>
				</div>
				
				<div className="team-block col-lg-3 col-md-6 col-sm-12">
					<div className="inner-box">
						<div className="image">
							<a href="team.html"><img  loading="lazy" src="/assets/images/resource/team-3.jpg" alt="" /></a>
							<ul className="social-box">
								<li><a href="https://www.facebook.com/" className="fa fa-facebook-f"></a></li>
								<li><a href="https://www.twitter.com/" className="fa fa-twitter"></a></li>
								<li><a href="https://www.linkedin.com/" className="fa fa-linkedin"></a></li>
								<li><a href="https://www.instagram.com/" className="fa fa-instagram"></a></li>
							</ul>
						</div>
						<div className="lower-content">
							<h6><a href="team.html">James W. Ellerbe</a></h6>
							<div className="designation">Web Developer</div>
						</div>
					</div>
				</div>
				
				<div className="team-block col-lg-3 col-md-6 col-sm-12">
					<div className="inner-box">
						<div className="image">
							<a href="team.html"><img  loading="lazy" src="/assets/images/resource/team-4.jpg" alt="" /></a>
							<ul className="social-box">
								<li><a href="https://www.facebook.com/" className="fa fa-facebook-f"></a></li>
								<li><a href="https://www.twitter.com/" className="fa fa-twitter"></a></li>
								<li><a href="https://www.linkedin.com/" className="fa fa-linkedin"></a></li>
								<li><a href="https://www.instagram.com/" className="fa fa-instagram"></a></li>
							</ul>
						</div>
						<div className="lower-content">
							<h6><a href="team.html">Michael N. Gomer</a></h6>
							<div className="designation">Apps Designer</div>
						</div>
					</div>
				</div>
				
				<div className="team-block col-lg-3 col-md-6 col-sm-12">
					<div className="inner-box">
						<div className="image">
							<a href="team.html"><img  loading="lazy" src="/assets/images/resource/team-5.jpg" alt="" /></a>
							<ul className="social-box">
								<li><a href="https://www.facebook.com/" className="fa fa-facebook-f"></a></li>
								<li><a href="https://www.twitter.com/" className="fa fa-twitter"></a></li>
								<li><a href="https://www.linkedin.com/" className="fa fa-linkedin"></a></li>
								<li><a href="https://www.instagram.com/" className="fa fa-instagram"></a></li>
							</ul>
						</div>
						<div className="lower-content">
							<h6><a href="team.html">Marcel N. Marsh</a></h6>
							<div className="designation">CEO & Founder</div>
						</div>
					</div>
				</div>
				
				<div className="team-block col-lg-3 col-md-6 col-sm-12">
					<div className="inner-box">
						<div className="image">
							<a href="team.html"><img  loading="lazy" src="/assets/images/resource/team-6.jpg" alt="" /></a>
							<ul className="social-box">
								<li><a href="https://www.facebook.com/" className="fa fa-facebook-f"></a></li>
								<li><a href="https://www.twitter.com/" className="fa fa-twitter"></a></li>
								<li><a href="https://www.linkedin.com/" className="fa fa-linkedin"></a></li>
								<li><a href="https://www.instagram.com/" className="fa fa-instagram"></a></li>
							</ul>
						</div>
						<div className="lower-content">
							<h6><a href="team.html">Nearles S. Kwok</a></h6>
							<div className="designation">Senior Manager</div>
						</div>
					</div>
				</div>
				
				<div className="team-block col-lg-3 col-md-6 col-sm-12">
					<div className="inner-box">
						<div className="image">
							<a href="team.html"><img  loading="lazy" src="/assets/images/resource/team-7.jpg" alt="" /></a>
							<ul className="social-box">
								<li><a href="https://www.facebook.com/" className="fa fa-facebook-f"></a></li>
								<li><a href="https://www.twitter.com/" className="fa fa-twitter"></a></li>
								<li><a href="https://www.linkedin.com/" className="fa fa-linkedin"></a></li>
								<li><a href="https://www.instagram.com/" className="fa fa-instagram"></a></li>
							</ul>
						</div>
						<div className="lower-content">
							<h6><a href="team.html">James W. Ellerbe</a></h6>
							<div className="designation">Web Developer</div>
						</div>
					</div>
				</div>
				
				<div className="team-block col-lg-3 col-md-6 col-sm-12">
					<div className="inner-box">
						<div className="image">
							<a href="team.html"><img  loading="lazy" src="/assets/images/resource/team-8.jpg" alt="" /></a>
							<ul className="social-box">
								<li><a href="https://www.facebook.com/" className="fa fa-facebook-f"></a></li>
								<li><a href="https://www.twitter.com/" className="fa fa-twitter"></a></li>
								<li><a href="https://www.linkedin.com/" className="fa fa-linkedin"></a></li>
								<li><a href="https://www.instagram.com/" className="fa fa-instagram"></a></li>
							</ul>
						</div>
						<div className="lower-content">
							<h6><a href="team.html">Michael N. Gomer</a></h6>
							<div className="designation">Apps Designer</div>
						</div>
					</div>
				</div>
				
				<div className="team-block col-lg-3 col-md-6 col-sm-12">
					<div className="inner-box">
						<div className="image">
							<a href="team.html"><img  loading="lazy" src="/assets/images/resource/team-9.jpg" alt="" /></a>
							<ul className="social-box">
								<li><a href="https://www.facebook.com/" className="fa fa-facebook-f"></a></li>
								<li><a href="https://www.twitter.com/" className="fa fa-twitter"></a></li>
								<li><a href="https://www.linkedin.com/" className="fa fa-linkedin"></a></li>
								<li><a href="https://www.instagram.com/" className="fa fa-instagram"></a></li>
							</ul>
						</div>
						<div className="lower-content">
							<h6><a href="team.html">Marcel N. Marsh</a></h6>
							<div className="designation">CEO & Founder</div>
						</div>
					</div>
				</div>
				
				<div className="team-block col-lg-3 col-md-6 col-sm-12">
					<div className="inner-box">
						<div className="image">
							<a href="team.html"><img  loading="lazy" src="/assets/images/resource/team-10.jpg" alt="" /></a>
							<ul className="social-box">
								<li><a href="https://www.facebook.com/" className="fa fa-facebook-f"></a></li>
								<li><a href="https://www.twitter.com/" className="fa fa-twitter"></a></li>
								<li><a href="https://www.linkedin.com/" className="fa fa-linkedin"></a></li>
								<li><a href="https://www.instagram.com/" className="fa fa-instagram"></a></li>
							</ul>
						</div>
						<div className="lower-content">
							<h6><a href="team.html">Nearles S. Kwok</a></h6>
							<div className="designation">Senior Manager</div>
						</div>
					</div>
				</div>
				
				<div className="team-block col-lg-3 col-md-6 col-sm-12">
					<div className="inner-box">
						<div className="image">
							<a href="team.html"><img  loading="lazy" src="/assets/images/resource/team-11.jpg" alt="" /></a>
							<ul className="social-box">
								<li><a href="https://www.facebook.com/" className="fa fa-facebook-f"></a></li>
								<li><a href="https://www.twitter.com/" className="fa fa-twitter"></a></li>
								<li><a href="https://www.linkedin.com/" className="fa fa-linkedin"></a></li>
								<li><a href="https://www.instagram.com/" className="fa fa-instagram"></a></li>
							</ul>
						</div>
						<div className="lower-content">
							<h6><a href="team.html">James W. Ellerbe</a></h6>
							<div className="designation">Web Developer</div>
						</div>
					</div>
				</div>
				
				<div className="team-block col-lg-3 col-md-6 col-sm-12">
					<div className="inner-box">
						<div className="image">
							<a href="team.html"><img  loading="lazy" src="/assets/images/resource/team-12.jpg" alt="" /></a>
							<ul className="social-box">
								<li><a href="https://www.facebook.com/" className="fa fa-facebook-f"></a></li>
								<li><a href="https://www.twitter.com/" className="fa fa-twitter"></a></li>
								<li><a href="https://www.linkedin.com/" className="fa fa-linkedin"></a></li>
								<li><a href="https://www.instagram.com/" className="fa fa-instagram"></a></li>
							</ul>
						</div>
						<div className="lower-content">
							<h6><a href="team.html">Michael N. Gomer</a></h6>
							<div className="designation">Apps Designer</div>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	</section>
    <section className="skill-section" style={{backgroundImage: 'url(/assets/images/background/pattern-23.png)'}}>
		<div className="auto-container">
			<div className="row clearfix">
				
				<div className="content-column col-lg-6 col-md-12 col-sm-12">
					<div className="inner-column">
						<div className="sec-title">
							<div className="title">Best Skills</div>
							<h2><span>We’re Profesional and</span> <br/> Experience Team</h2>
							<div className="text">Sedut perspiciatis unde omnis iste natus error voluptate ccusantium doloremque laudantium totam aperiaaqus epsa quae abillo inventore veritatis quase experience team members</div>
						</div>
						<div className="row clearfix">
						
						
							
						</div>
					</div>
				</div>
				
				<div className="images-column col-lg-6 col-md-12 col-sm-12">
					<div className="inner-column parallax-scene-1">
						<div className="image" data-depth="0.20">
							<img  loading="lazy" src="/assets/images/resource/skill.png" alt="" />
						</div>
					</div>
				</div>
				
			</div>
		</div>
    </section>
    <section className="cta-section" style={{backgroundImage: 'url(/assets/images/background/1.jpg)'}}>
		<div className="pattern-layer" style={{backgroundImage: "url(/assets/images/background/pattern-6.png)"}}></div>
		<div className="auto-container">
			<div className="content-box wow rollIn" data-wow-delay="0ms" data-wow-duration="1500ms">
				<div className="sec-title light">
					<div className="title">Need a Project ?</div>
					<h2><span>Have Any Projects</span> <br/> On Mind! Say Hi</h2>
				</div>
				<div className="button-box">
					<a href="contact.html" className="theme-btn btn-style-three"><span className="txt">Contact Us <i className="fa fa-angle-right"></i></span></a>
				</div>
			</div>
		</div>
	</section>
   </>
  )
}

export default OurCommunity