import { Children, useEffect } from 'react';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Index from './pages/Index';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import OurCommunity from './pages/OurCommunity/OurCommunity';

import Contact from './pages/Contact/Contact-us';
import TermsandConditions from './pages/policy/TermsandConditions';
import PrivacyPolicy from './pages/policy/PrivacyPolicy';
import Notfound from './pages/notfound';
import Self_paced_training from './pages/Ourcourse/Self-paced-training/Self_paced_training';
import Live_classes from './pages/Ourcourse/Live-classes/Live_classes';
import Live_admin_training from './pages/Ourcourse/Live-classes/Live-admin-training/Live_admin_training';
import Live_Development_Training from './pages/Ourcourse/Live-classes/Live-development-training/Live_Development_Training';
import Live_lwc_training from './pages/Ourcourse/Live-classes/Live-lwc-training/Live_lwc_training';
import Admin_and_app_builder from './pages/Ourcourse/Live-classes/Live-admin-training/Admin-and-app-builder/Admin_and_app_builder';
import Salesforce_Business_Analyst_Training from './pages/Ourcourse/Live-classes/Live-admin-training/Business-analyst/Salesforce_Business_Analyst_Training';
import Salesforce_Administrator_Certification from './pages/Ourcourse/Self-paced-training/Administrator-certification/Salesforce_Administrator_Certification';
import Pd_2 from './pages/Ourcourse/Self-paced-training/administrator-and-pd-2/pd_2';
import Pd_1 from './pages/Ourcourse/Self-paced-training/Administrator-and-pd-1/Pd_1';
import Salesforce_Complete_Course from './pages/Ourcourse/Live-classes/Salesforce-Complete-Course/Salesforce_Complete_Course';
import P_dev_1 from './pages/Ourcourse/Live-classes/Live-development-training/Platefrom-dev-1/P_dev_1';
import P_dev_2 from './pages/Ourcourse/Live-classes/Live-development-training/Platefrom-dev-2/P_dev_2';
import Faq from './pages/Faq/Faq';
import Salesforce_Corporate_Training from './pages/Ourcourse/Live-classes/Salesforce-Corporate-Training/Salesforce_Corporate_Training';

import Refundpolicy from './pages/policy/Refundpolicy';
import Downloadbrochor from './pages/Home/component/Downloadbrochor';
import Singlepage from './pages/Singlepage/Singlepage';
import Thankyou from './pages/Thankyou';
import Course_Slug from './pages/Ourcourse/Slug/Course_Slug';
import Category_Slug from './pages/Ourcourse/Slug/Category_slug';
import Courses from './pages/Ourcourse/courses/Courses';
import Softwaretestingtrainingcourse from './pages/Singlepage/Softwaretestingtrainingcourse';
import Python from './pages/Singlepage/Python';







function App() {
	
	// const script = document.createElement('script');
  //   let url ='/assets/js/script.js';
  //   script.src = url;   //(This is external js url)
  //   script.async = true;
  //   document.body.appendChild(script);
	
	useEffect(() => {
		// Declare the script element variable in the component's scope
		let externalScript;
	  
		const LoadExternalScript = () => {
		  externalScript = document.createElement("script");
		  externalScript.id = "external";
		  externalScript.async = true;
		  externalScript.type = "text/javascript";
		  externalScript.setAttribute("crossorigin", "anonymous");		  
		  externalScript.src = '/assets/js/script.js';
		};
		LoadExternalScript();
	  
		return () => {
			 
		  // Remove the script element from the DOM
		  document.body.appendChild(externalScript);
		
		};
	  }, []);
	
	  
	//Update Header Style and Scroll to Top
const router = createBrowserRouter([
  {
    path:'/',
    element:<Index/>,

    children:[{
      path:'/',
      element:<Home/>
    },
    {
      path:'/about-dynopat',
      element:<About/>
    },
    {
      path:'/our-community',
      element:<OurCommunity/>
    },
    
    {
      path: '/contact',
      element:<Contact/>
    },


    {
      path:'/downloadbrochure/',
      element: <Downloadbrochor/>
    },
    {
      path:'/downloadbrochure/:slug',
      element: <Downloadbrochor/>
    },







    

    {
      path:'/terms-and-conditions',
      element:<TermsandConditions/>
    },
    {
      path:'/privacy-policy',
      element:<PrivacyPolicy/>
    },
    {
      path:'/refund-policy',
      element:<Refundpolicy/>
    },
    {
      path:'/faq',
      element:<Faq/>
    },
  







    {
      path:'/courses',
      element: <Courses/>
    },
    {
      path:'/courses/:slug/',
      element: <Category_Slug/>,
    
       
        
    },
    {  path:'/courses/:catslug/:slug',
    element: <Course_Slug />},



    {
      path:'/learn-salesforce/self-paced-training/',
      element: <Self_paced_training/>
    },
                    {
                      path:'/learn-salesforce/self-paced-training/administrator-certification/',
                      element: <Salesforce_Administrator_Certification />
                    },
                    {
                      path:'/learn-salesforce/self-paced-training/administrator-and-pd1/',
                      element: <Pd_1/>
                    },
                    {
                      path:'/learn-salesforce/self-paced-training/administrator-and-pd2/',
                      element: <Pd_2/>
                    },
                    {
                      path:'/learn-salesforce/self-paced-training/:slug/',
                      element: <Category_Slug type='self-paced-training'/>,
                    
                       
                        
                    },
                    {  path:'/learn-salesforce/self-paced-training/:catslug/:slug',
                    element: <Course_Slug />},
                    
    {
      path:'/learn-salesforce/live-classes/',
      element: <Live_classes/>
    },
    {
      path:'/learn-salesforce/live-classes/:slug/',
      element: <Category_Slug type='live-classes'/>,
    },
    {  path:'/learn-salesforce/live-classes/:catslug/:slug',
    element: <Course_Slug />},
    {
      path:'learn-salesforce/live-classes/live-admin-training/',
      element: <Live_admin_training/>
    },
                  {
                    path:'/learn-salesforce/live-classes/live-admin-training/admin-and-app-builder/',
                    element: <Admin_and_app_builder/>
                  },
                  {
                    path:'/learn-salesforce/live-classes/live-admin-training/business-analyst/',
                    element: <Salesforce_Business_Analyst_Training/>
                  },

    {
      path:'/learn-salesforce/live-classes/live-development-training/',
      element: <Live_Development_Training/>
    },
                  {
                    path:'/learn-salesforce/live-classes/live-development-training/pd1/',
                    element: <P_dev_1/>
                  },
                  {
                    path:'/learn-salesforce/live-classes/live-development-training/pd2/',
                    element: <P_dev_2/>
                  },
    {
      path:'/learn-salesforce/live-classes/live-lwc-training/',
      element: <Live_lwc_training/>
    },
    {
      path:'/learn-salesforce/live-classes/salesforce-full-course/',
      element: <Salesforce_Complete_Course/>
    },
    {
      path:'/learn-salesforce/live-classes/salesforce-corporate-training/',
      element: <Salesforce_Corporate_Training/>
    },
    {
      path:'/thankyou',
      element: <Thankyou/>
    },
    ]
  },
  {
path:'*',
element:<Notfound/>
  },
  {
    path:'/salesforce-free-demo-class',
    element:<Singlepage/>
  },
  {
    path:'/software-testing-training-course',
    element:<Softwaretestingtrainingcourse/>
  },
  {
    path:'/python-training-course',
    element:<Python/>
  },
])
	  
    return ( 
        <>
        <RouterProvider router={router}/>
        </>
    );
}

export default App;
