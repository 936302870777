import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import Conactfrom from '../compunent/Conactfrom';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
const Courses = () => {
	const navigator=useNavigate();
   const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://dynopat.com/dynopat_admin/api/category/cat');
      
      setData(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  return (
    <>
    
			<Helmet>
			<title>IT Training Online Course with Certificate | DynoPat</title>
         <meta name="description" content="A business analyst will analyze business requirements & create documents. Join our Salesforce Business Analyst certification training program to learn more." />
        	<meta name="keywords" content="Salesforce Live Training, Salesforce Business Analyst Certification Training" />
        
        {/* Open Graph / Facebook */}
  <meta property="og:type" content="website" />
  <meta
    property="og:url"
    content="https://dynopat.com/courses/"
  />
  <meta
    property="og:title"
    content="Salesforce Live Training | Salesforce Business Analyst Certification Training"
  />
  <meta
    property="og:description"
    content="A business analyst will analyze business requirements & create documents. Join our Salesforce Business Analyst certification training program to learn more."
  />
  <meta
    property="og:image"
    content="https://dynopat.com/assets/images/Live%20Class%20Image.png"
  />
  {/* Twitter */}
  <meta property="twitter:card" content="summary_large_image" />
  <meta
    property="twitter:url"
    content="https://dynopat.com/courses/"
  />
  <meta
    property="twitter:title"
    content="Salesforce Live Training | Salesforce Business Analyst Certification Training"
  />
  <meta
    property="twitter:description"
    content="A business analyst will analyze business requirements & create documents. Join our Salesforce Business Analyst certification training program to learn more."
  />
  <meta
    property="twitter:image"
    content="https://dynopat.com/assets/images/Live%20Class%20Image.png"
  />
              <link rel="canonical" href="https://dynopat.com/courses/" />
      		</Helmet>
     
        <section className="page-title style-three">
        
		<div className="pattern-layer-one" style={{backgroundImage: "url(/</section>assets/images/background/pattern-18.png)"}}></div>
		<div className="cloud-layer" style={{backgroundImage: "url(/</section>assets/images/background/pattern-19.png)"}}></div>
		<div className="big-letter">Dynopat</div>
    	<div className="auto-container">
			<div className="row clearfix">

				<div className="title-column col-lg-6 col-md-12 col-sm-12">
					<div className="inner-column">
						<h1>Dynopat Courses</h1>
						<ul className="page-breadcrumb">
							<li><Link to='/'>home</Link></li>
							<li>Courses</li>
						</ul>
					</div>
				</div>

				<div className="images-column col-lg-6 col-md-12 col-sm-12">
					<div className="inner-column">
						<div className="image">
							<img  loading="lazy" src="/assets/images/jhgk.png" alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
    </section>
    
    <div className="sidebar-page-container">
    	<div className="auto-container">
        	<div className="row clearfix">
            
           

                <div className="content-side col-lg-8 col-md-12 col-sm-12">
					<div className="service-detail">

               <div className='text-center'>
            <div className="row pb-5">
         <div className="col-xxl-12">
            <div className="course__tab-conent">
               <div className="tab-content" id="courseTabContent">
                  <div className="tab-pane fade active show" id="grid" role="tabpanel" aria-labelledby="grid-tab">
                     <div className="row">
   
                       
                        {data.map(item => item.category_type === 2 &&(

<><div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6" key={item.catagory_slug}>
<div className="course__item white-bg mb-30 fix">
   <div className="course__thumb w-img p-relative fix">
     
   <Link  to={`/courses/${item.catagory_slug}`}>
         <img  loading="lazy" src={`https://dynopat.com/dynopat_admin/storage/app/public/CateImg/${item.cover_image}`} alt=""/>
      </Link>
   </div>
   <div className="course__content">
      <div className="course__meta d-flex align-items-center justify-content-between">
        
      </div>
      <h3 className="course__title"><Link to={`/courses/${item.catagory_slug}`}>{item.catagory_name}</Link></h3>
    
      <div className="course__meta d-flex align-items-center justify-content-between">
          <h6 className="blue-2">₹{item.catagory_Price}</h6> 

        <h6 className="old-price">₹{parseFloat(item.catagory_Price) + (item.catagory_Price * 0.20) }</h6> 
        </div>
   </div>
   <div className="course__more d-flex justify-content-between align-items-center">
      <div className="course__status d-flex align-items-center">
       
         <Link to={`/courses/${item.catagory_slug}`}className="link-btn">
            Know Details 
       
         </Link>
      </div>
      <div className="course__btn">
      <Link to={`/courses/${item.catagory_slug}`}className="link-btn">
           
            <i className="fa fa-arrow-right"></i>
         </Link>
      </div>
   </div>
</div>
</div></>
))}
                     </div>
                  </div>
                 
                </div>
            </div>
         </div>
                        </div>
            </div>
						
				
   

					</div>
				 </div>
				

                <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
                	<aside className="sidebar sticky-top">
						
		
						
				<div className="sidebar-widget service-widget">
					
                  <div className="widget-content">
				  <h3 className='pb-2 text-center'>FAQs</h3>
                    <div className="accordion" id="accordionExample">
						
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          What will be the duration of the class?
                          </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p className='text'>The classes will run for an hour, usually from 9.30 PM EST/ 7 AM IST (the time may change based on your timezone).  </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          When will you start a new batch?
                          </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p className='text'>We are running free 1-week demo sessions for our upcoming batch right now. whenever we start a new batch, we will show it on the website and on social media. If you cannot register for that batch, you can keep an eye on social media for the next announcement. </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          Will I get 1:1 mentoring?
                          </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p className='text'>Yes, we are here to provide 1:1 mentoring to all our students. You can ask your questions and doubts to our instructors. We will ensure that you get prompt responses from your trainers. 
 </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingfour">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                          Do I need any previous experience?
                          </button>
                        </h2>
                        <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p className='text'>No, you don’t need any previous experience, but an understanding of OOPS and programming languages like Java may help if you want to enroll in Salesforce Developer Bootcamp. 
 </p>
                          </div>
                        </div>
                      </div>
                     
                    </div>

                  </div>
                </div>

						{/* <div className="sidebar-widget contact-widget">
							<div className="widget-content" style={{backgroundImage: "url(/assets/images/background/pattern-25.png)"}}>
								<div className="title">
									Have Any Project <span>or work together ?</span>
								</div>
								<a href="contact.html" className="theme-btn contact-btn">Contact us</a>
								<div className="phone">
									<span className="icon flaticon-call-1"></span>
									Cal :<a href="tel:+0123-456-7899"> +0123 (456) 7899</a>
								</div>
							</div>
						</div> */}
						<Conactfrom/>
						
					
						{/* <div className="sidebar-widget broucher-widget">
							<div className="widget-content">
								<ul className="broucher-list">
									<li><a href="#"><span className="icon flaticon-pdf"></span>Download pdf</a></li>
									<li><a href="#"><span className="icon flaticon-edit"></span>Download doc.</a></li>
								</ul>
							</div>
						</div> */}
						
					</aside>
				</div>
				
			</div>
		</div>
	</div>
    </>
  )
}

export default Courses