import React, { useState } from 'react'
import '../../Style/style.css'
import { Link } from 'react-router-dom'
import Contactfrom from '../../compunent/Conactfrom'
import Enroll from '../../compunent/Enroll'
import { Helmet } from 'react-helmet-async'
import Claimyourfreecourse from '../../compunent/Claimyourfreecourse'
function Live_lwc_training() {
   const [showpopup, setShowpopup] = useState(false);
	const [selectedVideoUrl, setSelectedVideoUrl] = useState('');
    const toggleshowpopup= (videoUrl) => {
		setSelectedVideoUrl(videoUrl);
        setShowpopup(!showpopup);
    };
	const url ="https://www.youtube.com/embed/R5Mg0rnjZ-c?si=FBWsRtgqBcDp6Ziq";
	
  return (
   
   <>
   <Helmet>
   <title>Salesforce LWC Training | Salesforce Lightning Training</title>
     <meta name="description" content="If you are familiar with Javascript & are interested in learning Salesforce frontend development, then you must enroll in our Salesforce LWC training." />
     <meta name="keywords" content="Salesforce LWC Training, Salesforce Lightning Training" />
      {/* Open Graph / Facebook */}
  <meta property="og:type" content="website" />
  <meta
    property="og:url"
    content="https://dynopat.com/learn-salesforce/live-classes/live-lwc-training/"
  />
  <meta
    property="og:title"
    content="Salesforce LWC Training | Salesforce Lightning Training"
  />
  <meta
    property="og:description"
    content="If you are familiar with Javascript & are interested in learning Salesforce frontend development, then you must enroll in our Salesforce LWC training."
  />
  <meta
    property="og:image"
    content="https://dynopat.com/assets/images/lernselsforcecourse/live/lwc/lwc.webp"
  />
  {/* Twitter */}
  <meta property="twitter:card" content="summary_large_image" />
  <meta
    property="twitter:url"
    content="https://dynopat.com/learn-salesforce/live-classes/live-lwc-training/"
  />
  <meta
    property="twitter:title"
    content="Salesforce LWC Training | Salesforce Lightning Training"
  />
  <meta
    property="twitter:description"
    content="If you are familiar with Javascript & are interested in learning Salesforce frontend development, then you must enroll in our Salesforce LWC training."
  />
  <meta
    property="twitter:image"
    content="https://dynopat.com/assets/images/lernselsforcecourse/live/lwc/lwc.webp"
  />
        <link rel="canonical" href="https://dynopat.com/learn-salesforce/live-classes/live-lwc-training/" />
      </Helmet>

    <section className="page__title-area pt-120 pb-90">
      
            <div className="page__title-shape">
               <img  loading="lazy" className="page-title-shape-5 d-none d-sm-block" src="/assets/img/page-title/page-title-shape-1.png" alt="" />
               <img  loading="lazy" className="page-title-shape-6" src="/assets/img/page-title/page-title-shape-6.png" alt="" />
               <img  loading="lazy" className="page-title-shape-7" src="/assets/img/page-title/page-title-shape-4.png" alt="" />
            </div>
            <div className="container">
               <div className="row">
                  <div className="col-xxl-8 col-xl-8 col-lg-8">
                     <div className="course__wrapper">
                        <div className="page__title-content mb-25">
                           <div className="page__title-breadcrumb">
                              <nav aria-label="breadcrumb">
                                 <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Live Lwc Training</li>
                                 </ol>
                              </nav>
                           </div>
                           <span className="page__title-pre">Live Lwc Training</span>
                           <h5 className="page__title-3">Salesforce LWC Bootcamp</h5>
                        </div>
                        {/* <div className="course__meta-2 d-sm-flex mb-30">
                           <div className="course__teacher-3 d-flex align-items-center mr-70 mb-30">
                              <div className="course__teacher-thumb-3 mr-15">
                                 <img  loading="lazy" src="/assets/img/course/teacher/teacher-1.jpg" alt="" />
                              </div>
                              <div className="course__teacher-info-3">
                                 <h5>Teacher</h5>
                                 <p><a href="#">Elon Gated</a></p>
                              </div>
                           </div>
                           <div className="course__update mr-80 mb-30">
                              <h5>Last Update:</h5>
                              <p>July 24, 2022</p>
                           </div>
                           <div className="course__rating-2 mb-30">
                              <h5>certification:</h5>
                              <div className="course__rating-inner d-flex align-items-center">
                                 <ul>
                                    <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                    <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                    <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                    <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                    <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                 </ul>
                                 <p>4.5</p>
                              </div>
                           </div>
                        </div> */}
                        <div className="course__img w-img mb-30">
                           <img  loading="lazy" src="/assets/images/lernselsforcecourse/live/lwc/lwc.webp" alt="" />
                        </div>
                        <div className="course__tab-2 mb-45 pb-3">
                           <ul className="nav nav-tabs" id="courseTab" role="tablist">
                              <li className="nav-item" role="presentation">
                                 <button className="nav-link active" id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview" type="button" role="tab" aria-controls="overview" aria-selected="true"> <i className="fa fa-bookmark"></i> <span>Overview</span> </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                 <button className="nav-link " id="curriculum-tab" data-bs-toggle="tab" data-bs-target="#curriculum" type="button" role="tab" aria-controls="curriculum" aria-selected="false"> <i className="fa fa-book"></i> <span>Curriculum</span> </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                 <button className="nav-link" id="certification-tab" data-bs-toggle="tab" data-bs-target="#certification" type="button" role="tab" aria-controls="certification" aria-selected="false"> <i className="fa fa-star"></i> <span>Certification</span> </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                 <button className="nav-link" id="faq-tab" data-bs-toggle="tab" data-bs-target="#faq" type="button" role="tab" aria-controls="faq" aria-selected="false"> <i className="fa fa-users"></i> <span>FAQs</span> </button>
                              </li>
                           </ul>
                        </div>
                        <div className="course__tab-content mb-95">
                           <div className="tab-content" id="courseTabContent">
                              <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                                 <div className="course__overview">
                                    <h3>Overview</h3>
                                    <p>Do you want to boost your career in the IT industry by showing your Salesforce specialization? Are you interested in Salesforce frontend development? Then Salesforce LWC (Lightning Web Components) is designed specifically for you. Our training program is created to equip you with all the necessary skills you need to work with LWC framework. This framework is mainly used to create single-page web applications to offer end-to-end solutions for your business customers. 

Who can enroll in this course? If you have experience as a Salesforce developer/ software. Web application developer, or a Salesforce Administrator, you are perfect for this course. People interested in learning LWC and its applications are welcome to join. You can understand the course content better if you have experience in HTML5, CSS3, JavaScript, RDBMS/ SQL, or Cloud. 

After going through our training, you can decide to prepare for the Salesforce LWC certification exam. You will gain the confidence to ace interviews for this profile whenever you apply for this profile.    
</p>



                                    <div className='service-detail'>
            <div className='inner-box'>
                <div className="blocks-outer">
                    <div className="row clearfix">


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>Salesforce LWC Training </h5>
                                {/* <div className="block-text">We offer personalized, interactive, & detailed Salesforce Admin & Development courses to students</div> */}
                            </div>
                        </div>


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>Learn Salesforce Frontend Development </h5>
                                {/* <div className="block-text">Ask your doubts about Salesforce to your instructor during the session.</div> */}
                            </div>
                        </div>


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>1: 1 Mentoring</h5>
                                {/* <div className="block-text">Apply your theoretical knowledge of Salesforce in real-time and check your progress.</div> */}
                            </div>
                        </div>


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>Live projects</h5>
                                {/* <div className="block-text">Regular 1-hour classes for 5 days a week, from Monday to Friday.</div> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>


                                 </div>
                              </div>
                              <div className="tab-pane fade" id="curriculum" role="tabpanel" aria-labelledby="curriculum-tab">
                                 <div className="course__curriculum">
                                    <div className="accordion" id="course__accordion">
                                       <div className="accordion-item mb-2">
                                          <h2 className="accordion-header" id="week-01">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-01-content" aria-expanded="true" aria-controls="week-01-content">
                                             Introduction of LWC (Lightning Web Components) 
                                             </button>
                                          </h2>
                                          <div id="week-01-content" className="accordion-collapse collapse show " aria-labelledby="week-01" data-bs-parent="#course__accordion">
                                             <div className="accordion-body">
                                                <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                   <div className="course__curriculum-info">
                                                      <ul>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to sign up for the developer edition? </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to set up Visual Studio code and Salesforce CLI?  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Hello world Trailhead </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Overview of LWC components & LWC playground </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Hello world Trailhead </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Learning VS Code </span> </h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Fundamentals of web components</span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>SFDX commands </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Libraries  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Styling your web components </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Data binding </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Basic HTML </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Loops and iterations   </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>What are web component lifecycle hooks? </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Web component composition </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Metadata  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>When to choose Aura or LWC?  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to choose between Aura and LWC?   </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Fields & properties  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Component accessibility  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Difference between Aura & LWC   </span></h3></li>
                                                      </ul>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="accordion" id="course__accordion-2">
                                       <div className="accordion-item mb-50">
                                          <h2 className="accordion-header" id="week-02">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-02-content" aria-expanded="true" aria-controls="week-02-content">
                                             Fundamentals of Javascript
                                             </button>
                                          </h2>
                                          <div id="week-02-content" className="accordion-collapse  collapse " aria-labelledby="week-02" data-bs-parent="#course__accordion-2">
                                             <div className="accordion-body">
                                                <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                <div className="course__curriculum-info">
                                                      <ul>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span> Private & Public Properties  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Getter </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to share a JavaScript code between LWC and Aura?   </span></h3></li>
                                                      </ul>
                                                      </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="accordion" id="course__accordion-3">
                                       <div className="accordion-item mb-50">
                                          <h2 className="accordion-header" id="week-03">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-03-content" aria-expanded="true" aria-controls="week-03-content">
                                             Events
                                             </button>
                                          </h2>
                                          <div id="week-03-content" className="accordion-collapse  collapse " aria-labelledby="week-03" data-bs-parent="#course__accordion-3">
                                             <div className="accordion-body">
                                                <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                <div className="course__curriculum-info">
                                                      <ul>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to create, handle, & dispatch events? </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to configure event propagation? t</span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Best practices for events </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to communicate between parent-child, & child-parent components? </span></h3></li>
                                                      </ul>
                                                      </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="accordion" id="course__accordion-4">
                                       <div className="accordion-item mb-50">
                                          <h2 className="accordion-header" id="week-04">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-04-content" aria-expanded="true" aria-controls="week-04-content">
                                             How to Access Salesforce Data? 
                                             </button>
                                          </h2>
                                          <div id="week-04-content" className="accordion-collapse  collapse " aria-labelledby="week-04" data-bs-parent="#course__accordion-4">
                                             <div className="accordion-body">
                                                <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                <div className="course__curriculum-info">
                                                      <ul>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>What are data guidelines?</span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Method calling using wire </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Method calling using imperative</span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Error handling </span></h3></li>

                                                      </ul>
                                                      </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="accordion" id="course__accordion-5">
                                       <div className="accordion-item mb-50">
                                          <h2 className="accordion-header" id="week-05">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-05-content" aria-expanded="true" aria-controls="week-05-content">
                                             Visualforce 
                                             </button>
                                          </h2>
                                          <div id="week-05-content" className="accordion-collapse  collapse " aria-labelledby="week-05" data-bs-parent="#course__accordion-5">
                                             <div className="accordion-body">
                                                <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                <div className="course__curriculum-info">
                                                      <ul>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Controllers </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Partial page refresh </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Best practices for showing errors </span></h3></li>

                                                      </ul>
                                                      </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="accordion" id="course__accordion-6">
                                       <div className="accordion-item mb-50">
                                          <h2 className="accordion-header" id="week-06">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-06-content" aria-expanded="true" aria-controls="week-06-content">
                                             Salesforce Targets  
                                             </button>
                                          </h2>
                                          <div id="week-06-content" className="accordion-collapse  collapse" aria-labelledby="week-06" data-bs-parent="#course__accordion-6">
                                             <div className="accordion-body">
                                                <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                <div className="course__curriculum-info">
                                                      <ul>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Lightning App Builder </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Flow </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Quick Action </span></h3></li>
                                                        
                                                      </ul>
                                                      </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="accordion" id="course__accordion-7">
                                       <div className="accordion-item mb-50">
                                          <h2 className="accordion-header" id="week-07">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-07-content" aria-expanded="true" aria-controls="week-07-content">
                                             Team Management 
                                             </button>
                                          </h2>
                                          <div id="week-07-content" className="accordion-collapse  collapse" aria-labelledby="week-07" data-bs-parent="#course__accordion-7">
                                             <div className="accordion-body">
                                                <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                <div className="course__curriculum-info">
                                                      <ul>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Queues</span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Public Group </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Features</span></h3></li>
                                                        
                                                      </ul>
                                                      </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="accordion" id="course__accordion-8">
                                       <div className="accordion-item mb-50">
                                          <h2 className="accordion-header" id="week-08">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-08-content" aria-expanded="true" aria-controls="week-08-content">
                                             Navigation
                                             </button>
                                          </h2>
                                          <div id="week-08-content" className="accordion-collapse  collapse" aria-labelledby="week-08" data-bs-parent="#course__accordion-8">
                                             <div className="accordion-body">
                                                <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                <div className="course__curriculum-info">
                                                      <ul>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Toast notification </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to design attributes in LWC? </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to navigate in Salesforce? </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to use web components in VisualForce? </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>What are static resources?  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to use the lightning data table? </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to use button groups?</span></h3></li>

                                                        
                                                      </ul>
                                                      </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="accordion" id="course__accordion-9">
                                       <div className="accordion-item mb-50">
                                          <h2 className="accordion-header" id="week-09">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-09-content" aria-expanded="true" aria-controls="week-09-content">
                                             Advanced Salesforce LWC
                                             </button>
                                          </h2>
                                          <div id="week-09-content" className="accordion-collapse  collapse" aria-labelledby="week-09" data-bs-parent="#course__accordion-9">
                                             <div className="accordion-body">
                                                <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                <div className="course__curriculum-info">
                                                      <ul>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Integration </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Synchronous vs asynchronous </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Batch Apex </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Outbound messages </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Queuable  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>SOAP </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>REST  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Bulk </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Streaming </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Canvas  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Workflows </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Community builder  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Community management  </span></h3></li>




                                                        
                                                      </ul>
                                                      </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="accordion" id="course__accordion-10">
                                       <div className="accordion-item mb-50">
                                          <h2 className="accordion-header" id="week-10">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-10-content" aria-expanded="true" aria-controls="week-10-content">
                                             How to migrate bundle files? 
                                             </button>
                                          </h2>
                                          <div id="week-10-content" className="accordion-collapse  collapse" aria-labelledby="week-10" data-bs-parent="#course__accordion-10">
                                             <div className="accordion-body">
                                                <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                <div className="course__curriculum-info">
                                                      <ul>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to migrate bundle files?  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>What are migrate events, markups, and interfaces?   </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to migrate JavaScript, Apex, & CSS?  </span></h3></li>

                                                        
                                                      </ul>
                                                      </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    
                                 </div>
                              </div>
                              <div className="tab-pane fade" id="certification" role="tabpanel" aria-labelledby="certification-tab">
                                 <div className="course__certification">
                                    <h3>Certification</h3>
                                    <p>After completing our Salesforce LWC Bootcamp, you will have first-hand knowledge of LWC components and how they work in real life. You will work on real-life projects to help you prepare for this position. You can also attend our mock interview session to understand what type of questions will be asked in the interview. After completing the assignments, taking the quizzes, and giving the test, you will be eligible for our certificate.  </p>
                                    <img  loading="lazy" className='certificat' src='/assets/images/Certificate-Design.jpg'></img>
                                 </div>
                              </div>
                              <div className="tab-pane fade" id="faq" role="tabpanel" aria-labelledby="faq-tab">
                                 <div className="course__faq mb-45">



                                    <div className="accordion" id="accordionExample">
                                       <div className="accordion-item">
                                          <h2 className="accordion-header" id="headingOne">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                             Can I get opportunities to learn from peers? 
                                             </button>
                                          </h2>
                                          <div id="collapseOne" className="accordion-collapse collapse show " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                             <div className="accordion-body">
                                             <div className="course__curriculum-info">
                                                <strong>We have a community section where you can post your queries and doubts and ask for solutions. Your instructors or your friends/ peers can answer your question or share resources that you can use for learning. Similarly, you can answer others’ questions on this page.  </strong>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="accordion-item">
                                          <h2 className="accordion-header" id="headingTwo">
                                             <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                             What type of projects will I work on? 

                                             </button>
                                          </h2>
                                          <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                             <div className="accordion-body">
                                                <strong>You will learn to develop CRM applications for different industries such as education, banking, real estate, eCommerce, and medical/ hospital. By doing these projects, you will get to know how these industries work, what they want in a CRM, and how to achieve that. The practical knowledge you gain would impart practical skills and how you will be a valuable asset to an organization.  </strong>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="accordion-item">
                                          <h2 className="accordion-header" id="headingThree">
                                             <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                             What are the skills, roles, and responsibilities of a Salesforce developer? 

                                             </button>
                                          </h2>
                                          <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                             <div className="accordion-body">
                                                <strong>According to various job boards, these are skills a Salesforce developer absolutely needs to succeed in his/ her career: </strong>
                                                <ul>

                                                   <li style={{ listStyleType: 'circle', marginLeft: '30px' }}>Proficiency in Salesforce IDE</li>
                                                   <li style={{ listStyleType: 'circle', marginLeft: '30px' }}>Have a Bachelor’s degree or higher </li>
                                                   <li style={{ listStyleType: 'circle', marginLeft: '30px' }}>Have a solid understanding of Apex and other programming languages</li>
                                                   <li style={{ listStyleType: 'circle', marginLeft: '30px' }}>Must be well-versed in LWC (depending on the position)</li>
                                                   <li style={{ listStyleType: 'circle', marginLeft: '30px' }}>Should have excellent communication skills </li>
                                                   <li style={{ listStyleType: 'circle', marginLeft: '30px' }}>A Salesforce developer should be able to understand business problems and provide appropriate solutions  </li>
                                                   <li style={{ listStyleType: 'circle', marginLeft: '30px' }}>The candidate should have the ability to manage multiple projects and teams   </li>


                                                   </ul>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="accordion-item">
                                          <h2 className="accordion-header" id="headingFour">
                                             <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                             Roles and Responsibilities of a Salesforce Developer

                                             </button>
                                          </h2>
                                          <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                             <div className="accordion-body">
                                             <ul>
                                             <li style={{ listStyleType: 'circle', marginLeft: '30px' }}>Salesforce developers should be able to identify business requirements and provide customized solutions </li>
                                             <li style={{ listStyleType: 'circle', marginLeft: '30px' }}>Be present in team meetings, track the progress of projects (from initiation to end), and provide valuable input there </li>
                                             <li style={{ listStyleType: 'circle', marginLeft: '30px' }}>Must delegate the tasks to others for completing the projects on time</li>
                                             <li style={{ listStyleType: 'circle', marginLeft: '30px' }}>Responsible for writing codes, developing, configuring, maintaining security, implementing, and testing the applications  </li>
                                             <li style={{ listStyleType: 'circle', marginLeft: '30px' }}>They ensure that all the best practices are followed for developing and implementing the applications. </li>
                                             </ul>
                                             </div>
                                          </div>
                                       </div>
                                    </div>





                                 </div>
                              </div>

                           </div>
                        </div>
                     </div>
                  </div>
                 <div className="col-xxl-4 col-xl-4 col-lg-4">
                     <div className="course__sidebar pl-70 p-relative">
                        <div className="course__shape">
                           <img  loading="lazy" className="course-dot" src="/assets/img/course/course-dot.png" alt="" />
                        </div>
                        <div className="course__sidebar-widget-2 white-bg mb-20">
                           <div className="course__video" onClick={()=>toggleshowpopup(url)}>
                              <div className="course__video-thumb w-img mb-25">
                                 <img  loading="lazy" src="/assets/images/video.png" alt="" />
                                 <div className="course__video-play">
                                    <a href="#" data-fancybox="" className="play-btn"> <i className="fa fa-play"></i> </a>
                                 </div>
                              </div>
                              <div className="course__video-meta mb-25 d-flex align-items-center justify-content-between">
                                 {/* <div className="course__video-price">
                                    <h5>$74.<span>00</span> </h5>
                                    <h5 className="old-price">$129.00</h5>
                                 </div>
                                 <div className="course__video-discount">
                                    <span>68% OFF</span>
                                 </div> */}
                              </div>
                              <div className="course__video-content mb-35">
                                 <ul>
                                    {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Instructor :</span> Eleanor Fant</h5>
                                       </div>
                                    </li> */}
                                    {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Lectures :</span>14</h5>
                                       </div>
                                    </li> */}
                                    <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Duration :</span>3 weeks</h5>
                                       </div>
                                    </li>
                                    {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Enrolled :</span>20 students</h5>
                                       </div>
                                    </li> */}
                                    <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Language :</span>English</h5>
                                       </div>
                                    </li>
                                 </ul>
                              </div>
                              <div className="course__payment mb-35">
                                 <h3>price: ₹11,000</h3>
                                
                              </div>
                    <Claimyourfreecourse />

                    <button onClick={()=>{window.open('https://rzp.io/l/diHPz5u')}}  class="e-btn e-btn-7 w-100">Enroll <i class="fa fa-arrow-right"></i></button>

                                                  

                           </div>
                        </div>
                       
                        <Contactfrom/>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         {showpopup&& <div className="popup-container">
                    <div className="popups">


                        <div className="row">
                            <div className="col-10">

                                <img className='lllog' src="/assets/images/dino.png" alt="" />
                            </div>
                            <div className="col-2 text-end">
                                <button className="close-button" onClick={toggleshowpopup}>Close</button>

                            </div>
                        </div>
						<iframe src={selectedVideoUrl} width="100%" height="400px" ></iframe>
                    </div>
                </div>
                }

         </>
  )
}

export default Live_lwc_training