import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom/dist/umd/react-router-dom.development';

const DownloadBrochure = () => {
  const { slug } = useParams();
  const [isDownloading, setIsDownloading] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const navigate = useNavigate();

  const handleDownload = () => {
    setIsDownloading(true);
    const fileUrl = `/assets/brochure/${slug}`;
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', slug);
    link.onload = () => {
      setIsDownloading(false);
    };
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    if (isDownloading) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      setTimeout(() => {
        clearInterval(timer);
        navigate('/'); // Redirect to the home page
      }, 5000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [isDownloading, navigate]);

  return (
    <div className="containerbut">
      <div>
        {isDownloading ? (
          <> <p>Downloading start... Redirecting to Home page </p>
          <p className='text-center'><span >{countdown} seconds</span></p></>
         
        ) : (
          <button
            className="buttondd"
            onClick={handleDownload}
            disabled={isDownloading}
          >
            Download PDF
          </button>
        )}
      </div>
    </div>
  );
};

export default DownloadBrochure;
