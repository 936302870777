import React, { useState } from 'react'
import '../../../Style/style.css'
import { Link } from 'react-router-dom'
import Conactfrom from '../../../compunent/Conactfrom'
import Enroll from '../../../compunent/Enroll'
import { Helmet } from 'react-helmet-async'
import Claimyourfreecourse from '../../../compunent/Claimyourfreecourse'

function P_dev_1() {
  const [showpopup, setShowpopup] = useState(false);
	const [selectedVideoUrl, setSelectedVideoUrl] = useState('');
    const toggleshowpopup= (videoUrl) => {
		setSelectedVideoUrl(videoUrl);
        setShowpopup(!showpopup);
    };
	const url ="https://www.youtube.com/embed/J9hfUNT814k?si=37hm3Ktfg9k0g7Bh";
	
  return (
    <>
    
			<Helmet>
			<title>Salesforce Platform Developer 1 Certification Training | Salesforce PD1 Training</title>
        	<meta name="description" content="Your resume score will be higher if you have some real-time projects to showcase. Learn how to work on those projects in our Salesforce PD1 training." />
        	<meta name="keywords" content="Salesforce Platform Developer 1 Certification Training, Salesforce PD1 Training" />
        	{/* Open Graph / Facebook */}
  <meta property="og:type" content="website" />
  <meta
    property="og:url"
    content="https://dynopat.com/learn-salesforce/live-classes/live-development-training/pd1/"
  />
  <meta
    property="og:title"
    content="Salesforce Platform Developer 1 Certification Training | Salesforce PD1 Training"
  />
  <meta
    property="og:description"
    content="Your resume score will be higher if you have some real-time projects to showcase. Learn how to work on those projects in our Salesforce PD1 training."
  />
  <meta
    property="og:image"
    content="https://dynopat.com/assets/images/lernselsforcecourse/live/development/pd1.webp"
  />
  {/* Twitter */}
  <meta property="twitter:card" content="summary_large_image" />
  <meta
    property="twitter:url"
    content="https://dynopat.com/learn-salesforce/live-classes/live-development-training/pd1/"
  />
  <meta
    property="twitter:title"
    content="Salesforce Platform Developer 1 Certification Training | Salesforce PD1 Training"
  />
  <meta
    property="twitter:description"
    content="Your resume score will be higher if you have some real-time projects to showcase. Learn how to work on those projects in our Salesforce PD1 training."
  />
  <meta
    property="twitter:image"
    content="https://dynopat.com/assets/images/lernselsforcecourse/live/development/pd1.webp"
  />
            <link rel="canonical" href="https://dynopat.com/learn-salesforce/live-classes/live-development-training/pd1/" />
      		</Helmet>
     
      <section className="page__title-area pt-120 pb-90">
        <div className="page__title-shape">
          <img  loading="lazy" className="page-title-shape-5 d-none d-sm-block" src="/assets/img/page-title/page-title-shape-1.png" alt="" />
          <img  loading="lazy" className="page-title-shape-6" src="/assets/img/page-title/page-title-shape-6.png" alt="" />
          <img  loading="lazy" className="page-title-shape-7" src="/assets/img/page-title/page-title-shape-4.png" alt="" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-lg-8">
              <div className="course__wrapper">
                <div className="page__title-content mb-25">
                  <div className="page__title-breadcrumb">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Salesforce Administrator & PD1 Certification</li>
                      </ol>
                    </nav>
                  </div>
                  <span className="page__title-pre">Live Development Training</span>
                  <h5 className="page__title-3">Salesforce Administrator & PD1 Certification</h5>
                </div>
                {/* <div className="course__meta-2 d-sm-flex mb-30">
                           <div className="course__teacher-3 d-flex align-items-center mr-70 mb-30">
                              <div className="course__teacher-thumb-3 mr-15">
                                 <img  loading="lazy" src="/assets/img/course/teacher/teacher-1.jpg" alt="" />
                              </div>
                              <div className="course__teacher-info-3">
                                 <h5>Teacher</h5>
                                 <p><a href="#">Elon Gated</a></p>
                              </div>
                           </div>
                           <div className="course__update mr-80 mb-30">
                              <h5>Last Update:</h5>
                              <p>July 24, 2022</p>
                           </div>
                           <div className="course__rating-2 mb-30">
                              <h5>certification:</h5>
                              <div className="course__rating-inner d-flex align-items-center">
                                 <ul>
                                    <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                    <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                    <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                    <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                    <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                 </ul>
                                 <p>4.5</p>
                              </div>
                           </div>
                        </div> */}
                <div className="course__img w-img mb-30">
                  <img  loading="lazy" src="/assets/images/lernselsforcecourse/live/development/pd1.webp" alt="" />
                </div>
                <div className="course__tab-2 mb-45 pb-3">
                  <ul className="nav nav-tabs" id="courseTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview" type="button" role="tab" aria-controls="overview" aria-selected="true"> <i className="fa fa-bookmark"></i> <span>Overview</span> </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link " id="curriculum-tab" data-bs-toggle="tab" data-bs-target="#curriculum" type="button" role="tab" aria-controls="curriculum" aria-selected="false"> <i className="fa fa-book"></i> <span>Curriculum</span> </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="certification-tab" data-bs-toggle="tab" data-bs-target="#certification" type="button" role="tab" aria-controls="certification" aria-selected="false"> <i className="fa fa-star"></i> <span>Certification</span> </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="faq-tab" data-bs-toggle="tab" data-bs-target="#faq" type="button" role="tab" aria-controls="faq" aria-selected="false"> <i className="fa fa-users"></i> <span>FAQs</span> </button>
                    </li>
                  </ul>
                </div>
                <div className="course__tab-content mb-95">
                  <div className="tab-content" id="courseTabContent">
                    <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                      <div className="course__overview">
                        <h3>Overview</h3>
                        <p>Our Live Salesforce Administrator and PD1 certification program will prepare you for the job profiles of Salesforce Platform developer and administrator. This course is for people who want to switch their careers from any other technology, such as Java, Mainframe, etc., to Salesforce. However, freshers can also choose this course if they have the right coding skills. The video modules from Rajesh Chatterjee will help you unlock your full potential and become a qualified Salesforce developer.

                          <br />You can progress through the course by going through the lectures and by doing the assignments and projects. You will have lifetime access to the LMS content (videos and blogs) as well and can view them anytime.
                        </p>



                        <div className='service-detail'>
                          <div className='inner-box'>
                            <div className="blocks-outer">
                              <div className="row clearfix">


                                <div className="block-column col-lg-6 col-md-6 col-sm-12">
                                  <div className="inner-column">
                                    <h5>Classes are taken by an expert lecturer, Rajesh Chatterjee
                                    </h5>
                                    {/* <div className="block-text">In three weeks, we will cover the basic & advanced topics of Salesforce.</div> */}
                                  </div>
                                </div>


                                <div className="block-column col-lg-6 col-md-6 col-sm-12">
                                  <div className="inner-column">
                                    <h5>Flexible scheduling </h5>
                                    {/* <div className="block-text">Develop CRM projects with advanced features and functionalities</div> */}
                                  </div>
                                </div>


                                <div className="block-column col-lg-6 col-md-6 col-sm-12">
                                  <div className="inner-column">
                                    <h5>Certification </h5>
                                    {/* <div className="block-text">You can get dedicated support from mentors at every step of your Salesforce learning.</div> */}
                                  </div>
                                </div>


                                <div className="block-column col-lg-6 col-md-6 col-sm-12">
                                  <div className="inner-column">
                                    <h5>Live Salesforce learning</h5>
                                    {/* <div className="block-text">Master Salesforce and start applying for high-paying jobs</div> */}
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>


                      </div>
                    </div>
                    <div className="tab-pane fade" id="curriculum" role="tabpanel" aria-labelledby="curriculum-tab">
                      <div className="course__curriculum">
                        <div className="course__curriculum">
                          <div className="accordion" id="course__accordion">
                            <div className="accordion-item mb-2">
                              <h2 className="accordion-header" id="week-01">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-01-content" aria-expanded="true" aria-controls="week-01-content">
                                  Overview of Salesforce CRM

                                </button>
                              </h2>
                              <div id="week-01-content" className="accordion-collapse collapse show " aria-labelledby="week-01" data-bs-parent="#course__accordion">
                                <div className="accordion-body">
                                  <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                    <div className="course__curriculum-info">
                                      <ul>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Foundations of Salesforce CRM</span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Introduction to cloud </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Accounts </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Users, applications, and tabs  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Overview of System </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Roles  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Profiles and permission sets</span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Page layouts  </span></h3></li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion" id="course__accordion-2">
                            <div className="accordion-item mb-50">
                              <h2 className="accordion-header" id="week-02">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-02-content" aria-expanded="true" aria-controls="week-02-content">
                                  Foundations of Sales Cloud
                                </button>
                              </h2>
                              <div id="week-02-content" className="accordion-collapse  collapse " aria-labelledby="week-02" data-bs-parent="#course__accordion-2">
                                <div className="accordion-body">
                                  <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                    <div className="course__curriculum-info">
                                      <ul>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span> Salesforce offerings  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Leads (Home Page view) </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>List views </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>How to create and convert leads?</span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Email template generation </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Auto response rules </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Account, Contact, and Opportunity</span></h3></li>

                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion" id="course__accordion-3">
                            <div className="accordion-item mb-50">
                              <h2 className="accordion-header" id="week-03">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-03-content" aria-expanded="true" aria-controls="week-03-content">
                                  Salesforce Configuration & Workflows
                                </button>
                              </h2>
                              <div id="week-03-content" className="accordion-collapse  collapse " aria-labelledby="week-03" data-bs-parent="#course__accordion-3">
                                <div className="accordion-body">
                                  <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                    <div className="course__curriculum-info">
                                      <ul>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Chatter </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>How to create a free user using Chatter?</span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Automating processes</span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Workflow rules </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Creating and configuring workflow rules and tasks</span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Email alerts for workflow rules</span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Process builders  </span></h3></li>

                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion" id="course__accordion-4">
                            <div className="accordion-item mb-50">
                              <h2 className="accordion-header" id="week-04">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-04-content" aria-expanded="true" aria-controls="week-04-content">
                                  Data Management
                                </button>
                              </h2>
                              <div id="week-04-content" className="accordion-collapse  collapse " aria-labelledby="week-04" data-bs-parent="#course__accordion-4">
                                <div className="accordion-body">
                                  <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                    <div className="course__curriculum-info">
                                      <ul>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Wizard for data importing </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Data loader </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Mass delete </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Sandboxes </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Demo loading </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Working with tags</span></h3></li>


                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion" id="course__accordion-5">
                            <div className="accordion-item mb-50">
                              <h2 className="accordion-header" id="week-05">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-05-content" aria-expanded="true" aria-controls="week-05-content">
                                  Deployment and Change sets
                                </button>
                              </h2>
                              <div id="week-05-content" className="accordion-collapse  collapse " aria-labelledby="week-05" data-bs-parent="#course__accordion-5">
                                <div className="accordion-body">
                                  <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                    <div className="course__curriculum-info">
                                      <ul>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>VScode </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Basics of Deployment </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Change sets </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>How to create change sets for deployment   </span></h3></li>

                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion" id="course__accordion-6">
                            <div className="accordion-item mb-50">
                              <h2 className="accordion-header" id="week-06">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-06-content" aria-expanded="true" aria-controls="week-06-content">
                                  Access & Security Levels
                                </button>
                              </h2>
                              <div id="week-06-content" className="accordion-collapse  collapse" aria-labelledby="week-06" data-bs-parent="#course__accordion-6">
                                <div className="accordion-body">
                                  <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                    <div className="course__curriculum-info">
                                      <ul>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Data access </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Role hierarchies </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Organizational Wide Defaults </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>How to view object security? </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Login flows  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>AppExchange  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Setup menu  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Metadata  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Access developer console  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Salesforce documentation  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Custom objects   </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>sObjects  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Formula and summary fields </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>SOSL, SOQL, and the differences between them </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Relationship queries  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Salesforce classes and wrapper classesSalesforce classes and wrapper classes  </span></h3></li>




                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion" id="course__accordion-7">
                            <div className="accordion-item mb-50">
                              <h2 className="accordion-header" id="week-07">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-07-content" aria-expanded="true" aria-controls="week-07-content">
                                  Apex
                                </button>
                              </h2>
                              <div id="week-07-content" className="accordion-collapse  collapse" aria-labelledby="week-07" data-bs-parent="#course__accordion-7">
                                <div className="accordion-body">
                                  <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                    <div className="course__curriculum-info">
                                      <ul>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Class and triggers</span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Development processes and tools  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Collections </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Control statements and loop statements  </span></h3></li>



                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion" id="course__accordion-8">
                            <div className="accordion-item mb-50">
                              <h2 className="accordion-header" id="week-08">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-08-content" aria-expanded="true" aria-controls="week-08-content">
                                  Data management
                                </button>
                              </h2>
                              <div id="week-08-content" className="accordion-collapse  collapse" aria-labelledby="week-08" data-bs-parent="#course__accordion-8">
                                <div className="accordion-body">
                                  <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                    <div className="course__curriculum-info">
                                      <ul>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Database operations </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>DML methods </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>How do you invoke DML methods? </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Handle DML operations  </span></h3></li>

                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion" id="course__accordion-9">
                            <div className="accordion-item mb-50">
                              <h2 className="accordion-header" id="week-09">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-09-content" aria-expanded="true" aria-controls="week-09-content">
                                  Triggers
                                </button>
                              </h2>
                              <div id="week-09-content" className="accordion-collapse  collapse" aria-labelledby="week-09" data-bs-parent="#course__accordion-9">
                                <div className="accordion-body">
                                  <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                    <div className="course__curriculum-info">
                                      <ul>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Exceptions and exception statements  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Debug logs </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Anonymous blocks  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>How to create debug logs?   </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>How to set up anonymous logs?   </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>How to read debug logs? </span></h3></li>




                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion" id="course__accordion-10">
                            <div className="accordion-item mb-50">
                              <h2 className="accordion-header" id="week-10">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-10-content" aria-expanded="true" aria-controls="week-10-content">
                                  Testing
                                </button>
                              </h2>
                              <div id="week-10-content" className="accordion-collapse  collapse" aria-labelledby="week-10" data-bs-parent="#course__accordion-10">
                                <div className="accordion-body">
                                  <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                    <div className="course__curriculum-info">
                                      <ul>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Testing framework </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>How to write and run tests? </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Developer console </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Writing codes for DML operations </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Code coverage</span></h3></li>


                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion" id="course__accordion-11">
                            <div className="accordion-item mb-50">
                              <h2 className="accordion-header" id="week-11">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-11-content" aria-expanded="true" aria-controls="week-11-content">
                                  Security
                                </button>
                              </h2>
                              <div id="week-11-content" className="accordion-collapse  collapse" aria-labelledby="week-11" data-bs-parent="#course__accordion-11">
                                <div className="accordion-body">
                                  <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                    <div className="course__curriculum-info">
                                      <ul>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>How to measure and audit network security?   </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Organization-wide defaults  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Custom buttons and links  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Profiles and record types  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Actions   </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>List view </span></h3></li>


                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion" id="course__accordion-12">
                            <div className="accordion-item mb-50">
                              <h2 className="accordion-header" id="week-12">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-12-content" aria-expanded="true" aria-controls="week-12-content">
                                  Controller extensions
                                </button>
                              </h2>
                              <div id="week-12-content" className="accordion-collapse  collapse" aria-labelledby="week-12" data-bs-parent="#course__accordion-12">
                                <div className="accordion-body">
                                  <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                    <div className="course__curriculum-info">
                                      <ul>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Controller architecture     </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Standard and custom controllers  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Custom controllers  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Controller extension </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>How to deploy controllers? </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Action methods for controllers </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Getter and setters </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Properties  </span></h3></li>



                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion" id="course__accordion-13">
                            <div className="accordion-item mb-50">
                              <h2 className="accordion-header" id="week-13">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-13-content" aria-expanded="true" aria-controls="week-13-content">
                                  Data Management & Workbenchs
                                </button>
                              </h2>
                              <div id="week-13-content" className="accordion-collapse  collapse" aria-labelledby="week-13" data-bs-parent="#course__accordion-13">
                                <div className="accordion-body">
                                  <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                    <div className="course__curriculum-info">
                                      <ul>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Data loaders    </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>How to import and export data? </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>What is a workbench?  </span></h3></li>


                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>



                      </div>
                    </div>
                    <div className="tab-pane fade" id="certification" role="tabpanel" aria-labelledby="certification-tab">
                      <div className="course__certification">
                        <h3>Certification</h3>
                        <p className='text'>Dynopat will certify you as Salesforce Administrator & Developer after you finish the course. The course is created by the best instructor with the goal of helping you sit for Salesforce Dev 450 certification. When you work on projects, you gain real-world experience to fast-track your career. If you are preparing for the Salesforce Dev 450 exam, you can replay the videos to reiterate your learnings. </p>
                        <img  loading="lazy" className='certificat' src='/assets/images/Certificate-Design.jpg'></img>

                      </div>
                    </div>
                    <div className="tab-pane fade" id="faq" role="tabpanel" aria-labelledby="faq-tab">
                      <div className="course__faq mb-45">



                        <div className="accordion" id="accordionExample">
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                How soon can I start the live class?

                              </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <div className="course__curriculum-info">
                                  <strong>You can enter the new batch and start accessing the lesson from the specified date after making a payment. </strong>

                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                What will I learn from this training?

                              </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <strong>You will learn how to utilize the user interface of Salesforce, understand business logic, and deploy Salesforce for specific use cases within an organization. </strong>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Are there any prerequisites for this training?


                              </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <strong>If you have experience working on Java or similar technology, it will be a bonus. However, you can start this course from scratch.  </strong>

                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFour">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                What if I have questions regarding the course material?



                              </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <strong>We will offer Q&A sessions where you can ask your questions to the instructor himself and watch him answer them.
                                </strong>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFive">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                Can I opt for an instructor-led class instead of self-learning?

                              </button>
                            </h2>
                            <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <strong>Yes, you can opt for live classes by paying a small amount. You join live classes from the next batch. You can either enroll for our live sessions through the courses listed on the website or by asking for assistance from our customer support team.
                                </strong>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingsix">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                              How can I get certified? 

                              </button>
                            </h2>
                            <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <strong>You can get the certificate by attending the lessons, and by taking the quizzes (present at the end of each recording. You can also follow the document attached to these sessions. The goal of these quizzes is to assess your level of understanding and help you identify your weak points. 
                                </strong>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingseven">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven">
                              Can I ask for additional classes? 


                              </button>
                            </h2>
                            <div id="collapseseven" className="accordion-collapse collapse" aria-labelledby="headingseven" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <strong>If you feel that you need additional classes, you can enroll in our live classes. Aside from that, you can also ask our support team about it, and we will help you at the earliest.  
                                </strong>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingeight">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeight" aria-expanded="false" aria-controls="collapseeight">
                              What are the prerequisites for this course? 

                              </button>
                            </h2>
                            <div id="collapseeight" className="accordion-collapse collapse" aria-labelledby="headingeight" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <strong>You don’t need any prior experience or training before enrolling in this course. However, if you have an experience with OOPs or Java, you may grasp some of the topics quickly.  
                                </strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4">
              <div className="course__sidebar pl-70 p-relative">
                <div className="course__shape">
                  <img  loading="lazy" className="course-dot" src="/assets/img/course/course-dot.png" alt="" />
                </div>
                <div className="course__sidebar-widget-2 white-bg mb-20">
                  <div className="course__video" onClick={()=>toggleshowpopup(url)}>
                    <div className="course__video-thumb w-img mb-25">
                      <img  loading="lazy" src="/assets/images/video.png" alt="" />
                      <div className="course__video-play">
                        <a href="#" data-fancybox="" className="play-btn"> <i className="fa fa-play"></i> </a>
                      </div>
                    </div>
                    <div className="course__video-meta mb-25 d-flex align-items-center justify-content-between">
                      {/* <div className="course__video-price">
                        <h5>$74.<span>00</span> </h5>
                        <h5 className="old-price">$129.00</h5>
                      </div>
                      <div className="course__video-discount">
                        <span>68% OFF</span>
                      </div> */}
                    </div>
                    <div className="course__video-content mb-35">
                      <ul>
                        {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Instructor :</span> Eleanor Fant</h5>
                                       </div>
                                    </li> */}
                        {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Lectures :</span>14</h5>
                                       </div>
                                    </li> */}
                        <li className="d-flex align-items-center">
                          <div className="course__video-icon">

                          </div>
                          <div className="course__video-info">
                            <h5><span>Duration :</span>3 weeks</h5>
                          </div>
                        </li>
                        {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Enrolled :</span>20 students</h5>
                                       </div>
                                    </li> */}
                        <li className="d-flex align-items-center">
                          <div className="course__video-icon">

                          </div>
                          <div className="course__video-info">
                            <h5><span>Language :</span>English</h5>
                          </div>
                        </li>
                      </ul>
                    </div>
                    {/* <div className="course__payment mb-35">
                                 <h3>Payment:</h3>
                                 <a href="#">
                                    <img  loading="lazy" style={{width: '222px'}} src="/assets/images/stripe.png" alt="" />
                                 </a>
                              </div> */}
                    <Claimyourfreecourse />

                    <button onClick={()=>{window.open('https://rzp.io/l/WWAarOeF')}}  class="e-btn e-btn-7 w-100">Enroll <i class="fa fa-arrow-right"></i></button>

                  </div>
                </div>
                
                <Conactfrom />
              </div>
            </div>

          </div>
        </div>
      </section>
      {showpopup&& <div className="popup-container">
                    <div className="popups">


                        <div className="row">
                            <div className="col-10">

                                <img className='lllog' src="/assets/images/dino.png" alt="" />
                            </div>
                            <div className="col-2 text-end">
                                <button className="close-button" onClick={toggleshowpopup}>Close</button>

                            </div>
                        </div>
						<iframe src={selectedVideoUrl} width="100%" height="400px" ></iframe>
                    </div>
                </div>
                }

    </>
  )
}

export default P_dev_1