import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development'
import Conactfrom from '../../Ourcourse/compunent/Conactfrom';
import axios from 'axios';


const Footer = () => {
    const navigate = useNavigate();
    
    const [data, setData] = useState([]);

    useEffect(() => {
      fetchData();
    }, []);
  
    const fetchData = async () => {
      try {
        const response = await axios.get('https://dynopat.com/dynopat_admin/api/site-information');
        
        setData(response.data.siteInfo);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    return (
        <>
            <div className="running-text-container">
                <marquee className='running-text text'>  New Study Finds Salesforce Economy Will Create 9.3 Million Jobs and $1.6 Trillion in New Business Revenues by 2026</marquee>

            </div>
            <footer className="footer-style-three">
                <div className="auto-container">

                    <div className="widgets-section">
                        <div className="row clearfix">

                            <div className="big-column col-lg-6 col-md-12 col-sm-12">
                                <div className="row clearfix">

                                    <div className="footer-column col-lg-8 col-md-6 col-sm-12">
                                        <div className="footer-widget logo-widget">
                                            <div className="logo">
                                                <Link to='/'><img src="/assets/images/dino.png" alt="" /></Link>
                                            </div>
                                            <div className="text">Dynopat houses instructors who can <br />provide customized Salesforce training to students.<br />Dynopat Technologies Private Limited concern of Delipat IT, a Salesforce Implementation company</div>

                                        </div>
                                    </div>

                                    <div className="footer-column col-lg-4 col-md-6 col-sm-12">
                                        <div className="footer-widget links-widget">
                                            <h4>Links</h4>
                                            <ul className="nav-list">
                                                <li><Link to="/faq">FAQ</Link></li>
                                                <li><Link to='/learn-salesforce/live-classes/'>Learn Salesforce</Link></li>
                                                <li><Link to='/refund-Policy'>Refund Policy</Link></li>
                                                <li><Link to='/Privacy-Policy'>Privacy Policy</Link></li>
                                                <li><Link to='/terms-and-conditions'>Terms and Conditions</Link></li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="big-column col-lg-6 col-md-12 col-sm-12">
                                <div className="row clearfix">

                                    <div className="footer-column col-lg- col-md-6 col-sm-12">
                                        <div className="footer-widget links-widget">
                                            <h4>Get in Touch</h4>
                                            <ul className="list-list">
                                                <li><span className="fa fa-map-marker" dangerouslySetInnerHTML={{ __html: data.address}} /> </li>

                                                <li><span className="fa fa-phone"></span> <a href={`tel:${data.phone_f}`}>{data.phone_f}</a> <br/><span className="fa fa-phone"></span> <a href={`tel:${data.phone_s}`}> {data.phone_s}</a> </li>
                                                <li><span className="fa fa-envelope"> </span><a href= {`mailto:${data.email}`}> {data.email} </a></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                                        <div className="footer-widget info-widget">
                                            <h4>Timing</h4>
                                            <div className="timing">Mon - Sat: 8 am - 5 pm, <br /> Sunday: CLOSED</div>
                                            <ul className="social-box">
                                                <li><a href="https://www.facebook.com/dynopat" className="fa fa-facebook-f"></a></li>
                                                <li><a href="https://twitter.com/DynoPatPvtLtd" className="fa fa-twitter"></a></li>
                                                <li><a href="https://www.linkedin.com/company/dynopat/ " className="fa fa-linkedin"></a></li>
                                                <li><a href="https://www.instagram.com/dynopat/" className="fa fa-instagram"></a></li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className="upper-box">
                                <div className="row clearfix">
                                    <div className="title-column col-lg-9 mt-4 col-md-12 col-sm-12">
                                        <h5 style={{ lineHeight: '0' }}>
                                            <br /> <span className='text fotor'>Learn Salesforce development from the best experts.</span>
                                        </h5>
                                    </div>
                                    <div className="subscribe-column col-lg-3 col-md-12 col-sm-12">
                                        <div className="subscribe-box fotor">
                                            {/* <div className="hand-icon">
                            <img src="/assets/images/icons/hand-shake.png" alt="" />
                        </div> */}
                                            <div >
                                                <button onClick={()=>(window.open('https://dynopat.com/stripe-payment/'))} className="theme-btn btn-style-one mb-3 "><span className="txt">Pay Now</span></button>
                                               
                                            </div>
                                            {/* <form method="post" action="contact.html">
                            <div className="form-group">
                                <input type="email" name="search-field" value="" placeholder="Your Mail address" required/>
                                <button type="submit" className="theme-btn btn-style-seventeen"><span className="txt">Subscribe</span></button>
                            </div>
                        </form> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div className="footer-bottom">
                        <div className="row clearfix">
                            <div className="column col-lg-6 col-md-12 col-sm-12">
                                <div className="copyright">Copyright &copy; 2023 <a href='https://dynopat.com'>Dynopat.</a></div>
                            </div>
                            <div className="column col-lg-6 col-md-12 col-sm-12">
                                <div className="profile">Design and developed by || <a href='https://delipat.com/'>Delipat IT Private Limited - Salesforce Partner
                                </a></div>
                            </div>
                        </div>
                    </div>

                </div>
            </footer>

        </>

    )
}

export default Footer