import React, { useState,useEffect } from 'react'
import Banner from './banner'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import Slider from 'react-slick';
import Bandlogo from './Bandlogo';
import { Helmet } from 'react-helmet-async';
import Downloadpdf from './component/Downloadpdf';


const Home = () => {
	const [showpopup, setShowpopup] = useState(false);
	const [selectedVideoUrl, setSelectedVideoUrl] = useState('');
    const toggleshowpopup= (videoUrl) => {
		setSelectedVideoUrl(videoUrl);
        setShowpopup(!showpopup);
    };
	const url ={
		url1:"https://drive.google.com/file/d/1KxvnFZEhg5QEzys1cDizmmmI-JfCoTaR/preview",
		url2:"https://drive.google.com/file/d/1RMU1Wa5SAYstO3oX2CYxkAlk01WG0BRW/preview",
		url3:"https://drive.google.com/file/d/1WZ1btvW7wSqhZgR-fKqJoBi4grx_YtMd/preview"
	}
    const [showContactForm, setShowContactForm] = useState(false);

    const toggleContactForm = () => {
        setShowContactForm(!showContactForm);
    };
	useEffect(() => {
		// Use jQuery to add functionality or make changes to the DOMo
		$('.tab-btn').click(function () {
			var tab_id = $(this).attr('data-tab');
			$('.tab-btn').removeClass('active-btn');
			$('.tab').removeClass('active-tab');
			$(this).addClass('active-btn');
			$(tab_id).addClass('active-tab');
		});


	}, []);
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true, // auto-run the slider
		autoplaySpeed: 4000,  // time in milliseconds between each slide
	};

	const videotesti = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 4000,
		responsive: [
			{
				breakpoint: 768, // This is the breakpoint for mobile screens
				settings: {
					slidesToShow: 1, // Show only 1 slide on mobile screens
					slidesToScroll: 1,
				}
			}
		]
	};
	
	const [expanded, setExpanded] = useState(false);
	const handleToggle = () => {
		setExpanded(!expanded);
	}

	const text = "Expert Trainers : Our expert trainers are capable of explaining the topics in a simple yet effective manner. We only work with a batch of 55 students so that everyone gets an equal opportunity to ask questions. They will guide you through Salesforce and help you to master it. Join our classes and take a leap into a high-paying career. ";

	const [expanded2, setExpanded2] = useState(false);
	const handleToggle2 = () => {
		setExpanded2(!expanded2);
	}

	const text2 = "Real-time Projects : If you want to be a successful developer, you need to have real-time projects in your portfolio. You start working on projects from the Ranger package itself, and you will work on creating Salesforce CRM applications for different industries, such as banking, medical, etc. ";

	const [expanded3, setExpanded3] = useState(false);
	const handleToggle3 = () => {
		setExpanded3(!expanded3);
	}

	const text3 = "Comprehensive Curriculum & Assignments : Our  Salesforce Developer Training curriculum covers all the topics of Salesforce, starting from the simple ones to the most complex topics. We take utmost care to include new topics in the curriculum so that you always stay up to date. Our assignments are designed to check if you are understanding these topics correctly and clearly.";

	const styles = {
		color: '#0e2863',
		fontSize: '12px'
	};
	return (
		<>
			{/* <div className="preloader"></div> */}




			<Helmet>
				
            <title>Salesforce Learning | Salesforce Online Training | Dynopat</title>
			<meta name="description" content="Master Salesforce & gain in-demand skills with Dynopat’s flexible and engaging Salesforce online training. Few seats are remaining, enroll today!" />
        	<meta name="keywords" content="Salesforce Online Training" />
  <meta
    property="og:title"
    content="Salesforce Learning | Salesforce Online Training"
  />
  <meta property="og:site_name" content="DynoPat" />
  <meta property="og:url" content="https://dynopat.com/" />
  <meta
    property="og:description"
    content="Master Salesforce & gain in-demand skills with Dynopat’s flexible and engaging Salesforce online training. Few seats are remaining, enroll today!"
  />
  <meta property="og:type" content="website" />
  <meta
    property="og:image"
    content="https://dynopat.com/assets/images/resource/learning.svg"
  />

			<link rel="canonical" href="https://dynopat.com/" />
      		</Helmet>
     

        
       
        
   

			<section className="cta-section-two">

				<Banner toggleContactForm={toggleContactForm} />
			
				<div className="auto-container">
					<div className="inner-container" style={{
						backgroundImage: 'url("/assets/images/selasforce.gif")', backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
					}}>
						<div className="row clearfix" >
							{/* <h4 className='text-center'>Notification Board</h4> */}
							<div className="title-column col-lg-7 col-md-12 col-sm-12">

								<div className="inner-column">

									<div className="text pt-3 "><h5 className='colorchange'> Register For Demo Class</h5></div>
								</div>
							</div>

							<div className="apps-column col-lg-5 col-md-12 col-sm-12">
								<div className="inner-column">
									<div className="button-box">
										<Link to="/salesforce-free-demo-class/" className="theme-btn btn-style-one"><span className="txt">Register Now<i className="fa fa-angle-right"></i></span></Link>
									</div>
								</div>
								
							</div>

						</div>

						<div className="side-icon">
							<img  loading="lazy" src="/assets/images/cta-icon.png" alt="" />
						</div>

					</div>
				
				</div>
			</section>

			
			<section className="finance-cards-section mt-4 mb-5">
				<div className="auto-container">
				<div className="sec-title-three centered mt-5 mb-5">
						{/* <div className="title">Features</div> */}
						<h2>Boost<span> Your Career With Salesforce Training</span> </h2>
					</div>
					<div className="cards-info-tabs">
						{/* <div className="pattern-layer" style={{ backgroundImage: ' url(/assets/images/background/pattern-42.png)' }} ></div> */}
						<div className="cards-tabs tabs-box">

							<ul className="tab-btns tab-buttons clearfix">
								
								{/* <li className="text" data-tab="#prod-Demo" className="tab-btn active-btn">Demo Session</li> */}
								<li data-tab="#Self-paced" className="tab-btn active-btn">Self-Paced Salesforce Certification Training</li>
								<li data-tab="#Mastery" className="tab-btn">Live Admin Training</li>
								<li data-tab="#Developer-Bootcamp" className="tab-btn">Live Development Training</li>
								<li data-tab="#LWC" className="tab-btn">LWC Training</li>
								<li data-tab="#Salesforce-Pro" className="tab-btn">Full Course</li>
								<li data-tab="#Corporate" className="tab-btn">Corporate Training</li>


								

							</ul>

							<div className="tabs-content">

								<div className="tab active-tab" id="Self-paced">
									<div className="content">
										<div className="row clearfix">
											{/* <div className="image-column col-lg-3 col-md-12 col-sm-12">
												<div className="image">
													<img  loading="lazy" src="/assets/images/rangeer.png" alt="" />
												</div>
											</div> */}
											<div className="mr57 content-column col-lg-11 col-md-12 col-sm-12">
												<ul style={{marginLeft:'28px'}}>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Video Based Step By Step Salesforce admin and Developer Training</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Each Chapter is Divided into multiple Small Small Videos.</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Each video is Supported with A Blog Content</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Content  Covers Salesforce Admin Certification, Platform Developer 1 and Platform Developer 2 Certification, App Builder Certication</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Lifetime Access to LMS videos</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Live Q&A Session with the Salesforce Expert</h4> </li>
													

												</ul>
												<div className="button-box">
													<Link to='/learn-salesforce/self-paced-training/' className="theme-btn btn-style-one mt-3" style={{ padding: '5px 9px' }}><span className="txt">More Details<i className="fa fa-angle-right"></i></span></Link>
												</div>
												{/* <div className="company">
											<img  loading="lazy" src="/assets/images/resource/company.png" alt="" />
										</div> */}
											</div>
										</div>
									</div>
								</div>

								<div className="tab" id="Mastery">
									<div className="content">
										<div className="row clearfix">
											{/* <div className="image-column col-lg-6 col-md-12 col-sm-12">
												<div className="image">
													<img  loading="lazy" src="/assets/images/advanture.png" alt="" />
												</div>
											</div> */}
											<div className="mr57 content-column col-lg-11 col-md-12 col-sm-12" style={{ height: '300px', overflowY: 'scroll' }}>
												
												<ul style={{marginLeft:'28px'}}>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>This Course will be for you if you want to Become Salesforce Consultant not Developer.</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>This Course will Cover Salesforce Admin and Salesforce Technical Business Analyst</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>This Course Will Include all the Self Paced Salesforce Certification Features along with Live Classes.</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>This Course is for the People who are coming drom Non IT or Non Coding Background.</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>One week Free Demo to Classes the if this course is meant for  you.</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Monday to Friday 9.30 to 10.30 PM EST Live Class with Instructor.</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Every Day 15 Minutes Q&A Session Before the Class Starts.</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Dedicated Mentor Support.</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Work On Real time Projects.</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Daily Assignments.</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Dedicated Escalation Manager</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Mock Interview After the Class.</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Help on Resume Preparation.</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Duration for this course will be 4 to 5 weeks</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Daily Assignments will be given after the class.</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Students Feedback from the trainer will be provided.</h4> </li>



												</ul>
												<div className="button-box">
													<Link to='/learn-salesforce/live-classes/live-admin-training/' className="theme-btn btn-style-one mt-3" style={{ padding: '5px 9px' }}><span className="txt">More Details<i className="fa fa-angle-right"></i></span></Link>
												</div>
												{/* <div className="company">
											<img  loading="lazy" src="/assets/images/resource/company.png" alt="" />
										</div> */}
											</div>
										</div>
									</div>
								</div>

								<div className="tab" id="Developer-Bootcamp">
									<div className="content">
										<div className="row clearfix">
											{/* <div className="image-column col-lg-6 col-md-12 col-sm-12">
												<div className="image">
													<img  loading="lazy" src="/assets/images/mount.png" alt="" />
												</div>
											</div> */}
											<div className="mr57 content-column col-lg-11 col-md-12 col-sm-12"  style={{ height: '300px', overflowY: 'scroll' }}>
												
												<ul style={{marginLeft:'28px'}}>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>This Course is for the People who wants to become a Salesforce Developer and Coming from IT and coding Background.</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>This Course will Cover the end to end Knowledge on Salesforce development</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>This course will Include Self Paced Salesforce training Videos Along with Live Class </h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Live class will Start from 9.30 PM EST to 10.30 PM EST. Monday to Friday</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Duration for this course will be 4 to 5 weeks</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Apex development will be started from scratch in this course.</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Basic Knowladge of Development is required for this course.</h4> </li>

												</ul>
												<div className="button-box">
													<Link to='/learn-salesforce/live-classes/live-development-training/' className="theme-btn btn-style-one mt-3" style={{ padding: '5px 9px' }}><span className="txt">More Details<i className="fa fa-angle-right"></i></span></Link>
												</div>
												{/* <div className="company">
											<img  loading="lazy" src="/assets/images/resource/company.png" alt="" />
										</div> */}
											</div>
										</div>
									</div>
								</div>

								<div className="tab" id="LWC">
									<div className="content">
										<div className="row clearfix">
											<div className="image-column col-lg-6 col-md-12 col-sm-12">
												<div className="image">
													<img  loading="lazy" src="/assets/images/lernselsforcecourse/live/lwc/lwc.webp" alt="" />
												</div>
											</div>
											<div className="content-column col-lg-6 col-md-12 col-sm-12">
												
												<ul style={{marginLeft:'28px'}}>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>This Course will have only Live training. Class Duration 1 Hr everyday Monday to Friday</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Basic Knowladge of HTML, CSS and Javascript is required to learn LWC </h4> </li>

												</ul>
												<div className="button-box">
													<Link to='/learn-salesforce/live-classes/live-lwc-training/' className="theme-btn btn-style-one mt-3" style={{ padding: '5px 9px' }}><span className="txt">More Details<i className="fa fa-angle-right"></i></span></Link>
												</div>
												{/* <div className="company">
											<img  loading="lazy" src="/assets/images/resource/company.png" alt="" />
										</div> */}
											</div>
										</div>
									</div>
								</div>

								
								<div className="tab" id="Salesforce-Pro">
									<div className="content">
										<div className="row clearfix">
											<div className="image-column col-lg-6 col-md-12 col-sm-12">
												<div className="image">
													<img  loading="lazy" src="/assets/images/lernselsforcecourse/fullcourse.webp" alt="" />
												</div>
											</div>
											<div className="content-column col-lg-5 col-md-12 col-sm-12" style={{ height: '300px', overflowY: 'scroll' }}>
											
												<ul style={{marginLeft:'28px'}}>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>This will include Full Self Paced Training access for lifetime </h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Full Salesforce admin and Business Analyst Live Training</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Full salesforce Developer Live Training</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Full Salesforce LWC Live Training</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Course Duration is 3 Months</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>3 Live Projects</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Dedicated Escalation Manager</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Dedicated Technical Support Executive</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Class Duration 1 Hr everyday Monday to Friday </h4> </li>



												</ul>
												<div className="button-box">
													<Link to='/learn-salesforce/live-classes/salesforce-full-course/ ' className="theme-btn btn-style-one mt-3" style={{ padding: '5px 9px' }}><span className="txt">More Details<i className="fa fa-angle-right"></i></span></Link>
												</div>
												{/* <div className="company">
											<img  loading="lazy" src="/assets/images/resource/company.png" alt="" />
										</div> */}
											</div>
										</div>
									</div>
								</div>
								<div className="tab" id="Corporate">
									<div className="content">
										<div className="row clearfix">
											<div className="image-column col-lg-6 col-md-12 col-sm-12">
												<div className="image">
													<img  loading="lazy" src="/assets/images/lernselsforcecourse/live/corporate/corporate.webp" alt="" />
												</div>
											</div>
											<div className="content-column col-lg-5 col-md-12 col-sm-12" style={{ height: '300px', overflowY: 'scroll' }}>
												<ul  style={{marginLeft:'28px'}}>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>This will include Full Self Paced Training access for lifetime</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Full Salesforce admin and Business Analyst Live Training </h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Full salesforce Developer Live Training</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Full Salesforce LWC Live Training</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Course Duration is 3 Months</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>3 Live Projects</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Online or Offline Training</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Trainer will be available for 8 Hours During weekend</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Entire course is 48 Hours</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Dedicated Excalation Manager will be Provided</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Assessment will be given in the end of every session</h4> </li>
													<li className="text" style={{ listStyleType: 'square', fontSize: '22px' }}><h4>Weekly feedback from the trainer will be provided to the company</h4> </li>



												</ul>
												<div className="button-box">
													<Link to='/learn-salesforce/live-classes/salesforce-corporate-training/' className="theme-btn btn-style-one mt-3" style={{ padding: '5px 9px' }}><span className="txt">More Details<i className="fa fa-angle-right"></i></span></Link>
												</div>
												{/* <div className="company">
											<img  loading="lazy" src="/assets/images/resource/company.png" alt="" />
										</div> */}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</section>
		
			<section className="steps-section"  >
				<div className="step-big-title">Dynopat</div>
				<div className="auto-container">
					<div className="sec-title-three centered bn">
						{/* <div className="title">Steps to get started</div> */}
						<h2>About <span>Dynopat </span> </h2>
					</div>
					<div className="step-block">
						<div className="inner-box">
							<div className="row clearfix fdfg">
								<div className="image-column col-lg-6 col-md-12 col-sm-12">
									<div className="inner-column">
										<div className="image wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
											<img  loading="lazy" src="/assets/images/resource/learning.svg" alt="" />
										</div>
										<div className="image-two wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms">
											{/* <img  loading="lazy" src="/assets/images/resource/step-2.png" alt="" /> */}
										</div>
									</div>
								</div>

								<div className="content-column col-lg-6 col-md-12 col-sm-12 ">
									<div className="inner-column align-middle">
										{/* <div className="title">Step 1</div> */}
										<div className='text'>
											<h4> Dynopat is an online Salesforce training company providing exclusive courses for upskilling and unlocking new career opportunities. </h4>

										</div>

										<div className="button-box">
											<Link to='/about-dynopat' className="theme-btn btn-style-one mt-3 pt-2"><span className="txt">Know More <i className="fa fa-angle-right"></i></span></Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="auto-container">
					<div className="sec-title-three centered">
						{/* <div className="title">Steps to get started</div> */}
						<h2>Why <span>Choose Us?</span></h2>
					</div>

					<div className="step-block">
						<div className="inner-box">
							<div className="row clearfix">

								<div className="content-column col-lg-6 col-md-12 col-sm-12">
									<div className="inner-column align-middle">
										{/* <div className="title">Step 1</div> */}
										<h2>Here are  <span>the reasons to choose Dynopat: </span></h2>
										<ul className="steps-list">
											<li className="text"><h4>{expanded ? text : text.substring(0, 16) + "..."}
												<span onClick={handleToggle} style={styles}>
													{expanded ? "read Less" : "read More"}
												</span></h4></li>
											<li className="text"><h4>{expanded2 ? text2 : text2.substring(0, 18) + "..."}
												<span onClick={handleToggle2} style={styles}>
													{expanded2 ? "read Less" : "read More"}
												</span></h4></li>
											<li className="text"><h4>{expanded3 ? text3 : text3.substring(0, 39) + "..."}
												<span onClick={handleToggle3} style={styles}>
													{expanded3 ? "read Less" : "read More"}
												</span></h4></li>


										</ul>
									</div>
								</div>

								<div className="image-column col-lg-6 col-md-12 col-sm-12">
									<div className="inner-column">
										<div className="image wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
											<img  loading="lazy" src="/assets/images/why.webp" alt="" />
										</div>
										<div className="image-two wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms">
											{/* <img  loading="lazy" src="/assets/images/resource/step-2.png" alt="" /> */}
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>


				</div>

			</section>
			{/* <section className="discount-section" style={{ backgroundImage: 'url(/assets/images/background/pattern-40.png)'}} >
		<div className="pattern-layer" style={{ backgroundImage: 'url(/assets/images/background/pattern-41.png)' }}></div>
		<div className="auto-container">
			<div className="row clearfix">
				
				<div className="content-column col-lg-6 col-md-12 col-sm-12">
					<div className="inner-column">
						<div className="sec-title-three">
							<div className="title">Online Discount</div>
							<h2>Get Unlimited <br/> <span>Payment Discount</span></h2>
							<div className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</div>
						</div>
						<div className="button-box">
							<a href="about.html" className="theme-btn btn-style-eightteen"><span className="txt"><i className="arrow-left flaticon-right-arrows"></i>Get Started <i className="arrow-right flaticon-right-arrows"></i></span></a>
						</div>
					</div>
				</div>
				
				<div className="image-column col-lg-6 col-md-12 col-sm-12">
					<div className="inner-column">
						<div className="image" data-tilt data-tilt-max="6">
							<img  loading="lazy" src="/assets/images/resource/discount.png" alt="" />
						</div>
					</div>
				</div>
				
			</div>
		</div>
	</section> */}

			<section className="digital-section">
				<div className="auto-container">
					<div className="sec-title">
					<div className="sec-title-three">
						<h2 >Our<span> Training Process
						</span></h2></div>
						<div className='text' ><h4>We believe that everyone should have the opportunity to train. </h4></div>
					</div>

					<div className="side-image">
						<img  loading="lazy" src="/assets/images/resource/side-image.jpg" alt="" />
					</div>
					<div className="row clearfix">

						<div className="feature-block-two col-lg-4 col-md-6 col-sm-12">
							<div className="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
								<span className="icon transition-500ms">
									<img  loading="lazy" src="assets/images/demo.png" alt="" />
								</span>
								<h4><Link to="#">Demo Sessions & Assignments</Link></h4>
								<div className="text">Attend our free demo sessions for a week and see if this is the right course for you. We organize daily sessions and we need students to submit the assignments (mandatory). This is the chance to see if we are the right fit for you. </div>
								{/* <div className="button-box">
							<a href="service-detail.html" className="theme-btn btn-style-one"><span className="txt">Read More <i className="fa fa-angle-right"></i></span></a>
						</div> */}
							</div>
						</div>

						<div className="feature-block-two col-lg-4 col-md-6 col-sm-12">
							<div className="inner-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
								<span className="icon transition-500ms">
									<img  loading="lazy" src="assets/images/certification.png" alt="" />
								</span>
								<h4><Link to="#">Certification</Link></h4>
								<div className="text" style={{ lineHeight: '25px' }}>Now we have a fair understanding of your capabilities, we invite you to attend our online classes and start upskilling. At the end of the course, you will get a certificate that you can proudly add to your resume, share on social media, and show to your potential employers. </div>
								{/* <div className="button-box">
							<a href="service-detail.html" className="theme-btn btn-style-one"><span className="txt">Read More <i className="fa fa-angle-right"></i></span></a>
						</div> */}
							</div>
						</div>

						<div className="feature-block-two col-lg-4 col-md-6 col-sm-12">
							<div className="inner-box wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
								<span className="icon transition-500ms">
									<img  loading="lazy" src="assets/images/Support.png" alt="" />
								</span>
								<h4 >24/7 Support</h4>
								<div className="text" >We offer free Demo sessions on Salesforce Development/ Admin for a week. During these sessions, you can always contact us whenever you need help with the course or the technology. We are always ready to help our students with their queries.</div>
								{/* <div className="button-box">
							<a href="service-detail.html" className="theme-btn btn-style-one"><span className="txt">Read More <i className="fa fa-angle-right"></i></span></a>
						</div> */}
							</div>
						</div>

					</div>
				</div>
			</section>
			<section className="testimonial-section-five">
				<div className="auto-container">
					<div className="row clearfix">

						<div className="title-column col-lg-6 col-md-12 col-sm-12">
							<div className="inner-column">
								<div className="sec-title-three">
									<div className="title"></div>
									<h2>Testimonials</h2>
									<div >
										<div className="text" style={{opacity:'0.5'}}>
										<h4>You don’t have to believe what we are saying. But listen to what our students have to say about our course and instructors.</h4>

										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="carousel-column col-lg-6 col-md-12 col-sm-12">
							<div className="inner-column">
								<div className="">
									<Slider {...settings}>
										<div className="testimonial-block-five">
											<div className="inner-box">
												<div className="author-image">
													<img  loading="lazy" src="/assets/images/testimonial/1576261783901.jpg" alt="" />
												</div>
												<div className="content">
													<div className="text">Highly recommend Rajesh if you have a plan to start a new career or just need to know about changes and updates on salesforce</div>
													<h4>Shler Hamidi Handa</h4>
													<div className="designation">insurance Sales at Farmers Insurance</div>
													<span className="quote-icon flaticon-left-quotes-sign"></span>
												</div>
											</div>
										</div>

										<div className="testimonial-block-five">
											<div className="inner-box">
												<div className="author-image">
													<img  loading="lazy" src="/assets/images/testimonial/1654352424702.jpg" alt="" />
												</div>
												<div className="content">
													<div className="text">I learned Salesforce from LMS videos. This is  very good source to learn Salesforce, if anybody wants to learn Salesforce from scratch i would</div>
													<h4>Pinki Kumari</h4>
													<div className="designation">1X Centied Salesforce Developer</div>
													<span className="quote-icon flaticon-left-quotes-sign"></span>
												</div>
											</div>
										</div>

										<div className="testimonial-block-five">
											<div className="inner-box">
												<div className="author-image">
													<img  loading="lazy" src="/assets/images/testimonial/1684756210898.jpg" alt="" />
												</div>
												<div className="content">
													<div className="text">highly recommended to fearn Salesforce from scratch level to advance level from Ims videos. good talented Salesforce expert.</div>
													<h4>Ajay S</h4>
													<div className="designation">Associate Consultant at Infosys</div>
													<span className="quote-icon flaticon-left-quotes-sign"></span>
												</div>
											</div>
										</div>
										<div className="testimonial-block-five">
											<div className="inner-box">
												<div className="author-image">
													<img  loading="lazy" src="/assets/images/testimonial/1620219361246.jpg" alt="" />
												</div>
												<div className="content">
													<div className="text">highly recommended to fearn Salesforce from scratch level to advance level from Ims videos. good talented Salesforce expert.</div>
													<h4>Joel Tom</h4>
													<div className="designation">Technical Specialist</div>
													<span className="quote-icon flaticon-left-quotes-sign"></span>
												</div>
											</div>
										</div>
										<div className="testimonial-block-five">
											<div className="inner-box">
												<div className="author-image">
													<img  loading="lazy" src="/assets/images/testimonial/1678896009483.jpg" alt="" />
												</div>
												<div className="content">
													<div className="text">Rajesh was my Salesforce coach. He has tremendous knowledge and experience with Salesforce. I have learned a lot from Rajesh and</div>
													<h4>Jitesh Joshi</h4>
													<div className="designation">Accountant be</div>
													<span className="quote-icon flaticon-left-quotes-sign"></span>
												</div>
											</div>
										</div>
										<div className="testimonial-block-five">
											<div className="inner-box">
												<div className="author-image">
													<img  loading="lazy" src="/assets/images/testimonial/149071-removebg-preview.png" alt="" />
												</div>
												<div className="content">
													<div className="text">It was a really very good experience with LMS In depth knowledge videos with 100% practical and real time scenarios. It helps me to get a</div>
													<h4>Aniket Thawkar</h4>
													<div className="designation">Salesforce Developer</div>
													<span className="quote-icon flaticon-left-quotes-sign"></span>
												</div>
											</div>
										</div>
										<div className="testimonial-block-five">
											<div className="inner-box">
												<div className="author-image">
													<img  loading="lazy" src="/assets/images/testimonial/1634056602436.jpg" alt="" />
												</div>
												<div className="content">
													<div className="text">I have learned so much in my salesforce Admin | training with Rajesh Chatterji. You have covered everything in salesforce admin videos from</div>
													<h4>Puja Nathe</h4>
													<div className="designation">2X Certified Salesforce Technical Specialist</div>
													<span className="quote-icon flaticon-left-quotes-sign"></span>
												</div>
											</div>
										</div>
									</Slider>
								</div>
							</div>
						</div>

					</div>
				</div>
			</section>
			<section className="testimonial-section-five">
				<div className="auto-container">
					<div className="row clearfix">
			<Slider {...videotesti} >
				<div className='text-center t'>
					<img style={{width:'100%',height:"90%"}}  onClick={()=>toggleshowpopup(url.url1)} src='/assets/images//video/Review-Video-Thumbnail-2.webp'></img>
					<div className="course__video-play1">
                                    <span  onClick={()=>toggleshowpopup(url.url1)} data-fancybox="" className="play-btn"> <i className="fa fa-play"></i> </span>
                                 </div>
				</div>
				<div className='text-center t'>
					<img style={{width:'100%',height:"90%"}}  onClick={()=>toggleshowpopup(url.url2)} src='/assets/images//video/Review-Video-Thumbnail-1.webp'></img>
					<div className="course__video-play1">
                                    <span  onClick={()=>toggleshowpopup(url.url2)} data-fancybox="" className="play-btn"> <i className="fa fa-play"></i> </span>
                                 </div>
					</div>
				<div className='text-center t'>
					<img style={{width:'100%',height:"90%"}}  onClick={()=>toggleshowpopup(url.url3)}src='/assets/images//video/Review-Video-Thumbnail-3.webp'></img>
					<div className="course__video-play1">
                                    <span  onClick={()=>toggleshowpopup(url.url3)} data-fancybox="" className="play-btn"> <i className="fa fa-play"></i> </span>
                                 </div>
				</div>
				<div className='text-center t'>
					<img style={{width:'100%',height:"90%"}}  onClick={()=>toggleshowpopup(url.url2)} src='/assets/images//video/Review-Video-Thumbnail-1.webp'></img>
					<div className="course__video-play1">
                                    <span  onClick={()=>toggleshowpopup(url.url2)} data-fancybox="" className="play-btn"> <i className="fa fa-play"></i> </span>
                                 </div>
					</div>
				</Slider>
				</div>
				</div>
			</section>
			<Bandlogo/>

			<div>
                {showContactForm && <div className="popup-container">
                    <div className="popup">


                        <div className="row">
                            <div className="col-lg-10">

                                <img className='lllog' src="/assets/images/dino.png" alt="" />
                            </div>
                            <div className="col-lg-2 text-center">
                                <button className="close-button" onClick={toggleContactForm}>Close</button>

                            </div>
                        </div>
                        <Downloadpdf slug="dynopat-brochure.pdf" />
                    </div>
                </div>
                }
            </div>
			<div>
                {showpopup&& <div className="popup-container">
                    <div className="popups">


                        <div className="row">
                            <div className="col-10">

                                <img className='lllog' src="/assets/images/dino.png" alt="" />
                            </div>
                            <div className="col-2 text-end">
                                <button className="close-button" onClick={toggleshowpopup}>Close</button>

                            </div>
                        </div>
						<iframe src={selectedVideoUrl} width="100%" height="400px" allow="autoplay" ></iframe>
                    </div>
                </div>
                }
            </div>
		</>
	)
}

export default Home