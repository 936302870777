import React, { useState } from 'react'
import '../../Style/style.css'
import { Link } from 'react-router-dom'
import Conactfrom from '../../compunent/Conactfrom'
import { Helmet } from 'react-helmet-async'


function Live_Development_Training() {
   const [showpopup, setShowpopup] = useState(false);
	const [selectedVideoUrl, setSelectedVideoUrl] = useState('');
    const toggleshowpopup= (videoUrl) => {
		setSelectedVideoUrl(videoUrl);
        setShowpopup(!showpopup);
    };
	const url ="https://www.youtube.com/embed/GVgCSzdTWDI?si=KEzwKO8QfDrR6KA8";
	
  return (
   
   <>
   <Helmet>
   <title>Salesforce Developer Training | Salesforce Live Development Training</title>
     <meta name="description" content="Do you want to master Salesforce development from scratch? Do you want hands-on training? Our Salesforce developer training is an answer to all your queries." />
     <meta name="keywords" content="Salesforce Developer Training, Salesforce Live Development Training" />
     {/* Open Graph / Facebook */}
  <meta property="og:type" content="website" />
  <meta
    property="og:url"
    content="https://dynopat.com/learn-salesforce/live-classes/live-development-training/"
  />
  <meta
    property="og:title"
    content="Salesforce Developer training | Salesforce Live Development Training"
  />
  <meta
    property="og:description"
    content="Do you want to master Salesforce development from scratch? Do you want hands-on training? Our Salesforce developer training is an answer to all your queries.  "
  />
  <meta
    property="og:image"
    content="https://dynopat.com/assets/images/lernselsforcecourse/live/development/deve.webp"
  />
  {/* Twitter */}
  <meta property="twitter:card" content="summary_large_image" />
  <meta
    property="twitter:url"
    content="https://dynopat.com/learn-salesforce/live-classes/live-development-training/"
  />
  <meta
    property="twitter:title"
    content="Salesforce Developer training | Salesforce Live Development Training"
  />
  <meta
    property="twitter:description"
    content="Do you want to master Salesforce development from scratch? Do you want hands-on training? Our Salesforce developer training is an answer to all your queries.  "
  />
  <meta
    property="twitter:image"
    content="https://dynopat.com/assets/images/lernselsforcecourse/live/development/deve.webp"
  />
        <link rel="canonical" href="https://dynopat.com/learn-salesforce/live-classes/live-development-training/" />
      </Helmet>

   
        <section className="page-title style-three">
      
		<div className="pattern-layer-one" style={{backgroundImage: "url(/</section>assets/images/background/pattern-18.png)"}}></div>
		<div className="cloud-layer" style={{backgroundImage: "url(/</section>assets/images/background/pattern-19.png)"}}></div>
		<div className="big-letter">Dynopat</div>
    	<div className="auto-container">
			<div className="row clearfix">

				<div className="title-column col-lg-6 col-md-12 col-sm-12">
					<div className="inner-column">
						<h1>Live Development Training</h1>
						<ul className="page-breadcrumb">
							<li><Link to='/'>home</Link></li>
							<li>Live Development Training</li>
						</ul>
					</div>
				</div>

				<div className="images-column col-lg-6 col-md-12 col-sm-12">
					<div className="inner-column">
						<div className="image">
							<img  loading="lazy" src="/assets/images/lernselsforcecourse/live/development/deve.webp" alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
    </section>

    
    <div className="sidebar-page-container">
    	<div className="auto-container">
        	<div className="row clearfix">

                <div className="content-side col-lg-8 col-md-12 col-sm-12">
					<div className="service-detail">
						<div className="inner-box">
                     				
          <div className="row pb-5">
         <div className="col-xxl-12">
            <div className="course__tab-conent">
               <div className="tab-content" id="courseTabContent">
                  <div className="tab-pane fade active show" id="grid" role="tabpanel" aria-labelledby="grid-tab">
                     <div className="row">
   
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                           <div className="course__item white-bg mb-30 fix">
                              <div className="course__thumb w-img p-relative fix">
                              <Link to='/learn-salesforce/live-classes/live-development-training/pd1/'>
                                    <img  loading="lazy" src="/assets/images/lernselsforcecourse/live/development/pd1.webp" alt=""/>
                                 </Link>
                                
                              </div>
                              <div className="course__content">
                                 <div className="course__meta d-flex align-items-center justify-content-between">
                                   
                                 </div>
                                 <h3 className="course__title "><Link to='/learn-salesforce/live-classes/live-development-training/pd1/'>Salesforce Platform Developer 1 Certification Training </Link></h3>
                                
                              </div>
                              <div className="course__more d-flex justify-content-between align-items-center">
                                 <div className="course__status d-flex align-items-center">
                                    {/* <span className="orange">$62.00</span> */}
                                    {/* <span className="old-price">$97.00</span> */}
                                    <Link to='/learn-salesforce/live-classes/live-development-training/pd1/' className="link-btn">
                                       Know Details 
                                       
                                    </Link>
                                 </div>
                                 <div className="course__btn">
                                 <Link to='/learn-salesforce/live-classes/live-development-training/pd1/' className="link-btn">
                                     
                                       <i className="fa fa-arrow-right"></i>
                                    </Link>
                                 </div>
                              </div>
                           </div>
                        </div>
                        
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                           <div className="course__item white-bg mb-30 fix">
                              <div className="course__thumb w-img p-relative fix">
                              <Link to='/learn-salesforce/live-classes/live-development-training/pd2/'>
                                    <img  loading="lazy" src="/assets/images/lernselsforcecourse/live/development/pd2.webp" alt=""/>
                                 </Link>
                                
                              </div>
                              <div className="course__content">
                                 <div className="course__meta d-flex align-items-center justify-content-between">
                                   
                                 </div>
                                 <h3 className="course__title"><Link to='/learn-salesforce/live-classes/live-development-training/pd2/'>Salesforce Platform Developer 2 Certification Training </Link></h3>
                                
                              </div>
                              <div className="course__more d-flex justify-content-between align-items-center">
                                 <div className="course__status d-flex align-items-center">
                                    {/* <span className="blue-2">$46.00</span> */}
                                    {/* <span className="old-price">$72.00</span> */}
                                    <Link to='/learn-salesforce/live-classes/live-development-training/pd2/' className="link-btn">
                                       Know Details 
                                    </Link>
                                 </div>
                                 <div className="course__btn">
                                 <Link to='/learn-salesforce/live-classes/live-development-training/pd2/' className="link-btn">
                                       <i className="fa fa-arrow-right"></i>
                                    </Link>
                                 </div>
                              </div>
                           </div>
                        </div>
                        
                       
                     </div>
                  </div>
                 
                </div>
            </div>
         </div>
                        </div>
              <img  loading="lazy" style={{padding:'5px',background:'#0e2863'}} src="/assets/images/lernselsforcecourse/live/development/development.webp"/>
							<h3>Overview </h3>
							<p>Do you have coding proficiency and 1-2 years of experience as a developer? Do you have experience working as an Administrator, App Builder, or software developer? Then you can try enrolling in our Salesforce Developer Bootcamp. The course is curated to enable students to create customized applications by understanding business logic. As a developer, you have to work on the platform using the programming language Apex and the Force.com platform. After going through this training, you can be eligible to sit for the Salesforce Platform Developer 1 Certification Training & Self-paced Salesforce Platform Developer 2 Certification Training.  

For this course, you need to have exceptional programming capabilities and the acumen to understand real-life business logic and problems. If you have all that it takes, you are welcome to be a part of this course. 
 </p>
 <div className="blocks-outer">
                    <div className="row clearfix">


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>Learn Apex </h5>
                                <div className="block-text">We will teach you Apex, the fundamental programming language of Salesforce.</div>
                            </div>
                        </div>


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>3 weeks duration</h5>
                                <div className="block-text">In three weeks, we will cover the basic & advanced topics of Salesforce.</div>
                            </div>
                        </div>


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>Real-time projects </h5>
                                <div className="block-text">Develop CRM projects with advanced features and functionalities. </div>
                            </div>
                        </div>


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>Dedicated Mentor Support</h5>
                                <div className="block-text">You can dedicated support from mentors at every step of your Salesforce learning.  </div>
                            </div>
                        </div>

                    </div>
                </div>
							<h4>Curriculum </h4>
							<p>
                     The Salesforce Developer Bootcamp will help you understand the basic principles of Salesforce data modeling, writing efficient codes, Salesforce Development Cycle, and how to scale the code to fit large datasets. You will also learn how to practice data modeling, create interface design, and test the code to find inaccuracies and fix them. This means you will learn how to check code coverage, application behaviors, scalability, check environment independence, and the overall security of the application. Developers will also learn the process of implementing complex codes and sharing models by using declarative and programmatic methods. As deployment is a big part of a developer’s job description, we will also cover deployment, which tools to use for deploying applications, and when to use them. 

                </p>
                <h4>Certification </h4>
							<p>

                     You will receive this certification after completing this training. The curriculum of this course will have topics from Salesforce PD1 training and Salesforce PD2 training. You can decide to appear in these exams when you are ready. 


 
                </p>
							
						</div>


					</div>
				 </div>
				

                <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
                	<aside className="sidebar sticky-top">
						
		
						
				<div className="sidebar-widget service-widget">
            <div className="course__sidebar-widget-2 white-bg mb-5">
                           <div className="course__video" onClick={()=>toggleshowpopup(url)}>
                              <div className="course__video-thumb w-img mb-25">
                                 <img  loading="lazy" src="/assets/images/video.png" alt="" />
                                 <div className="course__video-play">
                                    <a href="#" data-fancybox="" className="play-btn"> <i className="fa fa-play"></i> </a>
                                 </div>
                              </div>
                              <div className="course__video-meta mb-25 d-flex align-items-center justify-content-between">
                                 {/* <div className="course__video-price">
                                    <h5>$74.<span>00</span> </h5>
                                    <h5 className="old-price">$129.00</h5>
                                 </div>
                                 <div className="course__video-discount">
                                    <span>68% OFF</span>
                                 </div> */}
                              </div>
                              <div className="course__video-content mb-35">
                                 <ul>
                                    {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Instructor :</span> Eleanor Fant</h5>
                                       </div>
                                    </li> */}
                                    {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Lectures :</span>14</h5>
                                       </div>
                                    </li> */}
                                    <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Duration :</span>6 weeks</h5>
                                       </div>
                                    </li>
                                    {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Enrolled :</span>20 students</h5>
                                       </div>
                                    </li> */}
                                    <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Language :</span>English</h5>
                                       </div>
                                    </li>
                                 </ul>
                              </div>
                              <div className="course__payment mb-35">
                                 <h3>Price: ₹13,000</h3>
                                 
                              </div>
                    

                                                                <button onClick={()=>{window.open('https://rzp.io/l/WWAarOeF')}}  class="e-btn e-btn-7 w-100">Enroll <i class="fa fa-arrow-right"></i></button>

                           </div>
                        </div>
                  <div className="widget-content">
				  <h3 className='pb-2 text-center'>FAQs</h3>
              <div className="accordion" id="accordionExample">
                               <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingOne">
                                     <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                     Do I need to learn any programming language? 

                                     </button>
                                  </h2>
                                  <div id="collapseOne" className="accordion-collapse collapse show " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                     <div className="accordion-body">
                                     <div className="course__curriculum-info">
                                        <strong>You will learn a new programming language Apex while doing our course. If you have experience in Java, you can understand these topics a little better.  </strong>
                                       
                                        </div>
                                     </div>
                                  </div>
                               </div>
                               <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingTwo">
                                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                     Why should I learn Salesforce from Dynopat? 

                                     </button>
                                  </h2>
                                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                     <div className="accordion-body">
                                        <strong>If you are looking for hands-on Salesforce admin and development training, then you have come to the best place. By going through this course, you will learn why Salesforce is named “the best CRM platform” by businesses around the world. In this course, you will learn a new programming language Apex. You will learn how to use this programming language, and its structure to create complex applications. The content of this course is prepared in alignment with Salesforce PD1 (Platform Development) and PD2 Certifications. You will get to work on real projects that would value to your portfolio. Dynopat will offer technical support, and assistance if you ever get stuck.  
</strong>
                                     </div>
                                  </div>
                               </div>
                               <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingThree">
                                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                     Can I choose other courses from Dynopat? 


                                     </button>
                                  </h2>
                                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                     <div className="accordion-body">
                                        <strong>Yes, you can choose from our other courses that cover Salesforce Admin training, & Salesforce Developer Training. If you want to pursue a degree in LWC, you can choose Mountaineer. 
</strong>
                                     </div>
                                  </div>
                               </div>
                               <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingFour">
                                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                     Does this course guarantee a job? 


                                     </button>
                                  </h2>
                                  <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                     <div className="accordion-body">
                                        <strong>No, this course will not guarantee a job, but you will be prepared for the current job listings. You can explore the opportunities and apply for a suitable job. However, the final decision will be bestowed with the employer and it will depend on how you perform during the interview. 
</strong>

                                     
                                     </div>
                                  </div>
                               </div>
                           
                            </div>


                  </div>
                </div>

						{/* <div className="sidebar-widget contact-widget">
							<div className="widget-content" style={{backgroundImage: "url(/assets/images/background/pattern-25.png)"}}>
								<div className="title">
									Have Any Project <span>or work together ?</span>
								</div>
								<a href="contact.html" className="theme-btn contact-btn">Contact us</a>
								<div className="phone">
									<span className="icon flaticon-call-1"></span>
									Cal :<a href="tel:+0123-456-7899"> +0123 (456) 7899</a>
								</div>
							</div>
						</div> */}
						<Conactfrom/>
						
					
						{/* <div className="sidebar-widget broucher-widget">
							<div className="widget-content">
								<ul className="broucher-list">
									<li><a href="#"><span className="icon flaticon-pdf"></span>Download pdf</a></li>
									<li><a href="#"><span className="icon flaticon-edit"></span>Download doc.</a></li>
								</ul>
							</div>
						</div> */}
						
					</aside>
				</div>
				
			</div>
		</div>
	</div>
   {showpopup&& <div className="popup-container">
                    <div className="popups">


                        <div className="row">
                            <div className="col-10">

                                <img className='lllog' src="/assets/images/dino.png" alt="" />
                            </div>
                            <div className="col-2 text-end">
                                <button className="close-button" onClick={toggleshowpopup}>Close</button>

                            </div>
                        </div>
						<iframe src={selectedVideoUrl} width="100%" height="400px" ></iframe>
                    </div>
                </div>
                }
    </>
  )
}

export default Live_Development_Training