import React, { useState } from 'react'
import '../../Style/style.css'
import { Link } from 'react-router-dom'
import Conactfrom from '../../compunent/Conactfrom'
import { Helmet } from 'react-helmet-async'


function  Live_admin_training() {
    const [showpopup, setShowpopup] = useState(false);
	const [selectedVideoUrl, setSelectedVideoUrl] = useState('');
    const toggleshowpopup= (videoUrl) => {
		setSelectedVideoUrl(videoUrl);
        setShowpopup(!showpopup);
    };
	const url ="https://www.youtube.com/embed/6oSzDXpTNO0?si=zIfUy_DpfU3G4LmV";
	
  return (
   <>
   
   <Helmet>
			<title>Salesforce Live Admin Training | Salesforce Live Admin Course</title>
        	<meta name="description" content="Our Salesforce live admin training sessions are interactive where everyone comes together & engages in a practical, project-based Salesforce learning journey." />
        	<meta name="keywords" content="Salesforce Live Admin Training, Salesforce Live Admin Course" />
            <meta property="og:type" content="website" />
  <meta
    property="og:url"
    content="https://dynopat.com/learn-salesforce/live-classes/live-admin-training/admin-and-app-builder/"
  />
  <meta
    property="og:title"
    content="Salesforce Administrator and App Builder Certification | Salesforce Certification Training"
  />
  <meta
    property="og:description"
    content="Acquire in-demand skills & supercharge your career with our industry-leading Salesforce Administrator and App Builder certification training. Join us now!"
  />
  <meta
    property="og:image"
    content="https://dynopat.com/assets/images/lernselsforcecourse/live/admin/app.webp"
  />
  {/* Twitter */}
  <meta property="twitter:card" content="summary_large_image" />
  <meta
    property="twitter:url"
    content="https://dynopat.com/learn-salesforce/live-classes/live-admin-training/admin-and-app-builder/"
  />
  <meta
    property="twitter:title"
    content="Salesforce Administrator and App Builder Certification | Salesforce Certification Training"
  />
  <meta
    property="twitter:description"
    content="Acquire in-demand skills & supercharge your career with our industry-leading Salesforce Administrator and App Builder certification training. Join us now!"
  />
  <meta
    property="twitter:image"
    content="https://dynopat.com/assets/images/lernselsforcecourse/live/admin/app.webp"
  />
                <link rel="canonical" href="https://dynopat.com/learn-salesforce/live-classes/live-admin-training/" />
      		</Helmet>
     
        <section className="page-title style-three">
        
		<div className="pattern-layer-one" style={{backgroundImage: "url(/</section>assets/images/background/pattern-18.png)"}}></div>
		<div className="cloud-layer" style={{backgroundImage: "url(/</section>assets/images/background/pattern-19.png)"}}></div>
		<div className="big-letter">Dynopat</div>
    	<div className="auto-container">
			<div className="row clearfix">

				<div className="title-column col-lg-6 col-md-12 col-sm-12">
					<div className="inner-column">
						<h1>Live Admin Training</h1>
						<ul className="page-breadcrumb">
							<li><Link to='/'>home</Link></li>
							<li>Live Admin Training</li>
						</ul>
					</div>
				</div>

				<div className="images-column col-lg-6 col-md-12 col-sm-12">
					<div className="inner-column">
						<div className="image">
							<img  loading="lazy" src="/assets/images/lernselsforcecourse/live/live-class.webp" alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
    </section>

    
    <div className="sidebar-page-container">
    	<div className="auto-container">
        	<div className="row clearfix">

                <div className="content-side col-lg-8 col-md-12 col-sm-12">
					<div className="service-detail">
						<div className="inner-box">
                     				
                        <div className="row pb-5">
                                                    <div className="col-xxl-12">
                                                        <div className="course__tab-conent">
                                                            <div className="tab-content" id="courseTabContent">
                                                                <div className="tab-pane fade active show" id="grid" role="tabpanel" aria-labelledby="grid-tab">
                                                                    <div className="row">

                                                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                                                            <div className="course__item white-bg mb-30 fix">
                                                                                <div className="course__thumb w-img p-relative fix">
                                                                                    <Link to='/learn-salesforce/live-classes/live-admin-training/admin-and-app-builder/'>
                                                                                        <img  loading="lazy" src="/assets/images/lernselsforcecourse/live/admin/app.webp" alt="" />
                                                                                    </Link>

                                                                                </div>
                                                                                <div className="course__content">
                                                                                    <div className="course__meta d-flex align-items-center justify-content-between">

                                                                                    </div>
                                                                                    <h3 className="course__title"><Link to='/learn-salesforce/live-classes/live-admin-training/admin-and-app-builder/'>Salesforce Administrator & App Builder Certification</Link></h3>

                                                                                </div>
                                                                                <div className="course__more d-flex justify-content-between align-items-center">
                                                                                    <div className="course__status d-flex align-items-center">
                                                                                        {/* <span className="orange">$62.00</span> */}
                                                                                        {/* <span className="old-price">$97.00</span> */}
                                                                                        <Link to='/learn-salesforce/live-classes/live-admin-training/admin-and-app-builder/' className="link-btn">
                                                                                            Know Details
                                                                                            
                                                                                        </Link>
                                                                                    </div>
                                                                                    <div className="course__btn">
                                                                                        <Link to='/learn-salesforce/live-classes/live-admin-training/admin-and-app-builder/' className="link-btn">
                                                                                           
                                                                                            <i className="fa fa-arrow-right"></i>
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    
                                                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                                                            <div className="course__item white-bg mb-30 fix">
                                                                                <div className="course__thumb w-img p-relative fix">
                                                                                <Link to="/learn-salesforce/live-classes/live-admin-training/business-analyst/">
                                                                                        <img  loading="lazy" src="/assets/images/lernselsforcecourse/live/admin/business.webp" alt="" />
                                                                                    </Link>

                                                                                </div>
                                                                                <div className="course__content">
                                                                                    <div className="course__meta d-flex align-items-center justify-content-between">

                                                                                    </div>
                                                                                    <h3 className="course__title"><Link to="/learn-salesforce/live-classes/live-admin-training/business-analyst/">Salesforce Business Analyst Training</Link></h3>

                                                                                </div>
                                                                                <div className="course__more d-flex justify-content-between align-items-center">
                                                                                    <div className="course__status d-flex align-items-center">
                                                                                        {/* <span className="blue-2">$46.00</span> */}
                                                                                        {/* <span className="old-price">$72.00</span> */}
                                                                                        <Link to="/learn-salesforce/live-classes/live-admin-training/business-analyst/" className="link-btn">
                                                                                            Know Details
                                                                                           
                                                                                        </Link>
                                                                                    </div>
                                                                                    <div className="course__btn">
                                                                                    <Link to="/learn-salesforce/live-classes/live-admin-training/business-analyst/" className="link-btn">
                                                                                          
                                                                                            <i className="fa fa-arrow-right"></i>
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
              <img  loading="lazy" style={{padding:'5px',background:'#0e2863'}} src='/assets/images/lernselsforcecourse/live/admin/admin.webp'/>
							<h3>Overview </h3>
							<p>Our Salesforce Live Admin course is designed to help students understand why Fortune 500 companies rely on this CRM platform. We have curated the curriculum keeping these things in mind, the job profile of a Salesforce Business Analyst, Salesforce Administrator, Consultant, and App Builder. You will learn what are the tools and techniques you will need to succeed in these profiles. If you are from a non-technical background and want to become a Salesforce consultant, this is the perfect course. </p>
                            <div className='service-detail'>
                                                    <div className='inner-box'>
                                                        <div className="blocks-outer">
                                                            <div className="row clearfix">


                                                                <div className="block-column col-lg-6 col-md-6 col-sm-12">
                                                                    <div className="inner-column">
                                                                        <h5>3 weeks duration </h5>
                                                                        <div className="block-text">In three weeks, we will cover the basic & advanced topics of Salesforce.</div>
                                                                    </div>
                                                                </div>


                                                                <div className="block-column col-lg-6 col-md-6 col-sm-12">
                                                                    <div className="inner-column">
                                                                        <h5>Real-time projects</h5>
                                                                        <div className="block-text">Develop CRM projects with advanced features and functionalities</div>
                                                                    </div>
                                                                </div>


                                                                <div className="block-column col-lg-6 col-md-6 col-sm-12">
                                                                    <div className="inner-column">
                                                                        <h5>Dedicated Mentor Support</h5>
                                                                        <div className="block-text">You can get dedicated support from mentors at every step of your Salesforce learning.</div>
                                                                    </div>
                                                                </div>


                                                                <div className="block-column col-lg-6 col-md-6 col-sm-12">
                                                                    <div className="inner-column">
                                                                        <h5>Attractive Job opportunities</h5>
                                                                        <div className="block-text">Master Salesforce and start applying for high-paying jobs</div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
							<h4>Curriculum </h4>
							<p>
                            The curriculum of the entire course will be divided into the Business Analyst section, App Builder Certification, and Administrator section. We will be covering these topics on our internal pages, therefore, you will get a comprehensive view of the course. 


                </p>
                <h4>Certification </h4>
							<p>

                            Students taking this course will receive a course-completion certificate which they can add to their resumes and social media platforms. The certification will cover topics from Salesforce Administration, App Builder, and Business Analyst exams. 



 
                </p>
							
						</div>


					</div>
				 </div>
				

                <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
                	<aside className="sidebar sticky-top">
						
		
						
				<div className="sidebar-widget service-widget">
                <div className="course__sidebar-widget-2 white-bg mb-5">
                           <div className="course__video" onClick={()=>toggleshowpopup(url)}>
                              <div className="course__video-thumb w-img mb-25">
                                 <img  loading="lazy" src="/assets/images/video.png" alt="" />
                                 <div className="course__video-play">
                                    <a href="#" data-fancybox="" className="play-btn"> <i className="fa fa-play"></i> </a>
                                 </div>
                              </div>
                              <div className="course__video-meta mb-25 d-flex align-items-center justify-content-between">
                                 {/* <div className="course__video-price">
                                    <h5>$74.<span>00</span> </h5>
                                    <h5 className="old-price">$129.00</h5>
                                 </div>
                                 <div className="course__video-discount">
                                    <span>68% OFF</span>
                                 </div> */}
                              </div>
                              <div className="course__video-content mb-35">
                                 <ul>
                                    {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Instructor :</span> Eleanor Fant</h5>
                                       </div>
                                    </li> */}
                                    {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Lectures :</span>14</h5>
                                       </div>
                                    </li> */}
                                    <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Duration :</span>6 weeks</h5>
                                       </div>
                                    </li>
                                    {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Enrolled :</span>20 students</h5>
                                       </div>
                                    </li> */}
                                    <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Language :</span>English</h5>
                                       </div>
                                    </li>
                                 </ul>
                              </div>
                              <div className="course__payment mb-35">
                                 <h3>Price: ₹13,000</h3>
                                 
                              </div>
                    

                                                                <button onClick={()=>{window.open('https://rzp.io/l/MjFxE4Ookn')}}  class="e-btn e-btn-7 w-100">Enroll <i class="fa fa-arrow-right"></i></button>

                           </div>
                        </div>
                  <div className="widget-content">
				  <h3 className='pb-2 text-center'>FAQs</h3>
                  <div className="accordion" id="accordionExample">
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingOne">
                                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                                What will I get from this course?
                                                            </button>
                                                        </h2>
                                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                <div className="course__curriculum-info">
                                                                    <strong>Our Live Admin course is for students pursuing a career as a Salesforce Consultant/ Business Analyst. You don’t need to come for a technical background to pursue this course. You will learn Salesforce Administration and Salesforce Business Analysis from our curated modules.

                                                                        What makes us different from others is our 7-day free demo sessions. During these demo sessions, you can assess if this is the right course for you. We can also help you make the right decision. These demo sessions will be for an hour from 9.30 P.M. EST, and you will get to ask your questions in the Q&A session. The duration of this Q&A session is 15 minutes, and we take pride in offering dedicated 1:1 mentor support. You will get to work on real-time projects, daily assignments, and a dedicated Escalation Support Manager.
                                                                    </strong>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingTwo">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                Do I need to learn any programming language?
                                                            </button>
                                                        </h2>
                                                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                <strong>If you have any prior experience in coding, it will be beneficial for you. However, it is not mandatory to come from a coding background to enroll in this course. </strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingThree">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                Why should I learn Salesforce from Dynopat?

                                                            </button>
                                                        </h2>
                                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                <strong>If you are looking for hands-on Salesforce admin and development training, you have come to the best place. By going through this course, you will learn why Salesforce is named “the best CRM platform” by businesses around the world. In this course, you will learn a new programming language Apex. You will learn how to use this programming language, and its structure to create complex applications. The content of this course is prepared in alignment with Salesforce PD1 (Platform Development) and PD2 Certifications. You will get to work on real projects that would value to your portfolio. Dynopat will offer technical support, and assistance if you ever get stuck.   </strong>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingFour">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                                Can I choose other courses from Dynopat?


                                                            </button>
                                                        </h2>
                                                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                <strong>Yes, you can choose from our other courses that cover Salesforce Admin training, & Salesforce Developer Training. If you want to pursue a degree in LWC, you can always pursue i.</strong>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingFive">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFour">
                                                                Does this course guarantee a job?
                                                            </button>
                                                        </h2>
                                                        <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                <strong>Yes, you can choose from our other courses that cover Salesforce Admin training, & Salesforce Developer Training. If you want to pursue a degree in LWC, you can always pursue i.</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                  </div>
                </div>

						{/* <div className="sidebar-widget contact-widget">
							<div className="widget-content" style={{backgroundImage: "url(/assets/images/background/pattern-25.png)"}}>
								<div className="title">
									Have Any Project <span>or work together ?</span>
								</div>
								<a href="contact.html" className="theme-btn contact-btn">Contact us</a>
								<div className="phone">
									<span className="icon flaticon-call-1"></span>
									Cal :<a href="tel:+0123-456-7899"> +0123 (456) 7899</a>
								</div>
							</div>
						</div> */}
						<Conactfrom/>
						
					
						{/* <div className="sidebar-widget broucher-widget">
							<div className="widget-content">
								<ul className="broucher-list">
									<li><a href="#"><span className="icon flaticon-pdf"></span>Download pdf</a></li>
									<li><a href="#"><span className="icon flaticon-edit"></span>Download doc.</a></li>
								</ul>
							</div>
						</div> */}
						
					</aside>
				</div>
				
			</div>
		</div>

	</div>
    {showpopup&& <div className="popup-container">
                    <div className="popups">


                        <div className="row">
                            <div className="col-10">

                                <img className='lllog' src="/assets/images/dino.png" alt="" />
                            </div>
                            <div className="col-2 text-end">
                                <button className="close-button" onClick={toggleshowpopup}>Close</button>

                            </div>
                        </div>
						<iframe src={selectedVideoUrl} width="100%" height="400px" ></iframe>
                    </div>
                </div>
                }
    </>
  )
}

export default Live_admin_training