import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom';
import $ from 'jquery'


const Menu = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setTimeout(() => {
            setIsOpen(!isOpen);
          },100);
    };

  return (
    
    <ul className="navigation clearfix">
    <li ><Link to='/'  className="current">Home</Link>
        </li>
        <li ><NavLink to="/about-dynopat" > About Dynopat</NavLink>
        </li>
        <li className={`dropdown ${isOpen ? 'open' : ''}`}onClick={(toggleMenu)}><NavLink to='#'aria-disabled >Learn Salesforce</NavLink><div className="dropdown-btn"><span className="fa fa-angle-down"></span></div>
        <ul style={{ display: isOpen ? 'block' : 'none' }}>
                    <li><Link to='/learn-salesforce/self-paced-training/'>Self Paced Training</Link></li>
                    <li><Link to='/learn-salesforce/live-classes/'>Live Classes</Link></li>
                    </ul>
        </li> 
        {/* <li><NavLink to="/our-community" className="current">Community</NavLink>
        </li>        */}
        {/* <li><Link to="/courses/" className="current dropdown">Courses</Link></li> */}
        <li><Link to="/courses/" className="current dropdown">Courses</Link></li>
        <li><a href="https://dynopat.com/blogs/" className="current dropdown">Blog</a>
        </li>                               
        <li><Link to='/contact'>Contact</Link></li>
        <div className="button-box paynow text-center">
                     <a href= 'https://razorpay.me/@Dynopat?amount=KxK8ikz%2BGFZ8lMDydVeeuA%3D%3D/' className="theme-btn btn-style-sixteen"><span className="txt">Register Now</span></a>
          </div>
    </ul>
  )
}

export default Menu