import React, { useEffect, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar';
import Menu from './Menu';
import Navmenu from './Navmenu';
import Conactfrom from '../../Ourcourse/compunent/Conactfrom';
import axios from 'axios';
// import { Helmet } from 'react-helmet-async';




const Header = () => {
    // const script = document.createElement('script');
    // let url ='/assets/js/script.js';
    // script.src = url;   //(This is external js url)
    // script.async = true;
    // document.body.appendChild(script);
   
    const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [showContactForm, setShowContactForm] = useState(false);

  const toggleContactForm = () => {
      setShowContactForm(!showContactForm);
  };

    useEffect(() => {
		// Declare the script element variable in the component's scope
        	//Mobile Nav Hide Show

		// let externalScript;
	  
		// const LoadExternalScript = () => {
		//   externalScript = document.createElement("script");
		//   externalScript.id = "external";
		//   externalScript.async = true;
		//   externalScript.type = "text/javascript";
		//   externalScript.setAttribute("crossorigin", "anonymous");		  
		//   externalScript.src = '/assets/js/script.js';
		// };
		// LoadExternalScript();
        // return () => {
			 
        //     // Remove the script element from the DOM
        //     document.body.appendChild(externalScript);
          
        //   };
        setIsOpen(false);
        },  [location]);




        const [isMobile, setIsMobile] = useState(false);

        useEffect(() => {
          const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
          };
      
          // Initial check on component mount
          handleResize();
      
          // Add event listener for window resize
          window.addEventListener('resize', handleResize);
      
          // Clean up event listener on component unmount
          return () => window.removeEventListener('resize', handleResize);
        }, []);

        const [data, setData] = useState([]);

        useEffect(() => {
          fetchData();
        }, []);
      
        const fetchData = async () => {
          try {
            const response = await axios.get('https://dynopat.com/dynopat_admin/api/site-information');
           
            setData(response.data.siteInfo);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
  return (
    <>
    {/* <Helmet>
    <meta name="twitter:card" content="summary"/>
<meta name="twitter:site" content="@DynoPatPvtLtd"/>
<meta name="twitter:title" content="Dynopat- Salesforce Learning | Salesforce Online Training"/>
<meta name="twitter:description" content="Master Salesforce & gain in-demand skills with Dynopat’s flexible and engaging Salesforce online training. Few seats are remaining, enroll today!"/>
<meta name="twitter:image" content="https://dynopat.com/assets/images/resource/learning.svg"/>
    </Helmet> */}
   <div className="running-tet-container1">
  
    <div className="row">
      <div className="col-lg-9 col-md-12" >
      <marquee  className='running-text1 text'> Dynopat Training is the brainchild of Delipat, a company with years of experience in Salesforce Implementation</marquee>
      </div>
      <div className="col-lg-3 col-md-12 mr-3" style={{zIndex:'999999999'}} >
        <p className='running-text1 text g' style={{ color: "white" }} ><span className="fa fa-phone"></span> <a href={`tel:${data.phone_f}`} style={{ color: "white" }}>{data.phone_f}</a> || <span className="fa fa-phone"></span>{' '}<a href={`tel:${data.phone_s}`} style={{ color: 'white' }}>
          {data.phone_s}
          </a></p>
      
    </div>
  </div>
</div>

<div className="col-lg-12 col-md-12 text-center sa">
      {isMobile && (
        <p className='running-text1 text'style={{ color: 'white', background: '#0e2863' }}>
          <span className="fa fa-phone"></span>{' '}
          <a href={`tel:${data.phone_f}`} style={{ color: 'white' }}>
          {data.phone_f} 
          </a> || <span className="fa fa-phone"></span>{' '}<a href={`tel:${data.phone_s}`} style={{ color: 'white' }}>
          {data.phone_s}
          </a>
        </p>
      )}
    </div>
    <div  className={`main-header header-style-six ${isOpen ? 'mobile-menu-visible' : ''}`}>
    <div className="header-lower">
        <div className="auto-container">

            <div className="inner-container d-flex justify-content-between align-items-center">

                <div className="pull-left logo-box">
                    <div className="logo"><Link to='/'><img src="/assets/images/dino.png" className='logoheader' alt="" title=""/></Link></div>
                </div>
                

                <div className="nav-outer clearfix">

                    <div className="mobile-nav-toggler " onClick={toggleMenu}><span className="icon flaticon-menu"></span></div>

                    <Navmenu/>
                    
                </div>
                

                <div className="outer-box clearfix">
                    

                    <div className="button-box">
                   

                        {/* <a href= 'https://dynopat.com/stripe-payment/' className="theme-btn btn-style-sixteen"><span className="txt">Pay Now</span></a> */}
                        <button onClick={()=>(window.open('https://rzp.io/l/dynopat'))} className="theme-btn btn-style-sixteen"><span className="txt">Register Now</span></button>
                                                {showContactForm && <div className="popup-container">
                                                    <div className="popup">


                                                        <div className="row">
                                                            <div className="col-lg-10">

                                                                <img className='lllog' src="/assets/images/dino.png" alt="" />
                                                            </div>
                                                            <div className="col-lg-2 text-center">
                                                                <button className="close-button" onClick={toggleContactForm}>Close</button>

                                                            </div>
                                                        </div>
                                                        <Conactfrom />
                                                    </div>
                                                </div>
                                                }
                    
                    </div>
 
                    
                </div>
                
            </div>
        </div>
    </div>


    <div className="sticky-header">
        <div className="auto-container clearfix">

            <div className="logo pull-left">
                <Link to='/' title=""><img src="/assets/images/dino.png" alt="" title=""/></Link>
            </div>

            <div className="pull-right">
            

                <nav className="main-menu">
                   <Navmenu/>
                   <button onClick={()=>(window.open('https://rzp.io/l/dynopat'))} className="theme-btn btn-style-sixteen mt-3"><span className="txt">Register Now</span></button>

                </nav>

                

                <div className="mobile-nav-toggler"><span className="icon flaticon-menu-1" onClick={toggleMenu}></span></div>
                
            </div>
        </div>
    </div>


    <div className="mobile-menu">
        <div className="menu-backdrop"></div>
        <div className="close-btn" onClick={toggleMenu}><span className="icon flaticon-multiply"></span></div>
        
        <nav className="menu-box">
            <div className="nav-logo"><Link to='/'><img src="/assets/images/dino.png" alt="" title=""/></Link></div>
            <div className=""><Menu/></div>
        </nav>
    </div>

</div>

</>
  )
}

export default Header