import React, { useState } from 'react'
import '../../Style/style.css'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import Claimyourfreecourse from '../../compunent/Claimyourfreecourse'


function Salesforce_Corporate_Training() {
    const [showpopup, setShowpopup] = useState(false);
	const [selectedVideoUrl, setSelectedVideoUrl] = useState('');
    const toggleshowpopup= (videoUrl) => {
		setSelectedVideoUrl(videoUrl);
        setShowpopup(!showpopup);
    };
	const url ="https://www.youtube.com/embed/uBOWe4w_raY?si=fR3jpJui0OWlILJl";
	
    return (
        <>
        <Helmet>
        <title>Salesforce Consultant Training | Salesforce Corporate Training</title>
        <meta name="description" content="Are you looking to train and upskill your workforce? Our trainers will provide onsite/online Salesforce corporate training for 8 hours per day for a week." />
        <meta name="keywords" content="Salesforce Consultant Training, Salesforce Corporate Training" />
        {/* Open Graph / Facebook */}
  <meta property="og:type" content="website" />
  <meta
    property="og:url"
    content="https://dynopat.com/learn-salesforce/live-classes/salesforce-corporate-training/"
  />
  <meta
    property="og:title"
    content="Salesforce Consultant Training | Salesforce Corporate Training"
  />
  <meta
    property="og:description"
    content="Are you looking to train and upskill your workforce? Our trainers will provide onsite/online Salesforce corporate training for 8 hours per day for a week."
  />
  <meta
    property="og:image"
    content="https://dynopat.com/assets/images/lernselsforcecourse/live/corporate/corporate.webp"
  />
  {/* Twitter */}
  <meta property="twitter:card" content="summary_large_image" />
  <meta
    property="twitter:url"
    content="https://dynopat.com/learn-salesforce/live-classes/salesforce-corporate-training/"
  />
  <meta
    property="twitter:title"
    content="Salesforce Consultant Training | Salesforce Corporate Training"
  />
  <meta
    property="twitter:description"
    content="Are you looking to train and upskill your workforce? Our trainers will provide onsite/online Salesforce corporate training for 8 hours per day for a week."
  />
  <meta
    property="twitter:image"
    content="https://dynopat.com/assets/images/lernselsforcecourse/live/corporate/corporate.webp"
  />
            <link rel="canonical" href="https://dynopat.com/learn-salesforce/live-classes/salesforce-corporate-training/" />
          </Helmet>
 
            <section className="page-title style-three">
                
                <div className="pattern-layer-one" style={{ backgroundImage: "url(/</section>assets/images/background/pattern-18.png)" }}></div>
                <div className="cloud-layer" style={{ backgroundImage: "url(/</section>assets/images/background/pattern-19.png)" }}></div>
                <div className="big-letter">Dynopat</div>
                <div className="auto-container">
                    <div className="row clearfix">

                        <div className="title-column col-lg-6 col-md-12 col-sm-12">
                            <div className="inner-column">
                                <h2>Salesforce Corporate Training </h2>
                                <ul className="page-breadcrumb">
                                    <li><Link to="/">home</Link></li>
                                    <li>Salesforce Corporate Training </li>
                                </ul>
                            </div>
                        </div>

                        <div className="images-column col-lg-6 col-md-12 col-sm-12">
                            <div className="inner-column">
                                <div className="image">
                                    <img  loading="lazy" src="/assets/images/dynolive.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className="sidebar-page-container">
                <div className="auto-container">
                    <div className="row clearfix">

                        <div className="content-side col-lg-8 col-md-12 col-sm-12">
                            <div className="service-detail">
                                <div className="inner-box">

                                    
                                    <img  loading="lazy" style={{  padding: '5px', background: '#0e2863' }} src='/assets/images/lernselsforcecourse/live/corporate/corporate.webp' />
                                    <h3>Overview </h3>
                                    <p>Dynopat also offers Salesforce Corporate training to companies wanting to create a skilled and high-value employee community. If you are looking to get a comprehensive Salesforce certification program to scale your workforce, we are the right choice for you. With our training, your employees will be able to work on complex business projects in no time. We will customize the curriculum as per the latest trends in the market and your requirements. This training program will be delivered by the industry’s top Salesforce experts, either online or in person. Your team will also have access to the LMS content, which can be viewed any number of times. 

Our program can be tailored for freshers, people working on other technologies, and for Salesforce experts looking to upskill.
 </p>
 <h4> Our training programs </h4>
                                    <div className='service-detail'>
                                        <div className='inner-box'>
                                            <div className="blocks-outer">
                                                <div className="row clearfix">


                                                    <div className="block-column col-lg-6 col-md-6 col-sm-12">
                                                        <div className="inner-column">
                                                            <h5>Salesforce Administrator Certification </h5>
                                                           
                                                        </div>
                                                    </div>


                                                    <div className="block-column col-lg-6 col-md-6 col-sm-12">
                                                        <div className="inner-column">
                                                            <h5>Salesforce Business Analyst Certification </h5>
                                                        </div>
                                                    </div>


                                                    <div className="block-column col-lg-6 col-md-6 col-sm-12">
                                                        <div className="inner-column">
                                                            <h5>Salesforce Administrator and App Builder Certification </h5>
                                                        </div>
                                                    </div>


                                                    <div className="block-column col-lg-6 col-md-6 col-sm-12">
                                                        <div className="inner-column">
                                                            <h5>Salesforce Platform Developer 1 Certification </h5>
                                                         
                                                        </div>
                                                    </div>
                                                    <div className="block-column col-lg-12 col-md-12 col-sm-12">
                                                        <div className="inner-column">
                                                            <h5>Salesforce Platform Developer 2 Certification </h5>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                 

                                </div>


                            </div>
                        </div>


                        <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
                            <aside className="sidebar sticky-top">


                            
                        <div className="course__sidebar-widget-2 white-bg mb-20">
                           <div className="course__video" onClick={()=>toggleshowpopup(url)}>
                              <div className="course__video-thumb w-img mb-25">
                                 <img  loading="lazy" src="/assets/images/video.png" alt="" />
                                 <div className="course__video-play">
                                    <a href="#" data-fancybox="" className="play-btn"> <i className="fa fa-play"></i> </a>
                                 </div>
                              </div>
                              <div className="course__video-meta mb-25 d-flex align-items-center justify-content-between">
                                 {/* <div className="course__video-price">
                                    <h5>$74.<span>00</span> </h5>
                                    <h5 className="old-price">$129.00</h5>
                                 </div>
                                 <div className="course__video-discount">
                                    <span>68% OFF</span>
                                 </div> */}
                              </div>
                              <div className="course__video-content mb-35">
                                 <ul>
                                    {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Instructor :</span> Eleanor Fant</h5>
                                       </div>
                                    </li> */}
                                    {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Lectures :</span>14</h5>
                                       </div>
                                    </li> */}
                                    <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Duration :</span>3 weeks</h5>
                                       </div>
                                    </li>
                                    {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Enrolled :</span>20 students</h5>
                                       </div>
                                    </li> */}
                                    <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Language :</span>English</h5>
                                       </div>
                                    </li>
                                 </ul>
                              </div>
                              <div className="course__payment mb-35">
                                 <h3>price: ₹ 25,000</h3>
                                 
                              </div>
                    <Claimyourfreecourse />

                    <button onClick={()=>{window.open('https://rzp.io/l/CRzlgUG')}}  class="e-btn e-btn-7 w-100">Enroll <i class="fa fa-arrow-right"></i></button>


                           </div>
                        </div>
                                <div className="sidebar-widget service-widget">

                                    <div className="widget-content">
                                        <h3 className='pb-2 text-center'>FAQs</h3>
                                        <div className="accordion" id="accordionExample">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                    Why Should You Choose Us? 

                                                    </button>
                                                </h2>
                                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <div className="course__curriculum-info">
                                                        <ul>

                                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}>Course curated by top experts 

Dynopat houses a team of Salesforce experts, led by Mr. Rajesh Chatterjee, who can create the most updated content, tailored for your business’s specific needs. 
</li>
                                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}>Chance to work on real scenarios 
Your team will get to understand how to utilize Salesforce for real-world scenarios, such as lead management, creating reports, and creating customer-specific apps. 
</li>
                                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}>Skill Assessment & Performance Feedback 
We will arrange skill assessment tests for your employees and provide performance feedback to help them master the essentials. 
</li>
                                                       
                                                        </ul>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingTwo">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    Aside from these, you will also get: 
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                    <ul>

<li style={{ listStyleType: 'circle', marginLeft: '30px' }}>Dedicated mentor support </li>
<li style={{ listStyleType: 'circle', marginLeft: '30px' }}>Q&A sessions</li>
<li style={{ listStyleType: 'circle', marginLeft: '30px' }}>Technical assistance </li>
</ul>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>


                                    </div>
                                </div>

                                {/* <div className="sidebar-widget contact-widget">
							<div className="widget-content" style={{backgroundImage: "url(/assets/images/background/pattern-25.png)"}}>
								<div className="title">
									Have Any Project <span>or work together ?</span>
								</div>
								<a href="contact.html" className="theme-btn contact-btn">Contact us</a>
								<div className="phone">
									<span className="icon flaticon-call-1"></span>
									Cal :<a href="tel:+0123-456-7899"> +0123 (456) 7899</a>
								</div>
							</div>
						</div> */}
                      
                               



                                {/* <div className="sidebar-widget broucher-widget">
							<div className="widget-content">
								<ul className="broucher-list">
									<li><a href="#"><span className="icon flaticon-pdf"></span>Download pdf</a></li>
									<li><a href="#"><span className="icon flaticon-edit"></span>Download doc.</a></li>
								</ul>
							</div>
						</div> */}

                            </aside>
                        </div>

                    </div>
                </div>
            </div>
            {showpopup&& <div className="popup-container">
                    <div className="popups">


                        <div className="row">
                            <div className="col-10">

                                <img className='lllog' src="/assets/images/dino.png" alt="" />
                            </div>
                            <div className="col-2 text-end">
                                <button className="close-button" onClick={toggleshowpopup}>Close</button>

                            </div>
                        </div>
						<iframe src={selectedVideoUrl} width="100%" height="400px" ></iframe>
                    </div>
                </div>
                }

        </>
    )
}

export default Salesforce_Corporate_Training
