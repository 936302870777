import React, { useState, useEffect } from 'react';
import './banner.css';

const FadeInText = ({ text }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <div className="fade-in-text-container">
      {text.split('').map((char, index) => {
        if (char === '\n') {
          return <span key={index}><br/> </span>;
        } else if (char === ' ') {
          return <span key={index}>&nbsp;</span>;
        } else {
          return (
            <span
              key={index}
              className={`fade-in-text-char ${visible ? 'visible' : ''}`}
              style={{ transitionDelay: `${index * 100}ms` }}
            >
              {char}
            </span>
          );
        }
      })}
    </div>
  );
};

export default FadeInText;
