import React from 'react'
import { Helmet } from 'react-helmet-async'

const PrivacyPolicy = () => {
    return (
        <>
        
			<Helmet>
			<title>Dynopat Home</title>
        	<meta name="description" content="Your meta description" />
        	<meta name="keywords" content="keyword1, keyword2, keyword3" />
        	<meta property="og:title" content="Your Open Graph title" />
        	<meta property="og:description" content="Your Open Graph description" />
        	<meta property="og:image" content="URL to your Open Graph image" />
       		 {/* Other OG tags */}
      		</Helmet>
   
        <section className='page__title-area pt-120 pb-90'>
            <div className='container'>
                <div className='row'>
                    <h2 className='text-center pb-4'>Dynopat Privacy Policy </h2>
                    <strong>BEFORE PROCEEDING, READ OUR PRIVACY POLICY CAREFULLY
                    </strong>
                    <p >We at Dynopat private limited (“Dynopat”, “we”, “our (as in our website)”, “us”) respect your privacy and want you to understand how we collect, use, and share data about you. This Privacy Policy covers our data collection practices and describes your rights to access, correct, or restrict the use of your personal data.</p>
                   
                    <p >Unless we link to a different policy or state otherwise, this Privacy Policy applies when you visit or use our website, mobile applications, APIs, or related services (the “Services”).</p>
                   
                    <p >You can get in touch with our team at </p><a href="mailto:info@dynopat.com"><p >info@dynopat.com</p></a>
                    
                    <p >By using the Services, you agree to the terms of this Privacy Policy. You shouldn’t use our Services if you don’t agree with this Privacy Policy or any other agreement that governs your use of the Services.</p>
                    <h4>LEGAL BASIS FOR PROCESSING DATA</h4>
                    <p >We process your data:</p>
                    
                    <ul>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >With your explicit consent for collecting, storing, and processing your data</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Our legal interests, i.e. registering and administering accounts on our Services to provide you access to all content, updates, and services you have purchased, and to facilitate the efficient running and operation of our business</p></li>
                    </ul>
                    <h4>DATA COLLECTION</h4>
                    <p>We collect certain data from you directly, like the information you enter yourself, data about your participation in courses, and data from third-party platforms you connect with our website. We also collect some data automatically, like information about your device and what parts of our Services you interact with or spend time using cookies.</p>
                    <h4>Data You Provide to Us</h4>
                    
                    <p >We may collect different data from or about you depending on how you use the Services. Below are some examples to help you better understand the data we collect</p>

                    <p >When you create an account and use the Services, including through a third-party platform, we collect the following data you provide directly:</p>
                    
                    <ul>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Account Data- When you create an account, we may store your name, email address, IP address, phone number, user name, courses (in which you have/ will enroll or what you have enquired), educational qualification, and work experience.  </p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >We collect data from your testimonials which are publicly viewed by others depending on where it is posted</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >We collect data about your enrolled/ completed programs, assignments, projects, quizzes, and progress rate. </p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >We collect your payment data (that you provided at the time of billing) for future reference. This may include your name, billing address, credit card/ PayPal/ Stripe information, and zip code. However, we will not store your credit card numbers or any authentication data. </p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >We may organize any giveaways/ offers from time to time. If you choose to participate, we will collect your personal data (name, email, phone number). Your data is governed by this privacy policy unless otherwise stated. </p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >We may collect your name, email, phone number, and your preferred courses when we run promotional advertisements on our website and social media pages. </p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >When you contact us, we will collect and store your contact information, message, or any other data you have provided.</p></li>
                    </ul>
                    <h4>HOW DO COLLECT YOUR DATA </h4>
                    <p >We use tools like cookies, web beacons, analytics services, and advertising providers to gather the data listed above. Some of these tools offer you the ability to opt out of data collection.</p>
                    <h5><p >Cookies </p></h5>
                    
                    <p >Dynopat and service providers acting on our behalf (like Google Analytics and third-party advertisers) use server log files and automated data collection tools like cookies, tags, scripts, customized links, device or browser fingerprints, and web beacons (together, “Data Collection Tools”) when you access and use the Services. These Data Collection Tools automatically track and collect certain System Data and Usage Data when you use the Services. In some cases, we tie data gathered through those Data Collection Tools to other data that we collect as described in this Privacy Policy.</p>
                    
                    <p >We use cookies for things like analyzing your use of the Services, personalizing your experience, making it easier to log into the Services, and recognizing you when you return. We use cookies for things like identifying whether a page was visited, identifying whether an email was opened, and advertising more efficiently by excluding current users from certain promotional messages or identifying the source of a new page visit.</p>
                    
                    <p >The types of cookies we use are: </p>
                    
                    <ul>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Essential Cookies: These cookies are essential for the basic functionalities offered by the Site and Service(s). They help in keeping a user logged in to the Service(s) and remember relevant information when they return to the Service(s).</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Insight Cookies: These are used for tracking the user activities within the Service(s), which in turn helps us in improving your user experience.</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Marketing Cookies: These are used for providing you with customized and interest-based ads based on your browsing behavior and other similar activities on our Websites.</p></li>
                    </ul>
                    <p >You can set your web browser to alert you about attempts to place cookies on your computer, limit the types of cookies you allow, or refuse cookies altogether. If you do, you may not be able to use some or all features of the Services, and your experience may be different or less functional.</p>
                    <h5><p >Analytics</p></h5>
                    <p >We use 3rd-party browsers, mobile analytics services (such as Google Analytics), and Data Collection Tools to help us analyze your use of the Services, including information like the third-party website you arrive from, how often you visit, events within the Services, usage, and performance data. We use this data to improve our Services, better understand how the Services perform on different devices and provide information that may be of interest to you.</p>
                    
                    <h5>Online Advertising</h5>

                    <p >We use third-party advertising services like Facebook, Google’s ad services, and other ad networks and ad servers to deliver advertising about our Services on other websites and applications you use. The ads may be based on things we know about you, like your Usage Data and System Data, and things that these ad service providers know about you based on their tracking data. The ads can be based on your recent activity or activity over time and across other sites and services and may be tailored to your interests.</p>

                    <p >Depending on the types of advertising services we use, they may place cookies or other tracking technologies on your computer, phone, or other devices to collect data about your use of our Services, and may access those tracking technologies in order to serve these tailored advertisements to you. To help deliver tailored advertising, we may provide the service providers with your email address and content that you share publicly on the Services.</p>
                    <h4>4. WHAT WE USE YOUR DATA FOR, AND HOW LONG DO WE STORE IT</h4>
                    
                    <ul>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >provide our Services, communicate with you, troubleshoot issues, secure against fraud and abuse, improve and update our Services, analyze how people use our Services, serve personalized advertising, and as required by law or necessary for safety and integrity.</p></li>
                    </ul>
                    <p >We use the data we collect through your use of the Services to:</p>
                    <ul>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Provide and administer the Services, including displaying customized content and facilitating communication with other users;</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Process your requests and orders for programs, courses, specific services, information, or features;</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Communicate with you about your account by:</p>
                            <ul>
                                <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Responding to your questions and concerns;</p></li>
                                <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Sending you administrative messages and information, including messages from instructors and teaching assistants, notifications about changes to our Service, and updates to our agreements;</p></li>
                                <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Sending you information and in-app messages about your progress in courses, feedback about the course, referral/reward programs, new services, new features, promotions, newsletters, and other available courses (which you can opt out of at any time);</p></li>
                            </ul>
                        </li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Manage your account preferences;</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Facilitate the Services’ technical functioning, including troubleshooting and resolving issues, securing the Services, and preventing fraud and abuse;</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Solicit feedback from users;</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Learn more about you by linking your data with additional data through third-party data providers or analyzing the data with the help of analytics service providers;</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Identify unique users across devices;</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Tailor advertisements across devices;</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Improve our Services and develop new products, services, and features;</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Analyze trends and traffic, track purchases, and track usage data;</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Advertise the Services on third-party websites and applications;</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >As required or permitted by law; or</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >As we, in our sole discretion, otherwise determine to be necessary to ensure the safety or integrity of our users, employees, third parties, the public, or our Services.</p></li>
                    </ul>
                    
                    <h5>Criteria for Determining Data Retention Period</h5>

                    <p >It is not possible for us to determine a specific period for which we may retain your data. However, we will retain your data for longer than necessary, taking into account the following:</p>
                    <ul>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >The purpose(s) and use of your data, both now and in the future (such as whether it is necessary to continue to store that information in furtherance of our obligations under a contract with you, or to contact you in the future);</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Whether we have any legal obligation to continue processing your data (such as any record-keeping obligations imposed by any applicable law);</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Whether we have any further legal basis to continue processing your information (including your consent);</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Whether there is any relevant agreed industry practice on how long such data should be retained;</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >The levels of risk and liability involved for us to continue holding the data;</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >The amount of difficulty we may face to ensure the data can be kept updated and accurate; and</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Any other circumstances (such as the nature and status of Dynopat’s relationship with you).</p></li>
                    </ul>
                    <h4>5. WHO DO WE SHARE YOUR DATA WITH</h4>
                    <p >We share certain data about you with instructors and learning managers. We may also share your data as needed for security, legal compliance, or as part of a corporate restructuring. Lastly, we can share data in other ways if it is aggregated or de-identified or if we get your consent.</p>

                    <p >We may share your data with third parties under the following circumstances or as otherwise described in this Privacy Policy:</p>
                    <ul>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >With Your Learning Consultants, Admissions, and Dynopat Team: We share data that we have about you with your Course Advisor / Admission Team, so they can suggest the right program or course for you. This data may include your city, country, email id, phone number, work experience, and other work-related information.</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >With Your Instructors, Evaluators, and Moderators: We share data that we have about you with instructors or teaching assistants for courses you enroll in so they can improve the course for you. Also, this data will be used by them to understand your performance in the program and helps in evaluating you for the skills you gained in the program for the issuing of a certificate from the respective college/university as per required for the book-keeping purpose.</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >The information shared with them are your email id, phone number, your work experience, your performance-related information, and your activities on the learning management system.</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >With Students, the public, and other mentors: If you’re a mentor or Instructor, we share data that we have about you with our students, program managers, and employees of Dynopat, so they can reach out to you to clarify questions or provide services to you. This data may include things like your name, email id, phone number, professional and educational details, and your activities on our website and learning management system.</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >With Service Providers, Contractors, and Agents: We share your data with third-party companies who perform services on our behalf, like payment processing, and advertising services (including retargeted advertising). These service providers may access your personal data and are required to use it solely as we direct, to provide our requested service.</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >For Security and Legal Compliance: We may disclose your data to third parties if we (in our sole discretion) have a good faith belief that the disclosure is:</p>
                            <ul>
                                <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Permitted or required by law;</p></li>
                                <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Requested as part of a judicial, governmental, or legal inquiry, order, or proceeding;</p></li>
                                <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Reasonably necessary as part of a valid subpoena, warrant, or other legally-valid requests;</p></li>
                                <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Reasonably necessary to enforce our Terms of Use, Privacy Policy, and other legal agreements;</p></li>
                                <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Required to detect, prevent, or address fraud, abuse, misuse, potential violations of law (or rule or regulation), or security or technical issues; or</p></li>
                                <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Reasonably necessary in our discretion to protect against imminent harm to the rights, property, or safety of Dynopat, our users, employees, members of the public, or our Services.</p></li>
                                <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >We may also disclose data about you to our auditors and legal advisors in order to assess our disclosure obligations and rights under this Privacy Policy.</p></li>
                            </ul>
                        </li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >During a Change in Control: If Dynopat undergoes a business transaction like a merger, acquisition, corporate divestiture, or dissolution (including bankruptcy), or a sale of all or some of its assets, we may share, disclose, or transfer all of your data to the successor organization during such transition or in contemplation of a transition (including during due diligence).</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >After Aggregation/De-identification: we can disclose or use aggregate or de-identified data for any purpose.</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >With Your Permission: with your consent, we may share data with third parties outside the scope of this Privacy Policy.</p></li>
                    </ul>
                    <h4>6. INFORMATION SECURITY</h4>
                    <p >Our Websites and Service(s) have industry-standard security measures in place to protect against the loss, misuse, and alteration of the information under our control. When you provide us with sensitive information (such as credit card information or login credentials), we will encrypt that information via Secure Socket Layer (SSL).</p>

                    <p >Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our Websites or via the Service(s) and any transmission is at your own risk. Once we receive your personal information, we will use strict procedures and security features to try to prevent unauthorized access.</p>

                    <p >We adopt appropriate data collection, storage, and processing practices, and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information, username, password, transaction information, and data stored on our Site.</p>
                    <ul>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Our website is scanned on a regular basis for security holes and known vulnerabilities in order to make your visit to our site as safe as possible.</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >We use regular Malware Scanning.</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Your personal information is contained behind secured networks and is only accessible by a limited number of persons with special access rights to such systems and are required to keep the information confidential. In addition, all sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology.</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >All payment transactions are processed through secure gateway providers and are not stored or processed on our servers</p></li>
                    </ul>
                    <h4>7. YOUR RIGHTS</h4>
                    <p >You have certain rights around the use of your data, including the ability to opt out of promotional emails, cookies, and collection of your data by certain analytics providers. You can update or terminate your account from within our Services, and can also contact us for individual rights requests about your personal data such as restricting our processing of your data, erasing all data we have stored about you, and the right to ask for the data to be provided to you/ported to any other service in a commonly used machine-readable format.</p>
                    
                    <h5>Your Choices about the Use of Your Data</h5>
                    
                    <p >You can choose not to provide certain data to us, but you may not be able to use certain features of the Services</p>
                    <ul>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >To stop receiving promotional communications from us, you can opt out by using the unsubscribe mechanism in the promotional communication you receive or by changing the email preferences in your account. Note that regardless of your email preference settings, we will send you transactional and relationship messages regarding the Services, including administrative confirmations, order confirmations, important updates about the Services, and notices about our policies.</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >The browser or device you use may allow you to control cookies and other types of local data storage. Your wireless device may also allow you to control whether location or other data is collected and shared.</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p>To get information and control cookies used for tailored advertising from participating companies, see the consumer opt-out pages for the <a href="http://optout.networkadvertising.org/?c=1">Network Advertising Initiative</a> and <a href="http://optout.aboutads.info/?c=2&amp;lang=EN">Digital Advertising Alliance</a>, or if you’re located in the European Union, visit the <a href="http://www.youronlinechoices.eu/">Your Online Choices site</a>. To opt out of Google’s display advertising or customize Google Display Network ads, visit the <a href="https://adssettings.google.com/authenticated">Google Ads Settings page.</a></p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p>To opt out of allowing Google Analytics, to use your data for analytics or enrichment, see the <a href="https://tools.google.com/dlpage/gaoptout"> Google Analytics Opt-out Browser Add-on.</a></p></li>
                    </ul>
                    <p >If you have any questions about your data, our use of it, or your rights, contact us at </p><a href="mailto:info@dynopat.com"><p >info@dynopat.com</p></a>
                    
                    <h5>Accessing, Updating and Correcting, Porting and Deleting Your Personal Data</h5>

                    <p >You can access and update the personal data that Dynopat collects and maintains as follows:</p>
                    <ul>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >To update data you provide directly, log into your account and update your account at any time.</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >To terminate your account you can drop us an email to <a href="mailto:info@dynopat.com">info@dynopat.com</a>to delete your account permanently.</p>
                            <ul>
                                <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >Please note: even after your account is terminated, some or all of your data may still be visible to others, including without limitation any data that has been (a) copied, stored, or disseminated by other users (including in course comment); (b) shared or disseminated by you or others (including in your shared content); or (c) posted to a third-party platform. Even after your account is terminated, we retain your data for as long as we have a legitimate purpose to do so (and in accordance with applicable law), including to assist with legal obligations, resolve disputes, and enforce our agreements. We may retain and disclose such data pursuant to this Privacy Policy after your account has been terminated.</p></li>
                            </ul>
                        </li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >To request to access, correct, port, or delete your personal data, or to restrict processing of your personal data, please email info@dynopat.com. Please allow up to 30 days for a response. For your protection, we may require that the request be sent through the email address associated with your account, and we may need to verify your identity before implementing your request. Please note that post deletion, we may retain certain data where we have a lawful basis to do so, including for mandatory record-keeping and to complete transactions.</p></li>
                    </ul>
                    <h5>Revocation of Consent</h5>

                    <p >We recognize that the processing of your data may be based on your consent. In case you wish to withdraw such consent given to us for the processing of your data as identified in Section 3 above, please e-mail us at info@dynopat.com. Please allow up to 30 days for a response. For your protection, we may require that the request be sent through the email address associated with your account and may need to verify your identity before implementing your request.</p>

                    <p >Further, please note that such revocation of consent shall be prospective in nature, and shall not affect the lawfulness of any data processed based on your consent given prior to revocation.</p>
                    
                    <h5>Complaints about our privacy policy or use of your data</h5>

                    <p >While we take all measures to protect and secure your data, we understand that you may have grievances about our use of your data, or of our data protection measures. Please feel free to reach out to us at </p><a href="mailto:info@dynopat.com"><p >info@dynopat.com</p></a><p > with any such complaints.</p>

                    <p >In case you are not satisfied with our response, you can also reach out to the national data protection authorities in your region (provided you are in the EU). The list of national data protection authorities for EU member states may be accessed </p><a href="https://edpb.europa.eu/about-edpb/board/members_en"><p >here.</p></a>

                    <p >Please note that while we urge you to write to us for any grievances or complaints you may have, this does not preclude you from directly approaching the national data protection authorities mentioned above.</p>
                    
                    <h5>Children's Personal Information</h5>

                    <p >Dynopat does not knowingly collect any personal information from children under the age of 16. If you are under the age of 16, please do not submit any personal information through our Websites or Service(s). We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce this Policy by instructing their children never to provide personal information through our Service(s) or Websites without their permission. If you have reason to believe that a child under the age of 16 has provided personal information to us through our Websites or Service(s), please contact us and we will remove that information and terminate the child's account from our databases.</p>
                    <h4>8. JURISDICTION-SPECIFIC RULES</h4>
                    <p >If you live in California, you have certain rights to request information. </p>
                    
                    <h5>Users in California</h5>

                    <p >If you are a California resident, you have the right to request certain details about what personal information we share with third parties for those third parties' direct marketing purposes. To submit your request, send an email to </p><a href="mailto:info@dynopat.com"><p >info@dynopat.com</p></a>
                    
                    <p >Since the internet industry is still working on Do Not Track standards, solutions, and implementations, we do not currently recognize or respond to browser-initiated Do Not Track signals.</p>
                    
                    <h4>9. UPDATES &amp; CONTACT INFO</h4>
                    <p >When we make a material change to this policy, we’ll notify users via email, in-product notice, or another mechanism required by law. Changes become effective the day they’re posted. Please contact us via email with any questions, concerns, or disputes.</p>
                    
                    <h5>Modifications to This Privacy Policy</h5>

                    <p >From time to time, we may update this Privacy Policy. If we make any material change to it, we will notify you via email, through a notification posted on the Services, or as required by applicable law. We will also include a summary of the key changes. Unless stated otherwise, modifications will become effective on the day they are posted.</p>

                    <p >As permitted by applicable law, if you continue to use the Services after the effective date of any change, then your access and/or use will be deemed an acceptance of (and agreement to follow and be bound by) the revised Privacy Policy. The revised Privacy Policy supersedes all previous Privacy Policies.</p>
                    
                    <h5>Interpretation</h5>

                    <p >Any capitalized terms not defined in this policy are defined as specified in Dynopat's Terms of Use.</p>
                    
                    <h5>Questions</h5>

                    <p >If you have any questions, concerns, or disputes regarding our Privacy Policy, please feel free to contact our privacy team (including our designated personal information protection manager) at </p><a href="mailto:info@dynopat.com"><p >info@dynopat.com</p></a><p >.  </p>
                    <h4>10. COOKIE POLICY</h4>
                    <p >Dynopat and our third-party partners, such as our advertising and analytics partners, (Google Adwords, Google Analytics, Google Drive, Facebook, Linkedin, Bing, Mailchimp, Zoho CRM, Zendesk, Getsitecontrol, Quora) use cookies and other tracking technologies (e.g., web beacons, device identifiers, and pixels) to provide functionality and to recognize you across different Services and devices.</p>

                    <p >You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser settings. Since each browser is different, look at your browser's Help Menu to learn the correct way to modify your cookies.</p>

                    <p >If you turn cookies off, some of the features that make your site experience more efficient may not function properly.</p>

                    <p >To learn more about targeting and advertising cookies and how you can opt-out, visit </p><a href="http://www.allaboutcookies.org/manage-cookies/index.html"><p >www.allaboutcookies.org/manage-cookies/index.html</p></a><p >, or if you’re located in the European Union, visit the </p><a href="http://www.youronlinechoices.eu/"><p >Your Online Choices site</p></a><p >.</p>

                    <p >Please note that where advertising technology is integrated into the Services, you may still receive advertising on other websites and applications, but it will not be tailored to your interests.</p>
                    <h4>11. LINKS TO THIRD PARTY SITES</h4>
                    <p >Our Websites contain links to other websites that are not owned or controlled by Dynopat. Please be aware that we are not responsible for the privacy practices of such other websites or third parties. We encourage you to be aware when you leave our Websites and to read the privacy policies of each and every website that collects personal information.</p>
                    <h4>12. PUBLIC FORUMS</h4>
                    <p >Our Websites offer publicly accessible blogs or community forums. You should be aware that any information you provide in these areas may be read, collected, and used by others who access them. To request the removal of your personal information from our blog or community forum, you need to write to </p><a href="mailto:info@dynopat.com"><p >info@dynopat.com</p></a><p >.  </p>
                    
                    <p >In some cases, we may not be able to remove your personal information, in which case we will let you know that we are unable to do so and why.</p>
                    
                    <p >Dynopat may provide blogs, or community threads on the Company’s Web sites. Any personal information you choose to submit in such a forum may be read, collected, or used by others who visit these forums and may be used to send you unsolicited messages. Dynopat is not responsible for the personal information you choose to submit in these forums.</p>

                    <p >Remember that when you share information publicly, it may be indexable by search engines.</p>
                    <h4>13. SINGLE SIGN-ON &amp; OTHER SOCIAL MEDIA FEATURES</h4>
                    <p >Our Websites embed social media features, such as the Facebook “Like” button, and the “Share” button. Where we have your consent, these features may collect your IP address, and which page you are visiting on our Websites, and may set a cookie to enable the feature to function properly. Social media features and widgets are either hosted by a third party or hosted directly on our Websites. Your interactions with these features are governed by the privacy policy of the company providing them.</p>

                    <p >You can log in to our Websites using sign-in services by Google. The services will authenticate your identity and provide you the option to share certain personal information with us such as your name and email address.</p>
                    <h4>Updates &amp; Contact Info</h4>
                    <p >From time to time, we may update this Cookie Policy. If we do, we’ll notify you by posting the policy on our site with a new effective date. If we make any material changes, we’ll take reasonable steps to notify you in advance of the planned change.</p>

                    <p >If you have any questions about our use of cookies, please email us at </p><a href="mailto:info@dynopat.com"><p >info@dynopat.com</p></a><p >. </p>
                <h4>Refund: </h4>
                <p>As we provide a 7-day free demo workshop on Salesforce Live Admin training, we don't refund any payment made later than that.</p>
                </div>
            </div>
        </section>
        </>
    )
}

export default PrivacyPolicy