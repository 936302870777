import React from 'react'

const Refundpolicy = () => {
    return (
        <section className='page__title-area pt-120 pb-90'>
            <div className='container'>
                <div className='row'>
                    <h2 className='text-center pb-4'>Refund & Cancellation </h2>
                    <p>Thank you for choosing Dynopat. We want to make sure that our students have a rewarding experience while they are assessing and purchasing our training courses, whether it may be for self-paced or online live training courses.
These are the terms and conditions of our Refund Policy. When you buy a training course on Dynopat’s website you agree to our Privacy Policy and Terms & Conditions, and the points mentioned below.</p>
                   <h4>CANCELLATION AND REFUNDS: LIVE CLASSES </h4>
                   <p>Dynopat reserves the right to postpone/cancel an event because of instructor illness or force-majeure events (like floods, earthquakes, political instability, or the pandemic).</p>
                   <ul>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >When you have attended the live classroom training for more than 7 days or accessed/downloaded course material from the learning portal, you cannot make any request for a refund.</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >If the learners want to re-attend the online instructor-led classes for the purchased course and if their no availability of the batch in the near future, then we may come up with a contingency plan.</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >If you cannot access the self-paced videos or class recordings due to technical reasons or have already attended the complete course through instructor-led online classes then no refund is applicable.</p></li>
                    </ul>
                    <h4>CANCELLATION AND REFUNDS: SELF-PACED LEARNING</h4>
                    <ul>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >No refund will be done for our LMS courses.</p></li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} ><p >We will revoke your access if we find that the course has been shared with others or it has been copied, then the account will be blocked and you cannot access the course anymore.</p></li>
                    </ul>
                    </div>
                    </div>
        </section>
    )
}

export default Refundpolicy