import React, { useEffect, useState } from 'react'
import Conactfrom from '../compunent/Conactfrom';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
const Live_classes = () => {
   const [data, setData] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://dynopat.com/dynopat_admin/api/category/cat');
       console.log(response.data.data)
      setData(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  return (
    <>
    
			<Helmet>
			<title>Salesforce Live Training | Salesforce Business Analyst Certification Training</title>
         <meta name="description" content="A business analyst will analyze business requirements & create documents. Join our Salesforce Business Analyst certification training program to learn more." />
        	<meta name="keywords" content="Salesforce Live Training, Salesforce Business Analyst Certification Training" />
        
        {/* Open Graph / Facebook */}
  <meta property="og:type" content="website" />
  <meta
    property="og:url"
    content="https://dynopat.com/learn-salesforce/live-classes/"
  />
  <meta
    property="og:title"
    content="Salesforce Live Training | Salesforce Business Analyst Certification Training"
  />
  <meta
    property="og:description"
    content="A business analyst will analyze business requirements & create documents. Join our Salesforce Business Analyst certification training program to learn more."
  />
  <meta
    property="og:image"
    content="https://dynopat.com/assets/images/Live%20Class%20Image.png"
  />
  {/* Twitter */}
  <meta property="twitter:card" content="summary_large_image" />
  <meta
    property="twitter:url"
    content="https://dynopat.com/learn-salesforce/live-classes/"
  />
  <meta
    property="twitter:title"
    content="Salesforce Live Training | Salesforce Business Analyst Certification Training"
  />
  <meta
    property="twitter:description"
    content="A business analyst will analyze business requirements & create documents. Join our Salesforce Business Analyst certification training program to learn more."
  />
  <meta
    property="twitter:image"
    content="https://dynopat.com/assets/images/Live%20Class%20Image.png"
  />
              <link rel="canonical" href="https://dynopat.com/learn-salesforce/live-classes/" />
      		</Helmet>
     
        <section className="page-title style-three">
        
		<div className="pattern-layer-one" style={{backgroundImage: "url(/</section>assets/images/background/pattern-18.png)"}}></div>
		<div className="cloud-layer" style={{backgroundImage: "url(/</section>assets/images/background/pattern-19.png)"}}></div>
		<div className="big-letter">Dynopat</div>
    	<div className="auto-container">
			<div className="row clearfix">

				<div className="title-column col-lg-6 col-md-12 col-sm-12">
					<div className="inner-column">
						<h1>Dynopat Live Classes</h1>
						<ul className="page-breadcrumb">
							<li><Link to='/'>home</Link></li>
							<li>Live Classes</li>
						</ul>
					</div>
				</div>

				<div className="images-column col-lg-6 col-md-12 col-sm-12">
					<div className="inner-column">
						<div className="image">
							<img  loading="lazy" src="/assets/images/dynolive.png" alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
    </section>
    
    <div className="sidebar-page-container">
    	<div className="auto-container">
        	<div className="row clearfix">
            
           

                <div className="content-side col-lg-8 col-md-12 col-sm-12">
					<div className="service-detail">

               <div className='text-center'>
            <div className="row pb-5">
         <div className="col-xxl-12">
            <div className="course__tab-conent">
               <div className="tab-content" id="courseTabContent">
                  <div className="tab-pane fade active show" id="grid" role="tabpanel" aria-labelledby="grid-tab">
                     <div className="row">
   
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                           <div className="course__item white-bg mb-30 fix">
                              <div className="course__thumb w-img p-relative fix">
                              <Link to='/learn-salesforce/live-classes/live-admin-training/'>
                                    <img  loading="lazy" src="/assets/images/lernselsforcecourse/live/admin/admin.webp" alt=""/>
                                 </Link>
                                
                              </div>
                              <div className="course__content">
                                 <div className="course__meta d-flex align-items-center justify-content-between">
                                   
                                 </div>
                                 <h3 className="course__title"><Link to='/learn-salesforce/live-classes/live-admin-training/'>Live Admin Training</Link></h3>
                                
                                 <div className="course__meta d-flex align-items-center justify-content-between">
                                 <h6 className="blue-2">₹13,000</h6> 
                 <h6 className="old-price">₹{(13000 + (13000 * 0.20))}</h6>
                                 </div>
                              </div>
                              <div className="course__more d-flex justify-content-between align-items-center">
                                 <div className="course__status d-flex align-items-center">
                                    {/* <span className="old-price">$97.00</span> */}
                                    <Link to='/learn-salesforce/live-classes/live-admin-training/' className="link-btn">
                                       Know Details
                                       
                                    </Link>
                                 </div>
                                 <div className="course__btn">
                                 <Link to='/learn-salesforce/live-classes/live-admin-training/' className="link-btn">
                                       
                                       <i className="fa fa-arrow-right"></i>
                                    </Link>
                                 </div>
                              </div>
                           </div>
                        </div>
                        
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                           <div className="course__item white-bg mb-30 fix">
                              <div className="course__thumb w-img p-relative fix">
                              <Link to='/learn-salesforce/live-classes/live-development-training/'>
                                    <img  loading="lazy" src="/assets/images/lernselsforcecourse/live/development/development.webp" alt=""/>
                                 </Link>
                                
                              </div>
                              <div className="course__content">
                                 <div className="course__meta d-flex align-items-center justify-content-between">
                                   
                                 </div>
                                 <h3 className="course__title"><Link to='/learn-salesforce/live-classes/live-development-training/'>Live Development Training</Link></h3>
                                 <h3 className="orange"></h3>
                                 <div className="course__meta d-flex align-items-center justify-content-between">
                                 <h6 className="blue-2">₹13,000</h6> 
                 <h6 className="old-price">₹{(13000 + (13000 * 0.20))}</h6>
                                 </div>
                                
                              </div>
                              <div className="course__more d-flex justify-content-between align-items-center">
                                 <div className="course__status d-flex align-items-center">
                                    {/* <span className="blue-2">$46.00</span> */}
                                    {/* <span className="old-price">$72.00</span> */}
                                    <Link to='/learn-salesforce/live-classes/live-development-training/' className="link-btn">
                                       Know Details
                                       
                                    </Link>
                                 </div>
                                 <div className="course__btn">
                                 <Link to='/learn-salesforce/live-classes/live-development-training/' className="link-btn">
                                      
                                       <i className="fa fa-arrow-right"></i>
                                    </Link>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                           <div className="course__item white-bg mb-30 fix">
                              <div className="course__thumb w-img p-relative fix">
                              <Link to='/learn-salesforce/live-classes/live-lwc-training/'>
                                    <img  loading="lazy" src="/assets/images/lernselsforcecourse/live/lwc/lwc.webp" alt=""/>
                                 </Link>
                              </div>
                              <div className="course__content">
                                 <div className="course__meta d-flex align-items-center justify-content-between">
                                   
                                 </div>
                                 <h3 className="course__title"><Link to='/learn-salesforce/live-classes/live-lwc-training/'>Live Lwc Training</Link></h3>
                               
                                 <div className="course__meta d-flex align-items-center justify-content-between">
                                 <h6 className="blue-2">₹11,000</h6> 
                 <h6 className="old-price">₹{(11000 + (11000 * 0.20))}</h6>
                                 </div>
                              </div>
                              <div className="course__more d-flex justify-content-between align-items-center">
                                 <div className="course__status d-flex align-items-center">
                                    {/* <span className="blue-2">$46.00</span>
                                    {/* <span className="old-price">$72.00</span> */}
                                    <Link to='/learn-salesforce/live-classes/live-lwc-training/'className="link-btn">
                                       Know Details
                                      
                                    </Link>
                                 </div>
                                 <div className="course__btn">
                                 <Link to='/learn-salesforce/live-classes/live-lwc-training/'className="link-btn">
                                       
                                       <i className="fa fa-arrow-right"></i>
                                    </Link>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                           <div className="course__item white-bg mb-30 fix">
                              <div className="course__thumb w-img p-relative fix">
                                 <Link to="/learn-salesforce/live-classes/salesforce-corporate-training/">
                                    <img  loading="lazy" src="/assets/images/lernselsforcecourse/live/corporate/corporate.webp" alt=""/>
                                 </Link>
                              </div>
                              <div className="course__content">
                                 <div className="course__meta d-flex align-items-center justify-content-between">
                                   
                                 </div>
                                 <h3 className="course__title"><Link to="/learn-salesforce/live-classes/salesforce-corporate-training/">Salesforce Corporate Training</Link></h3>
                                 
                                 <div className="course__meta d-flex align-items-center justify-content-between">
                                 <h6 className="blue-2">₹25,000</h6> 
                 <h6 className="old-price">₹{(25000 + (25000 * 0.20))}</h6>
                                 </div>
                              </div>
                              <div className="course__more d-flex justify-content-between align-items-center">
                                 <div className="course__status d-flex align-items-center">
                                    {/* <span className="blue-2">$46.00</span> */}
                                    {/* <span className="old-price">$72.00</span> */}
                                    <Link to="/learn-salesforce/live-classes/salesforce-corporate-training/" className="link-btn">
                                       Know Details
                                      
                                    </Link>
                                 </div>
                                 <div className="course__btn">
                                    <Link to="/learn-salesforce/live-classes/salesforce-corporate-training/" className="link-btn">
                                      
                                       <i className="fa fa-arrow-right"></i>
                                    </Link>
                                 </div>
                              </div>
                           </div>
                        </div>
                        {data.map(item => item.category_type === 1 &&(

<><div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6" key={item.catagory_slug}>
<div className="course__item white-bg mb-30 fix">
   <div className="course__thumb w-img p-relative fix">
     
   <Link  to={`/learn-salesforce/live-classes/${item.catagory_slug}`}>
         <img  loading="lazy" src={`https://dynopat.com/dynopat_admin/storage/app/public/CateImg/CateImg/${item.cover_image}`} alt=""/>
      </Link>
   </div>
   <div className="course__content">
      <div className="course__meta d-flex align-items-center justify-content-between">
        
      </div>
      <h3 className="course__title"><Link to={`/learn-salesforce/live-classes/${item.catagory_slug}`}>{item.catagory_name}</Link></h3>
      <div className="course__meta d-flex align-items-center justify-content-between">
          <h6 className="blue-2">₹{item.catagory_Price}</h6> 

        <h6 className="old-price">₹{parseFloat(item.catagory_Price) + (item.catagory_Price * 0.20) }</h6> 
        </div>
   </div>
   <div className="course__more d-flex justify-content-between align-items-center">
      <div className="course__status d-flex align-items-center">
         {/* <span className="blue-2">$46.00</span> */}
         {/* <span className="old-price">$72.00</span> */}
         <Link to={`/learn-salesforce/live-classes/${item.catagory_slug}`}className="link-btn">
            Know Details 
       
         </Link>
      </div>
      <div className="course__btn">
      <Link to={`/learn-salesforce/live-classes/${item.catagory_slug}`}className="link-btn">
           
            <i className="fa fa-arrow-right"></i>
         </Link>
      </div>
   </div>
</div>
</div></>
))}
                     </div>
                  </div>
                 
                </div>
            </div>
         </div>
                        </div>
            </div>
						<div className="inner-box">
                     
              <img  loading="lazy" style={{border:'double 3px',padding:'5px',background:'#0e2863'}} src='/assets/images/Live Class Image.png'/>
							<h3>Overview </h3>
							<p>Salesforce is one of the top technology stacks in the world, and it can create thousands of jobs every year. The best thing is, learning Salesforce is not difficult. If you get world-class trainers to teach you the CRM platform and how to utilize it to fit a business’s perspective. Our Live sessions are divided into 4 sub-groups. <br/> First, we have Salesforce Mastery Course, which is again subdivided into two courses, Salesforce Business Analyst Certification Training and Salesforce Administrator & App Builder Training. In this course, you can attend free demo workshops for a week, and after that, you have to pay. This course is perfect for beginners, and those from a non-technical background.
<br/>If you want to get learn advanced Salesforce, you can enroll in Salesforce Developer Bootcamp, where you will learn about Apex, the coding language working behind the platform. In this course, you can learn how to code like a pro. If you are interested in front-end development, you can pick our Salesforce LWC Bootcamp. 
<br/>If you are interested in the whole bundle, you can choose our Salesforce Pro package. This is for students who are interested in becoming a full-fledged Salesforce developer. We also provide Salesforce Corporate Training to companies and help them upskill their existing employees. We have structured our Salesforce training lessons to help students understand the topics and master the technology in no time. 
 </p>
 <div className="blocks-outer">
                    <div className="row clearfix">


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>Instructor-led Classes </h5>
                                <div className="block-text">We offer personalized, interactive, & detailed Salesforce Admin & Development courses to students</div>
                            </div>
                        </div>


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>1:1 Mentoring</h5>
                                <div className="block-text">Ask your doubts about Salesforce to your instructor during the session.</div>
                            </div>
                        </div>


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>Daily Assignments</h5>
                                <div className="block-text">Apply your theoretical knowledge of Salesforce in real-time and check your progress.</div>
                            </div>
                        </div>


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>1 Hour Duration </h5>
                                <div className="block-text">Regular 1-hour classes for 5 days a week, from Monday to Friday.</div>
                            </div>
                        </div>

                    </div>
                </div>
							<h4>Curriculum </h4>
							<p>
                     We have divided the Live Classes into 4 sub-categories. Each sub-category will have a separate curriculum. We did this division to cater our course to students with different requirements. Some may ask for Salesforce admin and development training while others may ask for Salesforce consultant training. Keeping that in mind, these sub-categories will have separate course modules for our students.  
                </p>
                <h4>Certification </h4>
							<p>

                     You will receive the certification for each of our courses after you have completed them successfully. You can add it to your resume or share it on social media. After completing a course, you can always begin the next course to get more certifications. You will work on real-time projects and assignments to gain practical exposure to how the industry and the developers actually work. Working on these scenarios, you are ready to work as a Salesforce Admin and Consultant for top MNCs in the world. These certifications can propel you in your career and get high-paying jobs.

 
                </p>
							
							<h4>Goals & Results</h4>
							<p>The goal of our Salesforce courses is to help you understand why it is the best technology to learn. More businesses are adopting it, so they need Salesforce Admins and Developers. You can get a high-paying job if you have the relevant skills and certifications.  </p>
						</div>
				
   

					</div>
				 </div>
				

                <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
                	<aside className="sidebar sticky-top">
						
		
						
				<div className="sidebar-widget service-widget">
					
                  <div className="widget-content">
				  <h3 className='pb-2 text-center'>FAQs</h3>
                    <div className="accordion" id="accordionExample">
						
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          What will be the duration of the class?
                          </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p className='text'>The classes will run for an hour, usually from 9.30 PM EST/ 7 AM IST (the time may change based on your timezone).  </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          When will you start a new batch?
                          </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p className='text'>We are running free 1-week demo sessions for our upcoming batch right now. whenever we start a new batch, we will show it on the website and on social media. If you cannot register for that batch, you can keep an eye on social media for the next announcement. </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          Will I get 1:1 mentoring?
                          </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p className='text'>Yes, we are here to provide 1:1 mentoring to all our students. You can ask your questions and doubts to our instructors. We will ensure that you get prompt responses from your trainers. 
 </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingfour">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                          Do I need any previous experience?
                          </button>
                        </h2>
                        <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p className='text'>No, you don’t need any previous experience, but an understanding of OOPS and programming languages like Java may help if you want to enroll in Salesforce Developer Bootcamp. 
 </p>
                          </div>
                        </div>
                      </div>
                     
                    </div>

                  </div>
                </div>

						{/* <div className="sidebar-widget contact-widget">
							<div className="widget-content" style={{backgroundImage: "url(/assets/images/background/pattern-25.png)"}}>
								<div className="title">
									Have Any Project <span>or work together ?</span>
								</div>
								<a href="contact.html" className="theme-btn contact-btn">Contact us</a>
								<div className="phone">
									<span className="icon flaticon-call-1"></span>
									Cal :<a href="tel:+0123-456-7899"> +0123 (456) 7899</a>
								</div>
							</div>
						</div> */}
						<Conactfrom/>
						
					
						{/* <div className="sidebar-widget broucher-widget">
							<div className="widget-content">
								<ul className="broucher-list">
									<li><a href="#"><span className="icon flaticon-pdf"></span>Download pdf</a></li>
									<li><a href="#"><span className="icon flaticon-edit"></span>Download doc.</a></li>
								</ul>
							</div>
						</div> */}
						
					</aside>
				</div>
				
			</div>
		</div>
	</div>
    </>
  )
}

export default Live_classes