import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';

const Thankyou = () => {
    const history = useNavigate();

    useEffect(() => {
      const redirectTimeout = setTimeout(() => {
        history('/'); // Replace '/' with the desired home page route
      }, 10000);
  
      return () => {
        clearTimeout(redirectTimeout);
      };
    }, [history]);
  
  return (
    <>
         <Helmet>
            <title>Thankyou-Dynopat</title>
        	<meta name="description" content="Master Salesforce & gain in-demand skills with Dynopat’s flexible and engaging Salesforce online training. Few seats are remaining, enroll today!" />
        	<meta name="keywords" content="Salesforce Online Training" />
        	<meta property="og:title" content="Salesforce Free Demo Class- DynoPat"/>
        	<meta property="og:description" content="Master Salesforce & gain in-demand skills with Dynopat’s flexible and engaging Salesforce online training. Few seats are remaining, enroll today!" />
        	<meta property="og:image" content="http://dynopat.com/assets/images/resource/learning.svg" />
       		 {/* Other OG tags */}
      		</Helmet>
  <div className="content">
    <div className="wrapper-1">
      <div className="wrapper-2">
        <h1 className='thankyou'>Thank you !</h1>
        <p className='thakyoup'>Thank you for submitting the form.</p>
        <p  className='thakyoup'>We will contact you shortly.</p>
        <button className="go-home ml-4"
        onClick={() => window.open('https://www.linkedin.com/in/rajesh-chatterjee-498b3b65/', '_blank')}>Visit LinkedIn Trainer Profile</button>
         <button className="go-home" onClick={()=>{
            history('/')
        }}>go home</button>
      </div>
     
    </div>
  </div>
  <link
    href="https://fonts.googleapis.com/css?family=Kaushan+Script|Source+Sans+Pro"
    rel="stylesheet"
  />
</>

  )
}

export default Thankyou
