import React from 'react'
import From from './From'
import {Helmet} from "react-helmet";

const Contact = () => {
  return (
    <>
	
	<Helmet>
			<title>Salesforce Training Providers | Salesforce Training Program</title>
        	<meta name="description" content="Dynopat is one of the best Salesforce Training Providers in the USA. Our Salesforce training program is designed to fit candidates coming from every background. " />
        	<meta name="keywords" content="Salesforce Training Providers, Salesforce Training Program" />
        	  {/* Open Graph / Facebook */}
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://dynopat.com/contact" />
  <meta
    property="og:title"
    content="Salesforce Training Providers | Salesforce Training Program"
  />
  <meta
    property="og:description"
    content="Dynopat is one of the best Salesforce Training Providers in the USA. Our Salesforce training program is designed to fit candidates coming from every background."
  />
  <meta
    property="og:image"
    content="https://dynopat.com/assets/images/resource/contact.png"
  />
  {/* Twitter */}
  <meta property="twitter:card" content="summary_large_image" />
  <meta property="twitter:url" content="https://dynopat.com/contact" />
  <meta
    property="twitter:title"
    content="Salesforce Training Providers | Salesforce Training Program"
  />
  <meta
    property="twitter:description"
    content="Dynopat is one of the best Salesforce Training Providers in the USA. Our Salesforce training program is designed to fit candidates coming from every background."
  />
  <meta
    property="twitter:image"
    content="https://dynopat.com/assets/images/resource/contact.png"
  />
				<link rel="canonical" href="https://dynopat.com/faq" />
      		</Helmet>
     
	{/* <title>Dynopat-contact-us</title> */}
        <section className="contact-banner-section">
		<div className="big-letter">Contact</div>
    	<div className="auto-container">
			<div className="row clearfix">
				<div className="info-column col-lg-6 col-md-12 col-sm-12">
					<div className="inner-column">
					
						<div className="info-box">
							<div className="box-inner">
								<span className="icon"><img src="assets/images/icons/info-1.png" alt="" /></span>
								<strong>Address</strong>
								2nd floor, Phase 2, Webel IT PARK, Palashdiha, Industrial Area, Durgapur, West Bengal 713208
							</div>
						</div>
						
						<div className="info-box">
							<div className="box-inner">
								<span className="icon"><img src="assets/images/icons/info-2.png" alt="" /></span>
								<strong>Email Us</strong>
								<a href='mailto:info@dynopat.com '>info@dynopat.com </a>
							</div>
						</div>
						
						<div className="info-box">
							<div className="box-inner">
								<span className="icon"><img src="assets/images/icons/info-3.png" alt="" /></span>
								<strong>Phone</strong>
								<a href='tel:+1(302)5676126'>+1 (302) 567-6126</a><br/>
								<a href='tel:+917501545113'>+91 75015 45113</a>
							</div>
						</div>
						
						<div className="info-box">
							<div className="box-inner">
								<ul className="social-box">
									<li><a href="https://www.facebook.com/dynopat" className="fa fa-facebook-f"></a></li>
									<li><a href="https://twitter.com/DynoPatPvtLtd" className="fa fa-twitter"></a></li>
									<li><a href="https://www.linkedin.com/company/dynopat/" className="fa fa-linkedin"></a></li>
									{/* <li><a href="https://www.youtube.com/" className="fa fa-youtube-play"></a></li> */}
									<li><a href="https://www.instagram.com/dynopat/" className="fa fa-instagram"></a></li>
									{/* <li><a href="https://www.pinterest.com/" className="fa fa-pinterest-p"></a></li> */}
									{/* <li><a href="https://www.google.com/" className="fa fa-send"></a></li> */}
								</ul>
							</div>
						</div>
						
					</div>
				</div>
				<div className="images-column col-lg-6 col-md-12 col-sm-12">
					<div className="inner-column parallax-scene-1">
						<div className="image" data-depth="0.40">
							<img src="assets/images/resource/contact.png" alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
    </section>
<From/>
    <section className="contact-map-section">
		<div className="outer-container">
			<div className="map-boxed">
				<div className="map-outer">
				<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14631.768427807014!2d87.28354590309836!3d23.534584561088533!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f7721e756aaaab%3A0x92bff4bc616b1770!2sDynoPat%20Salesforce%20Training!5e0!3m2!1sen!2sin!4v1692691847265!5m2!1sen!2sin" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
				</div>
			</div>
		</div>
	</section>

    </>
  )
}

export default Contact