import React, { useEffect, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import './assets/main.css'
import Slider from 'react-slick';
import { Helmet } from 'react-helmet-async'


import CourseLeadfrom from '../Ourcourse/compunent/CourseLeadfrom'
import Downloadpdf from '../Home/component/Downloadpdf';
const Softwaretestingtrainingcourse = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true, // auto-run the slider
    autoplaySpeed: 4000, // time in milliseconds between each slide
  };
  if (window.innerWidth <= 767) {
    settings.slidesToShow = 1;
  }
  const member = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true, // auto-run the slider
    autoplaySpeed: 4000, // time in milliseconds between each slide
  };
  if (window.innerWidth <= 767) {
    member.slidesToShow = 1;
  }
  const [openmodel, setOpenmodel] = useState(false)
  const [openmodel1, setOpenmodel1] = useState(false)
  const downloadbrochor = () => {
    setOpenmodel(!openmodel);
  }
  const joinus =() =>{
setOpenmodel1(!openmodel1)
  }
  const testimoniallink =()=>{
window.open('https://www.linkedin.com/in/rajesh-chatterjee-498b3b65/', '_blank');
  }
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };



    useEffect(() => {
		// Declare the script element variable in the component's scope
        	//Mobile Nav Hide Show

		// let externalScript;
	  
		// const LoadExternalScript = () => {
		//   externalScript = document.createElement("script");
		//   externalScript.id = "external";
		//   externalScript.async = true;
		//   externalScript.type = "text/javascript";
		//   externalScript.setAttribute("crossorigin", "anonymous");		  
		//   externalScript.src = '/assets/js/script.js';
		// };
		// LoadExternalScript();
        // return () => {
			 
        //     // Remove the script element from the DOM
        //     document.body.appendChild(externalScript);
          
        //   };
        setIsOpen(false);
        },  [location]);




        const [isMobile, setIsMobile] = useState(false);

        useEffect(() => {
          const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
          };
      
          // Initial check on component mount
          handleResize();
      
          // Add event listener for window resize
          window.addEventListener('resize', handleResize);
      
          // Clean up event listener on component unmount
          return () => window.removeEventListener('resize', handleResize);
        }, []);
        const [activeTab, setActiveTab] = useState(1);

        const toggleTab = (tabNumber) => {
          if (activeTab === tabNumber) {
            // If the same tab is clicked again, close it
            setActiveTab(null);
          } else {
            setActiveTab(tabNumber);
          }
        };
  return (
    <>
     <div className="page-wrapper">
     <Helmet>

            <title>Software Testing Training Online Course with Certificate </title>
        	<meta name="description" content="Master the Art of Software Testing  & gain in-demand skills with Dynopat’s flexible and engaging Salesforce online training. Few seats are remaining, enroll today!" />
        	<meta name="keywords" content="Salesforce Online Training" />
        	<meta property="og:title" content="Salesforce Free Demo Class- DynoPat"/>
        	<meta property="og:description" content="Master Salesforce & gain in-demand skills with Dynopat’s flexible and engaging Salesforce online training. Few seats are remaining, enroll today!" />
        	<meta property="og:image" content="http://dynopat.com/assets/images/resource/learning.svg" />
       		 {/* Other OG tags */}
            <script async src="https://www.googletagmanager.com/gtag/js?id=AW-324009643"></script>
        <script type="application/ld+json">
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'AW-324009643');
          `}
        </script>
      		</Helmet>

      {/* Required meta tags */}
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
<div className="col-lg-12 col-md-12 text-center sa">
      {isMobile && (
        <p className='running-text1 text'style={{ color: 'white', background: '#0e2863' }}>
          <span className="fa fa-phone"></span> <i class="fa fa-whatsapp" aria-hidden="true"></i>{' '}
          <a href="tel:+917501545113" style={{ color: 'white' }}>
          +91 75015 45113 
          </a> || <span className="fa fa-phone"></span> <i class="fa fa-whatsapp" aria-hidden="true"></i>{' '}<a href="tel: +13025676126" style={{ color: 'white' }}>
          +1 (302) 567-6126
          </a>
        </p>
      )}
    </div>
    <div  className={`main-header header-style-six ${isOpen ? 'mobile-menu-visible' : ''}`}>
    <div className="header-lower">
        <div className="auto-container">

            <div className="inner-container d-flex justify-content-between align-items-center bordernon">

                <div className="pull-left logo-box">
                    <div className="logo"><Link to='/'><img src="/assets/images/dino.png" className='logoheader' alt="" title=""/></Link></div>
                </div>
                

                <div className="nav-outer clearfix">

                    <div className="mobile-nav-toggler " onClick={toggleMenu}><span className="icon flaticon-menu"></span></div>

                    <div className="single">
        <p className='running-text1 text' style={{ color: "black" }} ><span className="fa fa-phone"></span> <i class="fa fa-whatsapp" aria-hidden="true"></i> <a href="tel:+917501545113" style={{ color: "black" }}>+91 75015 45113</a> <span className="fa fa-phone padi"></span> <i class="fa fa-whatsapp" aria-hidden="true"></i>{' '}<a href="tel: +13025676126" style={{ color: 'black' }}>
          +1 (302) 567-6126
          </a></p>
      
    </div>
                    
                </div>
                

                <div className="outer-box clearfix">
                    

                    <div className="button-box">
                    <button onClick={()=>(window.open('https://rzp.io/l/OtzhdH1ih'))} className="theme-btn btn-style-sixteen"><span className="txt">Register Now</span></button>

                        {/* <button className="theme-btn btn-style-sixteen" onClick={joinus}><span className="txt">FREE </span></button> */}
                    </div>

                    
                </div>
                
            </div>
        </div>
    </div>


    <div className="sticky-header">
        <div className="auto-container clearfix">

            <div className="logo pull-left">
                <Link to='/' title=""><img src="/assets/images/dino.png" alt="" title=""/></Link>
            </div>

            <div className="pull-right">
            

                <nav className="main-menu">
                   
                <div className="flex">
                  <div className='single pt-4'>
        <p className='running-text1 text maar' style={{ color: "black" }} ><span className="fa fa-phone"></span> <i class="fa fa-whatsapp" aria-hidden="true"></i> <a href="tel:+917501545113" style={{ color: "black" }}>+91 75015 45113</a> <span className="fa fa-phone padi"></span> <i class="fa fa-whatsapp" aria-hidden="true"></i>{' '}<a href="tel: +13025676126" style={{ color: 'black' }}>
          +1 (302) 567-6126
          </a></p>
          </div>
          <div className="button-box single">
          <button className="theme-btn btn-style-sixteen" onClick={()=>(window.open('https://rzp.io/l/OtzhdH1ih'))} ><span className="txt">Register Now</span></button>

                    </div>
    </div>
           
                </nav>

                

                <div className="mobile-nav-toggler"><span className="icon flaticon-menu-1" onClick={toggleMenu}></span></div>
                
            </div>
        </div>
    </div>


    <div className="mobile-menu">
        <div className="menu-backdrop"></div>
        <div className="close-btn" onClick={toggleMenu}><span className="icon flaticon-multiply"></span></div>
        
        <nav className="menu-box">
            <div className="nav-logo"><Link to='/'><img src="/assets/images/dino.png" alt="" title=""/></Link></div>
            {/* <div className=""><Menu/></div> */}
        </nav>
    </div>

</div>

      <header id="home" className="hero-area">
        <div className="overlay1">
          <span />

        </div>
        {/* <nav className="navbar navbar-expand-md bg-inverse fixed-top scrolling-navbar">
      <div className="container">
        
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav mr-auto w-100 justify-content-end">
            <li className="nav-item">
              <a className="nav-link page-scroll" href="#home">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link page-scroll" href="#services">
                About
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link page-scroll" href="#features">
                Services
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link page-scroll" href="#showcase">
                Showcase
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link page-scroll" href="#pricing">
                Pricing
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link page-scroll" href="#team">
                Team
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link page-scroll" href="#blog">
                Blog
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link page-scroll" href="#contact">
                Contact
              </a>
            </li>
           
          </ul>
        </div>
      </div>
    </nav> */}
        <div className="container">
          <div className="row space-100">
            <div className="col-lg-6 col-md-12 col-xs-12">
              <div className="contents">
                <h2 className="head-title" >
                 <span style={{color:'black'}}>Master the Art of Software Testing  For a Brighter Future
 </span> <br />

                </h2>
                <h4 >
                <span style={{color:'black'}} className='h3g'> Your dream career as a software tester is just a click away.</span>

                </h4>
                <div className="header-button">
                  <button href="#" className="btn btn-border-filled"onClick={()=>(window.open('https://rzp.io/l/OtzhdH1ih'))} >
                    Register Now
                  </button>
                  <button className="btn btn-border page-scroll" onClick={downloadbrochor}>
                    Download Brochure
                  </button>
                </div>
              </div>
            </div>



            <div className="col-lg-6 col-md-12 col-xs-12 p-0">
              <div className="intro-img">
                <img src="img/intro.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </header>
      <section id="business-plan">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 pl-4">
              <div className="business-item-info">
                <h3>Kickstart your career in the exciting field of software testing! </h3>
                
                
                <ul class="home-benefits ">
                  <li data-animation="fadeInUp" class="animated elementor-slide-desc fadeInUp" data-animation_dur="2000" style={{ animationDirection: '2000ms', opacity: '1'  }}><h4 className='text' style={{lineHeight:'3'}}><i class="fa fa-check"></i>Learn to identify and fix software defects before they impact users.</h4></li>
                  <li data-animation="fadeInUp" class="animated elementor-slide-desc fadeInUp" data-animation_dur="2500" style={{ animationDirection: '2500ms', opacity: '1' }}><h4 className='text' style={{lineHeight:'3'}}><i class="fa fa-check"></i>Develop a deep understanding of quality assurance practices.</h4></li>
                  <li data-animation="fadeInUp" class="animated elementor-slide-desc fadeInUp" data-animation_dur="3000" style={{ animationDirection: '3000ms', opacity: '1' }}><h4 className='text' style={{lineHeight:'2.5'}}><i class="fa fa-check"></i>Benefit from a skill set that is sought after by top companies worldwide.</h4></li>
                  <li data-animation="fadeInUp" class="animated elementor-slide-desc fadeInUp" data-animation_dur="3500" style={{ animationDirection: '3500ms', opacity: '1' }}><h4 className='text'style={{lineHeight:'3'}}><i class="fa fa-check"></i>Earn a competitive salary and enjoy financial stability.</h4></li>
                  <li data-animation="fadeInUp" class="animated elementor-slide-desc fadeInUp" data-animation_dur="4000" style={{ animationDirection: '4000ms', opacity: '1' }}><h4 className='text'style={{lineHeight:'3'}}><i class="fa fa-check"></i>Increase your chances of landing your dream job in a tech company.</h4></li>
                </ul>

              </div>
            </div>
            {/* Start Col */}
            <div className="col-lg-6 col-md-12 pl-0 pt-70 pr-5">
              <div className="business-item-img">
             
                <CourseLeadfrom demo="Book Salesforce Demo Class"/>
              </div>
            </div>
            {/* End Col */}

          </div>
        </div>
      </section>

      <section id="features" className="section">
        <div className="container">
          {/* Start Row */}
          <div className="row">
            <div className="col-lg-12">
              <div className="features-text section-header text-center">
                <div>
                  <h2 className="section-title">Explore Courses</h2>
                  <div className="desc-text">
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Row */}
          {/* Start Row */}
          <div className="row featured-bg">
            {/* Start Col */}
            <div className="col-lg-3 col-md-6 col-xs-12 p-0 text-center clickable-element"   onClick={joinus}>
              {/* Start Fetatures */}
              <div className="feature-item featured-border1">
                <img src='/assets/images/singlepage/software/manual.png'></img>
                <div className="feature-info float-left">
                  <h4>Manual software testing training course 
</h4>

                </div>
              </div>
              {/* End Fetatures */}
            </div>
            {/* End Col */}
            {/* Start Col */}
            <div className="col-lg-3 col-md-6 col-xs-12 p-0 text-center clickable-element" onClick={joinus}>
              {/* Start Fetatures */}
              <div className="feature-item featured-border2">
                <img src='/assets/images/singlepage/software/auto.webp'></img>
                <div className="feature-info float-left">
                  <h4>Automation software testing training course</h4>

                </div>
              </div>
              {/* End Fetatures */}
            </div>
            <div className="col-lg-3 col-md-6 col-xs-12 p-0 text-center clickable-element"   onClick={joinus}>
              {/* Start Fetatures */}
              <div className="feature-item featured-border1">
                <img src='/assets/images/singlepage/software/data.webp'></img>
                <div className="feature-info float-left">
                  <h4>Database Testing Training Course</h4>

                </div>
              </div>
              {/* End Fetatures */}
            </div>
            {/* End Col */}
            {/* Start Col */}
            <div className="col-lg-3 col-md-6 col-xs-12 p-0 text-center clickable-element" onClick={joinus}>
              {/* Start Fetatures */}
              <div className="feature-item featured-border2">
                <img src='/assets/images/singlepage/software/web.webp'></img>
                <div className="feature-info float-left">
                  <h4>Webservice Testing Training Course</h4>

                </div>
              </div>
              {/* End Fetatures */}
            </div>

          </div>
          {/* End Row */}
        </div>
      </section>
      {/* Header Section End */}
      {/* Services Section Start */}

      {/* Services Section End */}
      {/* Business Plan Section Start */}
      <section id="business-plan">
        <div className="container">

          <div className="row">
            {/* Start Col */}
            <div className="col-lg-6 col-md-12 pl-0  " >

              <div className="business-item-img text-center">
                <img
                  src="/assets/images/singlepage/software/ad69e5b8-f8d2-4972-b8d0-1c18dae47d8a.jpeg"
                  className="img-fluidv"
                  alt=""
                />

              </div>
            </div>
            {/* End Col */}
            {/* Start Col */}
            <div className="col-lg-6 col-md-12 pt-3">
  <div className="d-flex align-items-center" style={{ background: '#fff', minHeight: '100%' }}>
    <div>
      <h3 className='pb-3'>Who will be your trainer?</h3>
      <h4 className='text'>
        Our software testing training will be headed by Mr. Subhadip Ghosh, an experienced software tester with almost 6 years of training experience. Apart from that, he has over 10 years of industry experience and has been hailed as one of the best trainers in the industry.
      </h4>
      {/* Add your button or content here */}
    </div>
  </div>
</div>

            {/* End Col */}
          </div>
        </div>
      </section>
      {/* Business Plan Section End */}
      {/* Cool Fetatures Section Start */}

      {/* Cool Fetatures Section End */}

      {/* Client Testimoninals Section Start */}

      {/* Client Testimoninals Section End */}
      {/* Team section Start */}
      <section id="services" className="section">
        <div className="container">
          <div className="team-text section-header text-center">
            <div>
              <h2 className="section-title">How Does It Work? </h2>
            </div>
          </div>
          <div className="row">
            {/* Start Col */}

            <div className="col-lg-4 col-md-6 col-xs-12">
              <div className="services-item text-center pdd-30px">

                <img src='/assets/images/Land-Page-icon-A.webp'></img>

                <h4>Join Our Sessions </h4>
                <p className='pb-3 text'>
                Learn software testing procedures like white box testing, black box testing, and functional testing.
                </p>
              </div>
            </div>
            {/* End Col */}
            {/* Start Col */}
            <div className="col-lg-4 col-md-6 col-xs-12 ">
              <div className="services-item text-center pdd-30px" style={{paddingBottom:'40px'}}>
                <img src='/assets/images/Land-Page-icon-B.webp'></img>
                <h4>Complete Assignments & Tests</h4>
                <p className='pb-3 text'>
                Practice what you learn through hands-on projects, assignments, and tests.
                </p>
              </div>
            </div>
            {/* End Col */}
            {/* Start Col */}
            <div className="col-lg-4 col-md-6 col-xs-12">
              <div className="services-item text-center pdd-30px" style={{paddingBottom:'56px'}}>
                <img src='/assets/images/Land-Page-icon-D.webp'></img>
                <h4>Become Certified </h4>
                <p className='text'>
                Unlock your certificate and join the ranks of well-paid professionals in the tech industry.
                </p>
              </div>
            </div>
            {/* End Col */}
            
          </div>
        </div>
      </section>
      <section id="services" className="section">
        <div className="container">
          <div className="team-text section-header text-center">
            <div>
              <h2 className="section-title">What skills will you gain after this training? </h2>
            </div>
          </div>
          <div className="row">
            {/* Start Col */}
            <>
      <p>
        <button
          className={`btn st btn-primary ${activeTab === 1 ? 'active' : ''}`}
          type="button"
          onClick={() => toggleTab(1)}
        >
          Analytical Mindset
        </button>
        <button
          className={`btn st btn-primary ${activeTab === 2 ? 'active' : ''}`}
          type="button"
          onClick={() => toggleTab(2)}
        >
          Problem-solving capabilities
        </button>
        <button
          className={`btn st btn-primary ${activeTab === 3 ? 'active' : ''}`}
          type="button"
          onClick={() => toggleTab(3)}
        >
          Standard tools and techniques
        </button>
        <button
          className={`btn st btn-primary ${activeTab === 4 ? 'active' : ''}`}
          type="button"
          onClick={() => toggleTab(4)}
        >
          In-demand Skillset
        </button>
      </p>
      <div className="row">
        <div
          className={`collapse multi-collapse ${activeTab === 1 ? 'show' : ''}`}
          id="multiCollapseExample1"
        >
          <div className="card card-body">
          <p className= 'a text'>       You will learn how to think like a tester, analyze software functionality, and ensure its seamless operation.</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className={`collapse multi-collapse ${activeTab === 2 ? 'show' : ''}`}
          id="multiCollapseExample2"
        >
          <div className="card card-body">
          <p className= 'a text'>   This training will enhance your problem-solving abilities by enabling you to tackle complex scenarios.</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className={`collapse multi-collapse ${activeTab === 3 ? 'show' : ''}`}
          id="multiCollapseExample3"
        >
          <div className="card card-body">
          <p className= 'a text'> You will learn about the best practices, tools, and techniques of software testing.</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className={`collapse multi-collapse ${activeTab === 4 ? 'show' : ''}`}
          id="multiCollapseExample4"
        >
          <div className="card card-body">
          <p className= 'a text'>  Impress potential employers with your dedication to professional development.</p>
          </div>
        </div>
      </div>
    </>

          
            
          </div>
        </div>
      </section>
      
      {/* Team section End */}

      <section id="business-plan">
        <div className='container'> 
        <div className='row'>
        <div className="business-item-info">
                <h3 className='pb-2 text-center'>Testimonials</h3>

                <div className="inner-column">
                  <div className="">
                    <Slider {...settings}>
                      <div className="testimonial-block-five" onClick={testimoniallink}>
                        <div className="inner-box">
                          <div className="author-image">
                            <img loading="lazy" src="/assets/images/testimonial/149071-removebg-preview.png" alt="" />
                          </div>
                          <div className="content">
                            <div className="text">Before taking the software testing training, I felt lost and unsure of my career. After the training, I am more confident in my abilities and have a better understanding of the importance of testing in the software development process. The training was well-organized and the instructor was knowledgeable, patient, and engaging</div>
                            <h4>Pinky Gupta</h4>
                            <div className="designation">insurance Sales at Farmers Insurance</div>
                            <span className="quote-icon flaticon-left-quotes-sign"></span>
                          </div>
                        </div>
                      </div>

                      <div className="testimonial-block-five" onClick={testimoniallink}>
                        <div className="inner-box">
                          <div className="author-image">
                            <img loading="lazy" src="/assets/images/testimonial/149071-removebg-preview.png" alt="" />
                          </div>
                          <div className="content">
                            <div className="text">The software testing training was an eye-opener for me. I had never realized the extent to which poor testing can adversely affect the end product. The instructor learned a lot from the real-world examples and case studies shared during the training. I highly recommend this training to anyone looking to improve their testing skills.</div>
                            <h4>Nikesh Samanta</h4>
                            <div className="designation">1X Centied Salesforce Developer</div>
                            <span className="quote-icon flaticon-left-quotes-sign"></span>
                          </div>
                        </div>
                      </div>

                      <div className="testimonial-block-five" onClick={testimoniallink}>
                        <div className="inner-box">
                          <div className="author-image">
                            <img loading="lazy" src="/assets/images/testimonial/149071-removebg-preview.png" alt="" />
                          </div>
                          <div className="content">
                            <div className="text">As someone new to the field of software testing, the training was instrumental in helping me understand the fundamentals and best practices. The hands-on approach to learning helped me gain a deeper understanding of the concepts.</div>
                            <h4>Riley Smith</h4>
                            <div className="designation">Associate Consultant at Infosys</div>
                            <span className="quote-icon flaticon-left-quotes-sign"></span>
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-block-five" onClick={testimoniallink}>
                        <div className="inner-box">
                          <div className="author-image">
                            <img loading="lazy" src="/assets/images/testimonial/149071-removebg-preview.png" alt="" />
                          </div>
                          <div className="content">
                            <div className="text">The software testing training was an eye-opener for me. I had never realized the extent to which poor testing can adversely affect the end product. The instructor was excellent at explaining the nuances of testing, and I learned a lot from the real-world examples and case studies shared during the training. I highly recommend this training to anyone looking to improve their testing skills.</div>
                            <h4>Jacob Hardley </h4>
                            <div className="designation">Technical Specialist</div>
                            <span className="quote-icon flaticon-left-quotes-sign"></span>
                          </div>
                        </div>
                      </div>
                     
                    </Slider>
                  </div>

                </div>

              </div>
        </div>
        <div className='text-center'>
        <button className="btn btn-common n" onClick={() => window.open('https://www.linkedin.com/in/rajesh-chatterjee-498b3b65/', '_blank')}>
                 More Review
                </button>
                <button className=" btn btn-common n " style={{marginLeft:'10px'}}onClick={()=>(window.open('https://rzp.io/l/OtzhdH1ih'))} >
                Register Now
                </button>
        </div>
       
        </div>
        
      </section>
      <section className='section'>
      <div className="container">

         
<h3 className='pb-4 text-center'>Why Choose Dynopat?</h3>

  <div className="row">
    <div className="col-lg-6 col-md-12 pl-4">
    <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingeight">
                <button className="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#collapseeight" aria-expanded="false" aria-controls="collapseeight">
                Experienced instructors


                </button>
              </h2>
              <div id="collapseeight" className="accordion-collapse collapse show" aria-labelledby="headingeight" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                Dynopat has a team of highly experienced instructors who have extensive knowledge and practical experience in the field of software testing. They bring a wealth of industry insights and real-world examples to enhance the learning experience
                 </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingnine">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsenine" aria-expanded="false" aria-controls="collapsenine">
                Comprehensive curriculum


                </button>
              </h2>
              <div id="collapsenine" className="accordion-collapse collapse" aria-labelledby="headingnine" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                 
                The software testing training offered by Dynopat covers a wide range of topics, including manual testing, test automation, agile testing, and performance testing. The curriculum is designed to provide a holistic understanding of software testing principles and best practices. </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingten">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseten" aria-expanded="false" aria-controls="collapseten">
                Hands-on learning


                </button>
              </h2>
              <div id="collapseten" className="accordion-collapse collapse" aria-labelledby="headingten" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                Dynopat emphasizes practical learning through hands-on exercises, case studies, and real-world scenarios. This approach allows participants to apply the concepts learned in a simulated environment, enhancing their skills and confidence in software testing.
</div>
              </div>
            </div>
            



          </div>
     
    </div>
    {/* Start Col */}
    <div className="col-lg-6 col-md-12 pl-0 pr-5">

      <div className="accordion" id="accordionExample2">
        <div className="accordion-item">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingeleven">
              <button className="accordion-button  " type="button" data-bs-toggle="collapse" data-bs-target="#collapseeleven" aria-expanded="false" aria-controls="collapseeleven">
              Flexible training options

              </button>
            </h2>
            <div id="collapseeleven" className="accordion-collapse collapse show" aria-labelledby="headingeleven" data-bs-parent="#accordionExample2">
              <div className="accordion-body">
              Dynopat offers flexible training options to cater to the diverse needs of learners. They provide both online and in-person training sessions, allowing participants to choose the mode that works best for them.

                </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingfour">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
              Industry-recognized certification
              </button>
            </h2>
            <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample2">
              <div className="accordion-body">
              Upon successful completion of the training, participants receive an industry-recognized certification from Dynopat. This certification is valued by employers and can boost the career prospects of participants in the software testing field.

              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingfive">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
              Ongoing support and resources 

              </button>
            </h2>
            <div id="collapsefive" className="accordion-collapse collapse" aria-labelledby="headingfive" data-bs-parent="#accordionExample2">
              <div className="accordion-body">
              Dynopat provides ongoing support and resources even after the completion of the training. They offer access to a knowledge base, discussion forums, and expert guidance to help participants stay updated with the latest trends and challenges in software testing.

              </div>
            </div>
          </div>

       
        </div>
      </div>
    </div>
    


    {/* End Col */}

  </div>

</div>
      </section>
      {/* Contact Us Section */}
      <section id="contact" className="section">
        {/* Container Starts */}
        <div className="container">
          {/* Start Row */}
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-text section-header text-center">
                <div>
                  <h2 className="section-title">Get In Touch</h2>
                  <div className="desc-text">
                    <h4 className='text h'>
                    Ready to Start Your Training? Fill Out The Form Below 
                    </h4>

                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Row */}
          {/* Start Row */}
          <div className="row">
            {/* Start Col */}
            <div className="col-lg-6 col-md-12">
            <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">

<input type="hidden" name="oid" value="00D5i000002Qa7p"/>
        <input type="hidden" name="retURL" value="https://dynopat.com/thankyou" />
        <div className="form-outline mb-4">
          <div className='row'>
            <div className='col-md-6'>
            <label className="form-label black ss"  htmlFor="last_name">First Name</label>
          <input id="first_name" maxlength="80" name="last_name" type="text" className="form-control" required/>
            </div>
            <div className='col-md-6'>
            <label className="form-label black ss"  htmlFor="last_name">Last Name</label>
          <input id="last_name" maxlength="80" name="last_name" type="text" className="form-control" required/>
            </div>
          </div>
        


        </div>

        <div className="form-outline mb-4">
          <label className="form-label black ss"  htmlFor="email">Email</label>
          <input id="email" maxlength="80" name="email" type="text" className="form-control" required/>
        </div>

        <div className="form-outline mb-4">
          <div className='row'>
<label className="form-label black ss"  htmlFor="phone">Phone</label>

            <div className='col-md-3 mb-2'>
            <select className='form-control' id="00N5i00000TVZps" name="00N5i00000TVZps" title="Country Code"><option value="">--None--</option><option value="+93	       Afghanistan">+93	       Afghanistan</option>
<option value="+355	Albania">+355	Albania</option>
<option value="+213	Algeria">+213	Algeria</option>
<option value="+1	       United States">+1	       United States</option>
<option value="+376	Andorra">+376	Andorra</option>
<option value="+244	Angola">+244	Angola</option>
<option value="+1	       Antigua and Barbuda">+1	       Antigua and Barbuda</option>
<option value="+358	Finland">+358	Finland</option>
<option value="+599	Aruba">+599	Aruba</option>
<option value="+297	Ascension Island">+297	Ascension Island</option>
<option value="+61	        Australia">+61	        Australia</option>
<option value="+43	        Austria">+43	        Austria</option>
<option value="+994	Azerbaijan">+994	Azerbaijan</option>
<option value="+973	Bahrain">+973	Bahrain</option>
<option value="+880	Bangladesh">+880	Bangladesh</option>
<option value="+1	        Barbados">+1	        Barbados</option>
<option value="+375	Belarus">+375	Belarus</option>
<option value="+32	        Belgium">+32	        Belgium</option>
<option value="+501	Belize">+501	Belize</option>
<option value="+229	Benin">+229	Benin</option>
<option value="+1	        Bermuda">+1	        Bermuda</option>
<option value="+975	Bhutan">+975	Bhutan</option>
<option value="+591	Bolivia">+591	Bolivia</option>
<option value="+387	Bosnia and Herzegovina">+387	Bosnia and Herzegovina</option>
<option value="+267	Botswana">+267	Botswana</option>
<option value="+55	        Brazil">+55	        Brazil</option>
<option value="+246	British Indian Ocean Territory">+246	British Indian Ocean Territory</option>
<option value="+673	Brunei">+673	Brunei</option>
<option value="+359	Bulgaria">+359	Bulgaria</option>
<option value="+226	Burkina Faso">+226	Burkina Faso</option>
<option value="+257	Burundi">+257	Burundi</option>
<option value="+238	Cabo Verde">+238	Cabo Verde</option>
<option value="+855	Cambodia">+855	Cambodia</option>
<option value="+237	Cameroon">+237	Cameroon</option>
<option value="+1	        Canada">+1	        Canada</option>
<option value="+236	Central African Republic">+236	Central African Republic</option>
<option value="+235	Chad">+235	Chad</option>
<option value="+56	       Chile">+56	       Chile</option>
<option value="+86	       China">+86	       China</option>
<option value="+57	       Colombia">+57	       Colombia</option>
<option value="+269	Comoros">+269	Comoros</option>
<option value="+242	Congo, Democratic Republic of the">+242	Congo, Democratic Republic of the</option>
<option value="+243	Congo, Republic of the">+243	Congo, Republic of the</option>
<option value="+682	Cook Islands">+682	Cook Islands</option>
<option value="+506	Costa Rica">+506	Costa Rica</option>
<option value="+225	Côte d&#39;Ivoire">+225	Côte d&#39;Ivoire</option>
<option value="+385	Croatia">+385	Croatia</option>
<option value="+53	        Cuba">+53	        Cuba</option>
<option value="+599	Curaçao">+599	Curaçao</option>
<option value="+357	Cyprus">+357	Cyprus</option>
<option value="+420	Czech Republic">+420	Czech Republic</option>
<option value="+251	Djibouti">+251	Djibouti</option>
<option value="+46       	Sweden">+46       	Sweden</option>
<option value="+44    	United Kingdom">+44    	United Kingdom</option>
<option value="+253	Eritrea">+253	Eritrea</option>
<option value="+372	Estonia">+372	Estonia</option>
<option value="+254	Ethiopia">+254	Ethiopia</option>
<option value="+503	El Salvador">+503	El Salvador</option>
<option value="+233	Ghana">+233	Ghana</option>
<option value="+350	Gibraltar">+350	Gibraltar</option>
<option value="+299	Greenland">+299	Greenland</option>
<option value="+220	Gambia">+220	Gambia</option>
<option value="+224	Guinea">+224	Guinea</option>
<option value="+245	Guinea-Bissau">+245	Guinea-Bissau</option>
<option value="+500	Guyana">+500	Guyana</option>
<option value="+354	Iceland">+354	Iceland</option>
<option value="+91  	India">+91  	India</option>
<option value="+62  	Indonesia">+62  	Indonesia</option>
<option value="+98	        Iran">+98	        Iran</option>
<option value="+964	Iraq">+964	Iraq</option>
<option value="+353	Ireland">+353	Ireland</option>
<option value="+972	Israel">+972	Israel</option>
<option value="+39  	Italy">+39  	Italy</option>
<option value="+221	Senegal">+221	Senegal</option>
<option value="+258	Mozambique">+258	Mozambique</option>
<option value="+962	Jordan">+962	Jordan</option>
<option value="+7	         Kazakhstan">+7	         Kazakhstan</option>
<option value="+254	Kenya">+254	Kenya</option>
<option value="+686	Kiribati">+686	Kiribati</option>
<option value="+383	Kosovo">+383	Kosovo</option>
<option value="+965	Kuwait">+965	Kuwait</option>
<option value="+996	Kyrgyzstan">+996	Kyrgyzstan</option>
<option value="+850	North Korea">+850	North Korea</option>
<option value="+82	        South Korea">+82	        South Korea</option>
<option value="+963	Syria">+963	Syria</option>
<option value="+967	Yemen">+967	Yemen</option>
<option value="+268	Lesotho">+268	Lesotho</option>
<option value="+370	Lithuania">+370	Lithuania</option>
<option value="+352	Luxembourg">+352	Luxembourg</option>
<option value="+856	Laos">+856	Laos</option>
<option value="+371	Latvia">+371	Latvia</option>
<option value="+218	Libya">+218	Libya</option>
<option value="+423	Liechtenstein">+423	Liechtenstein</option>
<option value="+961	Lebanon">+961	Lebanon</option>
<option value="+266	Lesotho">+266	Lesotho</option>
<option value="+231	Liberia">+231	Liberia</option>
<option value="+212	Morocco">+212	Morocco</option>
<option value="+261	Madagascar">+261	Madagascar</option>
<option value="+389	North Macedonia">+389	North Macedonia</option>
<option value="+265	Malawi">+265	Malawi</option>
<option value="+60     	Malaysia">+60     	Malaysia</option>
<option value="+966	Saudi Arabia">+966	Saudi Arabia</option>
<option value="+223	Mali">+223	Mali</option>
<option value="+356	Malta">+356	Malta</option>
<option value="+692	Marshall Islands">+692	Marshall Islands</option>
<option value="+230	Mauritius">+230	Mauritius</option>
<option value="+262	Mayotte">+262	Mayotte</option>
<option value="+52	        Mexico">+52	        Mexico</option>
<option value="+691	Micronesia, Federated States of">+691	Micronesia, Federated States of</option>
<option value="+373	Moldova">+373	Moldova</option>
<option value="+377	Monaco">+377	Monaco</option>
<option value="+378	Montenegro">+378	Montenegro</option>
<option value="+216	Tunisia">+216	Tunisia</option>
<option value="+90	         Turkey">+90	         Turkey</option>
<option value="+993	Turkmenistan">+993	Turkmenistan</option>
<option value="+688	Tuvalu">+688	Tuvalu</option>
<option value="+256	Uganda">+256	Uganda</option>
<option value="+380	Ukraine">+380	Ukraine</option>
<option value="+971	United Arab Emirates">+971	United Arab Emirates</option>
<option value="+598	Uruguay">+598	Uruguay</option>
<option value="+1	       US Virgin Islands">+1	       US Virgin Islands</option>
<option value="+92     	Pakistan">+92     	Pakistan</option>
<option value="+678	Vanuatu">+678	Vanuatu</option>
<option value="+58	         Venezuela">+58	         Venezuela</option>
<option value="+681	Wallis and Futuna">+681	Wallis and Futuna</option>
<option value="+262	Western Sahara">+262	Western Sahara</option>
<option value="+968	Oman">+968	Oman</option>
<option value="+255	Tanzania">+255	Tanzania</option>
<option value="+676	Tonga">+676	Tonga</option>
<option value="+228	Togo">+228	Togo</option>
<option value="+670	East Timor">+670	East Timor</option>
<option value="+976	Mongolia">+976	Mongolia</option>
<option value="+252	Somalia">+252	Somalia</option>
<option value="+239	São Tomé and Príncipe">+239	São Tomé and Príncipe</option>
<option value="+381	Serbia">+381	Serbia</option>
<option value="+260	Zambia">+260	Zambia</option>
</select>
            </div>
<div className='col-md-9'>
<input id="phone" maxlength="40" name="phone" type="text" className="form-control"required />

</div>
          </div>
        </div>

        <div className="form-outline mb-4">
          <label className="form-label black ss"  htmlFor="lead_source">Where did you hear about us?</label>
          <select id="lead_source" name="lead_source" className="form-control" required>
            <option value="">--None--</option>
            <option value="Facebook">Facebook</option>
            <option value="Instagram">Instagram</option>
            <option value="Linkedin">Linkedin</option>
            <option value="Referral">Referral</option>
            <option value="Youtube">Youtube</option>
            <option value="Ad">Ad</option>
          </select>
      
          
        </div>
        <div className="form-outline mb-4">
          <label className="form-label black ss"  htmlFor="lead_source">Description</label>
          <textarea className="form-control" name="description"></textarea>
        </div>
        <input required id="00N5i00000TVZqC" name="00N5i00000TVZqC" type="hidden" value="DynoPat Landing Page" />

        <input style={{ width: '100%' }}
          className="button btn btn-primary btn-block xc"type="submit" name="submit" value="Submit" />
      </form>
            </div>
            {/* End Col */}
            {/* Start Col */}
            <div className="col-lg-1"></div>
            {/* End Col */}
            {/* Start Col */}
            <div className="col-lg-4 col-md-12">
              <div className="contact-img">
                <img src="/assets/images/single1.png" className="img-fluid" alt="" />
              </div>
            </div>
            {/* End Col */}
            {/* Start Col */}
            <div className="col-lg-1"></div>
            {/* End Col */}
          </div>
          {/* End Row */}
        </div>
      </section>
      <div>
      <div class="copyright footerbag">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="site-info text-center">
                <div className="footer-column">
                                        <div className="footer-widget info-widget">
                                            <ul className="social-box">
                                                <li><a href="https://www.facebook.com/dynopat" className="fa fa-facebook-f"></a></li>
                                                <li><a href="https://twitter.com/DynoPatPvtLtd" className="fa fa-twitter"></a></li>
                                                <li><a href="https://www.linkedin.com/company/dynopat/ " className="fa fa-linkedin"></a></li>
                                                <li><a href="https://www.instagram.com/dynopat/" className="fa fa-instagram"></a></li>
                                            </ul>
                                        </div>
                                    </div>
                </div>              
                
              </div>
            </div>
          </div>
        </div>

   
      </div>
      {/* Contact Us Section End */}

      {/* Go To Top Link */}
      <a href="#" className="back-to-top">
        <i className="lni-chevron-up" />
      </a>
      {/* Preloader
  <div id="preloader">
    <div className="loader" id="loader-1" />
  </div> */}
      {/* End Preloader */}
      {/* jQuery first, then Tether, then Bootstrap %PUBLIC_URL%/assetssinglepage/js. */}
      {openmodel && <div className="popup-container">
        <div className="popup">


          <div className="row">
            <div className="col-lg-10">

              <img className='lllog' src="/assets/images/dino.png" alt="" />
            </div>
            <div className="col-lg-2 text-center">
              <button className="close-button" onClick={downloadbrochor}>Close</button>

            </div>
          </div>
          <Downloadpdf slug='software-testing-brochure.pdf'/>
        </div>
     
      </div>
      }
{openmodel1 && <div className="popup-container">
        <div className="popup">


          <div className="row">
            <div className="col-lg-10">

              <img className='lllog' src="/assets/images/dino.png" alt="" />
            </div>
            <div className="col-lg-2 text-center">
              <button className="close-button" onClick={joinus}>Close</button>

            </div>
          </div>
          <CourseLeadfrom/>
        </div>
      </div>
      }
         <div class="sticky-buttons">
  <a href="https://web.whatsapp.com/send?phone=+wa.me/13025676126" class="whatsapp-button" target="_blank">
    <img src="/assets/images/whatsapp.webp" alt="WhatsApp Icon"/>
  </a>
  <a href="tel:+13025676126" class="call-button">
    <img src="/assets/images/png-clipart-telephone-call-dialer-android-android-text-telephone-call-thumbnail-removebg-preview.png" alt="Call Icon"/>
  </a>
</div>
</div>

    </>

  )
}

export default Softwaretestingtrainingcourse