import React, { useState } from 'react'
import '../../../Style/style.css'
import { Link,  } from 'react-router-dom'
import Contactfrom from '../../../compunent/Conactfrom'
// import Enroll from '../../../compunent/Enroll'
import { Helmet } from 'react-helmet-async'
import Claimyourfreecourse from '../../../compunent/Claimyourfreecourse'

function Admin_and_app_builder() {
   const [showpopup, setShowpopup] = useState(false);
	const [selectedVideoUrl, setSelectedVideoUrl] = useState('');
    const toggleshowpopup= (videoUrl) => {
		setSelectedVideoUrl(videoUrl);
        setShowpopup(!showpopup);
    };
	const url ="https://www.youtube.com/embed/TqG5V__uTp4?si=n95SEWBxGagAwWTO";
	
  
  return (
   <>
  
  <Helmet>
			<title>Salesforce Administrator and App Builder Certification | Salesforce Certification Training</title>
        	<meta name="description" content="Acquire in-demand skills & supercharge your career with our industry-leading Salesforce Administrator and App Builder certification training. Join us now!" />
        	<meta name="keywords" content="Salesforce Administrator and App Builder Certification, Salesforce Certification Training" />
        	<meta property="og:title" content="Salesforce Administrator and App Builder Certification | Salesforce Certification Training" />
        	<meta property="og:description" content="Acquire in-demand skills & supercharge your career with our industry-leading Salesforce Administrator and App Builder certification training. Join us now!" />
        	<meta property="og:image" content="https://dynopat.com/assets/images/lernselsforcecourse/live/admin/app.webp" />
       		 {/* Other OG tags */}
              <link rel="canonical" href="https://dynopat.com/learn-salesforce/live-classes/live-admin-training/admin-and-app-builder/" />
      		</Helmet>
     
    <section className="page__title-area pt-120 pb-90">
            <div className="page__title-shape">
               <img  loading="lazy" className="page-title-shape-5 d-none d-sm-block" src="/assets/img/page-title/page-title-shape-1.png" alt="" />
               <img  loading="lazy" className="page-title-shape-6" src="/assets/img/page-title/page-title-shape-6.png" alt="" />
               <img  loading="lazy" className="page-title-shape-7" src="/assets/img/page-title/page-title-shape-4.png" alt="" />
            </div>
            <div className="container">
               <div className="row">
                  <div className="col-xxl-8 col-xl-8 col-lg-8">
                     <div className="course__wrapper">
                        <div className="page__title-content mb-25">
                           <div className="page__title-breadcrumb">
                              <nav aria-label="breadcrumb">
                                 <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Salesforce Administrator & App Builder Certification</li>
                                 </ol>
                              </nav>
                           </div>
                           <span className="page__title-pre">Live admin training</span>
                           <h5 className="page__title-3">Salesforce Administrator & App Builder Certification</h5>
                        </div>
                        {/* <div className="course__meta-2 d-sm-flex mb-30">
                           <div className="course__teacher-3 d-flex align-items-center mr-70 mb-30">
                              <div className="course__teacher-thumb-3 mr-15">
                                 <img  loading="lazy" src="/assets/img/course/teacher/teacher-1.jpg" alt="" />
                              </div>
                              <div className="course__teacher-info-3">
                                 <h5>Teacher</h5>
                                 <p><a href="#">Elon Gated</a></p>
                              </div>
                           </div>
                           <div className="course__update mr-80 mb-30">
                              <h5>Last Update:</h5>
                              <p>July 24, 2022</p>
                           </div>
                           <div className="course__rating-2 mb-30">
                              <h5>certification:</h5>
                              <div className="course__rating-inner d-flex align-items-center">
                                 <ul>
                                    <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                    <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                    <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                    <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                    <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                 </ul>
                                 <p>4.5</p>
                              </div>
                           </div>
                        </div> */}
                        <div className="course__img w-img mb-30">
                           <img  loading="lazy" src="/assets/images/lernselsforcecourse/live/admin/app.webp" alt="" />
                        </div>
                        <div className="course__tab-2 mb-45 pb-3">
                           <ul className="nav nav-tabs" id="courseTab" role="tablist">
                              <li className="nav-item" role="presentation">
                                 <button className="nav-link active" id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview" type="button" role="tab" aria-controls="overview" aria-selected="true"> <i className="fa fa-bookmark"></i> <span>Overview</span> </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                 <button className="nav-link " id="curriculum-tab" data-bs-toggle="tab" data-bs-target="#curriculum" type="button" role="tab" aria-controls="curriculum" aria-selected="false"> <i className="fa fa-book"></i> <span>Curriculum</span> </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                 <button className="nav-link" id="certification-tab" data-bs-toggle="tab" data-bs-target="#certification" type="button" role="tab" aria-controls="certification" aria-selected="false"> <i className="fa fa-star"></i> <span>Certification</span> </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                 <button className="nav-link" id="faq-tab" data-bs-toggle="tab" data-bs-target="#faq" type="button" role="tab" aria-controls="faq" aria-selected="false"> <i className="fa fa-users"></i> <span>FAQs</span> </button>
                              </li>
                           </ul>
                        </div>
                        <div className="course__tab-content mb-95">
                           <div className="tab-content" id="courseTabContent">
                              <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                                 <div className="course__overview">
                                    <h3>Overview</h3>
                                    <p>We have designed our Salesforce Administrator and App Builder certification to help students prepare for Dev (402) exam. It will help students understand the core principles of Salesforce Administrator and App Builder. It is not designed to equip you with the knowledge of core Salesforce development.     </p>



                                    <div className='service-detail'>
            <div className='inner-box'>
                <div className="blocks-outer">
                    <div className="row clearfix">


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>Curated specifically for students from a non-technical background  </h5>
                                {/* <div className="block-text">We offer personalized, interactive, & detailed Salesforce Admin & Development courses to students</div> */}
                            </div>
                        </div>


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>Free demo sessions for a week </h5>
                                {/* <div className="block-text">Ask your doubts about Salesforce to your instructor during the session.</div> */}
                            </div>
                        </div>


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>Dedicated support from our instructor, Escalation Manager, and Customer Support Specialist 
</h5>
                                {/* <div className="block-text">Apply your theoretical knowledge of Salesforce in real-time and check your progress.</div> */}
                            </div>
                        </div>


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>Hands-on assistance on projects  </h5>
                                {/* <div className="block-text">Regular 1-hour classes for 5 days a week, from Monday to Friday.</div> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>


                                 </div>
                              </div>
                              <div className="tab-pane fade" id="curriculum" role="tabpanel" aria-labelledby="curriculum-tab">
                                 <div className="course__curriculum">
                                    <div className="accordion" id="course__accordion">
                                       <div className="accordion-item mb-2">
                                          <h2 className="accordion-header" id="week-01">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-01-content" aria-expanded="true" aria-controls="week-01-content">
                                             Overview of Salesforce 

                                             </button>
                                          </h2>
                                          <div id="week-01-content" className="accordion-collapse collapse show " aria-labelledby="week-01" data-bs-parent="#course__accordion">
                                             <div className="accordion-body">
                                                <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                   <div className="course__curriculum-info">
                                                      <ul>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Salesforce editions  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to customize the home page? </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>User management </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to create apps?  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Core CRM objects </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Declarative customizations and boundaries  </span> </h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Use cases of programmatic customizations </span></h3></li>
                                                         </ul>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="accordion" id="course__accordion-2">
                                       <div className="accordion-item mb-50">
                                          <h2 className="accordion-header" id="week-02">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-02-content" aria-expanded="true" aria-controls="week-02-content">
                                             Quotes 
                                             </button>
                                          </h2>
                                          <div id="week-02-content" className="accordion-collapse  collapse " aria-labelledby="week-02" data-bs-parent="#course__accordion-2">
                                             <div className="accordion-body">
                                                <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                <div className="course__curriculum-info">
                                                      <ul>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span> Quote templates  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to create quote records & templates  </span></h3></li>
                                                         
                                                      </ul>
                                                      </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="accordion" id="course__accordion-3">
                                       <div className="accordion-item mb-50">
                                          <h2 className="accordion-header" id="week-03">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-03-content" aria-expanded="true" aria-controls="week-03-content">
                                             Salesforce Objects 

                                             </button>
                                          </h2>
                                          <div id="week-03-content" className="accordion-collapse  collapse " aria-labelledby="week-03" data-bs-parent="#course__accordion-3">
                                             <div className="accordion-body">
                                                <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                <div className="course__curriculum-info">
                                                      <ul>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Introduction to field types  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to modify fields? t</span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Checkbox, number, and currency, percent & formula fields  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to create auto-number, date, time, text, area, geolocation, phone, and email fields</span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>URL encrypted-fields </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Objects and components (how to customize and modify them)</span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>External objects </span></h3></li>

                                                      </ul>
                                                      </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="accordion" id="course__accordion-4">
                                       <div className="accordion-item mb-50">
                                          <h2 className="accordion-header" id="week-04">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-04-content" aria-expanded="true" aria-controls="week-04-content">
                                             Buttons & Links 
                                             </button>
                                          </h2>
                                          <div id="week-04-content" className="accordion-collapse  collapse " aria-labelledby="week-04" data-bs-parent="#course__accordion-4">
                                             <div className="accordion-body">
                                                <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                <div className="course__curriculum-info">
                                                      <ul>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to create and update new records? </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to create buttons, links, and actions  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Call-logging actions </span></h3></li>

                                                      </ul>
                                                      </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="accordion" id="course__accordion-5">
                                       <div className="accordion-item mb-50">
                                          <h2 className="accordion-header" id="week-05">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-05-content" aria-expanded="true" aria-controls="week-05-content">
                                             Workflows, Validation, and Process Builder
                                             </button>
                                          </h2>
                                          <div id="week-05-content" className="accordion-collapse  collapse " aria-labelledby="week-05" data-bs-parent="#course__accordion-5">
                                             <div className="accordion-body">
                                                <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                <div className="course__curriculum-info">
                                                      <ul>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Introduction to visual workflow and schema builders  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Field creation  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to modify existing workflows? </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to create formulas and error messages? </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Understanding the concepts of rules, field updates, task assignments, and email alerts  </span></h3></li>


                                                      </ul>
                                                      </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="accordion" id="course__accordion-6">
                                       <div className="accordion-item mb-50">
                                          <h2 className="accordion-header" id="week-06">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-06-content" aria-expanded="true" aria-controls="week-06-content">
                                             Role Hierarchy  
                                             </button>
                                          </h2>
                                          <div id="week-06-content" className="accordion-collapse  collapse" aria-labelledby="week-06" data-bs-parent="#course__accordion-6">
                                             <div className="accordion-body">
                                                <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                <div className="course__curriculum-info">
                                                      <ul>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Understanding roles, groups, and permissions </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to assign roles to users? </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to enable field history? </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to create a role hierarchy? </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to restrict logins?  </span></h3></li>

                                                        
                                                      </ul>
                                                      </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="accordion" id="course__accordion-7">
                                       <div className="accordion-item mb-50">
                                          <h2 className="accordion-header" id="week-07">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-07-content" aria-expanded="true" aria-controls="week-07-content">
                                             Overview of profiles 
                                             </button>
                                          </h2>
                                          <div id="week-07-content" className="accordion-collapse  collapse" aria-labelledby="week-07" data-bs-parent="#course__accordion-7">
                                             <div className="accordion-body">
                                                <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                <div className="course__curriculum-info">
                                                      <ul>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to assign page layouts? </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Settings  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Custom apps, tabs, and records </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Permissions  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Password Rules  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Session timeout, and log-in hours </span></h3></li>
                                                        
                                                      </ul>
                                                      </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="accordion" id="course__accordion-8">
                                       <div className="accordion-item mb-50">
                                          <h2 className="accordion-header" id="week-08">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-08-content" aria-expanded="true" aria-controls="week-08-content">
                                             Reports and Dashboards 
                                             </button>
                                          </h2>
                                          <div id="week-08-content" className="accordion-collapse  collapse" aria-labelledby="week-08" data-bs-parent="#course__accordion-8">
                                             <div className="accordion-body">
                                                <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                <div className="course__curriculum-info">
                                                      <ul>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Lead, opportunity, scheduling, campaign, and accounts reports  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to create contacts? </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to handle interfaces and control access management?   </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to create charts and dashboards? </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to add filters to reports and dashboards?  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Understanding dynamic configuration</span></h3></li>

                                                        
                                                      </ul>
                                                      </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="accordion" id="course__accordion-9">
                                       <div className="accordion-item mb-50">
                                          <h2 className="accordion-header" id="week-09">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-09-content" aria-expanded="true" aria-controls="week-09-content">
                                             Data Management 
                                             </button>
                                          </h2>
                                          <div id="week-09-content" className="accordion-collapse  collapse" aria-labelledby="week-09" data-bs-parent="#course__accordion-9">
                                             <div className="accordion-body">
                                                <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                <div className="course__curriculum-info">
                                                      <ul>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to import leads, accounts, and contacts? </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How can you install, update, and delete data loaders?  </span></h3></li>
                                                    
                                                        
                                                      </ul>
                                                      </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="accordion" id="course__accordion-10">
                                       <div className="accordion-item mb-50">
                                          <h2 className="accordion-header" id="week-10">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-10-content" aria-expanded="true" aria-controls="week-10-content">
                                             App Exchange  
                                             </button>
                                          </h2>
                                          <div id="week-10-content" className="accordion-collapse  collapse" aria-labelledby="week-10" data-bs-parent="#course__accordion-10">
                                             <div className="accordion-body">
                                                <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                <div className="course__curriculum-info">
                                                      <ul>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Overview of AppExchange   </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Ways to find apps, developers, and consultants in AppExchange </span></h3></li>

                                                        
                                                      </ul>
                                                      </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>

                                    <div className="accordion" id="course__accordion-11">
                                       <div className="accordion-item mb-50">
                                          <h2 className="accordion-header" id="week-11">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-11-content" aria-expanded="true" aria-controls="week-11-content">
                                             Events 
                                             </button>
                                          </h2>
                                          <div id="week-11-content" className="accordion-collapse  collapse" aria-labelledby="week-11" data-bs-parent="#course__accordion-11">
                                             <div className="accordion-body">
                                                <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                <div className="course__curriculum-info">
                                                      <ul>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Component events   </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Application events  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to write handlers in JavaScript controllers?  </span></h3></li>

                                                        
                                                      </ul>
                                                      </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="accordion" id="course__accordion-12">
                                       <div className="accordion-item mb-50">
                                          <h2 className="accordion-header" id="week-12">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-12-content" aria-expanded="true" aria-controls="week-12-content">
                                             App Development 

                                             </button>
                                          </h2>
                                          <div id="week-12-content" className="accordion-collapse  collapse" aria-labelledby="week-12" data-bs-parent="#course__accordion-12">
                                             <div className="accordion-body">
                                                <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                <div className="course__curriculum-info">
                                                      <ul>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Key milestones in app development    </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>What to consider before developing an app?  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Use cases and unmanaged packages   </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Sandboxes   </span></h3></li>

                                                        
                                                      </ul>
                                                      </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    
                                 </div>
                              </div>
                              <div className="tab-pane fade" id="certification" role="tabpanel" aria-labelledby="certification-tab">
                                 <div className="course__certification">
                                    <h3>Certification</h3>
                                    <p>The Certificate will be given to students who will successfully complete the course, the assignments, projects, and quizzes. With this certificate, you can apply for the App Builder and the Salesforce Administrator profile. You can proceed to enroll in other courses from Dynopat or sit for Salesforce examinations.     </p>
                                    <img  loading="lazy" className='certificat' src='/assets/images/Certificate-Design.jpg'></img>
                                 </div>
                              </div>
                              <div className="tab-pane fade" id="faq" role="tabpanel" aria-labelledby="faq-tab">
                                 <div className="course__faq mb-45">



                                    <div className="accordion" id="accordionExample">
                                       <div className="accordion-item">
                                          <h2 className="accordion-header" id="headingOne">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                             What will I get from this course? 

                                             </button>
                                          </h2>
                                          <div id="collapseOne" className="accordion-collapse collapse show " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                             <div className="accordion-body">
                                             <div className="course__curriculum-info">
                                                <strong>Our Live Admin course is for students pursuing a career as a Salesforce Consultant/ Business Analyst. You don’t need to come for a technical background to pursue this course. You will learn Salesforce Administration and Salesforce Business Analysis from our curated modules<br/>What makes us different from others is our 7-day free demo sessions. During these demo sessions, you can assess if this is the right course for you. These demo sessions will be for an hour from 9.30 P.M. EST, and you will get to ask your questions in the Q&A session. The duration of this Q&A session is 15 minutes, and we take pride in offering dedicated 1:1 mentor support. You will get to work on real-time projects, daily assignments, and a dedicated Escalation Support Manager. 
 </strong>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="accordion-item">
                                          <h2 className="accordion-header" id="headingTwo">
                                             <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                             Do I need to learn any programming language? 

                                             </button>
                                          </h2>
                                          <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                             <div className="accordion-body">
                                                <strong>If you have any prior experience in coding, it will be beneficial for you. However, it is not mandatory to come from a coding background to enroll in this course. 
 </strong>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="accordion-item">
                                          <h2 className="accordion-header" id="headingThree">
                                             <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                             Why should I learn Salesforce from Dynopat? 



                                             </button>
                                          </h2>
                                          <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                             <div className="accordion-body">
                                                <strong>If you are looking for hands-on Salesforce admin and development training, you have come to the best place. By going through this course, you will learn why Salesforce is named “the best CRM platform” by businesses around the world. In this course, you will learn a new programming language Apex. You will learn how to use this programming language, and its structure to create complex applications. The content of this course is prepared in alignment with Salesforce PD1 (Platform Development) and PD2 Certifications. You will get to work on real projects that would value to your portfolio. Dynopat will offer technical support, and assistance if you ever get stuck.  
 </strong>
                                         
                                             </div>
                                          </div>
                                       </div>
                                       <div className="accordion-item">
                                          <h2 className="accordion-header" id="headingFour">
                                             <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                             Can I choose other courses from Dynopat? 
                                             </button>
                                          </h2>
                                          <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                             <div className="accordion-body">
                                             <strong>Yes, you can choose from our other courses that cover Salesforce Admin training, & Salesforce Developer Training. If you want to pursue a degree in LWC, you can always pursue it. 
</strong>
                                             </div>
                                          </div>
                                       </div>

                                       <div className="accordion-item">
                                          <h2 className="accordion-header" id="headingFive">
                                             <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                             Does this course guarantee a job? 
                                             </button>
                                          </h2>
                                          <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                             <div className="accordion-body">
                                             <strong>No, this course will not guarantee a job, but you will be prepared for the current job listings. You can explore the opportunities and apply for a suitable job. However, the final decision will be bestowed with the employer and it will depend on how you perform during the interview.  
</strong>
                                             </div>
                                          </div>
                                       </div>
                                    </div>





                                 </div>
                              </div>

                           </div>
                        </div>
                     </div>
                  </div>
                 <div className="col-xxl-4 col-xl-4 col-lg-4">
                     <div className="course__sidebar pl-70 p-relative">
                        <div className="course__shape">
                           <img  loading="lazy" className="course-dot" src="/assets/img/course/course-dot.png" alt="" />
                        </div>
                        <div className="course__sidebar-widget-2 white-bg mb-20">
                           <div className="course__video"  onClick={()=>toggleshowpopup(url)}>
                              <div className="course__video-thumb w-img mb-25">
                                 <img  loading="lazy" src="/assets/images/video.png" alt="" />
                                 <div className="course__video-play">
                                    <a href="#" data-fancybox="" className="play-btn"> <i className="fa fa-play"></i> </a>
                                 </div>
                              </div>
                              <div className="course__video-meta mb-25 d-flex align-items-center justify-content-between">
                                 {/* <div className="course__video-price">
                                    <h5>$74.<span>00</span> </h5>
                                    <h5 className="old-price">$129.00</h5>
                                 </div>
                                 <div className="course__video-discount">
                                    <span>68% OFF</span>
                                 </div> */}
                              </div>
                              <div className="course__video-content mb-35">
                                 <ul>
                                    {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Instructor :</span> Eleanor Fant</h5>
                                       </div>
                                    </li> */}
                                    {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Lectures :</span>14</h5>
                                       </div>
                                    </li> */}
                                    <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Duration :</span>3 weeks</h5>
                                       </div>
                                    </li>
                                    {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Enrolled :</span>20 students</h5>
                                       </div>
                                    </li> */}
                                    <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Language :</span>English</h5>
                                       </div>
                                    </li>
                                 </ul>
                              </div>
                              {/* <div className="course__payment mb-35">
                                 <h3>Payment:</h3>
                                 <a href="#">
                                    <img  loading="lazy" style={{width: '222px'}} src="/assets/images/stripe.png" alt="" />
                                 </a>
                              </div> */}
                    <Claimyourfreecourse />

                    <button onClick={()=>{window.open('https://rzp.io/l/MjFxE4Ookn')}}  class="e-btn e-btn-7 w-100">Enroll <i class="fa fa-arrow-right"></i></button>

 
                           </div>
                        </div>
                       
                        <Contactfrom/>
                     </div>
                  </div>
               </div>
            </div>
            {showpopup&& <div className="popup-container">
                    <div className="popups">


                        <div className="row">
                            <div className="col-10">

                                <img className='lllog' src="/assets/images/dino.png" alt="" />
                            </div>
                            <div className="col-2 text-end">
                                <button className="close-button" onClick={toggleshowpopup}>Close</button>

                            </div>
                        </div>
						<iframe src={selectedVideoUrl} width="100%" height="400px" ></iframe>
                    </div>
                </div>
                }
         </section>
         </>
  )
}

export default Admin_and_app_builder