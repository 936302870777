import React, { useEffect, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import './assets/main.css'
import Slider from 'react-slick';
import { Helmet } from 'react-helmet-async'


import CourseLeadfrom from '../Ourcourse/compunent/CourseLeadfrom'
import Downloadpdf from '../Home/component/Downloadpdf';
const Singlepage = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true, // auto-run the slider
    autoplaySpeed: 4000, // time in milliseconds between each slide
  };
  if (window.innerWidth <= 767) {
    settings.slidesToShow = 1;
  }
  const member = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true, // auto-run the slider
    autoplaySpeed: 4000, // time in milliseconds between each slide
  };
  if (window.innerWidth <= 767) {
    member.slidesToShow = 1;
  }
  const [openmodel, setOpenmodel] = useState(false)
  const [openmodel1, setOpenmodel1] = useState(false)
  const downloadbrochor = () => {
    setOpenmodel(!openmodel);
  }
  const joinus =() =>{
setOpenmodel1(!openmodel1)
  }
  const testimoniallink =()=>{
window.open('https://www.linkedin.com/in/rajesh-chatterjee-498b3b65/', '_blank');
  }
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };



    useEffect(() => {
		// Declare the script element variable in the component's scope
        	//Mobile Nav Hide Show

		// let externalScript;
	  
		// const LoadExternalScript = () => {
		//   externalScript = document.createElement("script");
		//   externalScript.id = "external";
		//   externalScript.async = true;
		//   externalScript.type = "text/javascript";
		//   externalScript.setAttribute("crossorigin", "anonymous");		  
		//   externalScript.src = '/assets/js/script.js';
		// };
		// LoadExternalScript();
        // return () => {
			 
        //     // Remove the script element from the DOM
        //     document.body.appendChild(externalScript);
          
        //   };
        setIsOpen(false);
        },  [location]);




        const [isMobile, setIsMobile] = useState(false);

        useEffect(() => {
          const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
          };
      
          // Initial check on component mount
          handleResize();
      
          // Add event listener for window resize
          window.addEventListener('resize', handleResize);
      
          // Clean up event listener on component unmount
          return () => window.removeEventListener('resize', handleResize);
        }, []);
  return (
    <>
     <div className="page-wrapper">
     <Helmet>
            <title>Salesforce Free Demo Class- DynoPat</title>
        	<meta name="description" content="Master Salesforce & gain in-demand skills with Dynopat’s flexible and engaging Salesforce online training. Few seats are remaining, enroll today!" />
        	<meta name="keywords" content="Salesforce Online Training" />
        	<meta property="og:title" content="Salesforce Free Demo Class- DynoPat"/>
        	<meta property="og:description" content="Master Salesforce & gain in-demand skills with Dynopat’s flexible and engaging Salesforce online training. Few seats are remaining, enroll today!" />
        	<meta property="og:image" content="http://dynopat.com/assets/images/resource/learning.svg" />
       		 {/* Other OG tags */}
      		</Helmet>

      {/* Required meta tags */}
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
<div className="col-lg-12 col-md-12 text-center sa">
      {isMobile && (
        <p className='running-text1 text'style={{ color: 'white', background: '#0e2863' }}>
          <span className="fa fa-phone"></span> <i class="fa fa-whatsapp" aria-hidden="true"></i>{' '}
          <a href="tel:+917501545113" style={{ color: 'white' }}>
          +91 75015 45113 
          </a> || <span className="fa fa-phone"></span> <i class="fa fa-whatsapp" aria-hidden="true"></i>{' '}<a href="tel: +13025676126" style={{ color: 'white' }}>
          +1 (302) 567-6126
          </a>
        </p>
      )}
    </div>
    <div  className={`main-header header-style-six ${isOpen ? 'mobile-menu-visible' : ''}`}>
    <div className="header-lower">
        <div className="auto-container">

            <div className="inner-container d-flex justify-content-between align-items-center bordernon">

                <div className="pull-left logo-box">
                    <div className="logo"><Link to='/'><img src="/assets/images/dino.png" className='logoheader' alt="" title=""/></Link></div>
                </div>
                

                <div className="nav-outer clearfix">

                    <div className="mobile-nav-toggler " onClick={toggleMenu}><span className="icon flaticon-menu"></span></div>

                    <div className="single">
        <p className='running-text1 text' style={{ color: "black" }} ><span className="fa fa-phone"></span> <i class="fa fa-whatsapp" aria-hidden="true"></i> <a href="tel:+917501545113" style={{ color: "black" }}>+91 75015 45113</a> <span className="fa fa-phone padi"></span> <i class="fa fa-whatsapp" aria-hidden="true"></i>{' '}<a href="tel: +13025676126" style={{ color: 'black' }}>
          +1 (302) 567-6126
          </a></p>
      
    </div>
                    
                </div>
                

                <div className="outer-box clearfix">
                    

                    <div className="button-box">
                    <button onClick={()=>(window.open('https://rzp.io/l/dynopat'))} className="theme-btn btn-style-sixteen"><span className="txt">Register DEMO CLASS</span></button>

                        {/* <button className="theme-btn btn-style-sixteen" onClick={joinus}><span className="txt">FREE </span></button> */}
                    </div>

                    
                </div>
                
            </div>
        </div>
    </div>


    <div className="sticky-header">
        <div className="auto-container clearfix">

            <div className="logo pull-left">
                <Link to='/' title=""><img src="/assets/images/dino.png" alt="" title=""/></Link>
            </div>

            <div className="pull-right">
            

                <nav className="main-menu">
                   
                <div className="flex">
                  <div className='single pt-4'>
        <p className='running-text1 text maar' style={{ color: "black" }} ><span className="fa fa-phone"></span> <i class="fa fa-whatsapp" aria-hidden="true"></i> <a href="tel:+917501545113" style={{ color: "black" }}>+91 75015 45113</a> <span className="fa fa-phone padi"></span> <i class="fa fa-whatsapp" aria-hidden="true"></i>{' '}<a href="tel: +13025676126" style={{ color: 'black' }}>
          +1 (302) 567-6126
          </a></p>
          </div>
          <div className="button-box single">
          <button className="theme-btn btn-style-sixteen" onClick={()=>(window.open('https://rzp.io/l/dynopat'))} ><span className="txt">Register Now</span></button>

                    </div>
    </div>
           
                </nav>

                

                <div className="mobile-nav-toggler"><span className="icon flaticon-menu-1" onClick={toggleMenu}></span></div>
                
            </div>
        </div>
    </div>


    <div className="mobile-menu">
        <div className="menu-backdrop"></div>
        <div className="close-btn" onClick={toggleMenu}><span className="icon flaticon-multiply"></span></div>
        
        <nav className="menu-box">
            <div className="nav-logo"><Link to='/'><img src="/assets/images/dino.png" alt="" title=""/></Link></div>
            {/* <div className=""><Menu/></div> */}
        </nav>
    </div>

</div>

      <header id="home" className="hero-area">
        <div className="overlay">
          <span />

        </div>
        {/* <nav className="navbar navbar-expand-md bg-inverse fixed-top scrolling-navbar">
      <div className="container">
        
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav mr-auto w-100 justify-content-end">
            <li className="nav-item">
              <a className="nav-link page-scroll" href="#home">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link page-scroll" href="#services">
                About
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link page-scroll" href="#features">
                Services
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link page-scroll" href="#showcase">
                Showcase
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link page-scroll" href="#pricing">
                Pricing
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link page-scroll" href="#team">
                Team
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link page-scroll" href="#blog">
                Blog
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link page-scroll" href="#contact">
                Contact
              </a>
            </li>
           
          </ul>
        </div>
      </div>
    </nav> */}
        <div className="container">
          <div className="row space-100">
            <div className="col-lg-6 col-md-12 col-xs-12">
              <div className="contents">
                <h2 className="head-title" >
                 <span style={{color:'black'}}> Upskill & Boost Your Career  </span> <br />

                </h2>
                <h4 >
                <span style={{color:'black'}} className='h3g'> Salesforce Training for Admins and Developers</span>

                </h4>
                <div className="header-button">
                  <button href="#" className="btn btn-border-filled" onClick={joinus}>
                    Register Now
                  </button>
                  <button className="btn btn-border page-scroll" onClick={downloadbrochor}>
                    Download Brochure
                  </button>
                </div>
              </div>
            </div>



            <div className="col-lg-6 col-md-12 col-xs-12 p-0">
              <div className="intro-img">
                <img src="img/intro.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </header>
      <section id="business-plan">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 pl-4">
              <div className="business-item-info">
                <h3>Looking to Begin Your Career As Salesforce Admin / Business Analyst / Consultant / Developer?</h3>
                <h4 className='pb-3'>Join Our Demo Class
                </h4>
                <ul class="home-benefits ">
                  <li data-animation="fadeInUp" class="animated elementor-slide-desc fadeInUp" data-animation_dur="2000" style={{ animationDirection: '2000ms', opacity: '1' }}><h4 className='text'><i class="fa fa-check"></i>Attend our demo live training session (No hidden fees / No credit card needed) </h4></li>
                  <li data-animation="fadeInUp" class="animated elementor-slide-desc fadeInUp" data-animation_dur="2500" style={{ animationDirection: '2500ms', opacity: '1' }}><h4 className='text'><i class="fa fa-check"></i>See what our course entails and figure out if it’s right for you</h4></li>
                  <li data-animation="fadeInUp" class="animated elementor-slide-desc fadeInUp" data-animation_dur="3000" style={{ animationDirection: '3000ms', opacity: '1' }}><h4 className='text'><i class="fa fa-check"></i>Understand the roles and responsibilities of a Salesforce Admin / BA / Consultant / Developer in a real-time project.</h4></li>
                  <li data-animation="fadeInUp" class="animated elementor-slide-desc fadeInUp" data-animation_dur="3500" style={{ animationDirection: '3500ms', opacity: '1' }}><h4 className='text'><i class="fa fa-check"></i>You will also have free access to LMS content after joining our Live Training</h4></li>
                  <li data-animation="fadeInUp" class="animated elementor-slide-desc fadeInUp" data-animation_dur="4000" style={{ animationDirection: '4000ms', opacity: '1' }}><h4 className='text'><i class="fa fa-check"></i>Say hello to a better career with Salesforce.</h4></li>
                </ul>

              </div>
            </div>
            {/* Start Col */}
            <div className="col-lg-6 col-md-12 pl-0 pt-70 pr-5">
              <div className="business-item-img">
             
                <CourseLeadfrom demo="Book Salesforce Demo Class"/>
              </div>
            </div>
            {/* End Col */}

          </div>
        </div>
      </section>

      <section id="features" className="section">
        <div className="container">
          {/* Start Row */}
          <div className="row">
            <div className="col-lg-12">
              <div className="features-text section-header text-center">
                <div>
                  <h2 className="section-title">Explore Courses</h2>
                  <div className="desc-text">
                    <h4 className='text h'>
                      Browse through our trending courses on Salesforce and book one now!
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Row */}
          {/* Start Row */}
          <div className="row featured-bg">
            {/* Start Col */}
            <div className="col-lg-6 col-md-6 col-xs-12 p-0 text-center clickable-element"   onClick={joinus}>
              {/* Start Fetatures */}
              <div className="feature-item featured-border1">
                <img src='/assets/images/Landing-Page-Icon-2.webp'></img>
                <div className="feature-info float-left">
                  <h4>Salesforce Live Admin Training</h4>

                </div>
              </div>
              {/* End Fetatures */}
            </div>
            {/* End Col */}
            {/* Start Col */}
            <div className="col-lg-6 col-md-6 col-xs-12 p-0 text-center clickable-element" onClick={joinus}>
              {/* Start Fetatures */}
              <div className="feature-item featured-border2">
                <img src='/assets/images/Landing-Page-Icon-1.webp'></img>
                <div className="feature-info float-left">
                  <h4>Salesforce Live Development Training</h4>

                </div>
              </div>
              {/* End Fetatures */}
            </div>


          </div>
          {/* End Row */}
        </div>
      </section>
      {/* Header Section End */}
      {/* Services Section Start */}

      {/* Services Section End */}
      {/* Business Plan Section Start */}
      <section id="business-plan">
        <div className="container">

          <div className="row">
            {/* Start Col */}
            <div className="col-lg-6 col-md-12 pl-0  " >

              <div className="business-item-img text-center">
                <img
                  src="/assets/images/Ne.webp"
                  className="img-fluidv"
                  alt=""
                />

              </div>
            </div>
            {/* End Col */}
            {/* Start Col */}
            <div className="col-lg-6 col-md-12 pt-3 ">
              <div className='' style={{ background: '#fff' }}>
                <h3 className='pb-3'>Who will be your trainer?</h3>
                <h4 className='text'>The Salesforce Admin training will be headed by Rajesh Chatterjee, Founder of Delipat IT. Delipat is an India Based Salesforce Authorized Implementation Partner and Founder of DynoPat ( A premium Salesforce Training Company). He has more than a decade of experience in Salesforce implementation for different customers around the world. Rajesh has completed 400 + batches with a head count of 7000+ students in the USA. His 7+ Salesforce certifications show that he has a lot to offer as a trainer to all the students enrolled in this course.</h4>
                <button className="btn btn-common n" onClick={() => window.open('https://www.linkedin.com/in/rajesh-chatterjee-498b3b65/', '_blank')}>
                <i class="fa fa-thumbs-up" aria-hidden="true"></i> LinkedIn Profile
                </button>
              </div>
            </div>
            {/* End Col */}
          </div>
        </div>
      </section>
      {/* Business Plan Section End */}
      {/* Cool Fetatures Section Start */}

      {/* Cool Fetatures Section End */}

      {/* Client Testimoninals Section Start */}

      {/* Client Testimoninals Section End */}
      {/* Team section Start */}
      <section id="services" className="section">
        <div className="container">
          <div className="team-text section-header text-center">
            <div>
              <h2 className="section-title">How Does Our Training Work?</h2>
            </div>
          </div>
          <div className="row">
            {/* Start Col */}

            <div className="col-lg-3 col-md-6 col-xs-12">
              <div className="services-item text-center pb-5">

                <img src='/assets/images/Land-Page-icon-A.webp'></img>

                <h4>Attend Demo Sessions</h4>
                <p className='pb-3 text'>
                  Attend our demo sessions and get a fundamental understanding of Salesforce and how its works.
                </p>
              </div>
            </div>
            {/* End Col */}
            {/* Start Col */}
            <div className="col-lg-3 col-md-6 col-xs-12 ">
              <div className="services-item text-center pb-5">
                <img src='/assets/images/Land-Page-icon-B.webp'></img>
                <h4>Assess Our Lessons</h4>
                <p className='pb-3 text'>
                  You don’t have to pay anything if you are not fully satisfied with the training quality or think this is not the right choice.
                </p>
              </div>
            </div>
            {/* End Col */}
            {/* Start Col */}
            <div className="col-lg-3 col-md-6 col-xs-12">
              <div className="services-item text-center">
                <img src='/assets/images/Land-Page-icon-D.webp'></img>
                <h4>Join Our Salesforce Admin/ Development Course</h4>
                <p className='text'>
                  After completing our demo Class, you can enroll in our 4-week Salesforce Admin and/ or Development courses by making a payment.
                </p>
              </div>
            </div>
            {/* End Col */}
            <div className="col-lg-3 col-md-6 col-xs-12">
              <div className="services-item text-center">
                <img src='/assets/images/Land-Page-icon-C.webp'></img>
                <h4>Get Certified</h4>
                <p className='text'>
                  You will get a certification from us after successfully completing our Salesforce training course. Also we will help you to get certification from Salesforce for Admin or/and Developer.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="team" className="section">
        <div className="container">
          {/* Start Row */}
          <div className="row">
            <div className="col-lg-12">
              <div className="team-text section-header text-center">
                <div>
                  <h2 className="section-title">The Team Behind DynoPat</h2>
                </div>
              </div>
            </div>
          </div>
          {/* End Row */}
          {/* Start Row */}
          <div className="row">
            {/* Start Col */}
            <Slider {...member}>
            <div className="col-lg-3 col-md-6 col-xs-12">
              <div className="single-team">
                <div className="team-thumb">
                  <img src="/assets/images/Disha.jpeg" className="img-fluid" alt="" />
                </div>
                <div className="team-details">
                  <div className="team-social-icons">
                    
                  </div>
                  <div className="team-inner text-center">
                    <h5 className="team-title">DISHA MUKHERJEE</h5>
                    <p className='text'>Career Counselor</p>
                    <h4 className='text'>
Disha is responsible for helping students with their queries, conveying them to trainers, and assignment submission, relaying feedback, setting extra classes for doubt clearing, and checking up on them throughout their Salesforce learning journey.</h4>
                  </div>
                </div>
              </div>
            </div>
            {/* Start Col */}
            {/* Start Col */}
            <div className="col-lg-3 col-md-6 col-xs-12">
              <div className="single-team">
                <div className="team-thumb">
                  <img src="/assets/images/Subham.webp" className="img-fluid" alt="" />
                </div>
                <div className="team-details">
                  <div className="team-social-icons">
                    
                  </div>
                  <div className="team-inner text-center" style={{paddingBottom:'85px'}}>
                    <h5 className="team-title">SUBHAM KONER</h5>
                    <p className='text'>Salesforce Consultant</p>
                    <h4 className='text'>Subham provides end to end project guidance and evaluate all the assignments submitted by the students.</h4>
                  </div>
                </div>
              </div>
            </div>
            {/* Start Col */}
            {/* Start Col */}
            <div className="col-lg-3 col-md-6 col-xs-12">
              <div className="single-team">
                <div className="team-thumb">
                  <img src="/assets/images/Ritwick.webp" className="img-fluid" alt="" />
                </div>
                <div className="team-details">
                  <div className="team-social-icons">
                    
                  </div>
                  <div className="team-inner text-center" style={{paddingBottom:'27px'}}>
                    <h5 className="team-title">RITWICK KARMAKAR</h5>
                    <p className='text'>Sr. Salesforce Lightning Developer</p>
                    <h4 className='text'>Ritwick is an experienced Salesforce Lightning Developer who will lead the Salesforce frontend development training. He has worked on numerous projects and trained students for a long time.</h4>
                  </div>
                </div>
              </div>
            </div>
            {/* Start Col */}
            {/* Start Col */}
            <div className="col-lg-3 col-md-6 col-xs-12">
              <div className="single-team">
                <div className="team-thumb">
                  <img src="/assets/images/anku.webp" className="img-fluid" alt="" />
                </div>
                <div className="team-details">
                  <div className="team-social-icons">
                    
                  </div>
                  <div className="team-inner text-center" style={{paddingBottom:'84px'}}>
                    <h5 className="team-title ">ANKUSH HAZRA</h5>
                    <p className='text'>Sr. Salesforce Developer</p>
                    <h4 className='text' >Ankush has years of experience as a Salesforce Developer, and he well lead Salesforce backend development training.</h4>
                  </div>
                </div>
              </div>
            </div>
            {/* Start Col */}
            </Slider>
          </div>
          {/* End Row */}
        </div>
      </section>
      {/* Team section End */}

      <section id="business-plan">
        <div className='container'> 
        <div className='row'>
        <div className="business-item-info">
                <h3 className='pb-2 text-center'>Testimonials</h3>

                <div className="inner-column">
                  <div className="">
                    <Slider {...settings}>
                      <div className="testimonial-block-five" onClick={testimoniallink}>
                        <div className="inner-box">
                          <div className="author-image">
                            <img loading="lazy" src="/assets/images/testimonial/1576261783901.jpg" alt="" />
                          </div>
                          <div className="content">
                            <div className="text">Highly recommend Rajesh if you have a plan to start a new career or just need to know about changes and updates on salesforce</div>
                            <h4>Shler Hamidi Handa</h4>
                            <div className="designation">insurance Sales at Farmers Insurance</div>
                            <span className="quote-icon flaticon-left-quotes-sign"></span>
                          </div>
                        </div>
                      </div>

                      <div className="testimonial-block-five" onClick={testimoniallink}>
                        <div className="inner-box">
                          <div className="author-image">
                            <img loading="lazy" src="/assets/images/testimonial/1654352424702.jpg" alt="" />
                          </div>
                          <div className="content">
                            <div className="text">I learned Salesforce from LMS videos. This is  very good source to learn Salesforce, if anybody wants to learn Salesforce from scratch i would</div>
                            <h4>Pinki Kumari</h4>
                            <div className="designation">1X Centied Salesforce Developer</div>
                            <span className="quote-icon flaticon-left-quotes-sign"></span>
                          </div>
                        </div>
                      </div>

                      <div className="testimonial-block-five" onClick={testimoniallink}>
                        <div className="inner-box">
                          <div className="author-image">
                            <img loading="lazy" src="/assets/images/testimonial/1684756210898.jpg" alt="" />
                          </div>
                          <div className="content">
                            <div className="text">highly recommended to fearn Salesforce from scratch level to advance level from Ims videos. good talented Salesforce expert.</div>
                            <h4>Ajay S</h4>
                            <div className="designation">Associate Consultant at Infosys</div>
                            <span className="quote-icon flaticon-left-quotes-sign"></span>
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-block-five" onClick={testimoniallink}>
                        <div className="inner-box">
                          <div className="author-image">
                            <img loading="lazy" src="/assets/images/testimonial/1620219361246.jpg" alt="" />
                          </div>
                          <div className="content">
                            <div className="text">highly recommended to fearn Salesforce from scratch level to advance level from Ims videos. good talented Salesforce expert.</div>
                            <h4>Joel Tom</h4>
                            <div className="designation">Technical Specialist</div>
                            <span className="quote-icon flaticon-left-quotes-sign"></span>
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-block-five" onClick={testimoniallink}>
                        <div className="inner-box">
                          <div className="author-image">
                            <img loading="lazy" src="/assets/images/testimonial/1678896009483.jpg" alt="" />
                          </div>
                          <div className="content">
                            <div className="text">Rajesh was my Salesforce coach. He has tremendous knowledge and experience with Salesforce. I have learned a lot from Rajesh and</div>
                            <h4>Jitesh Joshi</h4>
                            <div className="designation">Accountant be</div>
                            <span className="quote-icon flaticon-left-quotes-sign"></span>
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-block-five" onClick={testimoniallink}>
                        <div className="inner-box">
                          <div className="author-image">
                            <img loading="lazy" src="/assets/images/testimonial/149071-removebg-preview.png" alt="" />
                          </div>
                          <div className="content">
                            <div className="text">It was a really very good experience with LMS In depth knowledge videos with 100% practical and real time scenarios. It helps me to get a</div>
                            <h4>Aniket Thawkar</h4>
                            <div className="designation">Salesforce Developer</div>
                            <span className="quote-icon flaticon-left-quotes-sign"></span>
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-block-five" onClick={testimoniallink}>
                        <div className="inner-box">
                          <div className="author-image">
                            <img loading="lazy" src="/assets/images/testimonial/1634056602436.jpg" alt="" />
                          </div>
                          <div className="content">
                            <div className="text">I have learned so much in my salesforce Admin | training with Rajesh Chatterji. You have covered everything in salesforce admin videos from</div>
                            <h4>Puja Nathe</h4>
                            <div className="designation">2X Certified Salesforce Technical Specialist</div>
                            <span className="quote-icon flaticon-left-quotes-sign"></span>
                          </div>
                        </div>
                      </div>
                    </Slider>
                  </div>

                </div>

              </div>
        </div>
        <div className='text-center'>
        <button className="btn btn-common n" onClick={() => window.open('https://www.linkedin.com/in/rajesh-chatterjee-498b3b65/', '_blank')}>
                 More Review
                </button>
                <button className=" btn btn-common n " style={{marginLeft:'10px'}}onClick={()=>(window.open('https://rzp.io/l/dynopat'))} >
                Register Demo Class
                </button>
        </div>
       
        </div>
        
      </section>
      <section className='section'>
      <div className="container">

         
<h3 className='pb-4 text-center'>Why Choose Dynopat?</h3>

  <div className="row">
    <div className="col-lg-6 col-md-12 pl-4">
    <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingeight">
                <button className="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#collapseeight" aria-expanded="false" aria-controls="collapseeight">
                  Who is this course for?

                </button>
              </h2>
              <div id="collapseeight" className="accordion-collapse collapse show" aria-labelledby="headingeight" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <h4 className='text'><i class="fa fa-circle" style={{ fontsize: '36px', color: '#0e2863', paddingRight: '6px' }}></i>Students who want to start a new career in IT </h4>
                  <h4 className='text'> <i class="fa fa-circle" style={{ fontsize: '36px', color: '#0e2863', paddingRight: '6px' }}></i>Students who want to switch from non-IT background </h4>
                  <h4 className='text'> <i class="fa fa-circle" style={{ fontsize: '36px', color: '#0e2863', paddingRight: '6px' }}></i>Students who want to switch from another IT profile </h4>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingnine">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsenine" aria-expanded="false" aria-controls="collapsenine">
                  How long will the lessons take to complete?

                </button>
              </h2>
              <div id="collapsenine" className="accordion-collapse collapse" aria-labelledby="headingnine" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <h4 className='text'> <i class="fa fa-circle" style={{ fontsize: '36px', color: '#0e2863', paddingRight: '6px' }}></i>The Salesforce Admin course will be covered in 4-5 weeks.</h4>
                  <h4 className='text'> <i class="fa fa-circle" style={{ fontsize: '36px', color: '#0e2863', paddingRight: '6px' }}></i>Classes will happen for 1 hour from Monday to Friday.</h4>
                  <h4 className='text'> <i class="fa fa-circle" style={{ fontsize: '36px', color: '#0e2863', paddingRight: '6px' }}></i>The approximate duration of the course will be 40 hours.</h4>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingten">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseten" aria-expanded="false" aria-controls="collapseten">
                  What will you learn in this training?

                </button>
              </h2>
              <div id="collapseten" className="accordion-collapse collapse" aria-labelledby="headingten" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <h4 className='text'> <i class="fa fa-circle" style={{ fontsize: '36px', color: '#0e2863', paddingRight: '6px' }}></i>Roles of a Salesforce Admin</h4>
                  <h4 className='text'> <i class="fa fa-circle" style={{ fontsize: '36px', color: '#0e2863', paddingRight: '6px' }}></i>Responsibilities of a Technical Business Analyst or Consultant.</h4>
                </div>
              </div>
            </div>
            <div className="accordion-item ">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Experienced Trainers
              </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <h4 className='text' >Our courses are designed by Rajesh Chatterjee, a Salesforce-certified expert and the founder of Delipat and Dynopat. </h4>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingsix">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                Ready to Listen Anytime

              </button>
            </h2>
            <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <h4 className='text' >Our escalation support manager is always there to assist you with any issues you might be facing.
                </h4>
              </div>
            </div>
          </div>



          </div>
     
    </div>
    {/* Start Col */}
    <div className="col-lg-6 col-md-12 pl-0 pr-5">

      <div className="accordion" id="accordionExample2">
        <div className="accordion-item">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingeleven">
              <button className="accordion-button  " type="button" data-bs-toggle="collapse" data-bs-target="#collapseeleven" aria-expanded="false" aria-controls="collapseeleven">
                What skills are required for someone to enroll in the course?

              </button>
            </h2>
            <div id="collapseeleven" className="accordion-collapse collapse show" aria-labelledby="headingeleven" data-bs-parent="#accordionExample2">
              <div className="accordion-body">
                <h4 className='text'> <i class="fa fa-circle" style={{ fontsize: '36px', color: '#0e2863', paddingRight: '6px' }}></i>No prior coding knowledge is required to enroll in this course.</h4></div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingfour">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                Detailed Guidance
              </button>
            </h2>
            <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample2">
              <div className="accordion-body">
                <h4 className='text' >Our trainers will provide detailed guidance to each of our students regarding their assignments and how they can improve in the future.
                </h4>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingfive">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                Talk with Technical Experts

              </button>
            </h2>
            <div id="collapsefive" className="accordion-collapse collapse" aria-labelledby="headingfive" data-bs-parent="#accordionExample2">
              <div className="accordion-body">
                <h4 className='text' >If you are unsure about the course and whether it will be beneficial for you, you can always consult with our technical experts .
                </h4>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingseven">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven">
                Career Guidance & Resume Preparation

              </button>
            </h2>
            <div id="collapseseven" className="accordion-collapse collapse" aria-labelledby="headingseven" data-bs-parent="#accordionExample2">
              <div className="accordion-body">
                <h4 className='text' >We will also provide career assistance through mock interview preparation and resume-building guidance.
                </h4>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Demo Sessions For Three days
              </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample2">
              <div className="accordion-body">
                <h4 className='text' >We cover the basics of Salesforce Admin in our demo class . </h4>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Guided Learning & Assignments
              </button>
            </h2>
            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample2">
              <div className="accordion-body">
                <h4 className='text'>We will guide you throughout your learning journey, you can ask your questions anytime. We will provide assignments to help you evaluate your progress.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    


    {/* End Col */}

  </div>

</div>
      </section>
      {/* Contact Us Section */}
      <section id="contact" className="section">
        {/* Container Starts */}
        <div className="container">
          {/* Start Row */}
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-text section-header text-center">
                <div>
                  <h2 className="section-title">Get In Touch</h2>
                  <div className="desc-text">
                    <h4 className='text h'>
                      Do you have anything to ask regarding our Salesforce Training? Fill in the form below:
                    </h4>

                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Row */}
          {/* Start Row */}
          <div className="row">
            {/* Start Col */}
            <div className="col-lg-6 col-md-12">
            <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">

<input type="hidden" name="oid" value="00D5i000002Qa7p"/>
        <input type="hidden" name="retURL" value="https://dynopat.com/thankyou" />
        <div className="form-outline mb-4">
          <div className='row'>
            <div className='col-md-6'>
            <label className="form-label black ss"  htmlFor="last_name">First Name</label>
          <input id="first_name" maxlength="80" name="last_name" type="text" className="form-control" required/>
            </div>
            <div className='col-md-6'>
            <label className="form-label black ss"  htmlFor="last_name">Last Name</label>
          <input id="last_name" maxlength="80" name="last_name" type="text" className="form-control" required/>
            </div>
          </div>
        


        </div>

        <div className="form-outline mb-4">
          <label className="form-label black ss"  htmlFor="email">Email</label>
          <input id="email" maxlength="80" name="email" type="text" className="form-control" required/>
        </div>

        <div className="form-outline mb-4">
          <div className='row'>
<label className="form-label black ss"  htmlFor="phone">Phone</label>

            <div className='col-md-3 mb-2'>
            <select className='form-control' id="00N5i00000TVZps" name="00N5i00000TVZps" title="Country Code"><option value="">--None--</option><option value="+93	       Afghanistan">+93	       Afghanistan</option>
<option value="+355	Albania">+355	Albania</option>
<option value="+213	Algeria">+213	Algeria</option>
<option value="+1	       United States">+1	       United States</option>
<option value="+376	Andorra">+376	Andorra</option>
<option value="+244	Angola">+244	Angola</option>
<option value="+1	       Antigua and Barbuda">+1	       Antigua and Barbuda</option>
<option value="+358	Finland">+358	Finland</option>
<option value="+599	Aruba">+599	Aruba</option>
<option value="+297	Ascension Island">+297	Ascension Island</option>
<option value="+61	        Australia">+61	        Australia</option>
<option value="+43	        Austria">+43	        Austria</option>
<option value="+994	Azerbaijan">+994	Azerbaijan</option>
<option value="+973	Bahrain">+973	Bahrain</option>
<option value="+880	Bangladesh">+880	Bangladesh</option>
<option value="+1	        Barbados">+1	        Barbados</option>
<option value="+375	Belarus">+375	Belarus</option>
<option value="+32	        Belgium">+32	        Belgium</option>
<option value="+501	Belize">+501	Belize</option>
<option value="+229	Benin">+229	Benin</option>
<option value="+1	        Bermuda">+1	        Bermuda</option>
<option value="+975	Bhutan">+975	Bhutan</option>
<option value="+591	Bolivia">+591	Bolivia</option>
<option value="+387	Bosnia and Herzegovina">+387	Bosnia and Herzegovina</option>
<option value="+267	Botswana">+267	Botswana</option>
<option value="+55	        Brazil">+55	        Brazil</option>
<option value="+246	British Indian Ocean Territory">+246	British Indian Ocean Territory</option>
<option value="+673	Brunei">+673	Brunei</option>
<option value="+359	Bulgaria">+359	Bulgaria</option>
<option value="+226	Burkina Faso">+226	Burkina Faso</option>
<option value="+257	Burundi">+257	Burundi</option>
<option value="+238	Cabo Verde">+238	Cabo Verde</option>
<option value="+855	Cambodia">+855	Cambodia</option>
<option value="+237	Cameroon">+237	Cameroon</option>
<option value="+1	        Canada">+1	        Canada</option>
<option value="+236	Central African Republic">+236	Central African Republic</option>
<option value="+235	Chad">+235	Chad</option>
<option value="+56	       Chile">+56	       Chile</option>
<option value="+86	       China">+86	       China</option>
<option value="+57	       Colombia">+57	       Colombia</option>
<option value="+269	Comoros">+269	Comoros</option>
<option value="+242	Congo, Democratic Republic of the">+242	Congo, Democratic Republic of the</option>
<option value="+243	Congo, Republic of the">+243	Congo, Republic of the</option>
<option value="+682	Cook Islands">+682	Cook Islands</option>
<option value="+506	Costa Rica">+506	Costa Rica</option>
<option value="+225	Côte d&#39;Ivoire">+225	Côte d&#39;Ivoire</option>
<option value="+385	Croatia">+385	Croatia</option>
<option value="+53	        Cuba">+53	        Cuba</option>
<option value="+599	Curaçao">+599	Curaçao</option>
<option value="+357	Cyprus">+357	Cyprus</option>
<option value="+420	Czech Republic">+420	Czech Republic</option>
<option value="+251	Djibouti">+251	Djibouti</option>
<option value="+46       	Sweden">+46       	Sweden</option>
<option value="+44    	United Kingdom">+44    	United Kingdom</option>
<option value="+253	Eritrea">+253	Eritrea</option>
<option value="+372	Estonia">+372	Estonia</option>
<option value="+254	Ethiopia">+254	Ethiopia</option>
<option value="+503	El Salvador">+503	El Salvador</option>
<option value="+233	Ghana">+233	Ghana</option>
<option value="+350	Gibraltar">+350	Gibraltar</option>
<option value="+299	Greenland">+299	Greenland</option>
<option value="+220	Gambia">+220	Gambia</option>
<option value="+224	Guinea">+224	Guinea</option>
<option value="+245	Guinea-Bissau">+245	Guinea-Bissau</option>
<option value="+500	Guyana">+500	Guyana</option>
<option value="+354	Iceland">+354	Iceland</option>
<option value="+91  	India">+91  	India</option>
<option value="+62  	Indonesia">+62  	Indonesia</option>
<option value="+98	        Iran">+98	        Iran</option>
<option value="+964	Iraq">+964	Iraq</option>
<option value="+353	Ireland">+353	Ireland</option>
<option value="+972	Israel">+972	Israel</option>
<option value="+39  	Italy">+39  	Italy</option>
<option value="+221	Senegal">+221	Senegal</option>
<option value="+258	Mozambique">+258	Mozambique</option>
<option value="+962	Jordan">+962	Jordan</option>
<option value="+7	         Kazakhstan">+7	         Kazakhstan</option>
<option value="+254	Kenya">+254	Kenya</option>
<option value="+686	Kiribati">+686	Kiribati</option>
<option value="+383	Kosovo">+383	Kosovo</option>
<option value="+965	Kuwait">+965	Kuwait</option>
<option value="+996	Kyrgyzstan">+996	Kyrgyzstan</option>
<option value="+850	North Korea">+850	North Korea</option>
<option value="+82	        South Korea">+82	        South Korea</option>
<option value="+963	Syria">+963	Syria</option>
<option value="+967	Yemen">+967	Yemen</option>
<option value="+268	Lesotho">+268	Lesotho</option>
<option value="+370	Lithuania">+370	Lithuania</option>
<option value="+352	Luxembourg">+352	Luxembourg</option>
<option value="+856	Laos">+856	Laos</option>
<option value="+371	Latvia">+371	Latvia</option>
<option value="+218	Libya">+218	Libya</option>
<option value="+423	Liechtenstein">+423	Liechtenstein</option>
<option value="+961	Lebanon">+961	Lebanon</option>
<option value="+266	Lesotho">+266	Lesotho</option>
<option value="+231	Liberia">+231	Liberia</option>
<option value="+212	Morocco">+212	Morocco</option>
<option value="+261	Madagascar">+261	Madagascar</option>
<option value="+389	North Macedonia">+389	North Macedonia</option>
<option value="+265	Malawi">+265	Malawi</option>
<option value="+60     	Malaysia">+60     	Malaysia</option>
<option value="+966	Saudi Arabia">+966	Saudi Arabia</option>
<option value="+223	Mali">+223	Mali</option>
<option value="+356	Malta">+356	Malta</option>
<option value="+692	Marshall Islands">+692	Marshall Islands</option>
<option value="+230	Mauritius">+230	Mauritius</option>
<option value="+262	Mayotte">+262	Mayotte</option>
<option value="+52	        Mexico">+52	        Mexico</option>
<option value="+691	Micronesia, Federated States of">+691	Micronesia, Federated States of</option>
<option value="+373	Moldova">+373	Moldova</option>
<option value="+377	Monaco">+377	Monaco</option>
<option value="+378	Montenegro">+378	Montenegro</option>
<option value="+216	Tunisia">+216	Tunisia</option>
<option value="+90	         Turkey">+90	         Turkey</option>
<option value="+993	Turkmenistan">+993	Turkmenistan</option>
<option value="+688	Tuvalu">+688	Tuvalu</option>
<option value="+256	Uganda">+256	Uganda</option>
<option value="+380	Ukraine">+380	Ukraine</option>
<option value="+971	United Arab Emirates">+971	United Arab Emirates</option>
<option value="+598	Uruguay">+598	Uruguay</option>
<option value="+1	       US Virgin Islands">+1	       US Virgin Islands</option>
<option value="+92     	Pakistan">+92     	Pakistan</option>
<option value="+678	Vanuatu">+678	Vanuatu</option>
<option value="+58	         Venezuela">+58	         Venezuela</option>
<option value="+681	Wallis and Futuna">+681	Wallis and Futuna</option>
<option value="+262	Western Sahara">+262	Western Sahara</option>
<option value="+968	Oman">+968	Oman</option>
<option value="+255	Tanzania">+255	Tanzania</option>
<option value="+676	Tonga">+676	Tonga</option>
<option value="+228	Togo">+228	Togo</option>
<option value="+670	East Timor">+670	East Timor</option>
<option value="+976	Mongolia">+976	Mongolia</option>
<option value="+252	Somalia">+252	Somalia</option>
<option value="+239	São Tomé and Príncipe">+239	São Tomé and Príncipe</option>
<option value="+381	Serbia">+381	Serbia</option>
<option value="+260	Zambia">+260	Zambia</option>
</select>
            </div>
<div className='col-md-9'>
<input id="phone" maxlength="40" name="phone" type="text" className="form-control"required />

</div>
          </div>
        </div>

        <div className="form-outline mb-4">
          <label className="form-label black ss"  htmlFor="lead_source">Where did you hear about us?</label>
          <select id="lead_source" name="lead_source" className="form-control" required>
            <option value="">--None--</option>
            <option value="Facebook">Facebook</option>
            <option value="Instagram">Instagram</option>
            <option value="Linkedin">Linkedin</option>
            <option value="Referral">Referral</option>
            <option value="Youtube">Youtube</option>
            <option value="Ad">Ad</option>
          </select>
      
          
        </div>
        <div className="form-outline mb-4">
          <label className="form-label black ss"  htmlFor="lead_source">Description</label>
          <textarea className="form-control" name="description"></textarea>
        </div>
        <input required id="00N5i00000TVZqC" name="00N5i00000TVZqC" type="hidden" value="DynoPat Landing Page" />

        <input style={{ width: '100%' }}
          className="button btn btn-primary btn-block xc"type="submit" name="submit" value="Submit" />
      </form>
            </div>
            {/* End Col */}
            {/* Start Col */}
            <div className="col-lg-1"></div>
            {/* End Col */}
            {/* Start Col */}
            <div className="col-lg-4 col-md-12">
              <div className="contact-img">
                <img src="/assets/images/single1.png" className="img-fluid" alt="" />
              </div>
            </div>
            {/* End Col */}
            {/* Start Col */}
            <div className="col-lg-1"></div>
            {/* End Col */}
          </div>
          {/* End Row */}
        </div>
      </section>
      <div>
      <div class="copyright footerbag">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="site-info text-center">
                <div className="footer-column">
                                        <div className="footer-widget info-widget">
                                            <ul className="social-box">
                                                <li><a href="https://www.facebook.com/dynopat" className="fa fa-facebook-f"></a></li>
                                                <li><a href="https://twitter.com/DynoPatPvtLtd" className="fa fa-twitter"></a></li>
                                                <li><a href="https://www.linkedin.com/company/dynopat/ " className="fa fa-linkedin"></a></li>
                                                <li><a href="https://www.instagram.com/dynopat/" className="fa fa-instagram"></a></li>
                                            </ul>
                                        </div>
                                    </div>
                </div>              
                
              </div>
            </div>
          </div>
        </div>

   
      </div>
      {/* Contact Us Section End */}

      {/* Go To Top Link */}
      <a href="#" className="back-to-top">
        <i className="lni-chevron-up" />
      </a>
      {/* Preloader
  <div id="preloader">
    <div className="loader" id="loader-1" />
  </div> */}
      {/* End Preloader */}
      {/* jQuery first, then Tether, then Bootstrap %PUBLIC_URL%/assetssinglepage/js. */}
      {openmodel && <div className="popup-container">
        <div className="popup">


          <div className="row">
            <div className="col-lg-10">

              <img className='lllog' src="/assets/images/dino.png" alt="" />
            </div>
            <div className="col-lg-2 text-center">
              <button className="close-button" onClick={downloadbrochor}>Close</button>

            </div>
          </div>
          <Downloadpdf slug='dynopat-brochure.pdf'/>
        </div>
     
      </div>
      }
{openmodel1 && <div className="popup-container">
        <div className="popup">


          <div className="row">
            <div className="col-lg-10">

              <img className='lllog' src="/assets/images/dino.png" alt="" />
            </div>
            <div className="col-lg-2 text-center">
              <button className="close-button" onClick={joinus}>Close</button>

            </div>
          </div>
          <CourseLeadfrom/>
        </div>
      </div>
      }
         <div class="sticky-buttons">
  <a href="https://web.whatsapp.com/send?phone=+wa.me/13025676126" class="whatsapp-button" target="_blank">
    <img src="/assets/images/whatsapp.webp" alt="WhatsApp Icon"/>
  </a>
  <a href="tel:+13025676126" class="call-button">
    <img src="/assets/images/png-clipart-telephone-call-dialer-android-android-text-telephone-call-thumbnail-removebg-preview.png" alt="Call Icon"/>
  </a>
</div>
</div>

    </>

  )
}

export default Singlepage