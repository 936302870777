import React, { useState } from 'react'
import '../../../Style/style.css'
import { Link } from 'react-router-dom'
import Conactfrom from '../../../compunent/Conactfrom'
import Enroll from '../../../compunent/Enroll'
import { Helmet } from 'react-helmet-async'
import Claimyourfreecourse from '../../../compunent/Claimyourfreecourse'

function P_dev_2() {
  const [showpopup, setShowpopup] = useState(false);
	const [selectedVideoUrl, setSelectedVideoUrl] = useState('');
    const toggleshowpopup= (videoUrl) => {
		setSelectedVideoUrl(videoUrl);
        setShowpopup(!showpopup);
    };
	const url ="https://www.youtube.com/embed/tmXlpsexppE?si=cyKCHP0gHL-bdoCo";
	
  return (
    <>
    
			<Helmet>
			<title>Salesforce Platform Developer 2 Certification Training | Salesforce PD2 Training</title>
        	<meta name="description" content="Our Salesforce Platform Developer 2 Certification Training will help you clear the Salesforce exam in one attempt. Unlock your career potential today!" />
        	<meta name="keywords" content="Salesforce Platform Developer 2 Certification Training, Salesforce PD2 Training" />
        	<meta property="og:type" content="website" />
  <meta
    property="og:url"
    content="https://dynopat.com/learn-salesforce/live-classes/live-development-training/pd2/"
  />
  <meta
    property="og:title"
    content="Salesforce Platform Developer 2 Certification Training | Salesforce PD2 Training"
  />
  <meta
    property="og:description"
    content="Our Salesforce Platform Developer 2 Certification Training will help you clear the Salesforce exam in one attempt. Unlock your career potential today! "
  />
  <meta
    property="og:image"
    content="https://dynopat.com/assets/images/lernselsforcecourse/live/development/pd2.webp"
  />
  {/* Twitter */}
  <meta property="twitter:card" content="summary_large_image" />
  <meta
    property="twitter:url"
    content="https://dynopat.com/learn-salesforce/live-classes/live-development-training/pd2/"
  />
  <meta
    property="twitter:title"
    content="Salesforce Platform Developer 2 Certification Training | Salesforce PD2 Training"
  />
  <meta
    property="twitter:description"
    content="Our Salesforce Platform Developer 2 Certification Training will help you clear the Salesforce exam in one attempt. Unlock your career potential today! "
  />
  <meta
    property="twitter:image"
    content="https://dynopat.com/assets/images/lernselsforcecourse/live/development/pd2.webp"
  />
            <link rel="canonical" href="https://dynopat.com/learn-salesforce/live-classes/live-development-training/pd2/" />
      		</Helmet>

      <section className="page__title-area pt-120 pb-90">
        <div className="page__title-shape">
          <img  loading="lazy" className="page-title-shape-5 d-none d-sm-block" src="/assets/img/page-title/page-title-shape-1.png" alt="" />
          <img  loading="lazy" className="page-title-shape-6" src="/assets/img/page-title/page-title-shape-6.png" alt="" />
          <img  loading="lazy" className="page-title-shape-7" src="/assets/img/page-title/page-title-shape-4.png" alt="" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-lg-8">
              <div className="course__wrapper">
                <div className="page__title-content mb-25">
                  <div className="page__title-breadcrumb">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Salesforce Platform Developer 2 Certification 

</li>
                      </ol>
                    </nav>
                  </div>
                  <span className="page__title-pre">Live Development Training</span>
                  <h5 className="page__title-3">Salesforce Platform Developer 2 Certification 
</h5>
                </div>
                {/* <div className="course__meta-2 d-sm-flex mb-30">
                           <div className="course__teacher-3 d-flex align-items-center mr-70 mb-30">
                              <div className="course__teacher-thumb-3 mr-15">
                                 <img  loading="lazy" src="/assets/img/course/teacher/teacher-1.jpg" alt="" />
                              </div>
                              <div className="course__teacher-info-3">
                                 <h5>Teacher</h5>
                                 <p><a href="#">Elon Gated</a></p>
                              </div>
                           </div>
                           <div className="course__update mr-80 mb-30">
                              <h5>Last Update:</h5>
                              <p>July 24, 2022</p>
                           </div>
                           <div className="course__rating-2 mb-30">
                              <h5>certification:</h5>
                              <div className="course__rating-inner d-flex align-items-center">
                                 <ul>
                                    <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                    <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                    <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                    <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                    <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                 </ul>
                                 <p>4.5</p>
                              </div>
                           </div>
                        </div> */}
                <div className="course__img w-img mb-30">
                  <img  loading="lazy" src="/assets/images/lernselsforcecourse/live/development/pd2.webp" alt="" />
                </div>
                <div className="course__tab-2 mb-45 pb-3">
                  <ul className="nav nav-tabs" id="courseTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview" type="button" role="tab" aria-controls="overview" aria-selected="true"> <i className="fa fa-bookmark"></i> <span>Overview</span> </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link " id="curriculum-tab" data-bs-toggle="tab" data-bs-target="#curriculum" type="button" role="tab" aria-controls="curriculum" aria-selected="false"> <i className="fa fa-book"></i> <span>Curriculum</span> </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="certification-tab" data-bs-toggle="tab" data-bs-target="#certification" type="button" role="tab" aria-controls="certification" aria-selected="false"> <i className="fa fa-star"></i> <span>Certification</span> </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="faq-tab" data-bs-toggle="tab" data-bs-target="#faq" type="button" role="tab" aria-controls="faq" aria-selected="false"> <i className="fa fa-users"></i> <span>FAQs</span> </button>
                    </li>
                  </ul>
                </div>
                <div className="course__tab-content mb-95">
                  <div className="tab-content" id="courseTabContent">
                    <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                      <div className="course__overview">
                        <h3>Overview</h3>
                        <p>
Our Salesforce Platform Developer 2 Certification is designed for students who are certified in Salesforce PD1 certification. Students enrolling in this course should have an idea of Salesforce Admin, Platform Development, or App Building Processes. With this course, you will be able to master the course and have experience in how to maintain the security of an application, how to test/ debug the application’s security, and how to deploy the application in the real world. 
 </p>



                        <div className='service-detail'>
                          <div className='inner-box'>
                            <div className="blocks-outer">
                              <div className="row clearfix">


                                <div className="block-column col-lg-6 col-md-6 col-sm-12">
                                  <div className="inner-column">
                                    <h5>Learn Apex 

                                    </h5>
                                    <div className="block-text">We will teach you Apex, the fundamental programming language of Salesforce.</div>
                                  </div>
                                </div>


                                <div className="block-column col-lg-6 col-md-6 col-sm-12">
                                  <div className="inner-column">
                                    <h5>3 weeks duration</h5>
                                    <div className="block-text">In three weeks, we will cover the basic & advanced topics of Salesforce.</div>
                                  </div>
                                </div>


                                <div className="block-column col-lg-6 col-md-6 col-sm-12">
                                  <div className="inner-column">
                                    <h5>Real-time projects </h5>
                                    <div className="block-text">Develop CRM projects with advanced features and functionalities. </div>
                                  </div>
                                </div>


                                <div className="block-column col-lg-6 col-md-6 col-sm-12">
                                  <div className="inner-column">
                                    <h5>Dedicated Mentor Support</h5>
                                    <div className="block-text">You can dedicated support from mentors at every step of your Salesforce learning.  </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>


                      </div>
                    </div>
                    <div className="tab-pane fade" id="curriculum" role="tabpanel" aria-labelledby="curriculum-tab">
                      <div className="course__curriculum">
                        <div className="course__curriculum">
                          <div className="accordion" id="course__accordion">
                            <div className="accordion-item mb-2">
                              <h2 className="accordion-header" id="week-01">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-01-content" aria-expanded="true" aria-controls="week-01-content">
                                Overview of the Salesforce Platform 

                                </button>
                              </h2>
                              <div id="week-01-content" className="accordion-collapse collapse show " aria-labelledby="week-01" data-bs-parent="#course__accordion">
                                <div className="accordion-body">
                                  <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                    <div className="course__curriculum-info">
                                      <ul>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Understanding Salesforce Terminologies </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Multitenancy and Cloud  </span></h3></li>
                                       
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion" id="course__accordion-2">
                            <div className="accordion-item mb-50">
                              <h2 className="accordion-header" id="week-02">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-02-content" aria-expanded="true" aria-controls="week-02-content">
                                Understanding Salesforce Metadata & API 
                                </button>
                              </h2>
                              <div id="week-02-content" className="accordion-collapse  collapse " aria-labelledby="week-02" data-bs-parent="#course__accordion-2">
                                <div className="accordion-body">
                                  <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                    <div className="course__curriculum-info">
                                      <ul>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Understanding the architecture   </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Declarative vs Programmatic </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Application Development Tools In Salesforce </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>AppExchange </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Salesforce Documentation </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Salesforce Trust  </span></h3></li>
                                    

                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion" id="course__accordion-3">
                            <div className="accordion-item mb-50">
                              <h2 className="accordion-header" id="week-03">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-03-content" aria-expanded="true" aria-controls="week-03-content">
                                Data Model 
                                </button>
                              </h2>
                              <div id="week-03-content" className="accordion-collapse  collapse " aria-labelledby="week-03" data-bs-parent="#course__accordion-3">
                                <div className="accordion-body">
                                  <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                    <div className="course__curriculum-info">
                                      <ul>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Fields & Relationship Fields </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Junction Object </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Schema Builder </span></h3></li>
                                       

                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion" id="course__accordion-4">
                            <div className="accordion-item mb-50">
                              <h2 className="accordion-header" id="week-04">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-04-content" aria-expanded="true" aria-controls="week-04-content">
                                Business Logic 
                                </button>
                              </h2>
                              <div id="week-04-content" className="accordion-collapse  collapse " aria-labelledby="week-04" data-bs-parent="#course__accordion-4">
                                <div className="accordion-body">
                                  <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                    <div className="course__curriculum-info">
                                      <ul>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Data management </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Data import wizard  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Data loader </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>How to export data? </span></h3></li>
                                   


                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion" id="course__accordion-5">
                            <div className="accordion-item mb-50">
                              <h2 className="accordion-header" id="week-05">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-05-content" aria-expanded="true" aria-controls="week-05-content">
                                Apex 
                                </button>
                              </h2>
                              <div id="week-05-content" className="accordion-collapse  collapse " aria-labelledby="week-05" data-bs-parent="#course__accordion-5">
                                <div className="accordion-body">
                                  <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                    <div className="course__curriculum-info">
                                      <ul>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Apex development process</span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Apex development tools </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Governor limits </span></h3></li>
          

                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion" id="course__accordion-6">
                            <div className="accordion-item mb-50">
                              <h2 className="accordion-header" id="week-06">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-06-content" aria-expanded="true" aria-controls="week-06-content">
                                Overview of data types 
                                </button>
                              </h2>
                              <div id="week-06-content" className="accordion-collapse  collapse" aria-labelledby="week-06" data-bs-parent="#course__accordion-6">
                                <div className="accordion-body">
                                  <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                    <div className="course__curriculum-info">
                                      <ul>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Primitive data types </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>sObject</span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Enum </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Collections</span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Operators  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Logic control statements   </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Classes   </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Variables & Methods  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Access modifiers   </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Class constructors </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>This keyword  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Inheritance  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Sharing</span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Interface  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>System classes and methods    </span></h3></li>




                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion" id="course__accordion-7">
                            <div className="accordion-item mb-50">
                              <h2 className="accordion-header" id="week-07">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-07-content" aria-expanded="true" aria-controls="week-07-content">
                                Standard Object & Field API 
                                </button>
                              </h2>
                              <div id="week-07-content" className="accordion-collapse  collapse" aria-labelledby="week-07" data-bs-parent="#course__accordion-7">
                                <div className="accordion-body">
                                  <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                    <div className="course__curriculum-info">
                                      <ul>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Relationships </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Basic syntax </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>SOQL Functions </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>For Loops  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>SOQL keywords   </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Relationship Queries   </span></h3></li>




                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion" id="course__accordion-8">
                            <div className="accordion-item mb-50">
                              <h2 className="accordion-header" id="week-08">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-08-content" aria-expanded="true" aria-controls="week-08-content">
                                Salesforce Object Search Object (SOSL)
                                </button>
                              </h2>
                              <div id="week-08-content" className="accordion-collapse  collapse" aria-labelledby="week-08" data-bs-parent="#course__accordion-8">
                                <div className="accordion-body">
                                  <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                    <div className="course__curriculum-info">
                                      <ul>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>SOSL vs SOQL </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>DML  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Transaction Control </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>DMLOptions  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>SaveResult   </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Governor Limits   </span></h3></li>


                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion" id="course__accordion-9">
                            <div className="accordion-item mb-50">
                              <h2 className="accordion-header" id="week-09">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-09-content" aria-expanded="true" aria-controls="week-09-content">
                                  Triggers
                                </button>
                              </h2>
                              <div id="week-09-content" className="accordion-collapse  collapse" aria-labelledby="week-09" data-bs-parent="#course__accordion-9">
                                <div className="accordion-body">
                                  <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                    <div className="course__curriculum-info">
                                      <ul>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Triggers & Execution order  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Creating and viewing triggers </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Trigger context variables </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Trigger events   </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Bulkified triggers   </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Best practices for creating triggers   </span></h3></li>
                                        




                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion" id="course__accordion-10">
                            <div className="accordion-item mb-50">
                              <h2 className="accordion-header" id="week-10">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-10-content" aria-expanded="true" aria-controls="week-10-content">
                                Exceptions 
                                </button>
                              </h2>
                              <div id="week-10-content" className="accordion-collapse  collapse" aria-labelledby="week-10" data-bs-parent="#course__accordion-10">
                                <div className="accordion-body">
                                  <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                    <div className="course__curriculum-info">
                                      <ul>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Exception methods </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>User-defined exception  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Debugging using debug logs  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Anonymous blocks  </span></h3></li>


                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion" id="course__accordion-11">
                            <div className="accordion-item mb-50">
                              <h2 className="accordion-header" id="week-11">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-11-content" aria-expanded="true" aria-controls="week-11-content">
                                Testing 
                                </button>
                              </h2>
                              <div id="week-11-content" className="accordion-collapse  collapse" aria-labelledby="week-11" data-bs-parent="#course__accordion-11">
                                <div className="accordion-body">
                                  <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                    <div className="course__curriculum-info">
                                      <ul>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Test class methods </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Private variables & methods   </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Test data </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Unit Tests  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Application Development Lifecycle  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Development Environments</span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Testing tools and techniques for Apex classes and triggers and controllers </span></h3></li>



                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion" id="course__accordion-12">
                            <div className="accordion-item mb-50">
                              <h2 className="accordion-header" id="week-12">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-12-content" aria-expanded="true" aria-controls="week-12-content">
                                Deployment 
                                </button>
                              </h2>
                              <div id="week-12-content" className="accordion-collapse  collapse" aria-labelledby="week-12" data-bs-parent="#course__accordion-12">
                                <div className="accordion-body">
                                  <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                    <div className="course__curriculum-info">
                                      <ul>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Deployment best practices    </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Overview of VisualForce </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>MVC  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>VisualForce architecture  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Tools for VisualForce development </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Types of data binding </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Static resources  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>VisualForce Tags   </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>VisualForce Controller   </span></h3></li>




                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion" id="course__accordion-13">
                            <div className="accordion-item mb-50">
                              <h2 className="accordion-header" id="week-13">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-13-content" aria-expanded="true" aria-controls="week-13-content">
                                Standard List Controllers  
                                </button>
                              </h2>
                              <div id="week-13-content" className="accordion-collapse  collapse" aria-labelledby="week-13" data-bs-parent="#course__accordion-13">
                                <div className="accordion-body">
                                  <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                    <div className="course__curriculum-info">
                                      <ul>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Custom controllers and custom list controllers   </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Extensions  </span></h3></li>
                                        <li style={{ listStyleType: 'circle', marginLeft: '30px' }}><h3><span>Controller methods: order of execution   </span></h3></li>


                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>



                      </div>
                    </div>
                    <div className="tab-pane fade" id="certification" role="tabpanel" aria-labelledby="certification-tab">
                      <div className="course__certification">
                        <h3>Certification</h3>
                        <p className='text'>After completing this course on Salesforce Platform Development 2 Certification, you will gain insights into Salesforce development. You will work on real-time projects and understand the development lifecycle in detail. You can now show the certificate to your potential employers and apply for Salesforce Platform developer jobs. You can also apply for Salesforce Platform Developer 2 exam (provided you have passed the previous exam, i.e., the Salesforce PD1 exam). </p>
                        <img  loading="lazy" className='certificat' src='/assets/images/Certificate-Design.jpg'></img>

                      </div>
                    </div>
                    <div className="tab-pane fade" id="faq" role="tabpanel" aria-labelledby="faq-tab">
                      <div className="course__faq mb-45">



                        <div className="accordion" id="accordionExample">
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                              Do I need to learn any programming language? 


                              </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <div className="course__curriculum-info">
                                  <strong>You will learn a new programming language Apex while doing our course. If you have experience in Java, you can understand these topics a little better.  
</strong>

                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              Why should I learn Salesforce from Dynopat? 


                              </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <strong>If you are looking for hands-on Salesforce admin and development training, then you have come to the best place. By going through this course, you will learn why Salesforce is named “the best CRM platform” by businesses around the world. In this course, you will learn a new programming language Apex. You will learn how to use this programming language, and its structure to create complex applications. The content of this course is prepared in alignment with Salesforce PD1 (Platform Development) and PD2 Certifications. You will get to work on real projects that would value to your portfolio. Dynopat will offer technical support, and assistance if you ever get stuck.  
</strong>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                              Can I choose other courses from Dynopat? 


                              </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <strong>Yes, you can choose from our other courses that cover Salesforce Admin training, & Salesforce Developer Training. If you want to pursue a degree in LWC, you can choose Mountaineer.  </strong>

                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFour">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                              Does this course guarantee a job? 




                              </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <strong>
No, this course will not guarantee a job, but you will be prepared for the current job listings. You can explore the opportunities and apply for a suitable job. However, the final decision will be bestowed with the employer and it will depend on how you perform during the interview. 

                                </strong>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4">
              <div className="course__sidebar pl-70 p-relative">
                <div className="course__shape">
                  <img  loading="lazy" className="course-dot" src="/assets/img/course/course-dot.png" alt="" />
                </div>
                <div className="course__sidebar-widget-2 white-bg mb-20">
                  <div className="course__video" onClick={()=>toggleshowpopup(url)}>
                    <div className="course__video-thumb w-img mb-25">
                      <img  loading="lazy" src="/assets/images/video.png" alt="" />
                      <div className="course__video-play">
                        <a href="#" data-fancybox="" className="play-btn"> <i className="fa fa-play"></i> </a>
                      </div>
                    </div>
                    <div className="course__video-meta mb-25 d-flex align-items-center justify-content-between">
                      {/*<div className="course__video-price">
                        <h5>$74.<span>00</span> </h5>
                        <h5 className="old-price">$129.00</h5>
                      </div>
                      <div className="course__video-discount">
                        <span>68% OFF</span>
                      </div>*/}
                    </div>
                    <div className="course__video-content mb-35">
                      <ul>
                        {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Instructor :</span> Eleanor Fant</h5>
                                       </div>
                                    </li> */}
                        {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Lectures :</span>14</h5>
                                       </div>
                                    </li> */}
                        <li className="d-flex align-items-center">
                          <div className="course__video-icon">

                          </div>
                          <div className="course__video-info">
                            <h5><span>Duration :</span>3 weeks</h5>
                          </div>
                        </li>
                        {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Enrolled :</span>20 students</h5>
                                       </div>
                                    </li> */}
                        <li className="d-flex align-items-center">
                          <div className="course__video-icon">

                          </div>
                          <div className="course__video-info">
                            <h5><span>Language :</span>English</h5>
                          </div>
                        </li>
                      </ul>
                    </div>
                    {/* <div className="course__payment mb-35">
                                 <h3>Payment:</h3>
                                 <a href="#">
                                    <img  loading="lazy" style={{width: '222px'}} src="/assets/images/stripe.png" alt="" />
                                 </a>
                              </div> */}
                    <Claimyourfreecourse />

                    <button onClick={()=>{window.open('https://rzp.io/l/WWAarOeF')}}  class="e-btn e-btn-7 w-100">Enroll <i class="fa fa-arrow-right"></i></button>


                  </div>
                </div>
                
                <Conactfrom />
              </div>
            </div>

          </div>
        </div>
      </section>
      {showpopup&& <div className="popup-container">
                    <div className="popups">


                        <div className="row">
                            <div className="col-10">

                                <img className='lllog' src="/assets/images/dino.png" alt="" />
                            </div>
                            <div className="col-2 text-end">
                                <button className="close-button" onClick={toggleshowpopup}>Close</button>

                            </div>
                        </div>
						<iframe src={selectedVideoUrl} width="100%" height="400px" ></iframe>
                    </div>
                </div>
                }


    </>
  )
}

export default P_dev_2