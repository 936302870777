import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import Bandlogo from '../Home/Bandlogo';
import { Helmet } from 'react-helmet-async';

const About = () => {
	useEffect(() => {
		// Use jQuery to add functionality or make changes to the DOM
		$('.tab-btn').click(function () {
			var tab_id = $(this).attr('data-tab');
			$('.tab-btn').removeClass('active-btn');
			$('.tab').removeClass('active-tab');
			$(this).addClass('active-btn');
			$(tab_id).addClass('active-tab');
		});
	}, []);
	return (<>
	
	<Helmet>
			<title>Best Salesforce Training Institute | Salesforce Training Classes</title>
        	<meta name="description" content="Dynopat is the best Salesforce training institute that offers exclusive Salesforce training classes, daily assignments, real-time projects, and career guidance." />
        	<meta name="keywords" content="Best Salesforce Training Institute, Salesforce Training Classes" />
			<meta
    property="og:title"
    content="Best Salesforce Training Institute | Salesforce Training Classes"
  />
  <meta property="og:site_name" content="DynoPat" />
  <meta property="og:url" content="https://dynopat.com/about-dynopat" />
  <meta
    property="og:description"
    content="Dynopat is the best Salesforce training institute that offers exclusive Salesforce training classes, daily assignments, real-time projects, and career guidance."
  />
  <meta property="og:type" content="website" />
  <meta
    property="og:image"
    content="https://dynopat.com/assets/images/aboutdyno.png"
  />
				<link rel="canonical" href="https://dynopat.com/about" />
      		</Helmet>
     
	<title>About-Dynopat</title>
		<section className="page-title">
			<div className="pattern-layer-one" style={{ backgroundImage: 'url(/assets/images/background/pattern-18.png)' }}></div>
			<div className="cloud-layer" style={{ backgroundImage: 'url(/assets/images/background/pattern-19.png)' }}></div>
			<div className="big-letter">Dynopat</div>
			<div className="auto-container">
				<div className="row clearfix">

					<div className="title-column col-lg-6 col-md-12 col-sm-12">
						<div className="inner-column">
							<h1>About Dynopat</h1>
							<ul className="page-breadcrumb">
								<li><Link to="/">home</Link></li>
								<li>About us</li>
							</ul>
						</div>
					</div>

					<div className="images-column col-lg-6 col-md-12 col-sm-12">
						<div className="inner-column">
							<div className="image">
								<img src="/assets/images/aboutdyno.png" alt="" style={{ width: '100%', display: 'block', height: '480px' }} />
							</div>
							<div className="image">
								{/* <img src="/assets/images/resource/page-title-2.jpg" alt="" /> */}
							</div>
							<div className="image">
								{/* <img src="/assets/images/resource/page-title-3.jpg" alt="" /> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section className="about-section">
			<div className="auto-container">
				<div className="row clearfix">
					<div className="image-column col-lg-6 col-md-12 col-sm-12">
						<div className="inner-column parallax-scene-1 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
							<div className="image pb-3" data-depth="0.40">
								<img src="/assets/images/sir.webp" alt="" />
							</div>

						</div>
						<div style={{ background: '#faf9fa' }}>
							<h2 className='pb-3 text-center '>From Our CEO </h2>
							<div className="text"><h5 className="text-center ">We want students to learn a valuable skill and be job-ready</h5><br />Rajesh Chatterjee.

								Our CEO, Rajesh Chatterjee, started Dynopat with a vision of training students with the latest technologies. He is a Salesforce enthusiast, a visionary, and an expert trainer. He is the founder and CEO of Delipat, a B2B Salesforce development and implementation company. Dynopat is a sister concern of Delipat developed to train students and help them learn Salesforce. He aims to provide value to his clients (students) and drive the company’s success.

							</div>
						</div>
					</div>
					<div className="content-column col-lg-6 col-md-12 col-sm-12">
						<div className="inner-column">
							<div className="sec-title">
								<div className="title">About Company</div>
								<h2>Brand Story</h2>
								<div className="text">Our CEO, Rajesh Chatterjee saw the potential impact of Salesforce in the business world, how it can transform communication between stakeholders, and how it can help businesses arrive at informed decisions. But he saw a considerable skill gap between industry requirements and the job market. He wanted to bridge the gap by providing Salesforce certification training programs to students. His desire to provide quality training and create a community around it laid the foundations of Dynopat.

									It is sufficient to say that it was not a walk in the park. Dynopat has overcome some unexpected hurdles and established itself in the market. Our instructors and other team members worked tirelessly to build a brand and create a long-lasting image in the market.

									Happy Salesforce Learning!
								</div>
							</div>

							<div className="company-info-tabs">
								<div className="company-tabs tabs-box">

									<ul className="tab-btns tab-buttons clearfix">
										<li data-tab="#prod-quality" className="tab-btn active-btn"><span className="icon"><img src="/assets/images/icons/icon-1.png" alt="" /></span> Our Vision</li>
										<li data-tab="#prod-brand" className="tab-btn"><span className="icon"><img src="/assets/images/icons/icon-2.png" alt="" /></span> Our Mission</li>
									</ul>

									<div className="tabs-content">

										<div className="tab active-tab" id="prod-quality">
											<div className="content">
												<div className="text"><h4>We plan to establish our brand as a leading Salesforce online training company in the market.</h4><br />

													Dynopat’s vision is to provide a learning opportunity to everyone, even those from a non-technical background. We have taken the time to develop a high-quality curriculum that allows students to have a better chance at cracking interviews in today’s job market. We bring personalized lessons, pre-recorded sessions for self-paced learning, and interactive classes. With our training, we aim to deliver results through our customized sessions, mock interviews, and resume creation services.
												</div>
												{/* <div className="button-box">
												<a href="about.html" className="theme-btn btn-style-one"><span className="txt">Learn About Us <i className="fa fa-angle-right"></i></span></a>
											</div> */}
											</div>
										</div>

										<div className="tab" id="prod-brand">
											<div className="content">
												<div className="text"><h4>If you want to advance your career, we are the right choice. </h4>

													As per the latest reports, Salesforce is one of the high-paying technology stacks. We understand that it can be challenging to upskill if you don’t know where to start. That’s why we bring comprehensive training lessons to build skills and portfolios. These sessions are designed to meet your requirements (whether you need self-paced sessions or live classes). We also want to provide a supportive environment, so every student can upskill and get a better job.
												</div>
												{/* <div className="button-box">
												<a href="about.html" className="theme-btn btn-style-one"><span className="txt">Learn About Us <i className="fa fa-angle-right"></i></span></a>
											</div> */}
											</div>
										</div>

									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</section>
		<Bandlogo/>
		{/* 
    <section className="featured-section-four">
		<div className="auto-container">
			<div className="row clearfix">
			
				<div className="feature-block-four col-lg-4 col-md-6 col-sm-12">
					<div className="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
						<h5><a href="service-detail.html">Optimize Website</a></h5>
						<div className="text">Sedut perspiciatis unde omn ates voluptate accusantium doloremsyc laudantium totam apere</div>
					</div>
				</div>
				
				<div className="feature-block-four col-lg-4 col-md-6 col-sm-12">
					<div className="inner-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
						<h5><a href="service-detail.html">Analyze Competitors</a></h5>
						<div className="text">Sedut perspiciatis unde omn ates voluptate accusantium doloremsyc laudantium totam apere</div>
					</div>
				</div>
				
				<div className="feature-block-four col-lg-4 col-md-6 col-sm-12">
					<div className="inner-box wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
						<h5><a href="service-detail.html">Study Customers</a></h5>
						<div className="text">Sedut perspiciatis unde omn ates voluptate accusantium doloremsyc laudantium totam apere</div>
					</div>
				</div>
				
			</div>
			
		</div>
	</section>
    <section className="satatistics-section">
		<div className="auto-container">
			<div className="row clearfix">
				
				<div className="content-column col-lg-6 col-md-12 col-sm-12">
					<div className="inner-column">
						<div className="sec-title">
							<div className="title">Company Fun Fact</div>
							<h2><span>Something Known</span> <br/> Company Statistics</h2>
						</div>
						
						<div className="fact-counter">
							<div className="row clearfix">

								<div className="counter-column col-lg-6 col-md-6 col-sm-12">
									<div className="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
										<div className="content">
											<div className="dott"><i className="ripple"></i></div>
											<div className="count-outer count-box">
												<span className="count-text" data-speed="4000" data-stop="256">0</span>+
											</div>
											<h6>Project Done</h6>
										</div>
									</div>
								</div>
								
								<div className="counter-column col-lg-6 col-md-6 col-sm-12">
									<div className="inner wow fadeInLeft" data-wow-delay="150ms" data-wow-duration="1500ms">
										<div className="content">
											<div className="dott"><i className="ripple"></i></div>
											<div className="count-outer count-box">
												<span className="count-text" data-speed="5500" data-stop="89">0</span>+
											</div>
											<h6>Happy Clients</h6>
										</div>
									</div>
								</div>

								<div className="counter-column col-lg-6 col-md-6 col-sm-12">
									<div className="inner wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
										<div className="content">
											<div className="dott"><i className="ripple"></i></div>
											<div className="count-outer count-box">
												<span className="count-text" data-speed="3500" data-stop="362">0</span>
											</div>
											<h6>Team Member</h6>
										</div>
									</div>
								</div>

								<div className="counter-column col-lg-6 col-md-6 col-sm-12">
									<div className="inner wow fadeInLeft" data-wow-delay="450ms" data-wow-duration="1500ms">
										<div className="content">
											<div className="dott"><i className="ripple"></i></div>
											<div className="count-outer count-box">
												<span className="count-text" data-speed="3000" data-stop="85">0</span>%
											</div>
											<h6>Award Wins</h6>
										</div>
									</div>
								</div>
								
							</div>
						</div>
						
					</div>
				</div>
				
				<div className="image-column col-lg-6 col-md-12 col-sm-12">
					<div className="inner-column parallax-scene-1 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
						<div className="video-box">
							<figure className="video-image">
								<img className="transition-500ms" src="/assets/images/resource/video-img.jpg" alt=""/>
							</figure>
							<a href="https://www.youtube.com/watch?v=kxPCFljwJws" className="lightbox-image overlay-box"><span className="flaticon-media-play-symbol transition-900ms"><i className="ripple"></i></span></a>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	</section>
    <section className="team-section">
		<div className="auto-container">
			<div className="sec-title centered">
				<div className="title">Meet Our Team Member</div>
				<h2><span>We Have a Experience Team</span> <br/> Let’s Meet Our Team</h2>
			</div>
			<div className="row clearfix">
				
				<div className="team-block col-lg-3 col-md-6 col-sm-12">
					<div className="inner-box">
						<div className="image">
							<a href="team.html"><img src="/assets/images/resource/team-1.jpg" alt="" /></a>
							<ul className="social-box">
								<li><a href="https://www.facebook.com/" className="fa fa-facebook-f"></a></li>
								<li><a href="https://www.twitter.com/" className="fa fa-twitter"></a></li>
								<li><a href="https://www.linkedin.com/" className="fa fa-linkedin"></a></li>
								<li><a href="https://www.instagram.com/" className="fa fa-instagram"></a></li>
							</ul>
						</div>
						<div className="lower-content">
							<h6><a href="team.html">Marcel N. Marsh</a></h6>
							<div className="designation">CEO & Founder</div>
						</div>
					</div>
				</div>
				
				<div className="team-block col-lg-3 col-md-6 col-sm-12">
					<div className="inner-box">
						<div className="image">
							<a href="team.html"><img src="/assets/images/resource/team-2.jpg" alt="" /></a>
							<ul className="social-box">
								<li><a href="https://www.facebook.com/" className="fa fa-facebook-f"></a></li>
								<li><a href="https://www.twitter.com/" className="fa fa-twitter"></a></li>
								<li><a href="https://www.linkedin.com/" className="fa fa-linkedin"></a></li>
								<li><a href="https://www.instagram.com/" className="fa fa-instagram"></a></li>
							</ul>
						</div>
						<div className="lower-content">
							<h6><a href="team.html">Nearles S. Kwok</a></h6>
							<div className="designation">Senior Manager</div>
						</div>
					</div>
				</div>
				
				<div className="team-block col-lg-3 col-md-6 col-sm-12">
					<div className="inner-box">
						<div className="image">
							<a href="team.html"><img src="/assets/images/resource/team-3.jpg" alt="" /></a>
							<ul className="social-box">
								<li><a href="https://www.facebook.com/" className="fa fa-facebook-f"></a></li>
								<li><a href="https://www.twitter.com/" className="fa fa-twitter"></a></li>
								<li><a href="https://www.linkedin.com/" className="fa fa-linkedin"></a></li>
								<li><a href="https://www.instagram.com/" className="fa fa-instagram"></a></li>
							</ul>
						</div>
						<div className="lower-content">
							<h6><a href="team.html">James W. Ellerbe</a></h6>
							<div className="designation">Web Developer</div>
						</div>
					</div>
				</div>
				
				<div className="team-block col-lg-3 col-md-6 col-sm-12">
					<div className="inner-box">
						<div className="image">
							<a href="team.html"><img src="/assets/images/resource/team-4.jpg" alt="" /></a>
							<ul className="social-box">
								<li><a href="https://www.facebook.com/" className="fa fa-facebook-f"></a></li>
								<li><a href="https://www.twitter.com/" className="fa fa-twitter"></a></li>
								<li><a href="https://www.linkedin.com/" className="fa fa-linkedin"></a></li>
								<li><a href="https://www.instagram.com/" className="fa fa-instagram"></a></li>
							</ul>
						</div>
						<div className="lower-content">
							<h6><a href="team.html">Michael N. Gomer</a></h6>
							<div className="designation">Apps Designer</div>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	</section> */}

		{/* <section className="partner-section style-two">
		<div className="auto-container">
			<div className="inner-container" style={{backgroundImage: 'url(assets/images/background/pattern-22.png)'}}>
				<div className="row clearfix">
					<div className="title-column col-lg-5 col-md-12 col-sm-12">
						<div className="inner-column">
							<h4>We Have More Then <span>5683+</span> <br/> Global Premium</h4>
							<a href="service-detail.html" className="learn-more">Become a Partner</a>
						</div>
					</div>
					<div className="partners-column col-lg-7 col-md-12 col-sm-12">
						<div className="inner-column">
							<div className="row clearfix">
								<div className="column col-lg-4 col-md-4 col-sm-6">
									<a href="#"><img src="/assets/images/clients/7.png" alt="" /></a>
								</div>
								<div className="column col-lg-4 col-md-4 col-sm-6">
									<a href="#"><img src="/assets/images/clients/8.png" alt="" /></a>
								</div>
								<div className="column col-lg-4 col-md-4 col-sm-6">
									<a href="#"><img src="/assets/images/clients/9.png" alt="" /></a>
								</div>
								<div className="column col-lg-4 col-md-4 col-sm-6">
									<a href="#"><img src="/assets/images/clients/10.png" alt="" /></a>
								</div>
								<div className="column col-lg-4 col-md-4 col-sm-6">
									<a href="#"><img src="/assets/images/clients/11.png" alt="" /></a>
								</div>
								<div className="column col-lg-4 col-md-4 col-sm-6">
									<a href="#"><img src="/assets/images/clients/12.png" alt="" /></a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section> */}
	</>

	)
}

export default About