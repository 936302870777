import React, { useEffect, useState } from 'react'
import '../Style/style.css'
import { Link } from 'react-router-dom'
import Conactfrom from '../compunent/Conactfrom'
import Enroll from '../compunent/Enroll'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'
import { useParams } from 'react-router-dom/dist/umd/react-router-dom.development'
import Notfound from '../../notfound'
import Loder from '../compunent/Loder'


function Course_Slug() {
    


   const [data, setData] = useState({});
   const [weekData, setWeekData] = useState([]);
   const [weekData2, setWeekData2] = useState([]);
   const [loading, setLoading] = useState(true); 
   const [cadata, setCadata] = useState({});
   const { slug } = useParams();
   const { catslug } = useParams();
   const [notFound, setNotFound] = useState(false);
   
   useEffect(() => {
     fetchData();
   }, [slug, catslug]);
   
   const fetchData = async () => {
     try {
       const [courseResponse, categoryResponse] = await Promise.all([
         axios.get(`https://dynopat.com/dynopat_admin/api/course/${slug}`),
         axios.get(`https://dynopat.com/dynopat_admin/api/category/cat/${catslug}`)
       ]);
   
       setData(courseResponse.data.data);
       setWeekData(courseResponse.data.data.carriculams);
       setWeekData2(courseResponse.data.data.faqs);
       setCadata(categoryResponse.data.data);
       setLoading(false);
   
       if (!courseResponse.data.data || !categoryResponse.data.data) {
         setNotFound(true);
       }
     } catch (error) {
       console.error('Error fetching data:', error);
   
       // Check for specific error conditions, e.g., 404
       if (error.response && error.response.status === 404) {
         setNotFound(true);
       } else {
         // Handle other errors gracefully
         setNotFound(true); // You might want to handle this differently
       }
   
       setLoading(false);
     }
   };
   
   if (loading) {
     return <Loder />;
   } else if (notFound) {
     return <Notfound />;
   } else {
      const parsedHTML = document.createRange().createContextualFragment(data.meta_Tag);

      const metaTags = Array.from(parsedHTML.querySelectorAll('meta')).map((element) => ({
        name: element.getAttribute('name'),
        property: element.getAttribute('property'),
        content: element.getAttribute('content'),
      }));
      const canonicalLink = parsedHTML.querySelector('link[rel="canonical"]');
  return (
   <>
   
   <Helmet>
     {/* Iterate over the parsed HTML to extract and render title and meta tags */}
     <title>{parsedHTML.querySelector('title').textContent}</title>
        
        {/* Render other meta tags */}
        {metaTags.map((tag, index) => (
          <meta key={index} {...(tag.name ? { name: tag.name } : {})} {...(tag.property ? { property: tag.property } : {})} content={tag.content} />
        ))}

        {/* Render canonical link if found */}
        {canonicalLink && (
          <link rel="canonical" href={canonicalLink.getAttribute('href')} />
        )}
      </Helmet>
      {/* Rest of your component */}
      	

    <section className="page__title-area pt-120 pb-90">
      
    <div className="page__title-shape">
       <img  loading="lazy" className="page-title-shape-5 d-none d-sm-block" src="/assets/img/page-title/page-title-shape-1.png" alt="" />
       <img  loading="lazy" className="page-title-shape-6" src="/assets/img/page-title/page-title-shape-6.png" alt="" />
       <img  loading="lazy" className="page-title-shape-7" src="/assets/img/page-title/page-title-shape-4.png" alt="" />
    </div>
    <div className="container">
       <div className="row">
          <div className="col-xxl-8 col-xl-8 col-lg-8">
             <div className="course__wrapper">
                <div className="page__title-content mb-25">
                   <div className="page__title-breadcrumb">
                      <nav aria-label="breadcrumb">
                         <ol className="breadcrumb">
                         <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">{data.name}</li>
                         </ol>
                      </nav>
                   </div>
                   <span className="page__title-pre">{cadata.catagory_name}</span>
                   <h5 className="page__title-3">{data.name}</h5>
                </div>
                {/* <div className="course__meta-2 d-sm-flex mb-30">
                   <div className="course__teacher-3 d-flex align-items-center mr-70 mb-30">
                      <div className="course__teacher-thumb-3 mr-15">
                         <img  loading="lazy" src="/assets/img/course/teacher/teacher-1.jpg" alt="" />
                      </div>
                      <div className="course__teacher-info-3">
                         <h5>Teacher</h5>
                         <p><a href="#">Elon Gated</a></p>
                      </div>
                   </div>
                   <div className="course__update mr-80 mb-30">
                      <h5>Last Update:</h5>
                      <p>July 24, 2022</p>
                   </div>
                   <div className="course__rating-2 mb-30">
                      <h5>certification:</h5>
                      <div className="course__rating-inner d-flex align-items-center">
                         <ul>
                            <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                            <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                            <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                            <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                            <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                         </ul>
                         <p>4.5</p>
                      </div>
                   </div>
                </div> */}
                <div className="course__img w-img mb-30">
                   <img  loading="lazy" src={`https://dynopat.com/dynopat_admin/storage/app/public/CourseImage/${data.cover_image}`} alt="" />
                   <div className='service-detail'>
            <div className='inner-box'>
                <div className="blocks-outer">
                    <div className="row clearfix">


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>{data.objective_f} </h5>
                                {/* <div className="block-text">We offer personalized, interactive, & detailed Salesforce Admin & Development courses to students</div> */}
                            </div>
                        </div>


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>{data.objective_s} </h5>
                                {/* <div className="block-text">Ask your doubts about Salesforce to your instructor during the session.</div> */}
                            </div>
                        </div>


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>{data.objective_t}</h5>
                                {/* <div className="block-text">Self-paced Salesforce learning </div> */}
                            </div>
                        </div>


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>{data.objective_fo} </h5>
                                {/* <div className="block-text">Regular 1-hour classes for 5 days a week, from Monday to Friday.</div> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
                </div>
                <div className="course__tab-2 mb-45 pb-3">
                   <ul className="nav nav-tabs" id="courseTab" role="tablist">
                      <li className="nav-item" role="presentation">
                         <button className="nav-link active" id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview" type="button" role="tab" aria-controls="overview" aria-selected="true"> <i className="fa fa-bookmark"></i> <span>Overview</span> </button>
                      </li>
                      <li className="nav-item" role="presentation">
                         <button className="nav-link " id="curriculum-tab" data-bs-toggle="tab" data-bs-target="#curriculum" type="button" role="tab" aria-controls="curriculum" aria-selected="false"> <i className="fa fa-book"></i> <span>Curriculum</span> </button>
                      </li>
                      <li className="nav-item" role="presentation">
                         <button className="nav-link" id="certification-tab" data-bs-toggle="tab" data-bs-target="#certification" type="button" role="tab" aria-controls="certification" aria-selected="false"> <i className="fa fa-star"></i> <span>Certification</span> </button>
                      </li>
                      <li className="nav-item" role="presentation">
                         <button className="nav-link" id="faq-tab" data-bs-toggle="tab" data-bs-target="#faq" type="button" role="tab" aria-controls="faq" aria-selected="false"> <i className="fa fa-users"></i> <span>FAQs</span> </button>
                      </li>
                   </ul>
                </div>
                <div className="course__tab-content mb-95">
                   <div className="tab-content" id="courseTabContent">
                      <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                         <div className="course__overview">
                            <h3>Overview</h3>
                            <div dangerouslySetInnerHTML={{ __html: data.overview }} />
                     


                         </div>
                      </div>
                      <div className="tab-pane fade" id="curriculum" role="tabpanel" aria-labelledby="curriculum-tab">
                      <div className="accordion" id="accordionExample">
  {weekData.map((week, index) => (
    <div className="accordion-item" key={index}>
      <h2 className="accordion-header" id={`week-${index}`}>
        <button
          className= {`accordion-button  ${index === 0 ? '' : 'collapsed'}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapse-${index}`}
          aria-expanded={index === 0 ? 'true' : 'false'} // Set 'aria-expanded' to 'true' for the first item
          aria-controls={`collapse-${index}`}
        >
          {week.title}
        </button>
      </h2>
      <div
        id={`collapse-${index}`}
        className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`} // Add 'show' class only to the first item
        aria-labelledby={`week-${index}`}
        data-bs-parent="#accordionExample"
      >
        <div className="accordion-body">
          <div className="course__curriculum-info">
          <div dangerouslySetInnerHTML={{ __html: week.description }} />

          </div>
        </div>
      </div>
    </div>
  ))}
</div>



                      </div>
                      <div className="tab-pane fade" id="certification" role="tabpanel" aria-labelledby="certification-tab">
                         <div className="course__certification">
                            <h3>Certification</h3>
                            <div 
        dangerouslySetInnerHTML={{ __html: data.certificate }}
      />       
                           
                         </div>
                      </div>
                      <div className="tab-pane fade" id="faq" role="tabpanel" aria-labelledby="faq-tab">
                         <div className="course__faq mb-45">

     
                         <div className="accordion" id="accordionExample1">
  {weekData2.map((week, index) => (
    <div className="accordion-item" key={index}>
      <h2 className="accordion-header" id={`week-${index}`}>
        <button
          className= {`accordion-button  ${index === 0 ? '' : 'collapsed'}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapse-${index}`}
          aria-expanded={index === 0 ? 'true' : 'false'} // Set 'aria-expanded' to 'true' for the first item
          aria-controls={`collapse-${index}`}
        >
          {week.title}
        </button>
      </h2>
      <div
        id={`collapse-${index}`}
        className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`} // Add 'show' class only to the first item
        aria-labelledby={`week-${index}`}
        data-bs-parent="#accordionExample1"
      >
        <div className="accordion-body">
          <div className="course__curriculum-info">
                <div dangerouslySetInnerHTML={{ __html: week.description }} />
            
          </div>
        </div>
      </div>
    </div>
  ))}
</div>




                         </div>
                      </div>

                   </div>
                </div>
             </div>
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4">
             <div className="course__sidebar pl-70 p-relative">
                <div className="course__shape">
                   <img  loading="lazy" className="course-dot" src="/assets/img/course/course-dot.png" alt="" />
                </div>
                <div className="course__sidebar-widget-2 white-bg mb-20">
                           <div className="course__video">
                              <div className="course__video-thumb w-img mb-25">
                                 <img  loading="lazy" src="/assets/images/video.png" alt="" />
                                 <div className="course__video-play">
                                    <a href="#" data-fancybox="" className="play-btn"> <i className="fa fa-play"></i> </a>
                                 </div>
                              </div>
                              <div className="course__video-meta mb-25 d-flex align-items-center justify-content-between">
                               
                              {/* <h5 className="blue-2">₹{data.price}</h5> 
        <h5 className="old-price">₹{parseFloat(data.price) + (data.price  * 0.20) }</h5>  */}
          {/* <div className="course__video-price">
                                    <h5>$74.<span>00</span> </h5>
                                    <h5 className="old-price">$129.00</h5>
                                 </div>
                                 <div className="course__video-discount">
                                    <span>68% OFF</span>
                                 </div> */}
                              </div>
                              <div className="course__video-content mb-35">
                                 <ul>
                                    {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Instructor :</span> Eleanor Fant</h5>
                                       </div>
                                    </li> */}
                                    {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Lectures :</span>14</h5>
                                       </div>
                                    </li> */}
                                    <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Duration :</span>3 weeks</h5>
                                       </div>
                                    </li>
                                    {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Enrolled :</span>20 students</h5>
                                       </div>
                                    </li> */}
                                    <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Language :</span>English</h5>
                                       </div>
                                    </li>
                                 </ul>
                              </div>
                              {/* <div className="course__payment mb-35">
                                 <h3>Payment:</h3>
                                 <a href="#">
                                    <img  loading="lazy" style={{width: '222px'}} src="/assets/images/stripe.png" alt="" />
                                 </a>
                              </div> */}
                                                                <button onClick={()=>(window.open(data.enroll_url))}class="e-btn e-btn-7 w-100">Enroll <i class="fa fa-arrow-right"></i></button>
                             
                           </div>
                        </div>
                {/* <div className="course__sidebar-widget-2 white-bg mb-20">
                   <div className="course__sidebar-course">
                      <h3 className="course__sidebar-title">Related courses</h3>
                      <ul>
                      <li>
                            <div className="course__sm d-flex align-items-center mb-30">
                               <div className="course__sm-thumb mr-2">
                               <Link to='/demo-session'>
                                     <img  loading="lazy" src="/assets/images/business-training.jpg" alt="" />
                                  </Link>
                               </div>
                               <div className="course__sm-content">
                                  <div className="course__sm-rating">
                                     <ul>
                                        <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                        <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                        <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                        <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                        <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                     </ul>
                                  </div>
                                  <h5><Link to='/demo-session'>Demo</Link></h5>
                                  <div className="course__sm-price">
                                     <span>Free</span>
                                  </div>
                               </div>
                            </div>
                         </li>
                         <li>
                            <div className="course__sm d-flex align-items-center mb-30">
                               <div className="course__sm-thumb mr-2">
                                  <Link to='/ranger'>
                                     <img  loading="lazy" src="/assets/images/speaker-business-meeting-conference-hall.jpg" alt="" />
                                  </Link>
                               </div>
                               <div className="course__sm-content">
                                  <div className="course__sm-rating">
                                     <ul>
                                        <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                        <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                        <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                        <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                        <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                     </ul>
                                  </div>
                                  <h5><Link to='/ranger'>Ranger</Link></h5>
                                  <div className="course__sm-price">
                                     <span>Free</span>
                                  </div>
                               </div>
                            </div>
                         </li>
                         <li>
                            <div className="course__sm d-flex align-items-center mb-30">
                               <div className="course__sm-thumb mr-20">
                                  <Link to='/adventure'>
                                     <img  loading="lazy" src="/assets/images/entrepreneurs-meeting-office.jpg" alt="" />
                                  </Link>
                               </div>
                               <div className="course__sm-content">
                                  <div className="course__sm-rating">
                                     <ul>
                                        <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                        <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                        <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                        <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                        <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                     </ul>
                                  </div>
                                  <h5><Link to='/adventure'>Adventurer</Link></h5>
                                  <div className="course__sm-price">
                                     <span>$72.00</span>
                                  </div>
                               </div>
                            </div>
                         </li>
                         <li>
                            <div className="course__sm d-flex align-items-center mb-10">
                               <div className="course__sm-thumb mr-20">
                                  <Link to='/mountaineer'>
                                     <img  loading="lazy" src="/assets/images/positive-senior-leader-drawing-graph-meeting.jpg" alt="" />
                                  </Link>
                               </div>
                               <div className="course__sm-content">
                                  <div className="course__sm-rating">
                                     <ul>
                                        <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                        <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                        <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                        <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                        <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                     </ul>
                                  </div>
                                  <h5><Link to='/mountaineer'>Mountaineer</Link></h5>
                                  <div className="course__sm-price">
                                     <span>$54.00</span>
                                  </div>
                               </div>
                               
                            </div>
                            
                         </li>
                      </ul>
                   </div>
                </div> */}
                <Conactfrom/>
             </div>
          </div>
       </div>
    </div>
 </section>
 </>
  )
}
}

export default Course_Slug
