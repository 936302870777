import React from 'react'
import { Helmet } from 'react-helmet-async'

const TermsandConditions = () => {
    return (
<>

<Helmet>
			<title>Dynopat Home</title>
        	<meta name="description" content="Your meta description" />
        	<meta name="keywords" content="keyword1, keyword2, keyword3" />
        	<meta property="og:title" content="Your Open Graph title" />
        	<meta property="og:description" content="Your Open Graph description" />
        	<meta property="og:image" content="URL to your Open Graph image" />
       		 {/* Other OG tags */}
      		</Helmet>
     
        <section className='page__title-area pt-120 pb-90'>
            <div className='container'>
                <div className='row'>
                    <h2 className='text-center pb-4'>Dynopat’s Terms and Conditions</h2>
                    <p>You (User) are requested to go through Dynopat’s Terms and Conditions before proceeding to use our website. </p>
                    <p>These terms and conditions (“Terms and Conditions”) control your use of this website <a href='https://dynopat.com/'>“dynopat.com” </a>(“Website”). In these Terms and Conditions, “Dynopat private limited ” is referred to as the “Company”, “us,” “our (as in our website)”, or “we.”
                    </p><p>If you are a company or another person who gives access to company products, you agree to take full responsibility in case of damages or indemnification that could properly lie against the customer.
                    </p>
                    <p>The Dynopat website (the ‘Site’), the educational services made available through the site, and the content (the ‘Products’) are owned, operated, and maintained, as applicable, by us. The Site, Products, and Content are, collectively, the ‘Company Products’.
                    </p>
                    <p>By <strong>(a)</strong> using or accessing the Company Products, including, but not limited to downloading or accessing,
                        <br /><strong>(b)</strong> offering a Course through the Site or through Software; you agree to the terms and conditions set forth in these Terms of Use (the “Terms”)</p>
                    <p><b>BY USING THIS WEBSITE OR ITS PRODUCTS AND SERVICES, YOU WARRANT THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THESE TERMS. & OUR PRIVACY POLICY.
                    </b></p>
                    <p><b>IF YOU DO NOT ACCEPT THESE TERMS, YOU MUST REFRAIN FROM  USING – ALL OR ANY PORTION OF THE COMPANY’S WEBSITE AND ITS PRODUCTS OR SERVICES (AS DEFINED BELOW).</b></p>

                    <ul>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} >You should not use our site in an unauthorized and illegal manner; you must respect our terms and conditions, and privacy policy.</li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }}>Under no situations or circumstances, the company is liable for any change in the content which it provides on the website through its products and services, including but not limited to any errors, omissions, loss, or damage experienced in connection with the use of exposure, any content made available via our products, services or various resources such as email, blog, etc.</li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }}>You will be required to use login credentials for some of the sections on the site and the company reserves the right to block access to our services for any user who does not follow these conditions</li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }}>We make sure that users get uninterrupted access to our service, but there is no obligation to do so.</li>

                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }}>Dynopat is not responsible and is not obligated for issues in your network or server beyond certain limits.</li>

                    </ul>
                    <h4 className='pb-2 pt-2'>THE CONTENT</h4>
                    <p>All website content or information that can be seen, heard, or otherwise experienced on the Site is copyrighted and belongs to Dynopat or its partners, affiliates, or third parties. You may use the Site, the Service, and the Content for your personal, non-commercial use only.
                        You will not transfer any information from the website or produce derivative work which you can display, distribute or transmit.
                    </p>
                    <h4 className='pb-2 pt-2'>LINKS</h4>
                    <p>This website may have links to other websites. We do not undertake any control on the content of these websites; nor are we responsible for their website content. The sole purpose of the links included is to provide information to users. However, Dynopat will not be held responsible for any issues.
                    </p>
                    <h4 className='pb-2 pt-2'>User Terms and Conditions </h4>
                    <ul>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} >You may not mirror or frame the home page or any other pages of this Site on any other website or web page.</li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }}>Do not link to Dynopat pages and subpages with spam links/anchor text which could provide a false impression. This may create misunderstandings for the users.</li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }}>Do not use or include copyrighted or registered trademarks, or Intellectual property images, designs, or content as a link to the Dynopat website.</li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }}>Do not link to pages that support racism and terrorism.</li>

                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }}>Do not link to pages that provide pornographic content and violate human rights.</li>

                    </ul>
                    <h4 className='pb-2 pt-2'>COPYRIGHT AND INTELLECTUAL PROPERTY</h4>
                    <p>We value and respect the intellectual properties of others and expect our users to do the same.</p>
                    <p>The entire contents of the Site are protected by copyright and trademark laws. The owner of the copyrights and trademarks are Dynopat, its affiliates, or other third-party licensors. The material on the site, including text, graphics, code, and/or software is copyrighted and belongs to us, therefore you may not duplicate, modify, publish, or reproduce the content in any manner,</p>
                    <p>We do not take any responsibility for the content on other sites (except our partners and affiliates), that you may find when searching or accessing our products or services. The privacy policy and terms of use of those sites you visit will administer that material.
                    </p>
                    <p>We have all the rights to disable or prohibit access to users who do not respect and involve in the infringement of our intellectual property</p>
                    <p>You are not allowed to use any of the digital images or logos from the website. In case of copyright issues, there has to be written consent from the trademark owner</p>
                    <h4 className='pb-2 pt-2'>CLAIMS OF INTELLECTUAL PROPERTY VIOLATIONS</h4>
                    <p>If you believe that your work has been used without your permission in a way that prompts copyright infringement. Please provide us with relevant information (proof of your work and where it has been used on our website) and we will act on it. You can reach us at: <a href='mailto:info@dynopat.com'>info@dynopat.com </a> </p>
                    <h4 className='pb-2 pt-2'>TRANSACTION TERMS</h4>
                    <p>When you transact on our website, you agree to the following terms.
                    </p>
                    <ul>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }} >To make a transaction on the Dynopat website, you are bound to pay for that transaction.
                        </li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }}>Do not link to Dynopat pages and subpages with spam links/anchor text which could provide a false impression. This may create misunderstandings for the users.</li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }}>Please pay close attention to your payment details such as total bill, taxes, shipping costs, and discounts.</li>
                        <li style={{ listStyleType: 'square', fontSize: '16px', marginLeft: '50px' }}>There are certain products that require additional terms and conditions which you have to agree on before you make the purchase</li>

                    </ul>
                    <p>No additional or different terms contained in any purchase order, document, transmission, or other communication shall be binding unless agreed to by Dynopat in writing.
                    </p>
                    <p>
                        We, in sole discretion, reserve the right to modify, or change the website’s content without prior notice, to limit the order quantity on any item, and to refuse service to anyone.
                    </p>
                    <h4 className='pb-2 pt-2'>PRICING</h4>
                    <p>All prices, products, and offers on our website are subject to change without notice.
                        While we make sure to provide the most accurate and up-to-date information, however, in some cases, one or more items on our website may be priced incorrectly. This might happen due to human errors, digital images, or technical errors.
                    </p>
                    <p>We reserve the right to change prices for all our products, offers, or deals. These changes are done due to market conditions, course termination, price changes, errors in advertisements, and other mitigating circumstances. However, the price you paid at the time of purchase still holds for you.</p>

                    <h4 className='pb-2 pt-2'>PROPRIETARY USE </h4>
                    <p>We own the Intellectual property rights of all the study materials provided to the delegates, partners, and affiliates. Therefore, no part of any course materials may be duplicated, transmitted digitally, mechanically, or by photocopying, or recording, and should not be translated into other languages without explicit written permission. If caught doing so, we may take legal action</p>
                    <h4 className='pb-2 pt-2'>GRIEVANCE REDRESSAL</h4>
                    <p>For any grievances or support, contact <a href='mailto:info@dynopat.com '>info@dynopat.com </a> </p>
                </div>
            </div>
        </section>
</>
    )
}

export default TermsandConditions