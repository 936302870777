import React, { useState } from 'react'
import '../../../Style/style.css'
import { Link } from 'react-router-dom'
// import Enroll from '../../../compunent/Enroll'
import Contactfrom from '../../../compunent/Conactfrom'
import { Helmet } from 'react-helmet-async'
import Claimyourfreecourse from '../../../compunent/Claimyourfreecourse'


function Salesforce_Business_Analyst_Training () {
   
   const [showpopup, setShowpopup] = useState(false);
	const [selectedVideoUrl, setSelectedVideoUrl] = useState('');
    const toggleshowpopup= (videoUrl) => {
		setSelectedVideoUrl(videoUrl);
        setShowpopup(!showpopup);
    };
	const url ="https://www.youtube.com/embed/teucDZlSLro?si=dyAMMfFcPDy8duqg";
	
  return (
   <>
   <Helmet>

   <title>Salesforce Business Analyst Training | Salesforce Business Analyst Certification</title>
     <meta name="description" content="Want to become a highly-paid certified Salesforce Business Analyst? Enhance your career prospects with our specialized Salesforce Business Analyst Training." />
     <meta name="keywords" content="Salesforce Business Analyst Training, Salesforce Business Analyst Certification" />
    {/* Open Graph / Facebook */}
  <meta property="og:type" content="website" />
  <meta
    property="og:url"
    content="https://dynopat.com/learn-salesforce/live-classes/live-admin-training/business-analyst/"
  />
  <meta
    property="og:title"
    content="Salesforce Business Analyst Training | Salesforce Business Analyst Certification"
  />
  <meta
    property="og:description"
    content="Want to become a highly-paid certified Salesforce Business Analyst? Enhance your career prospects with our specialized Salesforce Business Analyst Training."
  />
  <meta
    property="og:image"
    content="https://dynopat.com/assets/images/lernselsforcecourse/live/admin/business.webp"
  />
  {/* Twitter */}
  <meta property="twitter:card" content="summary_large_image" />
  <meta
    property="twitter:url"
    content="https://dynopat.com/learn-salesforce/live-classes/live-admin-training/business-analyst/"
  />
  <meta
    property="twitter:title"
    content="Salesforce Business Analyst Training | Salesforce Business Analyst Certification"
  />
  <meta
    property="twitter:description"
    content="Want to become a highly-paid certified Salesforce Business Analyst? Enhance your career prospects with our specialized Salesforce Business Analyst Training."
  />
  <meta
    property="twitter:image"
    content="https://dynopat.com/assets/images/lernselsforcecourse/live/admin/business.webp"
  />
        <link rel="canonical" href="https://dynopat.com/learn-salesforce/live-classes/live-admin-training/business-analyst/" />
      </Helmet>

    <section className="page__title-area pt-120 pb-90">
            <div className="page__title-shape">
               <img  loading="lazy" className="page-title-shape-5 d-none d-sm-block" src="/assets/img/page-title/page-title-shape-1.png" alt="" />
               <img  loading="lazy" className="page-title-shape-6" src="/assets/img/page-title/page-title-shape-6.png" alt="" />
               <img  loading="lazy" className="page-title-shape-7" src="/assets/img/page-title/page-title-shape-4.png" alt="" />
            </div>
            <div className="container">
               <div className="row">
                  <div className="col-xxl-8 col-xl-8 col-lg-8">
                     <div className="course__wrapper">
                        <div className="page__title-content mb-25">
                           <div className="page__title-breadcrumb">
                              <nav aria-label="breadcrumb">
                                 <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Salesforce Business Analyst Certification</li>
                                 </ol>
                              </nav>
                           </div>
                           <span className="page__title-pre">Live admin training</span>
                           <h5 className="page__title-3">Salesforce Business Analyst Certification</h5>
                        </div>
                        {/* <div className="course__meta-2 d-sm-flex mb-30">
                           <div className="course__teacher-3 d-flex align-items-center mr-70 mb-30">
                              <div className="course__teacher-thumb-3 mr-15">
                                 <img  loading="lazy" src="/assets/img/course/teacher/teacher-1.jpg" alt="" />
                              </div>
                              <div className="course__teacher-info-3">
                                 <h5>Teacher</h5>
                                 <p><a href="#">Elon Gated</a></p>
                              </div>
                           </div>
                           <div className="course__update mr-80 mb-30">
                              <h5>Last Update:</h5>
                              <p>July 24, 2022</p>
                           </div>
                           <div className="course__rating-2 mb-30">
                              <h5>certification:</h5>
                              <div className="course__rating-inner d-flex align-items-center">
                                 <ul>
                                    <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                    <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                    <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                    <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                    <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                 </ul>
                                 <p>4.5</p>
                              </div>
                           </div>
                        </div> */}
                        <div className="course__img w-img mb-30">
                           <img  loading="lazy" src="/assets/images/lernselsforcecourse/live/admin/business.webp" alt="" />
                        </div>
                        <div className="course__tab-2 mb-45 pb-3">
                           <ul className="nav nav-tabs" id="courseTab" role="tablist">
                              <li className="nav-item" role="presentation">
                                 <button className="nav-link active" id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview" type="button" role="tab" aria-controls="overview" aria-selected="true"> <i className="fa fa-bookmark"></i> <span>Overview</span> </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                 <button className="nav-link " id="curriculum-tab" data-bs-toggle="tab" data-bs-target="#curriculum" type="button" role="tab" aria-controls="curriculum" aria-selected="false"> <i className="fa fa-book"></i> <span>Curriculum</span> </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                 <button className="nav-link" id="certification-tab" data-bs-toggle="tab" data-bs-target="#certification" type="button" role="tab" aria-controls="certification" aria-selected="false"> <i className="fa fa-star"></i> <span>Certification</span> </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                 <button className="nav-link" id="faq-tab" data-bs-toggle="tab" data-bs-target="#faq" type="button" role="tab" aria-controls="faq" aria-selected="false"> <i className="fa fa-users"></i> <span>FAQs</span> </button>
                              </li>
                           </ul>
                        </div>
                        <div className="course__tab-content mb-95">
                           <div className="tab-content" id="courseTabContent">
                              <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                                 <div className="course__overview">
                                    <h3>Overview</h3>
                                    <p>A Salesforce business analyst has to fulfill a crucial role within any organization using the CRM platform. He needs to collect and check the requirements, ensure all the criteria are met, and help stakeholders arrive at key decisions for a business. Does all of this sound exciting to you? The good news is, you can pursue a career in this profile even if you are coming from a non-technical background. <br/>

At Dynopat, we ensure you get the best training from the top Salesforce trainers in the industry. The free 7-day demo sessions are hosted by Rajesh Chatterjee and are arranged to train the basic concepts after which you are free to make a call in any direction. We have received excellent reviews from students of previous batches, as you can see from the testimonials. You are free to check out his YouTube channel (https://www.youtube.com/@Delipat-Rajesh) and see how he makes even the most complex topics simpler and fun to understand. Throughout this duration, you can ask your queries to your mentor and improve your understanding of the subject. </p>



                                    <div className='service-detail'>
            <div className='inner-box'>
                <div className="blocks-outer">
                    <div className="row clearfix">


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>Pre-recorded lessons </h5>
                                {/* <div className="block-text">We offer personalized, interactive, & detailed Salesforce Admin & Development courses to students</div> */}
                            </div>
                        </div>


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>Flexible scheduling </h5>
                                {/* <div className="block-text">Ask your doubts about Salesforce to your instructor during the session.</div> */}
                            </div>
                        </div>


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>Certification for High-paying Jobs  </h5>
                                {/* <div className="block-text">Apply your theoretical knowledge of Salesforce in real-time and check your progress.</div> */}
                            </div>
                        </div>


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>Dedicated Salesforce learning </h5>
                                {/* <div className="block-text">Regular 1-hour classes for 5 days a week, from Monday to Friday.</div> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>


                                 </div>
                              </div>
                              <div className="tab-pane fade" id="curriculum" role="tabpanel" aria-labelledby="curriculum-tab">
                                 <div className="course__curriculum">
                                    <div className="accordion" id="course__accordion">
                                       <div className="accordion-item mb-2">
                                          <h2 className="accordion-header" id="week-01">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-01-content" aria-expanded="true" aria-controls="week-01-content">
                                             Overview of Salesforce Business Analyst Profile 


                                             </button>
                                          </h2>
                                          <div id="week-01-content" className="accordion-collapse collapse show " aria-labelledby="week-01" data-bs-parent="#course__accordion">
                                             <div className="accordion-body">
                                                <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                   <div className="course__curriculum-info">
                                                      <ul>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Key responsibilities of a Salesforce business analyst   </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Skills required to be a business analyst </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Difference between a Salesforce business analyst and a Salesforce admin  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Types of Documentation  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to create project documentation?</span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to perform requirement analysis?s  </span> </h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Functional and non-functional requirements  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to finalize the requirements?    </span></h3></li>

                                                         </ul>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="accordion" id="course__accordion-2">
                                       <div className="accordion-item mb-50">
                                          <h2 className="accordion-header" id="week-02">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-02-content" aria-expanded="true" aria-controls="week-02-content">
                                             Salesforce CRM 
                                             </button>
                                          </h2>
                                          <div id="week-02-content" className="accordion-collapse  collapse " aria-labelledby="week-02" data-bs-parent="#course__accordion-2">
                                             <div className="accordion-body">
                                                <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                <div className="course__curriculum-info">
                                                      <ul>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span> Organization-wide defaults  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Sharing rules   </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Manual sharing  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Creating queues, roles, and hierarchies   </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to set up public groups?  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Workflow automation   </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Data management   </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Process builder   </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>History tracking     </span></h3></li>


                                                         
                                                      </ul>
                                                      </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="accordion" id="course__accordion-3">
                                       <div className="accordion-item mb-50">
                                          <h2 className="accordion-header" id="week-03">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-03-content" aria-expanded="true" aria-controls="week-03-content">
                                             Overview of lists, reports, and dashboards 

                                             </button>
                                          </h2>
                                          <div id="week-03-content" className="accordion-collapse  collapse " aria-labelledby="week-03" data-bs-parent="#course__accordion-3">
                                             <div className="accordion-body">
                                                <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                <div className="course__curriculum-info">
                                                      <ul>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>What are reports?  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to add summary fields? </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to add filters?  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to create reports on leads?</span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Dashboards and their components  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Case status dashboards </span></h3></li>

                                                      </ul>
                                                      </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="accordion" id="course__accordion-4">
                                       <div className="accordion-item mb-50">
                                          <h2 className="accordion-header" id="week-04">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-04-content" aria-expanded="true" aria-controls="week-04-content">
                                             Quality Assurance 

                                             </button>
                                          </h2>
                                          <div id="week-04-content" className="accordion-collapse  collapse " aria-labelledby="week-04" data-bs-parent="#course__accordion-4">
                                             <div className="accordion-body">
                                                <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                <div className="course__curriculum-info">
                                                      <ul>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Testing in Salesforce </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Business Analysis Process and Diagrams   </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Tools to use for quality assurance </span></h3></li>

                                                      </ul>
                                                      </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="tab-pane fade" id="certification" role="tabpanel" aria-labelledby="certification-tab">
                                 <div className="course__certification">
                                    <h3>Certification</h3>
                                    <p>After completing this certification, you will be able to demonstrate your knowledge of the requirements, user stories, and user acceptance. The goal of the certificate is to ensure that the attendee gets top-quality education from top instructors. You will gain the confidence to take on real-time business scenarios and work on projects. At the end of the course, you will receive a certificate from us declaring you as a Salesforce Business Analyst. The curriculum will help you understand the topics for the analyst test and appear for it. Our quizzes contain questions that are frequently asked in the main Salesforce certification exam. If you can get high scores on those quizzes, you are one step ahead of your competitors. 
    </p>
                                    <img  loading="lazy" className='certificat' src='/assets/images/Certificate-Design.jpg'></img>
                                 </div>
                              </div>
                              <div className="tab-pane fade" id="faq" role="tabpanel" aria-labelledby="faq-tab">
                                 <div className="course__faq mb-45">



                                    <div className="accordion" id="accordionExample">
                                       <div className="accordion-item">
                                          <h2 className="accordion-header" id="headingOne">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                             What are the job prospects of learning Salesforce? 

                                             </button>
                                          </h2>
                                          <div id="collapseOne" className="accordion-collapse collapse show " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                             <div className="accordion-body">
                                             <div className="course__curriculum-info">
                                                <strong>After completing the Salesforce certification, you can apply for these positions:
 
 </strong>
 <ul>

                                                   <li style={{ listStyleType: 'circle', marginLeft: '30px' }}>Salesforce Admin </li>
                                                   <li style={{ listStyleType: 'circle', marginLeft: '30px' }}>Salesforce Consultant </li>
                                                   <li style={{ listStyleType: 'circle', marginLeft: '30px' }}>Salesforce Business Analyst </li>
                                                </ul>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="accordion-item">
                                          <h2 className="accordion-header" id="headingTwo">
                                             <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                             What can I expect from Dynopat’s Live sessions?

                                             </button>
                                          </h2>
                                          <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                             <div className="accordion-body">
                                                <strong>Our Live lessons are taken by Rajesh Chatterjee, a top instructor having years of industry expertise on the subject matter. He has been tutoring students for a long time, check out his YouTube channel, https://www.youtube.com/@Delipat-Rajesh. In these videos, he has taken a detailed approach to cover and explain the core topics of Salesforce Administration/ Salesforce Business Analysis. <br/>The live sessions come equipped with 1:1 mentoring sessions with dedicated support from mentors. You will get daily assignments, which will be evaluated by our team members. In the end, you will work on real-time projects, and gain the skills and expertise of a Business Analyst.
 
 </strong>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="accordion-item">
                                          <h2 className="accordion-header" id="headingThree">
                                             <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                             How can I get certified? 




                                             </button>
                                          </h2>
                                          <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                             <div className="accordion-body">
                                                <strong>You can get the certificate by attending the lessons, and by taking the quizzes (present at the end of each recording. You can also follow the document attached to these sessions. The goal of these quizzes is to assess your level of understanding and help you identify your weak points. 
  
 </strong>
                                         
                                             </div>
                                          </div>
                                       </div>
                                       <div className="accordion-item">
                                          <h2 className="accordion-header" id="headingFour">
                                             <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                             Can I ask for additional classes? 
                                             </button>
                                          </h2>
                                          <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                             <div className="accordion-body">
                                             <strong>If you feel that you need additional classes, you can enroll in our live classes. Aside from that, you can also ask our support team about it, and we will help you at the earliest. 

</strong>
                                             </div>
                                          </div>
                                       </div>

                                       <div className="accordion-item">
                                          <h2 className="accordion-header" id="headingFive">
                                             <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                             What are the prerequisites for this course? 

                                             </button>
                                          </h2>
                                          <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                             <div className="accordion-body">
                                             <strong>You don’t need any prior experience or training before enrolling in this course. However, if you have an experience with OOPs or Java, you may grasp some of the topics quickly.   
</strong>
                                             </div>
                                          </div>
                                       </div>
                                    </div>





                                 </div>
                              </div>

                           </div>
                        </div>
                     </div>
                  </div>
                 <div className="col-xxl-4 col-xl-4 col-lg-4">
                     <div className="course__sidebar pl-70 p-relative">
                        <div className="course__shape">
                           <img  loading="lazy" className="course-dot" src="/assets/img/course/course-dot.png" alt="" />
                        </div>
                        <div className="course__sidebar-widget-2 white-bg mb-20">
                           <div className="course__video" onClick={()=>toggleshowpopup(url)}>
                              <div className="course__video-thumb w-img mb-25">
                                 <img  loading="lazy" src="/assets/images/video.png" alt="" />
                                 <div className="course__video-play">
                                    <a href="#" data-fancybox="" className="play-btn"> <i className="fa fa-play"></i> </a>
                                 </div>
                              </div>
                              <div className="course__video-meta mb-25 d-flex align-items-center justify-content-between">
                                 {/* <div className="course__video-price">
                                    <h5>$74.<span>00</span> </h5>
                                    <h5 className="old-price">$129.00</h5>
                                 </div>
                                 <div className="course__video-discount">
                                    <span>68% OFF</span>
                                 </div> */}
                              </div>
                              <div className="course__video-content mb-35">
                                 <ul>
                                    {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Instructor :</span> Eleanor Fant</h5>
                                       </div>
                                    </li> */}
                                    {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Lectures :</span>14</h5>
                                       </div>
                                    </li> */}
                                    <li className="d-flex align-items-center">
                                       <div className="course__video-icon" >

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Duration :</span>3 weeks</h5>
                                       </div>
                                    </li>
                                    {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Enrolled :</span>20 students</h5>
                                       </div>
                                    </li> */}
                                    <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Language :</span>English</h5>
                                       </div>
                                    </li>
                                 </ul>
                              </div>
                              {/* <div className="course__payment mb-35">
                                 <h3>Payment:</h3>
                                 <a href="#">
                                    <img  loading="lazy" style={{width: '222px'}} src="/assets/images/stripe.png" alt="" />
                                 </a>
                              </div> */}
                    <Claimyourfreecourse />

                    <button onClick={()=>{window.open('https://rzp.io/l/MjFxE4Ookn')}}  class="e-btn e-btn-7 w-100">Enroll <i class="fa fa-arrow-right"></i></button>


                           </div>
                        </div>
                        
                        <Contactfrom/>
                     </div>
                  </div>
               </div>
            </div>
            {showpopup&& <div className="popup-container">
                    <div className="popups">


                        <div className="row">
                            <div className="col-10">

                                <img className='lllog' src="/assets/images/dino.png" alt="" />
                            </div>
                            <div className="col-2 text-end">
                                <button className="close-button" onClick={toggleshowpopup}>Close</button>

                            </div>
                        </div>
						<iframe src={selectedVideoUrl} width="100%" height="400px" ></iframe>
                    </div>
                </div>
                }
         </section>
         </>
  )
}

export default Salesforce_Business_Analyst_Training 
