import React, { useEffect, useState } from 'react'
import '../Style/style.css'
import { Link } from 'react-router-dom'
import Conactfrom from '../compunent/Conactfrom'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'
import { useParams } from 'react-router-dom/dist/umd/react-router-dom.development'
import Loder from '../compunent/Loder'
import Notfound from '../../notfound'


function Category_Slug(props) {
   const [data, setData] = useState([]);
   const [cadata, setCadata] = useState([]);
   const [weekData, setWeekData] = useState([]);
   const [loading, setLoading] = useState(true); 

   const { slug } = useParams();
  useEffect(() => {
    fetchData();
  }, [slug]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`https://dynopat.com/dynopat_admin/api/category/cat/${slug}`);
      
      setData(response.data.data.courses);
      setCadata(response.data.data);
      setWeekData(response.data.data.catfaqs);
      setLoading(false);
console.log(response.data)
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
 
  if (loading) {
   return <Loder/>;
 } else if (!cadata || Object.keys(cadata).length === 0) {
   
   return <Notfound/>;
 } else {
   const parsedHTML = document.createRange().createContextualFragment(cadata.meta_tag);

   const metaTags = Array.from(parsedHTML.querySelectorAll('meta')).map((element) => ({
     name: element.getAttribute('name'),
     property: element.getAttribute('property'),
     content: element.getAttribute('content'),
   }));
   const canonicalLink = parsedHTML.querySelector('link[rel="canonical"]');
  return (
   <>
   
   <Helmet>
     {/* Iterate over the parsed HTML to extract and render title and meta tags */}
     <title>{parsedHTML.querySelector('title').textContent}</title>
        
        {/* Render other meta tags */}
        {metaTags.map((tag, index) => (
          <meta key={index} {...(tag.name ? { name: tag.name } : {})} {...(tag.property ? { property: tag.property } : {})} content={tag.content} />
        ))}

        {/* Render canonical link if found */}
        {canonicalLink && (
          <link rel="canonical" href={canonicalLink.getAttribute('href')} />
        )}
      </Helmet>
     
        <section className="page-title style-three">
        
		<div className="pattern-layer-one" style={{backgroundImage: "url(/</section>assets/images/background/pattern-18.png)"}}></div>
		<div className="cloud-layer" style={{backgroundImage: "url(/</section>assets/images/background/pattern-19.png)"}}></div>
		<div className="big-letter">Dynopat</div>
    	<div className="auto-container">
			<div className="row clearfix">

				<div className="title-column col-lg-7 col-md-12 col-sm-12">
					<div className="inner-column">
						<h1>{cadata.catagory_name}</h1>
						<ul className="page-breadcrumb">
							<li><Link to='/'>home</Link></li>
							<li>{cadata.catagory_name}</li>
						</ul>
					</div>
				</div>

				<div className="images-column col-lg-5 col-md-12 col-sm-12">
					<div className="inner-column">
						<div className="image">
							<img  loading="lazy" src="/assets/images/sdasda.png" alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
    </section>

    
    <div className="sidebar-page-container">
    	<div className="auto-container">
        	<div className="row clearfix">

                <div className="content-side col-lg-8 col-md-12 col-sm-12">
					<div className="service-detail">
						<div className="inner-box">
                     				
          <div className="row pb-5">
         <div className="col-xxl-12">
            <div className="course__tab-conent">
               <div className="tab-content" id="courseTabContent">
                  <div className="tab-pane fade active show" id="grid" role="tabpanel" aria-labelledby="grid-tab">
                     <div className="row">
   
                        
                       
                        {data.map(item =>(

          <><div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6" key={item.id}>
          <div className="course__item white-bg mb-30 fix">
             <div className="course__thumb w-img p-relative fix">

               
             <Link to={`${item.slug}`}>
                   <img  loading="lazy" src={`https://dynopat.com/dynopat_admin/storage/app/public/CourseImage/${item.cover_image}`} alt=""/>
                </Link>
             </div>
             <div className="course__content">
                <div className="course__meta d-flex align-items-center justify-content-between">
                
                </div>
                <h3 className="course__title">

                  <Link to={`${item.slug}`}>{item.name}</Link>
                  
                  </h3>
                  <div className="course__meta d-flex align-items-center justify-content-between" style={{}}>
                {/* <h6 className="blue-2">₹{item.price}</h6> 
                 <h6 className="old-price">₹{ parseFloat(item.price) + (item.price * 0.20) }</h6>  */}
                </div>
             </div>
             <div className="course__more d-flex justify-content-between align-items-center">
                <div className="course__status d-flex align-items-center">
               
                   <Link to={`${item.slug}`}className="link-btn">
                      Know Details 
                 
                   </Link>
                </div>
                <div className="course__btn">
                <Link to={`${item.slug}`}className="link-btn">
                     
                      <i className="fa fa-arrow-right"></i>
                   </Link>
                </div>
             </div>
          </div>
       </div></>
        ))}


                     </div>
                  </div>
                 
                </div>
            </div>
         </div>
                        </div>
              <img  loading="lazy" style={{padding:'5px',background:'#0e2863'}} src={`https://dynopat.com/dynopat_admin/storage/app/public/CateImg/${cadata.cover_image}`}/>
              <div className="blocks-outer">
                    <div className="row clearfix">


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>{cadata.objective_f}</h5>
                                {/* <div className="block-text">We offer personalized, interactive, & detailed Salesforce Admin & Development courses to students</div> */}
                            </div>
                        </div>


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>{cadata.objective_s}</h5>
                                {/* <div className="block-text">Ask your doubts about Salesforce to your instructor during the session.</div> */}
                            </div>
                        </div>


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>{cadata.objective_t}</h5>
                                {/* <div className="block-text">Apply your theoretical knowledge of Salesforce in real-time and check your progress.</div> */}
                            </div>
                        </div>


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>{cadata.objective_fo}</h5>
                                {/* <div className="block-text">Regular 1-hour classes for 5 days a week, from Monday to Friday.</div> */}
                            </div>
                        </div>

                    </div>
                </div>
                <h3>Overview </h3>
                <div dangerouslySetInnerHTML={{ __html: cadata.overview }} />
                <h3>Certificate </h3>
                <div dangerouslySetInnerHTML={{ __html: cadata.certificate }} />
							
						</div>


					</div>
				 </div>
				

                <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
                	<aside className="sidebar sticky-top">
						
		
						
				<div className="sidebar-widget service-widget">
        <div className="course__video pb-5">
                              <div className="course__video-thumb w-img mb-25">
                                 <img  loading="lazy" src="/assets/images/video.png" alt="" />
                                 <div className="course__video-play">
                                    <a href="#" data-fancybox="" className="play-btn"> <i className="fa fa-play"></i> </a>
                                 </div>
                              </div>
                              <div className="course__video-meta mb-25 d-flex align-items-center justify-content-between">
                                 {/* <div className="course__video-price">
                                    <h5>$74.<span>00</span> </h5>
                                    <h5 className="old-price">$129.00</h5>
                                 </div>
                                 <div className="course__video-discount">
                                    <span>68% OFF</span>
                                 </div> */}
                              </div>
                              <div className="course__video-content mb-35">
                                 <ul>
                                    {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Instructor :</span> Eleanor Fant</h5>
                                       </div>
                                    </li> */}
                                    {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Lectures :</span>14</h5>
                                       </div>
                                    </li> */}
                                    <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       {/* <div className="course__video-info">
                                          <h5><span>Duration :</span>6 weeks</h5>
                                       </div> */}
                                    </li>
                                    {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Enrolled :</span>20 students</h5>
                                       </div>
                                    </li> */}
                                    <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Language :</span>English</h5>
                                       </div>
                                    </li>
                                 </ul>
                              </div>
                              
                              <div className="course__payment mb-35">
                              <div className='row'>
                                <div className='col-6'>
                                <h3>Price: ₹{cadata.catagory_Price}</h3>
                                </div>
                                <div className='col-6 text-end'>
                                <h5 className="old-price"> ₹{parseFloat(cadata.catagory_Price) + (cadata.catagory_Price * (20 / 100)) }</h5> 

                                </div>
                              </div>
                                 
                              </div>
                    

                                                                <button onClick={()=>{window.open(cadata.catagory_enroll)}}  class="e-btn e-btn-7 w-100">Enroll <i class="fa fa-arrow-right"></i></button>

                           </div>
                  <div className="widget-content">
                  
				  <h3 className='pb-2 text-center'>FAQs</h3>
              <div className="accordion" id="accordionExample">
  {weekData.map((week, index) => (
    <div className="accordion-item" key={index}>
      <h2 className="accordion-header" id={`week-${index}`}>
        <button
          className= {`accordion-button  ${index === 0 ? '' : 'collapsed'}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapse-${index}`}
          aria-expanded={index === 0 ? 'true' : 'false'} // Set 'aria-expanded' to 'true' for the first item
          aria-controls={`collapse-${index}`}
        >
          {week.title}
        </button>
      </h2>
      <div
        id={`collapse-${index}`}
        className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`} // Add 'show' class only to the first item
        aria-labelledby={`week-${index}`}
        data-bs-parent="#accordionExample"
      >
        <div className="accordion-body">
          <div className="course__curriculum-info">
          <div dangerouslySetInnerHTML={{ __html: week.description }} />

          </div>
        </div>
      </div>
    </div>
  ))}
</div>


                  </div>
                </div>

						{/* <div className="sidebar-widget contact-widget">
							<div className="widget-content" style={{backgroundImage: "url(/assets/images/background/pattern-25.png)"}}>
								<div className="title">
									Have Any Project <span>or work together ?</span>
								</div>
								<a href="contact.html" className="theme-btn contact-btn">Contact us</a>
								<div className="phone">
									<span className="icon flaticon-call-1"></span>
									Cal :<a href="tel:+0123-456-7899"> +0123 (456) 7899</a>
								</div>
							</div>
						</div> */}
						<Conactfrom/>
						
					
						{/* <div className="sidebar-widget broucher-widget">
							<div className="widget-content">
								<ul className="broucher-list">
									<li><a href="#"><span className="icon flaticon-pdf"></span>Download pdf</a></li>
									<li><a href="#"><span className="icon flaticon-edit"></span>Download doc.</a></li>
								</ul>
							</div>
						</div> */}
						
					</aside>
				</div>
				
			</div>
		</div>
	</div>
    </>
  )
}
}

export default Category_Slug