import React from 'react'
import { Link } from 'react-router-dom'

const Notfound = () => {
  return (
    <section class="error-section">
    <div class="auto-container">
        <div class="content">
           <img src='\assets\images\error.png'/>
              <div class="text">Sorry, this page does not exist</div>
      <Link to='/' class="theme-btn btn-style-four"><span class="txt">Go to Home Page</span></Link>
          </div>
      </div>
  </section>
  )
}

export default Notfound