import React from 'react'
import '../Home/banner.css'
import 'slick-carousel';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import FadeInText from './Fadingtext';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';

const Banner = (props) => {
const navigate= useNavigate();
    const live =()=>{
 navigate('/learn-salesforce/live-classes/')
    }
    const self =()=>{
        navigate('/learn-salesforce/self-paced-training/')
           }
       

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true, // auto-run the slider
        autoplaySpeed: 4000, // time in milliseconds between each slide
    };
    
    return (

        <div>
            <Slider {...settings}>

                <section className="main-banner">
                    {/* <span className="shape-1 animate-this">
                        <img src="/assets/imagess/shape-1.png" alt="shape" />
                    </span> */}
                    {/* <span className="shape-2 animate-this">
                        <img src="/assets/imagess/shape-2.png" alt="shape" />
                    </span> */}
                    {/* <span className="shape-3 animate-this">
                        <img src="/assets/imagess/shape-3.png" alt="shape" />
                    </span> */}
                    <span className="shape-4 animate-this">
                        <img src="/assets/imagess/shape-4.png" alt="shape" />
                    </span>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7 bgg">
                                <div className="banner-content">
                    
                                    <h2 className='h2-subtitle wow animated' data-wow-delay=".4s">
                                    <FadeInText text={`Welcome to Dynopat\nSalesforce Learning`}/></h2>

                                    <h3 className="h1-title wow fadeInUp animated" data-wow-delay=".5s"><FadeInText text={`Master Salesforce with Our LMS Learning Platform`}/></h3>
                                    <div className="banner-btn wow fadeInUp animated" data-wow-delay=".7s">
                                        <li onClick={live} className="theme-btn btn-style-one"><span className="txt">Live Classes
                                        </span></li>
                                        <li  onClick={props.toggleContactForm} className="theme-btn btn-style-one"><span className="txt">Download Brochure
                                        </span></li>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="banner-img-box wow fadeInRight animated" data-wow-delay=".4s">
                                {/* <div className="aliment-0">
                                        <div className="aliment-icon-red">
                                            <img src="/assets/images/banner/lwcc.png" alt="icon" />
                                        </div>
                                        <div className="aliment-content">
                                            <h3 className="h3-title">Lightning Web</h3>
                                            <p className="h3-title"> Components</p>
                                        </div>
                                    </div> */}
                                    {/* <div className="aliment-1">
                                        <div className="aliment-icon-red">
                                            <img src="/assets/imagess/banner-aliment-icon-1.png" alt="icon" />
                                        </div>
                                        <div className="aliment-content">
                                            <h3 className="h3-title">400 Salesforce Batch</h3>
                                            <p className="h3-title"> completed</p>
                                        </div>
                                    </div>
                                    <div className="aliment-2">
                                        <div className="aliment-icon-purple">
                                            <img src="/assets/imagess/banner-aliment-icon-2.png" alt="icon" />
                                        </div>
                                        <div className="aliment-content">
                                            <h3 className="h3-title">9.3 Million Salesforce Jobs by 2026</h3>
                                            {/* <p>Tomorrow is our</p> 
                                        </div>
                                    </div>
                                    <div className="aliment-3">
                                        <div className="aliment-icon-green">
                                            <img src="/assets/imagess/banner-aliment-icon-3.png" alt="icon" />
                                        </div>
                                        <div className="aliment-content">
                                            <h3 className="h3-title">7000 Students Are Accredited </h3>

                                        </div>
                                    </div> */}
                                    {/* <div className="aliment-5">
                                        <img src="/assets/images/logo-salesforce.svg" alt="icon" />
                                    </div>
                                    <div className="aliment-6">
                                        <img src="/assets/images/banner/admin.png" alt="icon" />
                                    </div>
                                    <div className="aliment-7">
                                        <img src="/assets/images/banner/apex.png" alt="icon" />
                                    </div>
                                    <div className="aliment-8">
                                        <img src="/assets/images/banner/Community_CLoud.png" alt="icon" />
                                    </div>
                                    <div className="aliment-9">
                                        <img src="/assets/images/banner/marketing_cloud.png" alt="icon" />
                                    </div>
                                    <div className="aliment-10">
                                        <img src="/assets/images/banner/sales_cloud.png" alt="icon" />
                                    </div>
                                    <div className="aliment-11">
                                        <img src="/assets/images/banner/sales_cloud.png" alt="icon" />
                                    </div> */}
                                    <div className="banner-img">
                                        <img   className="banner-picture" 
                                        src="/assets/images/banner/banner1.webp" 
                                        alt="banner" 
                                         loading="lazy"
                                          />
                                    </div>
                                    
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                
                <section className="main-banner">

                    {/* <span className="shape-1 animate-this">
                        <img src="/assets/imagess/aaaaaaaaa.png" alt="shape" />
                    </span> */}
                    {/* <span className="shape-2 animate-this">
                        <img src="/assets/imagess/shape-2.png" alt="shape" />
                    </span> */}
                    {/* <span className="shape-3 animate-this">
                        <img src="/assets/imagess/shape-3.png" alt="shape" />
                    </span> */}
                    <span className="shape-4 animate-this">
                        <img src="/assets/imagess/shape-4.png" alt="shape" />
                    </span>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 bgg">
                                <div className="banner-content">
                                <h2 className='h2-subtitle wow animated' data-wow-delay=".4s">
                                    <FadeInText text={`Welcome to Dynopat\nSalesforce Learning`}/></h2>
                                    <h3 className="h1-title wow fadeInUp animated" data-wow-delay=".5s"><FadeInText text={`Become A Top Salesforce Developer With\nOur Live Classes`}/></h3>
                                    <div className="banner-btn wow fadeInUp animated" data-wow-delay=".7s">
                                        <li onClick={self} className="theme-btn btn-style-one"><span className="txt">Self Learning
                                        </span></li>
                                        <li onClick={props.toggleContactForm} className="theme-btn btn-style-one"><span className="txt">Download Brochure
                                        </span></li>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                            <div className="banner-img-box wow fadeInRight animated" data-wow-delay=".4s">
                                {/* <div className="aliment-0">
                                        <div className="aliment-icon-red">
                                            <img src="/assets/images/banner/lwcc.png" alt="icon" />
                                        </div>
                                        <div className="aliment-content">
                                            <h3 className="h3-title">Lightning Web</h3>
                                            <p className="h3-title"> Components</p>
                                        </div>
                                    </div> */}
                                    {/* <div className="aliment-1">
                                        <div className="aliment-icon-red">
                                            <img src="/assets/imagess/banner-aliment-icon-1.png" alt="icon" />
                                        </div>
                                        <div className="aliment-content">
                                            <h3 className="h3-title">400 Salesforce Batch</h3>
                                            <p className="h3-title"> completed</p>
                                        </div>
                                    </div> */}
                                    {/* <div className="aliment-2">
                                        <div className="aliment-icon-purple">
                                            <img src="/assets/imagess/banner-aliment-icon-2.png" alt="icon" />
                                        </div>
                                        <div className="aliment-content">
                                            <h3 className="h3-title">9.3 Million Salesforce Jobs by 2026</h3>
                                            {/* <p>Tomorrow is our</p> 
                                        </div>
                                    </div> 
                                    <div className="aliment-3">
                                        <div className="aliment-icon-green">
                                            <img src="/assets/imagess/banner-aliment-icon-3.png" alt="icon" />
                                        </div>
                                        <div className="aliment-content">
                                            <h3 className="h3-title">7000 Students Are Accredited </h3>

                                        </div>
                                    </div> */}
                                    {/* <div className="aliment-5">
                                        <img src="/assets/images/logo-salesforce.svg" alt="icon" />
                                    </div>
                                    <div className="aliment-6">
                                        <img src="/assets/images/banner/admin.png" alt="icon" />
                                    </div>
                                    <div className="aliment-7">
                                        <img src="/assets/images/banner/apex.png" alt="icon" />
                                    </div>
                                    <div className="aliment-8">
                                        <img src="/assets/images/banner/Community_CLoud.png" alt="icon" />
                                    </div>
                                    <div className="aliment-9">
                                        <img src="/assets/images/banner/marketing_cloud.png" alt="icon" />
                                    </div>
                                    <div className="aliment-10">
                                        <img src="/assets/images/banner/sales_cloud.png" alt="icon" />
                                    </div>
                                    <div className="aliment-11">
                                        <img src="/assets/images/banner/sales_cloud.png" alt="icon" />
                                    </div> */}
                                    <div className="banner-img">
                                        <img src="/assets/images/banner/banner2.webp"
                                         alt="banner" loading="lazy" 
                                          />
                                    </div>
                                    
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

            </Slider>
        </div>


    )
}

export default Banner