import React from 'react'
import Menu from './Menu'

const Navmenu = () => {
  return (
    <nav className="main-menu navbar-expand-md ">
    <div className="navbar-header">

        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
        </button>
    </div>
    
    <div className="navbar-collapse collapse clearfix" id="navbarSupportedContent">
       <Menu/>
    </div>
    
</nav>
  )
}

export default Navmenu