import React from 'react'
import '../../Style/style.css'
import { Link } from 'react-router-dom'
import Contactfrom from '../../compunent/Conactfrom'
import Enroll from '../../compunent/Enroll'
import { Helmet } from 'react-helmet-async'
import Claimyourfreecourse from '../../compunent/Claimyourfreecourse'
function Salesforce_Complete_Course() {
  return (
   <>
   <Helmet>
   <title>Salesforce Consulting Salesforce Admin and Development Training</title>
     <meta name="description" content="We offer a range of Salesforce courses to our students, including Salesforce consulting, Salesforce admin, & Salesforce development certification training." />
     <meta name="keywords" content="Salesforce Consulting, Salesforce Admin Training, Development Training" />
    {/* Open Graph / Facebook */}
  <meta property="og:type" content="website" />
  <meta
    property="og:url"
    content="https://dynopat.com/learn-salesforce/live-classes/salesforce-full-course/"
  />
  <meta
    property="og:title"
    content="Salesforce consulting salesforce admin and development training"
  />
  <meta
    property="og:description"
    content="We offer a range of Salesforce courses to our students, including Salesforce consulting, Salesforce admin, & Salesforce development certification training."
  />
  <meta
    property="og:image"
    content="https://dynopat.com/assets/images/lernselsforcecourse/fullcourse.webp"
  />
  {/* Twitter */}
  <meta property="twitter:card" content="summary_large_image" />
  <meta
    property="twitter:url"
    content="https://dynopat.com/learn-salesforce/live-classes/salesforce-full-course/"
  />
  <meta
    property="twitter:title"
    content="Salesforce consulting salesforce admin and development training"
  />
  <meta
    property="twitter:description"
    content="We offer a range of Salesforce courses to our students, including Salesforce consulting, Salesforce admin, & Salesforce development certification training."
  />
  <meta
    property="twitter:image"
    content="https://dynopat.com/assets/images/lernselsforcecourse/fullcourse.webp"
  />
        <link rel="canonical" href="https://dynopat.com/learn-salesforce/live-classes/salesforce-full-course/" />
      </Helmet>


    <section className="page__title-area pt-120 pb-90">
      
            <div className="page__title-shape">
               <img  loading="lazy" className="page-title-shape-5 d-none d-sm-block" src="/assets/img/page-title/page-title-shape-1.png" alt="" />
               <img  loading="lazy" className="page-title-shape-6" src="/assets/img/page-title/page-title-shape-6.png" alt="" />
               <img  loading="lazy" className="page-title-shape-7" src="/assets/img/page-title/page-title-shape-4.png" alt="" />
            </div>
            <div className="container">
               <div className="row">
                  <div className="col-xxl-8 col-xl-8 col-lg-8">
                     <div className="course__wrapper">
                        <div className="page__title-content mb-25">
                           <div className="page__title-breadcrumb">
                              <nav aria-label="breadcrumb">
                                 <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Salesforce Complete Course </li>
                                 </ol>
                              </nav>
                           </div>
                           <span className="page__title-pre">Salesforce Complete Course </span>
                           <h5 className="page__title-3">Salesforce Complete Course</h5>
                        </div>
                        {/* <div className="course__meta-2 d-sm-flex mb-30">
                           <div className="course__teacher-3 d-flex align-items-center mr-70 mb-30">
                              <div className="course__teacher-thumb-3 mr-15">
                                 <img  loading="lazy" src="/assets/img/course/teacher/teacher-1.jpg" alt="" />
                              </div>
                              <div className="course__teacher-info-3">
                                 <h5>Teacher</h5>
                                 <p><a href="#">Elon Gated</a></p>
                              </div>
                           </div>
                           <div className="course__update mr-80 mb-30">
                              <h5>Last Update:</h5>
                              <p>July 24, 2022</p>
                           </div>
                           <div className="course__rating-2 mb-30">
                              <h5>certification:</h5>
                              <div className="course__rating-inner d-flex align-items-center">
                                 <ul>
                                    <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                    <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                    <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                    <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                    <li><a href="#"> <i className="fa fa-star"></i> </a></li>
                                 </ul>
                                 <p>4.5</p>
                              </div>
                           </div>
                        </div> */}
                        <div className="course__img w-img mb-30">
                           <img  loading="lazy" src="/assets/images/lernselsforcecourse/fullcourse.webp" alt="" />
                        </div>
                        <div className="course__tab-2 mb-45 pb-3">
                           <ul className="nav nav-tabs" id="courseTab" role="tablist">
                              <li className="nav-item" role="presentation">
                                 <button className="nav-link active" id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview" type="button" role="tab" aria-controls="overview" aria-selected="true"> <i className="fa fa-bookmark"></i> <span>Overview</span> </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                 <button className="nav-link " id="curriculum-tab" data-bs-toggle="tab" data-bs-target="#curriculum" type="button" role="tab" aria-controls="curriculum" aria-selected="false"> <i className="fa fa-book"></i> <span>Curriculum</span> </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                 <button className="nav-link" id="certification-tab" data-bs-toggle="tab" data-bs-target="#certification" type="button" role="tab" aria-controls="certification" aria-selected="false"> <i className="fa fa-star"></i> <span>Certification</span> </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                 <button className="nav-link" id="faq-tab" data-bs-toggle="tab" data-bs-target="#faq" type="button" role="tab" aria-controls="faq" aria-selected="false"> <i className="fa fa-users"></i> <span>FAQs</span> </button>
                              </li>
                           </ul>
                        </div>
                        <div className="course__tab-content mb-95">
                           <div className="tab-content" id="courseTabContent">
                              <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                                 <div className="course__overview">
                                    <h3>Overview</h3>
                                    <p>Our Salesforce full course bundle contains the Salesforce Administrator course, Salesforce Business Analyst course, Salesforce Platform developer course, and the LWC course. This is a comprehensive curriculum that will help you become a pro in Salesforce. You will learn how to manage data in Salesforce, automate workflows, and create advanced applications in the platform. Using this knowledge, you can create real-world applications, thus helping you gain practical skills and expertise. Our expert instructor Rajesh Chatterjee will guide you through the entire process and will ensure you enjoy your learning experience. After completing our training, you can apply for industry-recognized Salesforce certifications. </p>



                                    <div className='service-detail'>
            <div className='inner-box'>
                <div className="blocks-outer">
                    <div className="row clearfix">


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>1:1 mentoring sessions</h5>
                                {/* <div className="block-text">We offer personalized, interactive, & detailed Salesforce Admin & Development courses to students</div> */}
                            </div>
                        </div>


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>Dedicated support manager </h5>
                                {/* <div className="block-text">Ask your doubts about Salesforce to your instructor during the session.</div> */}
                            </div>
                        </div>


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>Real-time projects</h5>
                                {/* <div className="block-text">Apply your theoretical knowledge of Salesforce in real-time and check your progress.</div> */}
                            </div>
                        </div>


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>Lifetime Access  </h5>
                                {/* <div className="block-text">Regular 1-hour classes for 5 days a week, from Monday to Friday.</div> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>


                                 </div>
                              </div>
                              <div className="tab-pane fade" id="curriculum" role="tabpanel" aria-labelledby="curriculum-tab">
                                 <div className="course__curriculum">
                                    <div className="accordion" id="course__accordion">
                                       <div className="accordion-item mb-2">
                                          <h2 className="accordion-header" id="week-01">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-01-content" aria-expanded="true" aria-controls="week-01-content">
                                             Salesforce Administrator Certification Training 
 
                                             </button>
                                          </h2>
                                          <div id="week-01-content" className="accordion-collapse collapse show " aria-labelledby="week-01" data-bs-parent="#course__accordion">
                                             <div className="accordion-body">
                                                <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                   <div className="course__curriculum-info">
                                                      <ul>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Introduction to Salesforce </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>User profiles and how to create them </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Role hierarchy  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Sharing rules </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Record-level security </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Data model</span> </h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Data import wizards</span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Importing and exporting data </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Data validation and data quality </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Creating custom page layouts </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Configuring validation rules</span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Workflow rules  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to create and manage approval processes  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Process builders  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Workflow automation  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to configure email alerts </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to automate repetitive tasks using scheduled actions  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to create reports and customized reports?  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Data visualization </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Filter and drill-down </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Report folders and sharing settings </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to configure and manage Salesforce email templates </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Email-to-case functionality </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Web-to-lead functionality </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Integrating it with external tools</span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Introduction </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to install third-party apps?  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Project  </span></h3></li>

                                                      </ul>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="accordion" id="course__accordion-2">
                                       <div className="accordion-item mb-50">
                                          <h2 className="accordion-header" id="week-02">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-02-content" aria-expanded="true" aria-controls="week-02-content">
                                             Salesforce Development Certification Training 
                                             </button>
                                          </h2>
                                          <div id="week-02-content" className="accordion-collapse  collapse " aria-labelledby="week-02" data-bs-parent="#course__accordion-2">
                                             <div className="accordion-body">
                                                <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                <div className="course__curriculum-info">
                                                      <ul>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Platform development overview   </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Salesforce data management (fields, relationships, and picklists)</span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Data loader (importing and exporting using data loaders) </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Salesforce IDEs</span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Version control systems </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Introduction to Apex programming language </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Variables, data types, and operators</span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Control flow</span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Triggers </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Creating complex codes in Apex </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Best practices </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Bulkification  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Introduction of LWC (Lightning Web Components)</span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to use Javascript in LWC?</span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Lightning Data Service  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Salesforce APIs (REST, SOAP, etc.) </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Callouts </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>External services </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Custom integrations </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Unit tests for testing </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Setting up test data and test execution </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Salesforce DX (Developer Experience) overview</span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>CI/ CD (Continuous Integration/ Continuous Development)</span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Governor limits </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Security model & access control  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Record-level security   </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Lightning App Builder </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Triggers    </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to configure Lightning Experience  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Lightning Design System (SLDS) </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Exception handling  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Design for scalability  </span></h3></li>



                                                      </ul>
                                                      </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="accordion" id="course__accordion-3">
                                       <div className="accordion-item mb-50">
                                          <h2 className="accordion-header" id="week-03">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#week-03-content" aria-expanded="true" aria-controls="week-03-content">
                                             Salesforce LWC Certification Training 
                                             </button>
                                          </h2>
                                          <div id="week-03-content" className="accordion-collapse  collapse " aria-labelledby="week-03" data-bs-parent="#course__accordion-3">
                                             <div className="accordion-body">
                                                <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                <div className="course__curriculum-info">
                                                      <ul>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>The foundation of Lightning Web Components </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>LWC file structure </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>LWC components, starting from Lightning Base Components  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to create LWC components? </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>What is the difference between LWC JavaScript & HTML templates?   </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Data binding and property decorators  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Child and parent components </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to create communication between components using properties and events </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to leverage cross-component communication?  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Conditional rendering and iteration  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Expression and directives  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Styling components  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Applying SLDS (Salesforce Lightning Design System) </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Fetching data using Apex </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>JavaScript promises and asynchronous operations</span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Handling errors and toast messages  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Data caching </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Controller and helper functions </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>DOM </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Fetch API and how to use it to make API calls  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Salesforce wire service </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Overview of Aura </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to transition from Aura to LWC?  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to pass data between LWC and Aura components?</span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Best practices to follow while making the transition </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Lightning Testing Service for component testing   </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to debug using browser developer tools? </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to optimize performance using best practices for LWC?</span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to package LWC components?</span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>How to deploy LWC components to Salesforce org? </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Version upgrades </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Salesforce Lightning App Builder  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>SLDS   </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Advanced component patterns  </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Access and manipulate DOM operations using imperative APIs in LWC </span></h3></li>
                                                         <li style={{listStyleType:'circle', marginLeft:'30px'}}><h3><span>Performance optimization    </span></h3></li>


                                                      </ul>
                                                      </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 
                                    
                                 </div>
                              </div>
                              <div className="tab-pane fade" id="certification" role="tabpanel" aria-labelledby="certification-tab">
                                 <div className="course__certification">
                                    <h3>Certification</h3>
                                    <p>When you complete this course, you will receive a completion certificate from Dynopat. You can decide which profile is the best for you from Administrator, Developer, and LWC expert. Our projects and assignments will play a crucial role in evaluating your knowledge and skills, and help you be ready for the real world. </p>
                                    <img  loading="lazy" className='certificat' src='/assets/images/Certificate-Design.jpg'></img>
                                 </div>
                              </div>
                              <div className="tab-pane fade" id="faq" role="tabpanel" aria-labelledby="faq-tab">
                                 <div className="course__faq mb-45">



                                    <div className="accordion" id="accordionExample">
                                       <div className="accordion-item">
                                          <h2 className="accordion-header" id="headingOne">
                                             <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                             What will I get in this complete course on Salesforce? 

                                             </button>
                                          </h2>
                                          <div id="collapseOne" className="accordion-collapse collapse show " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                             <div className="accordion-body">
                                             <div className="course__curriculum-info">
                                                <strong>We are providing a complete course on Salesforce CRM, which includes Salesforce Administration, Salesforce Development, and Salesforce LWC certification training. This is the course for people who are enthusiastic about Salesforce. You will get the training through live classes that come with 1:1 mentoring from Rajesh Chatterjee (https://www.youtube.com/@Delipat-Rajesh), and dedicated Q&A sessions. Apart from that, you will have unlimited access to the LMS platform. You will also work on projects and get resume-building services and mock interviews. However, we do not offer a job placement guarantee to our students. </strong>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="accordion-item">
                                          <h2 className="accordion-header" id="headingTwo">
                                             <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                             What type of projects will I work on during this course? 

                                             </button>
                                          </h2>
                                          <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                             <div className="accordion-body">
                                                <strong>At Dynopat, we take the utmost care to offer the latest and most relevant course content for our students. That’s why we ensure that you get to work on real-time projects, which include CRM applications for different industries, such as healthcare, finance and banking, and education. 
 </strong>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="accordion-item">
                                          <h2 className="accordion-header" id="headingThree">
                                             <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                             Can I get technical support for your team? 




                                             </button>
                                          </h2>
                                          <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                             <div className="accordion-body">
                                                <strong>Yes, at Dynopat, we are here to take care of our students. If you get stuck anywhere, we are here to help you out. Email us at info@dynopat.com. </strong>
                                               
                                             </div>
                                          </div>
                                       </div>
                                       <div className="accordion-item">
                                          <h2 className="accordion-header" id="headingFour">
                                             <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                             Can I request a Q&A session if I need it? 


                                             </button>
                                          </h2>
                                          <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                             <div className="accordion-body">
                                       <strong>Yes, you will get dedicated QA sessions in every class. But if you need more assistance, you can either ask during the session or email us at the given email id.   
</strong>
                                             </div>
                                          </div>
                                       </div>
                                    </div>





                                 </div>
                              </div>

                           </div>
                        </div>
                     </div>
                  </div>
                 <div className="col-xxl-4 col-xl-4 col-lg-4">
                     <div className="course__sidebar pl-70 p-relative">
                        <div className="course__shape">
                           <img  loading="lazy" className="course-dot" src="/assets/img/course/course-dot.png" alt="" />
                        </div>
                        <div className="course__sidebar-widget-2 white-bg mb-20">
                           <div className="course__video">
                              <div className="course__video-thumb w-img mb-25">
                                 <img  loading="lazy" src="/assets/images/video.png" alt="" />
                                 <div className="course__video-play">
                                    <a href="https://youtu.be/yJg-Y5byMMw" data-fancybox="" className="play-btn"> <i className="fa fa-play"></i> </a>
                                 </div>
                              </div>
                              <div className="course__video-meta mb-25 d-flex align-items-center justify-content-between">
                                 {/* <div className="course__video-price">
                                    <h5>$74.<span>00</span> </h5>
                                    <h5 className="old-price">$129.00</h5>
                                 </div>
                                 <div className="course__video-discount">
                                    <span>68% OFF</span>
                                 </div> */}
                              </div>
                              <div className="course__video-content mb-35">
                                 <ul>
                                    {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Instructor :</span> Eleanor Fant</h5>
                                       </div>
                                    </li> */}
                                    {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Lectures :</span>14</h5>
                                       </div>
                                    </li> */}
                                    <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Duration :</span>3 weeks</h5>
                                       </div>
                                    </li>
                                    {/* <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Enrolled :</span>20 students</h5>
                                       </div>
                                    </li> */}
                                    <li className="d-flex align-items-center">
                                       <div className="course__video-icon">

                                       </div>
                                       <div className="course__video-info">
                                          <h5><span>Language :</span>English</h5>
                                       </div>
                                    </li>
                                 </ul>
                              </div>
                              {/* <div className="course__payment mb-35">
                                 <h3>Payment:</h3>
                                 <a href="#">
                                    <img  loading="lazy" style={{width: '222px'}} src="/assets/images/stripe.png" alt="" />
                                 </a>
                              </div> */}
                    <Claimyourfreecourse />

                                                  <Enroll course='Salesforce Complete Course'/>

                           </div>
                        </div>
                       
                        <Contactfrom/>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         </>
  )
}

export default Salesforce_Complete_Course