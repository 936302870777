import React, { useEffect, useState } from 'react'
import '../Style/style.css'
import { Link } from 'react-router-dom'
import Conactfrom from '../compunent/Conactfrom'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'


function Self_paced_training() {
   const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://dynopat.com/dynopat_admin/api/category/cat');
      
      setData(response.data.data);
      console.log(response.data)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
   <>
   
   <Helmet>
			<title>Salesforce Quick Training | Salesforce Training Free Online</title>
        	<meta name="description" content="Dynopat brings multiple choices for Salesforce learners, you can enroll in either our Salesforce quick training program or our Salesforce training free online." />
        	<meta name="keywords" content="Salesforce Training Free Online"/>
           <meta property="og:type" content="website" />
  <meta
    property="og:url"
    content="https://dynopat.com/learn-salesforce/self-paced-training/"
  />
  <meta
    property="og:title"
    content="Salesforce Quick Training | Salesforce Training Free Online"
  />
  <meta
    property="og:description"
    content="Dynopat brings multiple choices for Salesforce learners, you can enroll in either our Salesforce quick training program or our Salesforce training free online."
  />
  <meta
    property="og:image"
    content="https://dynopat.com/assets/images/lernselsforcecourse/self/getting-salesforce-certified-1.webp"
  />
  {/* Twitter */}
  <meta property="twitter:card" content="summary_large_image" />
  <meta
    property="twitter:url"
    content="https://dynopat.com/learn-salesforce/self-paced-training/"
  />
  <meta
    property="twitter:title"
    content="Salesforce Quick Training | Salesforce Training Free Online"
  />
  <meta
    property="twitter:description"
    content="Dynopat brings multiple choices for Salesforce learners, you can enroll in either our Salesforce quick training program or our Salesforce training free online."
  />
  <meta
    property="twitter:image"
    content="https://dynopat.com/assets/images/lernselsforcecourse/self/getting-salesforce-certified-1.webp"
  />
       		 {/* Other OG tags */}
              <link rel="canonical" href="https://dynopat.com/learn-salesforce/self-paced-training/" />
      		</Helmet>
     
        <section className="page-title style-three">
        
		<div className="pattern-layer-one" style={{backgroundImage: "url(/</section>assets/images/background/pattern-18.png)"}}></div>
		<div className="cloud-layer" style={{backgroundImage: "url(/</section>assets/images/background/pattern-19.png)"}}></div>
		<div className="big-letter">Dynopat</div>
    	<div className="auto-container">
			<div className="row clearfix">

				<div className="title-column col-lg-7 col-md-12 col-sm-12">
					<div className="inner-column">
						<h1>Self-paced Salesforce Learnings</h1>
						<ul className="page-breadcrumb">
							<li><Link to='/'>home</Link></li>
							<li>Self-paced Salesforce Learning</li>
						</ul>
					</div>
				</div>

				<div className="images-column col-lg-5 col-md-12 col-sm-12">
					<div className="inner-column">
						<div className="image">
							<img  loading="lazy" src="/assets/images/lernselsforcecourse/self/self.webp" alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
    </section>

    
    <div className="sidebar-page-container">
    	<div className="auto-container">
        	<div className="row clearfix">

                <div className="content-side col-lg-8 col-md-12 col-sm-12">
					<div className="service-detail">
						<div className="inner-box">
                     				
          <div className="row pb-5">
         <div className="col-xxl-12">
            <div className="course__tab-conent">
               <div className="tab-content" id="courseTabContent">
                  <div className="tab-pane fade active show" id="grid" role="tabpanel" aria-labelledby="grid-tab">
                     <div className="row">
   
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                           <div className="course__item white-bg mb-30 fix">
                              <div className="course__thumb w-img p-relative fix">
                              <Link to='/learn-salesforce/self-paced-training/administrator-certification/'>
                                    <img  loading="lazy" src="/assets/images/lernselsforcecourse/self/administrate.webp" alt=""/>
                                 </Link>
                                
                              </div>
                              <div className="course__content">
                                 <div className="course__meta d-flex align-items-center justify-content-between">
                                   
                                 </div>
                                 <h3 className="course__title pb-3"><Link to='/learn-salesforce/self-paced-training/administrator-certification/'>Salesforce Administrator Certification</Link></h3>
                                
                              </div>
                              <div className="course__more d-flex justify-content-between align-items-center">
                                 <div className="course__status d-flex align-items-center">
                                    {/* <span className="orange">$62.00</span> */}
                                    {/* <span className="old-price">$97.00</span> */}
                                    <Link to='/learn-salesforce/self-paced-training/administrator-certification/' className="link-btn">
                                       Know Details 
                              
                                    </Link>
                                 </div>
                                 <div className="course__btn">
                                 <Link to='/learn-salesforce/self-paced-training/administrator-certification/' className="link-btn">
                                     
                                       <i className="fa fa-arrow-right"></i>
                                    </Link>
                                 </div>
                              </div>
                           </div>
                        </div>
                        
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                           <div className="course__item white-bg mb-30 fix">
                              <div className="course__thumb w-img p-relative fix">
                              <Link to='/learn-salesforce/self-paced-training/administrator-and-pd1/'>
                                    <img  loading="lazy" src="/assets/images/lernselsforcecourse/self/platfrom1.webp" alt=""/>
                                 </Link>
                                
                              </div>
                              <div className="course__content">
                                 <div className="course__meta d-flex align-items-center justify-content-between">
                                   
                                 </div>
                                 <h3 className="course__title"><Link to='/learn-salesforce/self-paced-training/administrator-and-pd1/'>Salesforce Training for Administrator & Platform Developer 1 Certification</Link></h3>
                                
                              </div>
                              <div className="course__more d-flex justify-content-between align-items-center">
                                 <div className="course__status d-flex align-items-center">
                                    {/* <span className="blue-2">$46.00</span> */}
                                    {/* <span className="old-price">$72.00</span> */}
                                    <Link to='/learn-salesforce/self-paced-training/administrator-and-pd1/' className="link-btn">
                                       Know Details 
                                    </Link>
                                 </div>
                                 <div className="course__btn">
                                 <Link to='/learn-salesforce/self-paced-training/administrator-and-pd1/' className="link-btn">
                                      
                                       <i className="fa fa-arrow-right"></i>
                                    </Link>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                           <div className="course__item white-bg mb-30 fix">
                              <div className="course__thumb w-img p-relative fix">
                              <Link to='/learn-salesforce/self-paced-training/administrator-and-pd2/'>
                                    <img  loading="lazy" src="/assets/images/lernselsforcecourse/self/platfrom2.webp" alt=""/>
                                 </Link>
                              </div>
                              <div className="course__content">
                                 <div className="course__meta d-flex align-items-center justify-content-between">
                                   
                                 </div>
                                 <h3 className="course__title"><Link to='/learn-salesforce/self-paced-training/administrator-and-pd2/'>Salesforce Training for Administrator & Platform Developer 2 Certification</Link></h3>
                                
                              </div>
                              <div className="course__more d-flex justify-content-between align-items-center">
                                 <div className="course__status d-flex align-items-center">
                                    {/* <span className="blue-2">$46.00</span> */}
                                    {/* <span className="old-price">$72.00</span> */}
                                    <Link to='/learn-salesforce/self-paced-training/administrator-and-pd2/'className="link-btn">
                                       Know Details 
                                  
                                    </Link>
                                 </div>
                                 <div className="course__btn">
                                 <Link to='/learn-salesforce/self-paced-training/administrator-and-pd2/'className="link-btn">
                                      
                                       <i className="fa fa-arrow-right"></i>
                                    </Link>
                                 </div>
                              </div>
                           </div>
                        </div>
                       
                        {data.map(item => item.category_type === 0 &&(

          <><div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6" key={item.catagory_slug}>
          <div className="course__item white-bg mb-30 fix">
             <div className="course__thumb w-img p-relative fix">
               
             <Link  to={`/learn-salesforce/self-paced-training/${item.catagory_slug}`}>
                   <img  loading="lazy" src={`https://dynopat.com/dynopat_admin/storage/app/public/CateImg/${item.cover_image}`} alt=""/>
                </Link>
             </div>
             <div className="course__content">
                <div className="course__meta d-flex align-items-center justify-content-between">
                  
                </div>
                <h3 className="course__title"><Link to={`/learn-salesforce/self-paced-training/${item.catagory_slug}`}>{item.catagory_name}</Link></h3>
                <div className="course__meta d-flex align-items-center justify-content-between">
          <h6 className="blue-2">₹{item.catagory_Price}</h6> 

        <h6 className="old-price">₹{parseFloat(item.catagory_Price) + (item.catagory_Price * 0.20) }</h6> 
        </div>
             </div>
             <div className="course__more d-flex justify-content-between align-items-center">
                <div className="course__status d-flex align-items-center">
                   {/* <span className="blue-2">$46.00</span> */}
                   {/* <span className="old-price">$72.00</span> */}
                   <Link to={`/learn-salesforce/self-paced-training/${item.catagory_slug}`}className="link-btn">
                      Know Details 
                 
                   </Link>
                </div>
                <div className="course__btn">
                <Link to={`/learn-salesforce/self-paced-training/${item.catagory_slug}`}className="link-btn">
                     
                      <i className="fa fa-arrow-right"></i>
                   </Link>
                </div>
             </div>
          </div>
       </div></>
        ))}


                     </div>
                  </div>
                 
                </div>
            </div>
         </div>
                        </div>
              <img  loading="lazy" style={{padding:'5px',background:'#0e2863'}} src='/assets/images/lernselsforcecourse/self/getting-salesforce-certified-1.webp'/>
							<h3>Overview </h3>
							<p>Our Self-paced, LMS-based Salesforce Certification Training will help you become proficient in Salesforce at your own pace. If you do not have the time to enroll in a live class, you can still master the platform from pre-recorded lessons available in our LMS platform. You will gain all the skills and knowledge to pursue a career in this domain, as a Salesforce Administrator, Salesforce Platform Developer, or App Builder. We are providing three types of self-paced Salesforce training programs, Self-paced Salesforce Administrator Certification, Self-paced Salesforce Training for Administrator & Platform Developer 1 Certification, and Self-paced Salesforce Training for Administrator & Platform Developer 2 Certification. </p>
 <div className="blocks-outer">
                    <div className="row clearfix">


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>Pre-recorded lessons</h5>
                                {/* <div className="block-text">We offer personalized, interactive, & detailed Salesforce Admin & Development courses to students</div> */}
                            </div>
                        </div>


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>Flexible scheduling</h5>
                                {/* <div className="block-text">Ask your doubts about Salesforce to your instructor during the session.</div> */}
                            </div>
                        </div>


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>Certification</h5>
                                {/* <div className="block-text">Apply your theoretical knowledge of Salesforce in real-time and check your progress.</div> */}
                            </div>
                        </div>


                        <div className="block-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                                <h5>1 Hour Duration</h5>
                                {/* <div className="block-text">Regular 1-hour classes for 5 days a week, from Monday to Friday.</div> */}
                            </div>
                        </div>

                    </div>
                </div>
							<h4>Curriculum </h4>
							<p>
                     The curriculum for these three separate courses, and covers the platform, from basics to advanced. In this curriculum, we will cover the basics of Salesforce, and how it can be used to create custom applications using this platform. Initially, we will introduce you to Salesforce, its editions, project life cycle, workflows, fields, field creation processes, validation rules, login access, new users, sharing defaults, and how to debug, validate data and maintain platform security. You will also learn how to create new users and talk about active/inactive freezing. The curriculum will be beneficial for those who are aspiring to have a career in Salesforce in the fields of Salesforce developers, architects, analysts, and administrators.
                </p>
                <h4>Certification </h4>
							<p>

                     When you complete this training and do all the tasks, you will unlock this certification. Later on, you can register for the Salesforce Admin certification. After passing that exam, you can work at any company anywhere in the world. You can also decide to opt for Salesforce Platform Developer 1, Salesforce App Builder Certification exam, and Platform Developer 2 exams.

 
                </p>
							
						</div>


					</div>
				 </div>
				

                <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
                	<aside className="sidebar sticky-top">
						
		
						
				<div className="sidebar-widget service-widget">
					
                  <div className="widget-content">
				  <h3 className='pb-2 text-center'>FAQs</h3>
              <div className="accordion" id="accordionExample">
                               <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingOne">
                                     <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                     What are the job prospects of learning Salesforce? 
                                     </button>
                                  </h2>
                                  <div id="collapseOne" className="accordion-collapse collapse show " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                     <div className="accordion-body">
                                     <div className="course__curriculum-info">
                                        <strong>After completing the Salesforce certification, you can apply for these positions: </strong>
                                        <ul>

                                           <li style={{ listStyleType: 'circle', marginLeft: '30px' }}>Salesforce Admin</li>
                                           <li style={{ listStyleType: 'circle', marginLeft: '30px' }}>Salesforce Executive </li>
                                           <li style={{ listStyleType: 'circle', marginLeft: '30px' }}>Salesforce Consultant</li>
                                           <li style={{ listStyleType: 'circle', marginLeft: '30px' }}>Salesforce Developer</li>
                                        </ul>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                               <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingTwo">
                                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                     What are the different types of training available at Dynopat? 
                                     </button>
                                  </h2>
                                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                     <div className="accordion-body">
                                        <strong>We provide two main types of Salesforce training, self-paced learning (LMS), and instructor-led learning (live classes). You can ascertain the quality of our training by going through our lecture videos. Our LMS lessons are prepared for those students who cannot attend a live session for their busy schedules. Our LMS platform is equipped with hundreds of videos, supporting documents, and a quiz section to ascertain your progress. Currently, we have the Salesforce Admin Certification Training program, Salesforce Platform Developer 1 Training, and Salesforce Platform Developer 2 Training </strong>
                                     </div>
                                  </div>
                               </div>
                               <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingThree">
                                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                     Can I opt for an instructor-led class instead of self-learning? 

                                     </button>
                                  </h2>
                                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                     <div className="accordion-body">
                                        <strong>Yes, you can opt for live classes by paying a small amount. You join live classes from the next batch. You can either enroll for our live sessions through the courses listed on the website or by asking for assistance from our customer support team.</strong>
                                     </div>
                                  </div>
                               </div>
                               <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingFour">
                                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                     How can I get certified? 

                                     </button>
                                  </h2>
                                  <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                     <div className="accordion-body">
                                        <strong>You can get the certificate by attending the lessons, and by taking the quizzes (present at the end of each recording. You can also follow the document attached to these sessions. The goal of these quizzes is to assess your level of understanding and help you identify your weak points.  </strong>

                                     
                                     </div>
                                  </div>
                               </div>
                               <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingFive">
                                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                     Can I ask for additional classes?  

                                     </button>
                                  </h2>
                                  <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                     <div className="accordion-body">
                                        <strong>
If you feel that you need additional classes, you can enroll in our live classes. Aside from that, you can also ask our support team about it, and we will help you at the earliest. 
</strong>
                                     </div>
                                  </div>
                               </div>
                               <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingsix">
                                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                                     What are the prerequisites for this course?  

                                     </button>
                                  </h2>
                                  <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#accordionExample">
                                     <div className="accordion-body">
                                        <strong>You don’t need any prior experience or training before enrolling in this course. However, if you have an experience with OOPs or Java, you may grasp some of the topics quickly. </strong>
                                     </div>
                                  </div>
                               </div>
                            </div>


                  </div>
                </div>

						{/* <div className="sidebar-widget contact-widget">
							<div className="widget-content" style={{backgroundImage: "url(/assets/images/background/pattern-25.png)"}}>
								<div className="title">
									Have Any Project <span>or work together ?</span>
								</div>
								<a href="contact.html" className="theme-btn contact-btn">Contact us</a>
								<div className="phone">
									<span className="icon flaticon-call-1"></span>
									Cal :<a href="tel:+0123-456-7899"> +0123 (456) 7899</a>
								</div>
							</div>
						</div> */}
						<Conactfrom/>
						
					
						{/* <div className="sidebar-widget broucher-widget">
							<div className="widget-content">
								<ul className="broucher-list">
									<li><a href="#"><span className="icon flaticon-pdf"></span>Download pdf</a></li>
									<li><a href="#"><span className="icon flaticon-edit"></span>Download doc.</a></li>
								</ul>
							</div>
						</div> */}
						
					</aside>
				</div>
				
			</div>
		</div>
	</div>
    </>
  )
}

export default Self_paced_training