import React from 'react'
import Slider from 'react-slick';


const Bandlogo = () => {
    const brandlogo = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 5,
		slidesToScroll: 1,
		autoplay: true, // auto-run the slider
		autoplaySpeed: 4000, // time in milliseconds between each slide
	};

  return (
    <section className="finance-sponsors-section">
    <div className="auto-container">
        <div className="inner-container">
            <div className="sponsors-outer">
                <ul className="">
                <Slider {...brandlogo}>
                    <li className="slide-item"><figure className="image-box"><a href="#"><img src="/assets/images/clients/client1.png" alt="" /></a></figure></li>
                    <li className="slide-item"><figure className="image-box"><a href="#"><img src="/assets/images/clients/Lorven.png" alt="" /></a></figure></li>
                    <li className="slide-item"><figure className="image-box"><a href="#"><img src="/assets/images/clients/sidgs.png" alt="" /></a></figure></li>
                    <li className="slide-item"><figure className="image-box"><a href="#"><img src="/assets/images/clients/yesm.png" alt="" /></a></figure></li>
                    <li className="slide-item"><figure className="image-box"><a href="#"><img src="/assets/images/clients/capg.webp" alt="" /></a></figure></li>
                    <li className="slide-item"><figure className="image-box"><a href="#"><img src="/assets/images/clients/infos.webp" alt="" /></a></figure></li>
                    <li className="slide-item"><figure className="image-box"><a href="#"><img src="/assets/images/clients/acen.webp" alt="" /></a></figure></li>
                    <li className="slide-item"><figure className="image-box"><a href="#"><img src="/assets/images/clients/cog.webp" alt="" /></a></figure></li>
                    <li className="slide-item"><figure className="image-box"><a href="#"><img src="/assets/images/clients/del.webp" alt="" /></a></figure></li>

                </Slider>
                </ul>
            </div>
        </div>
    </div>
</section>
  )
}

export default Bandlogo